import React, { ClipboardEvent, useContext, useState, useEffect } from 'react';
import Form from 'components/ui/Form';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserPassword } from 'services/user-profile/actions';
import { getPasswordUpdateState } from 'services/user-profile';
import {
  ChangePasswordSuccessConfirmation,
  CheckIcon,
  SuccessLabel,
  CustomExpandableCard,
  Container,
} from './styles';
import { ThemeContext } from 'styled-components';
import { PasswordUpdateState } from 'services/user-profile/models';
import FormInput from 'components/ui/v2/Inputs';
import { TranslationKey, useAdminTranslation } from 'hooks/use-translation';
import { Label } from '../ProfileImageSelector/view';

const UpdatePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsAreDifferent, setPasswordsAreDifferent] = useState(false);
  const [currentPasswordIsIncorrect, setCurrentPasswordIsIncorrect] = useState(
    false,
  );
  const [newPasswordIsInvalid, setNewPasswordIsInvalid] = useState(false);
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const [forceToggle, setForceToggle] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { t } = useAdminTranslation();

  const expandableCardProps = {
    disabled: false,
    expandTextKey: 'ADMIN_LABEL_PASSWORD_TOGGLE_BUTTON' as TranslationKey,
    subtitle: `***********`,
  };

  const checkPasswordEquality = (options: {
    confirmPassword: string;
    newPassword: string;
  }) => {
    setPasswordsAreDifferent(
      !!options.newPassword &&
      !!options.confirmPassword &&
      options.newPassword !== options.confirmPassword,
    );
  };

  const checkPasswordValidate = (value: string) => {
    setNewPasswordIsInvalid(value.length < 8);
  };

  const handleChangeCurrentPassword = (value: string) => {
    setCurrentPassword(value);
    setCurrentPasswordIsIncorrect(false);
  };

  const handleChangeNewPassword = (value: string) => {
    setNewPassword(value);
    checkPasswordValidate(value);
    checkPasswordEquality({ newPassword: value, confirmPassword });
  };

  const handleChangeConfirmPassword = (value: string) => {
    setConfirmPassword(value);
    checkPasswordEquality({ newPassword, confirmPassword: value });
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  const handleClickClose = () => {
    setForceToggle(!forceToggle);
  };

  const handleSubmitPassword = () => {
    dispatch(updateUserPassword({
      newPassword,
      oldPassword: currentPassword,
    }));
  };

  const handleCloseResetErrors = () => {
    setPasswordsAreDifferent(false);
    setCurrentPasswordIsIncorrect(false);
};

  const passwordUpdateState = useSelector(getPasswordUpdateState);

  useEffect(() => {
    setDisableSubmit(
      !currentPassword ||
      !newPassword ||
      !confirmPassword ||
      passwordsAreDifferent ||
      currentPasswordIsIncorrect ||
      newPasswordIsInvalid,
    );
  }, [currentPassword, newPassword, confirmPassword]);

  useEffect(() => {
    if (passwordUpdateState === PasswordUpdateState.ERROR) {
      setCurrentPasswordIsIncorrect(true);
    }

    if (passwordUpdateState === PasswordUpdateState.SUCCESS) {
      setForceToggle(!forceToggle);
    }
  }, [passwordUpdateState]);

  return (
    <Container>
      <Label>{t('PASSWORD')}</Label>
      {passwordUpdateState === PasswordUpdateState.SUCCESS && (
        <ChangePasswordSuccessConfirmation>
          <CheckIcon
            admin={true}
            name="circledCheckmark"
            theme={themeContext}
          />{' '}
          <SuccessLabel
            labelKey="PASSWORD_CHANGE_SUCCESS"
            color={themeContext.site.colors.accentPrimary}
            textSize="h6"
          />
        </ChangePasswordSuccessConfirmation>
      )}
      <CustomExpandableCard
        {...expandableCardProps}
        forceToggle={forceToggle}
        onClose={handleCloseResetErrors}
      >
        <Form
          headerKey="ADMIN_LABEL_PASSWORD_TOGGLE_BUTTON"
          submitKey="SUBMIT"
          onSubmit={handleSubmitPassword}
          addCloseButton={true}
          addCloseButtonClick={handleClickClose}
          disabled={disableSubmit}
          loading={passwordUpdateState === PasswordUpdateState.LOADING}
        >
          <FormInput.Root error={currentPasswordIsIncorrect}>
            <FormInput.FieldSet>
              <FormInput.Legend>{t('CURRENT_PASSWORD')}</FormInput.Legend>
              <FormInput.PasswordInput
                autoFocus={true}
                name="currentPassword"
                onChange={handleChangeCurrentPassword}
                value={currentPassword}
              />
            </FormInput.FieldSet>
            {currentPasswordIsIncorrect && <FormInput.SupportingText>{t('INVALID_CURRENT_PASSWORD')}</FormInput.SupportingText>}
          </FormInput.Root>

          <FormInput.Root>
            <FormInput.FieldSet>
              <FormInput.Legend>{t('NEW_PASSWORD')}</FormInput.Legend>
              <FormInput.PasswordInput
                autoFocus={true}
                name="password"
                onChange={handleChangeNewPassword}
                value={newPassword}
                onPaste={handlePaste}
              />
            </FormInput.FieldSet>
            <FormInput.SupportingText>{t('INVALID_LENGTH_PASSWORD')}</FormInput.SupportingText>
          </FormInput.Root>

          <FormInput.Root error={passwordsAreDifferent}>
            <FormInput.FieldSet>
              <FormInput.Legend>{t('CONFIRM_PASSWORD')}</FormInput.Legend>
              <FormInput.PasswordInput
                autoFocus={true}
                name="password"
                onChange={handleChangeConfirmPassword}
                value={confirmPassword}
                onPaste={handlePaste}
              />
            </FormInput.FieldSet>
            <FormInput.SupportingText role="">{t('PASSWORD_MUST_MATCH')}</FormInput.SupportingText>
            {passwordsAreDifferent && <FormInput.SupportingText>{t('PASSWORD_MISMATCH')}</FormInput.SupportingText>}
          </FormInput.Root>
        </Form>
      </CustomExpandableCard>
    </Container>
  );
};

export default UpdatePassword;

import { connect } from 'react-redux';
import { saveSiteSettings, settingsTypes } from 'services/admin';
import { getSiteId, getSiteProfileImages } from 'services/app/selectors';
import { isUserAdmin, isUserChatModeratorRole } from 'services/auth';
import UserProfilesTab from './view';

const mapStateToProps = state => ({
  currentProfileImages: getSiteProfileImages(state),
  isChatModerator: isUserChatModeratorRole(state),
  isUserAdmin: isUserAdmin(state),
  siteId: getSiteId(state),
});

const mapDispatchToProps = dispatch => ({
  handleSave: data => dispatch(
    saveSiteSettings(settingsTypes.USER_PROFILES, data),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfilesTab);

import { connect } from 'react-redux';
import { removeOverlay } from 'services/overlays';
import { trackOverlay } from 'services/insights';
import { isVideoOnlyMode } from 'services/user-layout';
import { isUserAdmin } from 'services/auth';
import LowerThirdOverlay from './view';

const mapStateToProps = state => ({
  isAdmin: isUserAdmin(state),
  isVideoOnlyMode: isVideoOnlyMode(state),
});

const mapDispatchToProps = dispatch => ({
  onDismiss: key => dispatch(removeOverlay(key)),
  trackDismiss: doc => dispatch(trackOverlay('dismiss', doc)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LowerThirdOverlay);

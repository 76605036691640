import styled, { css } from 'styled-components';
import UploadZone from 'components/admin2/UploadZone';
import { ADMIN_SURFACE_3, ADMIN_SURFACE_6, ADMIN_TEXT_100, ADMIN_TEXT_300 } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_MEDIUM, ADMIN_TEXT_TITLE_S } from 'style/design-system/textStyles';
import { SINGLE_LINE_ELLIPSIS } from 'style/mixins';
import Label from 'components/admin2/ui/Label';

export const BlockPanelSettingsWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const BlockPanelSettingsTitle = styled.span`
  ${ADMIN_TEXT_TITLE_S}
  color: ${ADMIN_TEXT_100};
`;

export const StyledLabel = styled(Label)`
  margin-bottom: 16px;
`;

export const UploadZoneWrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

export const DesktopBackgroundUploadZone = styled(UploadZone).attrs(() => ({
  canDrop: true,
  dimensionRequirements: {
    pixelHeight: 1080,
    pixelWidth: 1920,
  },
  height: 177,
  padding: '0',
}))``;

export const MobileBackgroundUploadZone = styled(UploadZone).attrs(() => ({
  canDrop: true,
  dimensionRequirements: {
    pixelHeight: 1200,
    pixelWidth: 750,
  },
  height: 177,
  padding: '0',
}))``;

export const OptionsToggle = styled.div`
  display: flex;
  border-radius: 6px;
  height: 44.48px;
  background-color: ${ADMIN_SURFACE_3};
  contain: content;
`;

export const OptionToggle = styled.button<{ active: boolean; }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 20px 10px 20px;
  ${ADMIN_TEXT_LABEL_M_MEDIUM}
  color: ${ADMIN_TEXT_300};
  ${SINGLE_LINE_ELLIPSIS}

  ${({ active }) => active && css`
    color: ${ADMIN_TEXT_100};
    background-color: ${ADMIN_SURFACE_6};
  `};

  &:hover {
    color: ${ADMIN_TEXT_100};
    background-color: ${ADMIN_SURFACE_6};
  }
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

/* eslint react/destructuring-assignment: off */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import OverlayCta from 'components/overlays/OverlayCta';
import OverlayHeader from '../OverlayHeader';

export const ContainerFullScreen = styled.div`
  width: auto;
`;

const StyledClickableImage = styled.img`
  display: inline-block;
  max-height: 360px;
  max-width: 100%;
  vertical-align: bottom;
  cursor: pointer;
  margin-top: 7px;
`;

export default class LowerThirdOverlay extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      key: PropTypes.string.isRequired,
      payload: PropTypes.shape({
        data: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isVideoOnlyMode: PropTypes.bool.isRequired,
    onDismiss: PropTypes.func.isRequired,
    overlayType: PropTypes.string.isRequired,
    renderer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    trackDismiss: PropTypes.func.isRequired,
  };

  state = { engaged: 0 };

  dismissOverlay = () => {
    const { data, onDismiss } = this.props;
    const { key } = data;
    onDismiss(key);
  };

  onValue = (doc) => {
    const { engaged } = doc?.data?.state || {};
    const { engaged: oldEngaged } = this.state;

    if (oldEngaged !== engaged) {
      this.setState({
        engaged,
      });
    }
  };

  render() {
    const {
      data: {
        key,
        payload: {
          data: { broadcastId, views },
        },
      },
      renderer: {
        ctaRenderer,
        imageUrl,
        overlayId,
      },
      isAdmin,
      isVideoOnlyMode,
      onDismiss,
      trackDismiss,
    } = this.props;

    const hasCta = Boolean(ctaRenderer);

    const message = hasCta ? ctaRenderer.message : '';

    if (isVideoOnlyMode) {
      return (
        <ContainerFullScreen className={this.props.overlayType}>
          <StyledClickableImage src={imageUrl} />
        </ContainerFullScreen>
      );
    }

    if (!imageUrl) {
      return null;
    }
    const showPreview = key?.startsWith('preview');

    return (
      <ContainerFullScreen className={this.props.overlayType}>
        <OverlayHeader
          broadcastId={broadcastId}
          data={this.props.data}
          defaultState={{ engaged: 0 }}
          isAdmin={isAdmin}
          onDismiss={onDismiss}
          overlayType="lower_third"
          showPreview={showPreview}
          trackDismiss={trackDismiss}
          views={views}
        />
        <OverlayCta
          broadcastId={broadcastId}
          broadcastKey={key}
          ctaRenderer={ctaRenderer}
          message={message}
          overlayId={overlayId}
          rawData={this.props.data}
        >
          <StyledClickableImage src={imageUrl} />
        </OverlayCta>
      </ContainerFullScreen>
    );
  }
}

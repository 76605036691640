import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PageLink from 'components/ui/PageLink';
import { BACKGROUND_ACTIVE, TITLE_FONT } from 'style/mixins';
import { withEndUserT, DynamicTranslationType } from 'hooks/use-translation';

const FlexWrapper = styled.div`
  ${BACKGROUND_ACTIVE}
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px;
  ${TITLE_FONT}
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
`;

class GoToChannelOverlayCta extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    ctaRenderer: PropTypes.shape({
      channelSlug: PropTypes.string,
    }).isRequired,
    endUserT: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
    onEngagedOverlay: PropTypes.func.isRequired,
    testId: PropTypes.string,
  };

  static defaultProps = {
    children: undefined,
    testId: undefined,
  };

  handleOnClick = () => {
    const { onDismiss, onEngagedOverlay } = this.props;

    onEngagedOverlay();
    onDismiss();
  };

  render() {
    const {
      children,
      ctaRenderer: { channelSlug: slug },
      endUserT,
      testId,
    } = this.props;

    // TODO: probably don't need this logic, can just render the ObjectLink
    const LinkComponent = slug ? PageLink : 'div';
    const linkProps = slug ? { onClick: this.handleOnClick, plain: true, slug } : {};

    // TODO: Tokenize translation text
    return (
      <LinkComponent data-testid={testId} {...linkProps}>
        {
          children || (
            <FlexWrapper>
              <span>
                {
                  endUserT(
                    [DynamicTranslationType.overlayCtaGoToChannel, { variables: { slug } }],
                    ['GO_TO_CHANNEL_SLUG', { slug }],
                  )
                }
              </span>
            </FlexWrapper>
          )
        }
      </LinkComponent>
    );
  }
}

export default withEndUserT(GoToChannelOverlayCta);

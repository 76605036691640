import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
  RefObject,
  memo,
} from 'react';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import IframeMemoized from 'components/core/IframeMemoized';
import InsightsViewable from 'components/insights/InsightsViewable';
import { useSelector } from 'react-redux';
import { getUserAccount } from 'services/auth';
import { getSite, getPageId } from 'services/app/selectors';
import { getAttrsFromIframeTag, addSearchParamsToUrl } from './helper';
import hash from 'json-stable-stringify';

type IProps = {
  className?: string;
  doc: any;
  height?: number;
  hidden?: boolean;
  renderer: {
    id: string;
    iframeTag: string;
    url: string;
  };
  width?: number;
};

function IFramePanel({ className, doc, height, hidden, renderer, width }: IProps) {
  const account = useSelector(getUserAccount);
  const site = useSelector(getSite);
  const channelId = useSelector(getPageId);
  const [loading, setLoading] = useState<boolean>(true);
  const iframeRef = useRef<HTMLIFrameElement>();
  const onLoad = useCallback(() => setLoading(false), []);

  const { id: panelId, iframeTag, url } = renderer;

  useEffect(() => {
    if (!loading && iframeRef.current) {
      iframeRef.current?.contentWindow?.postMessage(
        {
          account: {
            _id: account?._id,
          },
          channel: { _id: channelId },
          panelId: { _id: panelId },
          site: {
            style: site.style,
            slug: site.slug,
            _id: site._id,
            settings: { domain: site.settings.domain },
          },
          location: window.location.href,
        },
        '*',
      );
    }
  }, [loading, hash(account), panelId, channelId, hash(site)]);

  const isIframeTag = useMemo(() => {
    return (iframeTag && iframeTag.trim() && iframeTag.length > 0);
  }, [iframeTag]);

  const iframeAttrs = useMemo(() => {
    if (isIframeTag) {
      const attrs = getAttrsFromIframeTag(iframeTag);
      attrs.src = addSearchParamsToUrl(attrs.src, {
        accountId: account?._id,
        channelId,
        panelId,
        siteId: site?._id,
      });
      return attrs;
    }
    return null;
  }, [hash(account), isIframeTag, iframeTag, panelId, channelId, hash(site)]);

  useEffect(() => {
    setLoading(true);
  }, [hash(account)]);

  return (
    <>
      <InsightsViewable
        doc={doc}
        extra={{ url }}
        kingdom="panel"
        visible={!hidden}
      />
      {loading && <LoadingSpinner />}
      {isIframeTag ? (
        <IframeMemoized
          ref={iframeRef as RefObject<HTMLIFrameElement>}
          onLoad={onLoad}
          {...iframeAttrs}
          style={loading ? { display: 'none' } : {}}
        />
      ) : (
          <IframeMemoized
            className={className}
            height={height}
            loading={loading}
            onLoad={onLoad}
            src={url}
            title={url}
            width={width}
          />
        )}
    </>
  );
}

export default memo(IFramePanel);

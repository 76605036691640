import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';
import Dropdown from 'components/admin2/ui/Dropdown';
import { THEME_PADDING } from 'style/mixins';
import styled, { css } from 'styled-components';
import { OPTIONAL_DYNAMIC_THEME_COLOR } from 'style/constants';
import { IBlockRegion } from 'services/app/models/ILandingPageContent';

export const StyledQuickEditOverlay = styled(QuickEditOverlay)<{ backgroundColor: string; region: IBlockRegion; }>`
  ${THEME_PADDING}
  background-color: ${({ backgroundColor }) => OPTIONAL_DYNAMIC_THEME_COLOR(backgroundColor)};
  gap: 50px;

  ${({ region }) => region === 'panel' && css`
    padding: 12px;
    padding-top: 30px;
    overflow-x: hidden;
    flex: 1;
  `};
`;

export const BackgroundImage = styled.img`
  inset: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StyledDropdown = styled(Dropdown)<{ isPanel?: boolean; }>`
  height: 44.48px;
  min-width: 200px;

  & .dropdown-button {
    height: 44.48px;
    min-height: unset;
  }

  & .dropdown-menu {
    ${({ isPanel }) => !isPanel && css`
      width: auto;
      min-width: 100%;
    `};
  }
`;

import { ACCENT_PRIMARY } from 'style/constants';
import {
  FLEX_CENTER,
  SINGLE_LINE_ELLIPSIS,
  mobileOnly,
} from 'style/mixins';

import { ProfilePanelButton } from 'components/ui/Button/styles';

import styled, { css } from 'styled-components';
import { ADMIN_TEXT_BODY_XS_MEDIUM, ADMIN_TEXT_TITLE_M } from 'style/design-system/textStyles';
import { MOBILE_TAB_BAR_HEIGHT } from 'components/mobile/MobileTabBar/styles';

export const EditProfileButton = styled(ProfilePanelButton as any).attrs({
  'data-testid': 'upEditButton',
})``;

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  flex-shrink: 0;
  overflow-y: auto;
  height: 100%;
  ${mobileOnly`padding-bottom: ${MOBILE_TAB_BAR_HEIGHT}px;`}
`;

export const ProfileContainer = styled.div`
  ${FLEX_CENTER}
  flex-direction: column;
  padding: 20px 15px 0px 15px;
  ${(props) =>
    css`
      background: linear-gradient(180deg,
      ${ACCENT_PRIMARY(props)}50 0%,
      ${ACCENT_PRIMARY(props)}10 20%,
      transparent 70%);
    `};
  flex-shrink: 0;
`;

export const PictureWrapper = styled.div`
  ${FLEX_CENTER}
  width: 100%;
  margin-top: 20px;
  flex-shrink: 0;
`;

export const Picture = styled.div<{ src: string }>`
  background: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

export const Name = styled.div`
  ${ADMIN_TEXT_TITLE_M}
  margin-top: 10px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
  width: 100%;
  word-break: break-all;
  ${SINGLE_LINE_ELLIPSIS}
`;

export const MemberDateText = styled.div`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  margin-top: 2px;
  opacity: 0.7;
`;

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { rgba } from 'colors';
import TranslatedText from 'components/i18n/TranslatedText';
import CloseButton from 'components/ui/CloseButton';
import { SURFACE_3, SPACING_SMALL, TEXT_100 } from 'style/constants';
import { BACKGROUND_ACTIVE, PADDING_SMALL } from 'style/mixins';

const Container = styled.div`
  align-items: stretch;
  background-color: ${props => rgba(SURFACE_3(props), 0.9)};
  color: ${TEXT_100};
  display: flex;
  flex-flow: row nowrap;
  & > :not(:first-child) {
    margin-left: ${SPACING_SMALL};
  }
`;

const LeftSidebar = styled.div`
  padding: ${SPACING_SMALL};
  padding-right: 0;
`;

const Message = styled.div`
  flex: 1 1 auto;
  padding: ${SPACING_SMALL} 0;
`;

const EnterButton = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  ${BACKGROUND_ACTIVE};
  padding: ${SPACING_SMALL};
`;

const StatusMessage = styled.div`
  color: ${props => rgba(TEXT_100(props), 0.9)};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  font-style: italic;
  padding: ${SPACING_SMALL} 0;
`;

const Countdown = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  ${PADDING_SMALL};
  padding-left: 0;
`;

export default class RaffleActiveOverlay extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      key: PropTypes.string.isRequired,
      payload: PropTypes.shape({
        data: PropTypes.shape({
          broadcastId: PropTypes.string,
        }).isRequired,
      }).isRequired,
      raffleStatus: PropTypes.string,
      timeRemaining: PropTypes.number.isRequired,
    }).isRequired,
    loggedIn: PropTypes.bool.isRequired,
    onDismiss: PropTypes.func.isRequired,
    onEnterClick: PropTypes.func.isRequired,
    overlayType: PropTypes.string.isRequired,
    trackDismiss: PropTypes.func.isRequired,
    trackEngage: PropTypes.func.isRequired,
  };

  enterRaffle = () => {
    const { onEnterClick, data, trackEngage } = this.props;
    onEnterClick(data.payload.data.broadcastId);
    trackEngage(data, { action: 'submit_entry' });
  };

  dismissRaffle = () => {
    const { onDismiss, trackDismiss, data } = this.props;
    onDismiss(data.key);
    trackDismiss(data);
  };

  render() {
    const {
      data: { raffleStatus, timeRemaining = 45 },
      loggedIn,
    } = this.props;

    const stringKey = loggedIn ? 'RAFFLE_ACTIVE_LOGGED_IN' : 'RAFFLE_ACTIVE_LOGGED_OUT';
    const { overlayType } = this.props;
    return (
      <Container className={overlayType}>
        <LeftSidebar>
          <CloseButton onClick={this.dismissRaffle} />
        </LeftSidebar>
        <Message>
          <TranslatedText stringKey={stringKey} />
        </Message>
        {
          loggedIn && !raffleStatus && (
            <EnterButton onClick={this.enterRaffle}>
              <TranslatedText stringKey="ACTION_ENTER_RAFFLE" />
            </EnterButton>
          )
        }
        {
          raffleStatus === 'request' && (
            <StatusMessage>
              <TranslatedText stringKey="RAFFLE_ENTRY_SUBMITTING" />
            </StatusMessage>
          )
        }
        {
          raffleStatus === 'success' && (
            <StatusMessage>
              <TranslatedText stringKey="RAFFLE_ENTRY_SUCCESS" />
            </StatusMessage>
          )
        }
        {
          raffleStatus === 'failure' && (
            <StatusMessage>
              <TranslatedText stringKey="RAFFLE_ENTRY_FAILURE" />
            </StatusMessage>
          )
        }
        <Countdown>{ timeRemaining }</Countdown>
      </Container>
    );
  }
}

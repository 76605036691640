import styled, { css } from 'styled-components';
import { MAX_HERO_BANNER_BLEED_PX, MIN_HERO_BANNER_HEIGHT_PX, SURFACE_1 } from 'style/constants';
import { supportsMaskImageCSS } from 'utils';
import { ABSOLUTE_CENTER, IImageProps } from '../styles';

export const Image = styled.img<IImageProps>`
  aspect-ratio: 1920 / ${({ bleed }) => bleed ? (MIN_HERO_BANNER_HEIGHT_PX + MAX_HERO_BANNER_BLEED_PX) : MIN_HERO_BANNER_HEIGHT_PX};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  ${({ bleed }) => bleed && supportsMaskImageCSS && css`
    mask-image: linear-gradient(rgb(0 0 0) 60%, rgba(0, 0, 0, 0));
  `};

  @media (max-width: 1919px) {
    ${({ hasContentOnHeroBanner }) => hasContentOnHeroBanner && css`
        min-height: ${MIN_HERO_BANNER_HEIGHT_PX}px;
        object-fit: none;
    `};
  }
`;

export const GradientLayer = styled.div<{ imageHeight: number; }>`
  ${ABSOLUTE_CENTER};
  height: ${({ imageHeight }) => imageHeight}px;
  width: 100%;
  opacity: 0.8;
  background: linear-gradient(90deg, ${SURFACE_1} 0%, rgba(0,0,0,0) 100%);
  ${() => supportsMaskImageCSS && css`mask-image: linear-gradient(rgb(0, 0, 0) 50%, rgba(0,0,0,0))`};
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TwitterShareButton } from 'react-share';
import memoizeOne from 'memoize-one';
import { getReferralUrl } from 'services/insights';
import { withEndUserT, DynamicTranslationType } from 'hooks/use-translation';
import Icon from 'components/ui/Icon';
import { TWITTER_BLUE, TEXT_100 } from 'style/constants';
import { TITLE_FONT } from 'style/mixins';

const FlexWrapper = styled.div`
  background-color: ${TWITTER_BLUE};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  ${TITLE_FONT}
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${TEXT_100};
`;

const PaddedIcon = styled(Icon)`
  padding: 5px;
`;

const ShareText = styled.div`
  padding-right: 5px;
`;

const Wrapper = styled.div``;

const memoizeSetUrl = memoizeOne(getReferralUrl);

class TwitterShareOverlayCta extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    ctaRenderer: PropTypes.shape({
      tweetMessage: PropTypes.string,
    }).isRequired,
    endUserT: PropTypes.func.isRequired,
    onEngagedOverlay: PropTypes.func.isRequired,
    referralId: PropTypes.string.isRequired,
    testId: PropTypes.string,
  };

  static defaultProps = {
    children: undefined,
    testId: undefined,
  };

  onBeforeShare = async () => {
    const { onEngagedOverlay } = this.props;
    onEngagedOverlay();
  };

  render() {
    const { referralId, children, ctaRenderer: { tweetMessage }, endUserT, testId } = this.props;
    const referralUrl = memoizeSetUrl(referralId);

    return (
      <TwitterShareButton
        beforeOnClick={this.onBeforeShare}
        title={tweetMessage}
        url={referralUrl}
      >
        <Wrapper data-testid={testId}>
          {children || (
            <FlexWrapper>
              <PaddedIcon name="twitter" />
              <ShareText>
                {
                  endUserT(
                    [DynamicTranslationType.overlayCtaTweet],
                    ['TWEET'],
                  )
                }
              </ShareText>
            </FlexWrapper>
          )}
        </Wrapper>
      </TwitterShareButton>
    );
  }
}

export default withEndUserT(TwitterShareOverlayCta);

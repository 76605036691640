import React, { memo } from 'react';
import { IQuestsBlockData } from './utils';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';
import { BlurLayer, GradientLayer, Image, OpacityLayer } from './styles';

type BackgroundImageProps = {
  background: IQuestsBlockData['background'];
};

const BackgroundImage = ({ background }: BackgroundImageProps) => {
  const isMobile = useSelector(isMobileLayout);

  const imageBackground = React.useMemo(() => {
    if (background.type !== 'image') {
      return null;
    }

    if (isMobile && background.image.mobile.url) {
      return background.image.mobile;
    }

    if (!isMobile && background.image.desktop.url) {
      return background.image.desktop;
    }

    return null;
  }, [
    isMobile,
    background.image.mobile,
    background.image.desktop,
    background.type,
  ]);

  if (!imageBackground) {
    return null;
  }

  return (
    <>
      <Image
        src={imageBackground.url}
        alt="Quests Block Background Image"
        display={imageBackground.display}
        position={imageBackground.position}
        loading="lazy"
      />
      <OpacityLayer backgroundColor={background.color} overlayOpacity={imageBackground.overlayOpacity} />
      {background.image.gradientMask === 'gradient' && <GradientLayer backgroundColor={background.color} />}
      {background.image.gradientMask === 'blur' && <BlurLayer />}
    </>
  );
};

export default memo(BackgroundImage);

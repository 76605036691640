import { connect } from 'react-redux';
import { trackOverlay } from 'services/insights';
import { getUserId } from 'services/auth';
import { removeOverlay } from 'services/overlays';
import RaffleWinnerOverlay from './RaffleWinnerOverlay';

const mapStateToProps = state => ({
  userId: getUserId(state) || null,
});

const mapDispatchToProps = dispatch => ({
  onDismiss: key => dispatch(removeOverlay(key)),
  trackDismiss: doc => dispatch(trackOverlay('dismiss', doc)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RaffleWinnerOverlay);

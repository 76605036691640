import styled from 'styled-components';
import { ADMIN_TEXT_200 } from 'style/constants';
import { ADMIN_TEXT_TITLE_S } from 'style/design-system/textStyles';

export const SubSectionTitle = styled.div`
  ${ADMIN_TEXT_TITLE_S};
  color: ${ADMIN_TEXT_200};
  text-transform: capitalize;
`;

export const Section = styled.div<{gap: number}>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap}px;
`;

import styled from 'styled-components';
import { ACCENT_PRIMARY, SURFACE_3, TEXT_100 } from 'style/constants';
import { MULTI_LINE_ELLIPSIS, TEXT_ELLIPSIS, mobileOnly } from 'style/mixins';
import { TEXT_BODY_L_MEDIUM, TEXT_BODY_S_MEDIUM, TEXT_BODY_XL_MEDIUM } from 'style/design-system/textStyles';
import Button from 'components/ui/Button';

export const StyledClickableImage = styled.img`
  display: inline-block;
  max-height: 360px;
  max-width: 100%;
  vertical-align: bottom;
  cursor: pointer;
`;

export const Container = styled.div`
  max-width: 545px;
  width: 100%;
  min-width: 395px;
  ${mobileOnly`
    min-width: auto;
    max-width: 250px;
  `}
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
	${mobileOnly`
    min-width: auto;
    max-width: 195px;
	`}
`;

export const ProductLabel = styled.div`
	${TEXT_BODY_XL_MEDIUM}
  padding: 20px 20px 27px 20px;
  height: 91px;
  background-color: ${SURFACE_3};
  color: ${TEXT_100};
  margin-bottom: 5px;
  border-radius: 4px;

  ${mobileOnly`
    height: 30px;
    padding: 4px 0px 0px 6px;
    ${TEXT_BODY_S_MEDIUM}
  `}
`;

export const EllipsisContainer = styled.div<{ isMobile: boolean }>`
  ${({ isMobile }) => isMobile ? TEXT_ELLIPSIS : MULTI_LINE_ELLIPSIS }
`;

export const PriceContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 53px;
  color: ${TEXT_100};
  justify-content: space-between;
  border-radius: 4px;
  align-items: center;
  border: 1px solid ${ACCENT_PRIMARY};
  ${mobileOnly`
    padding: 1px 0px 0px 2.5px;
    height: 25px;
  `}
`;

export const BackgroundColorContent = styled.div`
  background: ${SURFACE_3};
  opacity: 0.6;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
`;

export const StyledPrice = styled.div`
  ${TEXT_BODY_XL_MEDIUM}
  padding-left: 20px;
  font-style: normal;
  white-space: nowrap;
  ${mobileOnly`
    padding: 0 2px;
    ${TEXT_BODY_S_MEDIUM}
    font-size: 9px;
  `}
`;

export const BuyButton = styled(Button)`
  ${TEXT_BODY_L_MEDIUM}
  padding: 13px 20px;
  width: 118px;
  height: 40px;
  margin-right: 5px;
  ${mobileOnly`
    ${TEXT_BODY_S_MEDIUM}
    height: 20px;
    width: 57px;
    padding: 0px;
    margin-right: 2.5px;
  `}
`;

export const ProductImage = styled.img<{ disable: boolean}>`
  border-radius: 6px;
  height: 149px;
  margin-right: 5px;
  object-fit: cover;
  width: 144px;
  ${({ disable }) => disable ? 'display: none' : null};
  ${mobileOnly`
    width: 50px;
    height: 60px;
  `}
`;

export const ShopifyContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 9px;
  ${mobileOnly`
    margin: 2px;
  `}
`;
import React from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { IVideoSpotlight } from '../utils';
import { Section, SectionTitle } from 'components/admin-bridge/EditorModal/ModalComponents/Section';
import {
  UploadZoneWrapper,
  DesktopBackgroundUploadZone,
  MobileBackgroundUploadZone,
  StyledLabel,
  ToggleWrapper,
} from './styles';
import { OptionRow } from 'components/admin-bridge/EditorModal';
import { LAYOUT_WITH_VIDEO_OPTIONS_ROW, TEXT_ALIGNMENT_OPTIONS } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow/types';
import ToggleSwitch from 'components/admin2/ui/ToggleSwitch';
import { Checkbox } from 'components/admin2/ui/Checkbox';
import useEditPageBlock from 'hooks/use-page-block';
import BackgroundColorPicker from 'components/admin-bridge/EditorModal/ModalComponents/BackgroundColorOptions';
import usePageBlockBackgroundColorOptions from 'components/admin-bridge/EditorModal/ModalComponents/BackgroundColorOptions/use-page-block-background-color-options';
import Dropdown from 'components/admin2/ui/Dropdown';
import { injectT, useAdminTranslation } from 'hooks/use-translation';
import { lazy } from 'utils/lazy';
import PageBlockSettings from 'components/admin-bridge/PageBlockSettings';

interface IBlockSettingsProps {
  isOpen: boolean;
  item: IVideoSpotlight;
  onChange: (item: IVideoSpotlight) => void;
  onClose: () => void;
}

type EditableStringPaths = (
  'title.show' |
  'description.show' |
  'tags.show' |
  'uploadDate.show' |
  'playerBehavior' |
  'layout' |
  'alignment' |
  'background.image.mobile' |
  'background.image.desktop' |
  'background.customColor' |
  'background.color' |
  'background.image.show'
);

const LABEL_MAP = injectT(
  (t) => lazy(
    () => ({
      inline: t('ADMIN_VIDEO_SPOTLIGHT_IN_LINE'),
      videoPage: t('ADMIN_VIDEO_SPOTLIGHT_VIDEO_PAGE'),
    }),
  ),
);

const PLAYER_BEHAVIOR_OPTIONS = injectT(
  (t) => lazy<{ label: string; value: IVideoSpotlight['playerBehavior']; }[]>(
    () => [
      {
        label: LABEL_MAP(t).inline,
        value: 'inline',
      },
      {
        label: LABEL_MAP(t).videoPage,
        value: 'videoPage',
      },
    ],
  ),
);

const BlockSettings = ({
  item,
  isOpen,
  onClose,
  onChange,
}: IBlockSettingsProps) => {
  const { t } = useAdminTranslation();
  const { isCustomColor } = usePageBlockBackgroundColorOptions(item.background.color);
  const { handleChange } = useEditPageBlock<EditableStringPaths, IVideoSpotlight>({
    item,
    onChange,
  });

  const handleClearDesktopBackground = React.useCallback(() => {
    handleChange('background.image.desktop')('');
  }, [handleChange]);

  const handleClearMobileBackground = React.useCallback(() => {
    handleChange('background.image.mobile')('');
  }, [handleChange]);

  const handleChangeBackgroundColor = React.useCallback((value: string) => {
    const newItem: IVideoSpotlight = structuredClone(item);

    newItem.background.color = value;
    if (isCustomColor(value)) {
      newItem.background.customColor = value;
    }

    onChange(newItem);
  }, [item, onChange, isCustomColor]);

  return (
    <PageBlockSettings
      isOpen={isOpen}
      titleKey="ADMIN_LABEL_VIDEO_SPOTLIGHT_SETTINGS"
      onClose={onClose}
    >
      <Section gap={30}>
        <Section gap={16}>
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_DISPLAY" />
          <Section gap={15}>
            <Checkbox
              checked={item.title.show}
              onCheckedChange={handleChange('title.show')}
              labelKey="ADMIN_LABEL_TITLE"
            />
            <Checkbox
              checked={item.description.show}
              labelKey="ADMIN_LABEL_DESCRIPTION"
              onCheckedChange={handleChange('description.show')}
            />
            <Checkbox
              checked={item.tags.show}
              labelKey="ADMIN_LABEL_TAGS"
              onCheckedChange={handleChange('tags.show')}
            />
            <Checkbox
              checked={item.uploadDate.show}
              labelKey="ADMIN_LABEL_UPLOAD_DATE"
              onCheckedChange={handleChange('uploadDate.show')}
            />
          </Section>
        </Section>
        <Section gap={16}>
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_PLAYER_BEHAVIOR" />
          <Dropdown
            onChange={handleChange('playerBehavior')}
            options={PLAYER_BEHAVIOR_OPTIONS(t)}
            value={{ value: item.playerBehavior, label: LABEL_MAP(t)[item.playerBehavior] }}
            padding="0"
            admin={true}
            isSearchable={false}
          />
        </Section>
        <Section gap={16}>
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_LAYOUT" />
          <OptionRow
            options={LAYOUT_WITH_VIDEO_OPTIONS_ROW}
            value={item.layout}
            onChange={handleChange('layout')}
            wrapText={true}
          />
        </Section>
        <Section gap={16}>
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_TEXT_ALIGNMENT" />
          <OptionRow
            options={TEXT_ALIGNMENT_OPTIONS}
            value={item.alignment}
            onChange={handleChange('alignment')}
          />
        </Section>
        <Section gap={20}>
          <ToggleWrapper>
            <TranslatedText component={SectionTitle} stringKey="ADMIN_SETTINGS_DESIGN_PAGE_BACKGROUND_IMAGE" />
            <ToggleSwitch checked={item.background.image.show} onChange={handleChange('background.image.show')} />
          </ToggleWrapper>
          {
            item.background.image.show && (
              <>
                <UploadZoneWrapper>
                  <StyledLabel padding="0" labelKey="ADMIN_LABEL_DESKTOP" />
                  <DesktopBackgroundUploadZone
                    imagePreview={item.background.image.desktop}
                    onClearImage={handleClearDesktopBackground}
                    onFileSubmit={handleChange('background.image.desktop')}
                  />
                </UploadZoneWrapper>
                <UploadZoneWrapper>
                  <StyledLabel padding="0" labelKey="ADMIN_LABEL_MOBILE" />
                  <MobileBackgroundUploadZone
                    imagePreview={item.background.image.mobile}
                    onClearImage={handleClearMobileBackground}
                    onFileSubmit={handleChange('background.image.mobile')}
                  />
                </UploadZoneWrapper>
              </>
            )
          }
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_BACKGROUND_COLOR" />
          <BackgroundColorPicker
            lastCustomColor={item.background.customColor}
            color={item.background.color}
            onChange={handleChangeBackgroundColor}
          />
        </Section>
      </Section>
    </PageBlockSettings>
  );
};

export default BlockSettings;

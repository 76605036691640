import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getMoneyFormat } from 'services/shopify';
import styled from 'styled-components';

interface PriceProps {
  fontSize?: string;
  isUSD?: boolean;
  keepDecimalsFontSize?: boolean;
  price: string;
}

interface IStyleProps {
  fontSize?: string;
  isSymbolOnTheLeftSide: boolean;
  keepDecimalsFontSize?: boolean;
}

const PriceContent = styled.div<IStyleProps>`
  display: flex;
  font-size: ${props => props.fontSize ? props.fontSize : '16px' };
  flex-direction: ${props => props.isSymbolOnTheLeftSide ? 'row' : 'row-reverse'};
`;

const CurrencySymbol = styled.span`
  margin-right: 2px;
`;

const Amount = styled.span<IStyleProps>`
  ${props => !props.isSymbolOnTheLeftSide && 'margin-right: 3px;'}
`;

const Decimals = styled.label<IStyleProps>`
  ${props => props.isSymbolOnTheLeftSide && !props.keepDecimalsFontSize && 'font-size: 12px;'}
`;

export default function Price({ keepDecimalsFontSize, price, isUSD = false, fontSize, ...rest }: PriceProps) {
  const [showDecimals, setShowDecimals] = useState(true);
  const [priceWithoutDecimals, setPriceWithoutDecimals] = useState('');
  const [decimals, setDecimals] = useState('');
  const [decimalSeparator, setDecimalSeparator] = useState('.');
  const [roundedPrice, setRoundedPrice] = useState('');
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [isSymbolOnTheLeftSide, setIsSymbolOnTheLeftSide] = useState(true);
  const moneyFormat = useSelector(getMoneyFormat);

  useEffect(() => {
    const format = moneyFormat.substring(moneyFormat.indexOf('{{') + 2, moneyFormat.indexOf('}}'));
    let thousandSeparator = ',';

    const symbolOnTheLeft = moneyFormat.split('{{')[0];
    const symbolOnTheRight = moneyFormat.split('}}')[1];

    if (symbolOnTheLeft) {
      setCurrencySymbol(symbolOnTheLeft);
      setIsSymbolOnTheLeftSide(true);
    } else if (symbolOnTheRight) {
      setCurrencySymbol(symbolOnTheRight);
      setIsSymbolOnTheLeftSide(false);
    } else {
      setCurrencySymbol('$');
      setIsSymbolOnTheLeftSide(true);
    }

    if (format.indexOf('with_comma_separator') !== -1) {
      thousandSeparator = '.';
      setDecimalSeparator(',');
    }

    if (format.indexOf('with_apostrophe_separator') !== -1) {
      thousandSeparator = '\'';
      setDecimalSeparator('.');
    }

    if (format.indexOf('no_decimals') !== -1) {
      const roundedPriceStr = String(Math.round(Number(price)));
      setRoundedPrice(roundedPriceStr.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator));
      setShowDecimals(false);
      return;
    }

    const priceNumberWithoutDecimals = parseInt(price, 10);
    setPriceWithoutDecimals(priceNumberWithoutDecimals.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)); // eslint-disable-line max-len
    setDecimals((Number(price) % 1).toFixed(2).substr(2));
  }, [moneyFormat, price]);

  return (
    <PriceContent isSymbolOnTheLeftSide={isSymbolOnTheLeftSide} fontSize={fontSize} {...rest}>
      <CurrencySymbol>
        {currencySymbol}
      </CurrencySymbol>
      <Amount isSymbolOnTheLeftSide={isSymbolOnTheLeftSide}>
        {showDecimals ? (
          <>
            {priceWithoutDecimals}
            {decimalSeparator}
            <Decimals isSymbolOnTheLeftSide={isSymbolOnTheLeftSide} keepDecimalsFontSize={keepDecimalsFontSize}> {/*eslint-disable-line max-len*/}
              {decimals}
            </Decimals>
            {isUSD ? ` USD` : null}
          </>
          ) : (
          <>
            {roundedPrice}
          </>
          )}
      </Amount>
    </PriceContent>
  );
}

import { connect } from 'react-redux';
import { trackOverlay } from 'services/insights';
import { enterRaffle, removeOverlay } from 'services/overlays';
import { isLoggedIn } from 'services/auth';

import RaffleActiveOverlay from './RaffleActiveOverlay';

const mapStateToProps = state => ({
  loggedIn: isLoggedIn(state),
});

const mapDispatchToProps = dispatch => ({
  onDismiss: key => dispatch(removeOverlay(key)),
  onEnterClick: (broadcastId) => {
    dispatch(enterRaffle(broadcastId));
  },
  trackDismiss: doc => dispatch(trackOverlay('dismiss', doc)),
  trackEngage: (doc, extra) => dispatch(trackOverlay('engage', doc, extra)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RaffleActiveOverlay);

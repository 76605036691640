import React, { createContext, FC, PropsWithChildren, useContext } from 'react';
import type { DateData } from 'hooks/use-calendar';
import useCalendar from 'hooks/use-calendar';
import { EventsMinimalInfoDateMap, ICalendarEvent } from 'models/ICalendarEvent';
import useEventCalendarApi from './use-event-calendar-api';
import { IEventCategory } from 'models/IEventCategory';

type TContext = {
  eventsOnWeek: ICalendarEvent[];
  filterEvents: (events: ICalendarEvent[], selectedSiteCategories: ISelectedSiteCategories) => ICalendarEvent[];
  getEventsForDay: (targetEvents: ICalendarEvent[], date: DateData) => ICalendarEvent[];
  isSameDay: (first: DateData, second: DateData) => boolean;
  isToday: (day: DateData) => boolean;
  miniCalendarEvents: EventsMinimalInfoDateMap;
  removeEvent: (event: ICalendarEvent) => Promise<void>;
  result: ReturnType<typeof useCalendar>;
  selectedDate: DateData;
  selectedSiteCategories: ISelectedSiteCategories;
  setEventsOnWeek: (events: ICalendarEvent[]) => void;
  setMiniCalendarEvents: (events: EventsMinimalInfoDateMap) => void;
  setSelectedDate: (date: DateData) => void;
  setSelectedSiteCategories: (filters: ISelectedSiteCategories) => void;
  setTimestamp: (timestamp: number) => void;
  setWeekDays: (days: DateData[]) => void;
  siteCategories: IEventCategory[];
  timestamp: number;
  timestampDateFormat: DateData;
  upsertEvent: (event: ICalendarEvent) => Promise<void>;
  weekDays: DateData[];
};

export const EventCalendarContext = createContext<TContext>({} as any);

export type ISelectedSiteCategories = Record<string, IEventCategory['labels'][0]>;


export const useHelpers = () => {
  const isEventHappeningOnDay = React.useCallback((event: ICalendarEvent, date: DateData) => {
    const eventStart = new Date(event.date.startTimestamp);
    const eventStartDay = eventStart.getDate();
    const eventStartMonth = eventStart.getMonth();
    const eventStartYear = eventStart.getFullYear();

    const timestampDay = date.day;
    const timestampMonth = date.month;
    const timestampYear = date.year;

    if (eventStartDay === timestampDay && eventStartMonth === timestampMonth && eventStartYear === timestampYear) {
      return true;
    }

    return false;
  }, []);

  const getEventsForDay = React.useCallback((targetEvents: ICalendarEvent[], date: DateData) => {
    return targetEvents.filter(event => isEventHappeningOnDay(event, date));
  }, [isEventHappeningOnDay]);

  const isSameDay = React.useCallback((first: DateData, second: DateData) => {
    return first.day === second.day && first.month === second.month && first.year === second.year;
  }, []);

  const isToday = React.useCallback((day: DateData) => {
    const today = new Date();
    return day.day === today.getDate() && day.month === today.getMonth() && day.year === today.getFullYear();
  }, []);

  return {
    isEventHappeningOnDay,
    getEventsForDay,
    isSameDay,
    isToday,
  };
};

export const EventCalendarProvider: FC<PropsWithChildren> = ({ children }) => {
  const [timestamp, setTimestamp] = React.useState<number>(Date.now());
  const [eventsOnWeek, setEventsOnWeek] = React.useState<ICalendarEvent[]>([]);
  const [weekDays, setWeekDays] = React.useState<DateData[]>([]);
  const [miniCalendarEvents, setMiniCalendarEvents] = React.useState<EventsMinimalInfoDateMap>({});
  const [siteCategories, setSiteCategories] = React.useState<IEventCategory[]>([]);
  const [selectedSiteCategories, setSelectedSiteCategories] = React.useState<ISelectedSiteCategories>({});
  const api = useEventCalendarApi();
  const helpers = useHelpers();

  const fetchSiteCategories = React.useCallback(async (captureCancellator?: ((fn: () => void) => void)) => {
    api.getSiteCategories(captureCancellator)
    .then(categories => {
      const serializedFilters: IEventCategory[] = categories.map(c => {
        return {
          ...c,
          labels: [
            {
              value: '',
              color: 'surface6',
            },
            ...c.labels,
          ],
        };
      });

      setSiteCategories(serializedFilters);
      setSelectedSiteCategories(
        serializedFilters.reduce((acc, filter) => {
          acc[filter.title] = filter.labels[0];
          return acc;
        }, {} as ISelectedSiteCategories),
      );
    });
  }, []);

  React.useEffect(() => {
    const abort = () => {
      // placeholder
    };
    fetchSiteCategories(abort);
    return () => {
      abort();
    };
  }, [fetchSiteCategories]);

  const timestampDateFormat: DateData = React.useMemo(() => {
    const date = new Date(timestamp);
    return {
      day: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear(),
    };
  }, [timestamp]);
  const [selectedDate, setSelectedDate] = React.useState<DateData>(timestampDateFormat);

  const result = useCalendar({
    month: timestampDateFormat.month,
    year: timestampDateFormat.year,
    selectedDate,
  });

  const handleChangeSelectedDate = React.useCallback((targetDate: DateData) => {
    setSelectedDate(targetDate);
    if (targetDate.month !== timestampDateFormat.month) {
      setTimestamp(new Date(targetDate.year, targetDate.month, 1).getTime());
    }
  }, [timestampDateFormat.month]);

  const filterEvents = React.useCallback((targetEvents: ICalendarEvent[], targetFilters: ISelectedSiteCategories) => {
    const isFiltersClear = Object.values(targetFilters).every(f => !f.value);
    if (isFiltersClear) {
      return targetEvents;
    }

    let filteredEvents: ICalendarEvent[] = targetEvents;
    for (const [categoryName, filter] of Object.entries(targetFilters)) {
      if (!filter.value) {
        continue;
      }

      filteredEvents = filteredEvents.filter(event => {
        return event.categories?.some(c => {
          return c.title === categoryName && c.label.title === filter.value;
        });
      });
    }

    return filteredEvents;
  }, [selectedSiteCategories]);

  const appendToWeekEvents = (event: ICalendarEvent) => {
    const newEvents = [...eventsOnWeek];

    const existingEventIndex = newEvents.findIndex(e => e._id === event._id);
    if (existingEventIndex === -1) {
      newEvents.push(event);
    } else {
      newEvents[existingEventIndex] = event;
    }

    setEventsOnWeek(newEvents.sort((a, b) => a.date.startTimestamp - b.date.startTimestamp));
  };

  const appendToMiniCalendarEvents = (event: ICalendarEvent) => {
    const date = new Date(new Date(event.date.startTimestamp).setHours(0, 0, 0, 0));
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const parsedDate = new Date(date.getTime() - userTimezoneOffset).getTime();

    setMiniCalendarEvents(prev => {
      const newEvents = { ...prev };
      if (!newEvents[parsedDate]) {
        newEvents[parsedDate] = {
          quantity: 1,
          firstLabelColors: [event.categories[0]?.label?.color || 'surface6'],
          ids: [event._id!],
        };
        return newEvents;
      }

      newEvents[parsedDate].quantity += 1;
      newEvents[parsedDate].firstLabelColors.push(event.categories[0]?.label?.color || 'surface6');
      newEvents[parsedDate].ids.push(event._id!);

      return newEvents;
    });
  };

  const upsertEvent = async (event: ICalendarEvent) => {
    const eventCategories = event.categories || [];
    let parsedEvent: ICalendarEvent = {
      ...event,
      categories: eventCategories.filter(category => category.id && category.label.id),
    };

    if (event._id) {
      await api.updateEvent(parsedEvent);
    } else {
      parsedEvent = await api.createEvent(parsedEvent);
    }

    appendToWeekEvents(parsedEvent);
    appendToMiniCalendarEvents(parsedEvent);
    fetchSiteCategories();
  };

  const removeEvent = React.useCallback(async (event: ICalendarEvent) => {
    if (!event._id) {
      return;
    }

    await api.deleteEvent(event._id);

    const parsedDate = new Date(new Date(event.date.startTimestamp).setHours(0, 0, 0, 0));
    const userTimezoneOffset = parsedDate.getTimezoneOffset() * 60000;
    const parsedDateTimestamp = new Date(parsedDate.getTime() - userTimezoneOffset).getTime();

    setEventsOnWeek(prev => prev.filter(e => e._id !== event._id));
    setMiniCalendarEvents(prev => {
      const newEvents = { ...prev };
      if (!newEvents[parsedDateTimestamp]) {
        return newEvents;
      }

      if (newEvents[parsedDateTimestamp].quantity === 1) {
        delete newEvents[parsedDateTimestamp];
        return newEvents;
      }

      newEvents[parsedDateTimestamp].quantity -= 1;
      newEvents[parsedDateTimestamp].ids = newEvents[parsedDateTimestamp].ids.filter(id => id !== event._id);

      return newEvents;
    });
  }, []);


  return (
    <EventCalendarContext.Provider
      value={{
        eventsOnWeek,
        setEventsOnWeek,
        miniCalendarEvents,
        setMiniCalendarEvents,
        removeEvent,
        upsertEvent,
        filterEvents,
        isSameDay: helpers.isSameDay,
        isToday: helpers.isToday,
        getEventsForDay: helpers.getEventsForDay,
        result,
        timestamp,
        setTimestamp,
        selectedDate,
        setSelectedDate: handleChangeSelectedDate,
        timestampDateFormat,
        siteCategories,
        selectedSiteCategories,
        setSelectedSiteCategories,
        setWeekDays,
        weekDays,
      }}
    >
      {children}
    </EventCalendarContext.Provider>
  );
};


export const useEventCalendarContext = () => useContext(EventCalendarContext);

/* eslint react/destructuring-assignment: off */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AdminOverlayEngagement from 'components/admin/AdminOverlayEngagement';
import { withEndUserT, DynamicTranslationType } from 'hooks/use-translation';
import { mobileOnly, mobilePortraitOnly, PADDING_SMALL, TEXT_H5 } from 'style/mixins';
import OverlayCta from 'components/overlays/OverlayCta';
import { SURFACE_1 } from 'style/constants';

const THEME_TEXT_COLOR = ({ dark }) => dark ? 'white' : 'black';
const THEME_BACKGROUND = ({ dark }) => dark ? SURFACE_1 : 'white';
const MOBILE_MSG_WIDTH = ({ length }) => `${length < 58 ? 60 : 90}%`;
const MSG_WIDTH = ({ length }) => `${length < 58 ? 30 : 43}%`;
const MIN_MSG_WIDTH = ({ length }) => `${length < 58 ? 240 : 300}px`;

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-left: 20px;
  margin-bottom: 24px;
  width: 100%;
  ${props => props.hideOnPortrait ?
    mobilePortraitOnly`display: none;` :
    ''}
`;

const OverlayCtaContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const OverlayContent = styled.div`
  background-color: ${THEME_BACKGROUND};
  color: ${THEME_TEXT_COLOR};
  font-size: 18px;
  line-height: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 4px;
  width: ${MSG_WIDTH};
  min-width: ${MIN_MSG_WIDTH};
  padding: 13px 19px;
  word-break: break-word;
  & button {
    color: ${THEME_TEXT_COLOR};
  }
  &:after {
    position: absolute;
    bottom: -25px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 15px 14px 15px;
    border-color: ${THEME_BACKGROUND} transparent transparent;
    content: "";
  }
  ${mobileOnly`
    ${TEXT_H5}
    width: ${MOBILE_MSG_WIDTH};
    ${PADDING_SMALL}
  `}
`;

const StyledEngagement = styled(AdminOverlayEngagement)`
  flex-flow: row wrap;
  width: ${MSG_WIDTH};
  height: auto;
  padding-bottom: 4px;
  margin-bottom: -4px;
  border-radius: 4px 4px 0 0;
  ${mobileOnly`
    width: ${MOBILE_MSG_WIDTH};
  `}
`;
const OverlayFooter = styled.footer`
  display: ${props => props.editing ? 'flex' : 'none'};
  flex-direction: row-reverse;
  width: 100%;
`;
const CharCounter = styled.span`
  ${TEXT_H5}
  color: #aaa;
`;

class MessageOverlay extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    editing: PropTypes.bool.isRequired,
    endUserT: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
    overlayType: PropTypes.string.isRequired,
    renderer: PropTypes.shape({
      ctaRenderer: PropTypes.object,
      message: PropTypes.string,
      theme: PropTypes.string,
    }).isRequired,
    trackDismiss: PropTypes.func.isRequired,
  };

  handleDismiss = () => {
    const { data, onDismiss, trackDismiss } = this.props;
    trackDismiss(data);
    onDismiss(data.key);
  };

  render() {
    const {
      data: {
        key,
        payload: {
          data: { broadcastId, views, theme },
          removeOnPortrait,
        },
      },
      editing,
      endUserT,
      renderer: {
        ctaRenderer,
        message,
        overlayId,
        theme: previewTheme,
      },
    } = this.props;

    const hasCta = Boolean(ctaRenderer);
    const { overlayType } = this.props;

    return (
      <Container hideOnPortrait={removeOnPortrait}>
        <StyledEngagement
          broadcastId={broadcastId}
          broadcastKey={key}
          length={message.length}
          showEngaged={hasCta}
          views={views}
        />
        <OverlayCtaContainer>
          <OverlayContent className={overlayType} dark={(previewTheme || theme) === 'dark'} data-testid="overlayContainer" length={message.length}>
            <span>
              {
                endUserT([
                  DynamicTranslationType.overlayText,
                  { overlayId },
                ]) || message
              }
            </span>
            <OverlayCta
              broadcastId={broadcastId}
              broadcastKey={key}
              ctaRenderer={ctaRenderer}
              message={message}
              overlayId={overlayId}
              overlayType="message"
              rawData={this.props.data}
            />
            <OverlayFooter editing={editing}>
              <CharCounter>{`${message.length}/140`}</CharCounter>
            </OverlayFooter>
          </OverlayContent>
        </OverlayCtaContainer>
      </Container>
    );
  }
}

export default withEndUserT(MessageOverlay);

import React from 'react';
import { EditableStringPaths, GeneralProps } from '.';
import { Section, SectionTitle } from 'components/admin-bridge/EditorModal/ModalComponents/Section';
import TranslatedText from 'components/i18n/TranslatedText';
import styled, { css } from 'styled-components';
import { ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_3, ADMIN_SURFACE_4, ADMIN_SURFACE_6, ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_BOLD } from 'style/design-system/textStyles';
import useDebounceWithFunction from 'hooks/use-debounce-with-function';

const Spacing = ({ handleChange, item }: GeneralProps) => {
  const topInputRef = React.useRef<HTMLInputElement>(null);
  const bottomInputRef = React.useRef<HTMLInputElement>(null);
  const leftInputRef = React.useRef<HTMLInputElement>(null);
  const rightInputRef = React.useRef<HTMLInputElement>(null);
  const [spacing, setSpacing] = React.useState(item.spacing);

  useDebounceWithFunction(() => {
    if (JSON.stringify(spacing) !== JSON.stringify(item.spacing)) {
      handleChange('spacing')(spacing);
    }
  }, spacing, 500);

  const focusInput = (inputRef: React.RefObject<HTMLInputElement>) => () => {
    inputRef.current?.focus();
  };

  const parseNumber = (value: string) => {
    const parsed = parseInt(value, 10);
    return isNaN(parsed) ? 0 : parsed;
  };

  const onChange = (stringPath: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseNumber(e.target.value);
    setSpacing(prev => ({
      ...prev,
      [stringPath]: value,
    }));
  };

  return (
    <Section gap={16}>
      <TranslatedText stringKey="SPACING" component={SectionTitle} />
      <SpacingWrapper>
        <Left onClick={focusInput(leftInputRef)} />
        <Right onClick={focusInput(rightInputRef)} />
        <Top onClick={focusInput(topInputRef)} />
        <Bottom onClick={focusInput(bottomInputRef)} />
        <Main />

        <TopInput ref={topInputRef} value={spacing.top} onChange={onChange('top')}/>
        <BottomInput ref={bottomInputRef} value={spacing.bottom} onChange={onChange('bottom')}/>
        <LeftInput ref={leftInputRef} value={spacing.left} onChange={onChange('left')}/>
        <RightInput ref={rightInputRef} value={spacing.right} onChange={onChange('right')}/>
      </SpacingWrapper>
    </Section>
  );
};

const INPUT_STYLES = css`
  all: unset;
  position: absolute;
  z-index: 2;
  background: transparent;
  height: 28px;
  border-radius: 4px;
  text-align: center;
  caret-color: ${ADMIN_ACCENT_PRIMARY};
  ${ADMIN_TEXT_LABEL_M_BOLD}
  color: ${ADMIN_TEXT_100};

  &:focus {
    border: 2px solid ${ADMIN_SURFACE_6};
  }
`;

export const RightInput = styled.input`
  ${INPUT_STYLES}
  transform: translateY(-50%);
  right: 6px;
  width: 50px;
  top: 50%;
`;

export const LeftInput = styled.input`
  ${INPUT_STYLES}
  transform: translateY(-50%);
  left: 6px;
  width: 50px;
  top: 50%;
`;

export const BottomInput = styled.input`
  ${INPUT_STYLES}
  left: 50%;
  bottom: 5px;
  transform: translateX(-50%);
  width: 100px;
`;

export const TopInput = styled.input`
  ${INPUT_STYLES}
  left: 50%;
  top: 5px;
  transform: translateX(-50%);
  width: 100px;
`;

export const LeftRightInput = styled(TopInput)``;

export const SpacingWrapper = styled.div`
  position: relative;
  border: 2px solid ${ADMIN_SURFACE_6};
  height: 134px;
  overflow: hidden;

  & input:hover {
    & ~ button {
      background-color: ${ADMIN_SURFACE_3};
    }
  }
`;

export const Left = styled.button`
  all: unset;
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 100px;
  border: 2px solid ${ADMIN_SURFACE_6};
  transform-origin: 0px 0px;
  transform: rotate(146deg) skewX(22deg);
  cursor: pointer;
  background-color: ${ADMIN_SURFACE_4};

  &:hover {
    background-color: ${ADMIN_SURFACE_3};
  }
`;

export const Right = styled.button`
  all: unset;
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  top: 50%;
  left: calc(100% - 100px);
  border: 2px solid ${ADMIN_SURFACE_6};
  transform-origin: 0px 0px;
  transform: rotate(-34deg) skewX(22deg);
  cursor: pointer;
  background-color: ${ADMIN_SURFACE_4};

  &:hover {
    background-color: ${ADMIN_SURFACE_3};
  }
`;

export const Top = styled.button`
  all: unset;
  position: absolute;
  z-index: 1;
  height: calc(50% - 26px);
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  background-color: ${ADMIN_SURFACE_4};

  &:hover {
    background-color: ${ADMIN_SURFACE_3};
  }
`;

export const Bottom = styled.button`
  all: unset;
  position: absolute;
  z-index: 1;
  height: calc(50% - 26px);
  width: 100%;
  bottom: 0;
  left: 0;
  cursor: pointer;
  background-color: ${ADMIN_SURFACE_4};

  &:hover {
    background-color: ${ADMIN_SURFACE_3};
  }
`;

export const Main = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 125px);
  z-index: 2;
  position: absolute;
  height: 52px;
  border: 2px solid ${ADMIN_ACCENT_PRIMARY};
  background-color: ${ADMIN_SURFACE_4};
`;

export default Spacing;

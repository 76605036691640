import { FONT_FAMILY_TITLE, OPTIONAL_DYNAMIC_THEME_COLOR, TEXT_100 } from 'style/constants';
import { THEME_PADDING } from 'style/mixins';
import styled from 'styled-components';
import InlineTextEditor from 'components/admin2/ui/InlineTextEditor';
import QuickEditOverlay from '../../admin-bridge/QuickEditOverlay';

export const StyledQuickEditOverlay = styled(QuickEditOverlay)<{ backgroundColor: string; }>`
  background-color: ${({ backgroundColor }) => OPTIONAL_DYNAMIC_THEME_COLOR(backgroundColor)};
`;

export const Wrapper = styled.div<{ isScrollable: boolean; }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${THEME_PADDING}
  ${({ isScrollable }) => isScrollable && 'padding-right: 0 !important;'}
  position: relative;
`;

export const Title = styled(InlineTextEditor)`
  width: unset;
  & .mce-content-body {
    & * {
      font-family: ${FONT_FAMILY_TITLE};
    }
    color: ${TEXT_100};
  }
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`;

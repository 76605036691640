import React from 'react';
import { IRichText } from './utils';
import usePageBlock from 'hooks/use-page-block';
import BlockSettings from './BlockSettings';
import useResizeObserver from 'hooks/use-resize-observer';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import useToolbarPosition from './use-toolbar-position';
import { Image, StyledQuickEditOverlay, StyledFullEditor, ToolbarPositionRef, StyledRichTextEditorV3 } from './styles';
import { PREDEFINED_TOOLBAR_BUTTONS } from 'components/ui/RichTextEditorV2/utils';
import { getIsRichTextEditorV3Enabled } from 'services/feature-gate/selectors';
import { IPageBlockProps } from 'services/app/models/ILandingPageContent';

export interface IRichTextItem {
  arrayId: string;
  data: IRichText;
  kind: string;
}

type IRichTextBlockProps = IPageBlockProps<IRichTextItem>;

const RichTextBlock: React.FC<IRichTextBlockProps> = ({
  item,
  onEdit,
  onRemove,
  onMoveDown,
  onMoveUp,
}) => {
  const [ready, setReady] = React.useState(false);
  const isEditing = useSelector(isEditMode);
  const [imageRef, { height }] = useResizeObserver<HTMLImageElement>();
  const { ref, sticking, toolbarHeight } = useToolbarPosition();
  const isRichTextEditorV3Enabled = useSelector(getIsRichTextEditorV3Enabled);

  const handleChangeData = React.useCallback((update: IRichText) => {
    onEdit({ ...item, data: update });
  }, [item, onEdit]);

  const { handleChange, handleCloseSettings, handleOpenSettings, isSettingsOpen } = usePageBlock<keyof IRichText, IRichText>({
    item: item.data,
    onChange: handleChangeData,
  });

  const onReady = () => {
    setReady(true);
  };

  const renderEditor = () => {
    if (isRichTextEditorV3Enabled && item.data.contentRawV2) {
      return (
        <StyledRichTextEditorV3
          data={item.data.contentRawV2}
          onChange={handleChange('contentRawV2')}
          sticking={sticking}
          toolbarHeight={toolbarHeight}
          isEditing={isEditing}
        />
      );
    }

    return (
      <StyledFullEditor
        inline={false}
        toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.fullEditor}
        data={item.data.contentRaw}
        isEditMode={isEditing}
        onChange={handleChange('contentRaw')}
        sticking={sticking}
        toolbarHeight={toolbarHeight}
        onReady={onReady}
      />
    );
  };

  return (
    <StyledQuickEditOverlay
      backgroundColor={item.data.background.color}
      headerColor={item.data.header.color}
      imageHeight={item.data.coverImage ? height : 0}
      onRemove={onRemove}
      titleKey="RICH_TEXT"
      onEdit={handleOpenSettings}
      testId={`rich-text-${item.arrayId}`}
      ready={ready || Boolean(item.data.contentRawV2 && isRichTextEditorV3Enabled)}
      onMoveDown={onMoveDown}
      onMoveUp={onMoveUp}
    >
      {item.data.coverImage && <Image ref={imageRef} src={item.data.coverImage} />}
      <ToolbarPositionRef isEditMode={isEditing} ref={ref}>
        {renderEditor()}
      </ToolbarPositionRef>
      <BlockSettings
        isOpen={isSettingsOpen}
        onClose={handleCloseSettings}
        item={item.data}
        onChange={handleChangeData}
      />
    </StyledQuickEditOverlay>
  );
};

export default RichTextBlock;

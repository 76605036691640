import React from 'react';
import { useFortniteLeaderboardContext } from './Context/Context';
import RankBadge from './RankBadge';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';
import { IFortniteLeaderboardItem, RANK_ICONS_MAP } from 'models/IFortniteLeaderboard';
import { StyledTableBodyRow } from './styles';

const TableBodyRow: React.FC<{ item: IFortniteLeaderboardItem; }> = ({ item, ...props }) => {
  const isMobile = useSelector(isMobileLayout);
  const { isRanked, isPanel } = useFortniteLeaderboardContext();

  const short = isMobile || isPanel;

  if (isRanked) {
    return (
      <StyledTableBodyRow {...props} isRanked={isRanked} rank={item.rank} short={short}>
        <td>{item.rank}</td>
        {
          typeof item.rankDivision !== 'undefined' && (
            <td>
              <RankBadge rank={item.rankDivision} />
              {!short && RANK_ICONS_MAP[item.rankDivision].split('.')[0].split('_').join(' ')}
            </td>
          )
        }
        <td>
          {item.players.map(player => (
            <React.Fragment key={player.displayName}>
              <span>{player.displayName}</span>
              <br />
            </React.Fragment>
          ))}
        </td>
      </StyledTableBodyRow>
    );
  }

  return (
    <StyledTableBodyRow {...props} isRanked={isRanked} rank={item.rank} short={short}>
      <td>{item.rank}</td>
      <td>
        {item.players.map(player => (
          <>
            <span key={player.displayName}>{player.displayName}</span>
            <br/>
          </>
        ))}
      </td>
      <td>{item.points}</td>
      <td>{item.royals}</td>
      <td>{item.matches}</td>
      <td>{item.eliminations}</td>
    </StyledTableBodyRow>
  );
};

export default TableBodyRow;

import { VideoPlayback } from 'components/Video/video-content/VideoPlayback';
import { IVideo } from 'models';
import React from 'react';
import { PlayerSpot } from 'services/video';

interface IInlineVideoPlayerProps {
  arrayId: string;
  video: IVideo;
}

const InlineVideoPlayer: React.FC<IInlineVideoPlayerProps> = ({ video }) => {
  return (
    <VideoPlayback
      width="100%"
      video={video}
      scheduledVideoStartTime={null}
      spot={PlayerSpot.videoSpotlight}
    />
  );
};

export default InlineVideoPlayer;

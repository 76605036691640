import { IPageBlockPanelProps, IQuestsBlock } from 'services/app/models/ILandingPageContent';
import { getDefaultEditorState, getDefaultTextNode } from 'components/ui/RichTextEditorV3/getDefaultEditorState';
import { BackgroundType, GradientMaskOption, OverflowBehavior } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';
import { MAESTRO_BLACK } from 'style/constants';
import { GridTextAlignmentOptions } from 'components/admin-bridge/EditorModal/ModalComponents/GridTextAlignment/types';
import { THEME_PADDING_DESKTOP_PX } from 'style/mixins';

export type IQuestsBlockProps = IPageBlockPanelProps<IQuestsBlock>;

export interface IQuestsBlockData {
  background: {
    color: string;
    customColor: string;
    image: {
      desktop: {
        display: ObjectFit;
        overlayOpacity: number;
        position: GridTextAlignmentOptions;
        url: string;
      };
      gradientMask: GradientMaskOption;
      mobile: {
        display: ObjectFit;
        overlayOpacity: number;
        position: GridTextAlignmentOptions;
        url: string;
      }
    }
    type: BackgroundType;
  }
  description: {
    rawData: string;
    show: boolean;
  }
  label: {
    rawData: string;
    show: boolean;
  }
  overflowBehavior: {
    desktop: OverflowBehavior;
    mobile: OverflowBehavior;
  }
  questIds?: string[];
  questsPerRow: number;
  spacing: {
    bottom: number;
    left: number;
    right: number;
    top: number;
  }
  title: {
    rawData: string;
    show: boolean;
  }
}

export const DEFAULT_QUESTS: IQuestsBlockData = {
  spacing: {
    bottom: THEME_PADDING_DESKTOP_PX,
    left: THEME_PADDING_DESKTOP_PX,
    right: THEME_PADDING_DESKTOP_PX,
    top: THEME_PADDING_DESKTOP_PX,
  },
  questIds: [],
  background: {
    color: 'surface1',
    customColor: MAESTRO_BLACK,
    type: 'color',
    image: {
      gradientMask: 'none',
      desktop: {
        display: 'cover',
        overlayOpacity: 0,
        position: 'center',
        url: '',
      },
      mobile: {
        display: 'cover',
        overlayOpacity: 0,
        position: 'center',
        url: '',
      },
    },
  },
  description: {
    show: true,
    rawData: getDefaultEditorState([
      getDefaultTextNode({
        text: 'Lorem ipsum dolor sit amet consectetur. Et vulputate lectus odio tincidunt. Risus pellentesque pellentesque sed senectus nulla proin in varius.',
        fontSize: 20,
      }),
    ]),
  },
  title: {
    show: true,
    rawData: getDefaultEditorState([
      getDefaultTextNode({
        text: 'Lorem ipsum dolor sit amet consectetur',
        textFormatType: 'bold',
        fontSize: 48,
      }),
    ]),
  },
  label: {
    show: true,
    rawData: getDefaultEditorState([
      getDefaultTextNode({
        text: 'Lorem ipsum dolor sit amet.',
        textFormatType: 'bold',
        fontSize: 16,
      }),
    ]),
  },
  overflowBehavior: {
    desktop: 'slider',
    mobile: 'slider',
  },
  questsPerRow: 4,
};

export type ObjectFit = 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';

export const OBJECT_FIT_LABEL_MAP: Record<ObjectFit, string> = {
  'scale-down': 'Scale',
  contain: 'Contain',
  cover: 'Cover',
  fill: 'Fill',
  none: 'None',
};

export const OBJECT_FIT_OPTIONS: { label: string; value: ObjectFit; }[] = Object.keys(OBJECT_FIT_LABEL_MAP).map((key) => ({
  label: OBJECT_FIT_LABEL_MAP[key as ObjectFit],
  value: key as ObjectFit,
}));

import React from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { IHeroBanner } from '../utils';
import { Section, SectionTitle } from 'components/admin-bridge/EditorModal/ModalComponents/Section';
import Switch from 'components/admin2/ui/LabelToggle';
import TextInput from 'components/admin2/TextInput';
import {
  DesktopBackgroundInfoIcon,
  UploadZoneWrapper,
  DesktopBackgroundUploadZone,
  Label,
  LabelWrapper,
  MobileBackgroundInfoIcon,
  MobileBackgroundUploadZone,
  TooltipInfoIcon,
  BackgroundImageText,
} from './styles';
import { Checkbox } from 'components/admin2/ui/Checkbox';
import usePageBlock from 'hooks/use-page-block';
import PageBlockSettings from 'components/admin-bridge/PageBlockSettings';
import BehaviorDropdown from 'components/admin-bridge/CTAEditorModal/Content/BehaviorDropdown';
import Behaviors from 'components/admin-bridge/CTAEditorModal/Behaviors';
import { useAdminTranslation } from 'hooks/use-translation';

interface IBlockSettingsProps {
  isOpen: boolean;
  item: IHeroBanner;
  onChange: (item: IHeroBanner) => void;
  onClose: () => void;
}

type EditableStringPaths = 'primaryCta.show' | 'title.show' | 'description.show' | 'secondaryCta.show' | 'background.desktop' | 'background.mobile' | 'background.bleed' | 'background.gradientMask' | 'background.behavior';

const BlockSettings = ({
  item,
  isOpen,
  onClose,
  onChange,
}: IBlockSettingsProps) => {
  const { t } = useAdminTranslation();
  const { handleChange } = usePageBlock<EditableStringPaths, IHeroBanner>({
    item,
    onChange,
  });

  // if primary CTA is disabled, disable secondary CTA as well
  const handleChangePrimaryCta = React.useCallback((value: boolean) => {
    const updatedItem: IHeroBanner = structuredClone(item);
    updatedItem.primaryCta.show = value;
    if (!value) {
      updatedItem.secondaryCta.show = false;
    }
    onChange(updatedItem);
  }, [item, onChange]);

  const handleClearDesktopBackground = React.useCallback(() => {
    handleChange('background.desktop')('');
  }, [handleChange]);

  const handleClearMobileBackground = React.useCallback(() => {
    handleChange('background.mobile')('');
  }, [handleChange]);

  const showBehaviors = React.useMemo(() => !item.primaryCta.show && !item.secondaryCta.show, [item.primaryCta.show, item.secondaryCta.show]);

  return (
    <PageBlockSettings
      isOpen={isOpen}
      titleKey="ADMIN_LABEL_HERO_BANNER_SETTINGS"
      onClose={onClose}
    >
      <Section gap={20}>
        <Section gap={24}>
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_DISPLAY" />
          <Section gap={15}>
            <Checkbox
              checked={item.title.show}
              onCheckedChange={handleChange('title.show')}
              labelKey="ADMIN_LABEL_TITLE"
            />
            <Checkbox
              checked={item.description.show}
              labelKey="ADMIN_LABEL_DESCRIPTION"
              onCheckedChange={handleChange('description.show')}
            />
            <Checkbox
              checked={item.primaryCta.show}
              labelKey="ADMIN_LABEL_PRIMARY_CALL_TO_ACTION"
              onCheckedChange={handleChangePrimaryCta}
            />
            <Checkbox
              disabled={!item.primaryCta.show}
              checked={item.secondaryCta.show}
              labelKey="ADMIN_LABEL_SECONDARY_CALL_TO_ACTION"
              onCheckedChange={handleChange('secondaryCta.show')}
            />
          </Section>
          {showBehaviors && (
            <Section gap={5}>
              <SectionTitle>{t('ADMIN_LABEL_ACTION')}</SectionTitle>
              <BehaviorDropdown behavior={item.background.behavior} onChange={handleChange('background.behavior')} />
              <Behaviors behavior={item.background.behavior} onChange={handleChange('background.behavior')} />
            </Section>
          )}
        </Section>
        <Section gap={5}>
          <TranslatedText component={BackgroundImageText} stringKey="ADMIN_SETTINGS_DESIGN_PAGE_BACKGROUND_IMAGE" />
          <UploadZoneWrapper>
            <LabelWrapper>
              <TranslatedText component={Label} stringKey="ADMIN_LABEL_DESKTOP" />
              <DesktopBackgroundInfoIcon tooltipKey="ADMIN_HERO_BANNER_DESKTOP_TOOLTIP" />
            </LabelWrapper>
            <DesktopBackgroundUploadZone
              imagePreview={item.background.desktop}
              onClearImage={handleClearDesktopBackground}
              onFileSubmit={handleChange('background.desktop')}
            />
          </UploadZoneWrapper>
          <UploadZoneWrapper>
            <LabelWrapper>
              <TranslatedText component={Label} stringKey="ADMIN_LABEL_MOBILE" />
              <MobileBackgroundInfoIcon tooltipKey="ADMIN_HERO_BANNER_MOBILE_TOOLTIP" />
            </LabelWrapper>
            <MobileBackgroundUploadZone
              imagePreview={item.background.mobile}
              onClearImage={handleClearMobileBackground}
              onFileSubmit={handleChange('background.mobile')}
            />
          </UploadZoneWrapper>
          <Switch
            labelKey="ADMIN_LABEL_IMAGE_BLEED"
            checked={item.background.bleed}
            onChange={handleChange('background.bleed')}
            labelTooltip={<TooltipInfoIcon tooltipKey="ADMIN_LABEL_IMAGE_BLEED_TOOLTIP" />}
            padding="0"
          />
          <Switch
            labelKey="ADMIN_LABEL_USE_GRADIENT_MASK"
            checked={item.background.gradientMask}
            onChange={handleChange('background.gradientMask')}
            labelTooltip={<TooltipInfoIcon tooltipKey="ADMIN_LABEL_USE_GRADIENT_MASK_TOOLTIP" />}
            padding="0"
          />
        </Section>
      </Section>
    </PageBlockSettings>
  );
};

export default BlockSettings;

import styled from 'styled-components';
import { HEADER_HEIGHT } from '../Header/styles';
import { ACCENT_TERTIARY, SURFACE_2, SURFACE_4, TEXT_100, TEXT_200 } from 'style/constants';
import { TEXT_BODY_S_REGULAR, TEXT_LABEL_L_BOLD, TEXT_LABEL_M_BOLD, TEXT_LABEL_M_MEDIUM, TEXT_TITLE_M } from 'style/design-system/textStyles';
import Labels from '../../EventCard/Labels';
import { mobileOnly } from 'style/mixins';
import Icon from 'components/ui/Icon';
import { Title as FullEventCardTitle, Description as FullEventCardDescription } from '../../EventCard/FullEventCard/styles';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${SURFACE_2};
  width: 100%;
  text-align: left;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  height: ${HEADER_HEIGHT}px;
  padding: 0px 20px;
  border-bottom: 1px solid ${SURFACE_4};
`;

export const Body = styled.section<{ hasImage: boolean; }>`
  display: flex;
  flex-direction: column;
  padding: ${({ hasImage }) => hasImage ? '20px' : '40px 20px'};
`;

export const Image = styled.img`
  object-fit: cover;
  aspect-ratio: 2 / 3;
  max-width: 100%;
  margin-bottom: 20px;
`;

export const Date = styled.span`
  ${TEXT_LABEL_M_MEDIUM}
  color: ${TEXT_200};
`;

export const Title = styled(FullEventCardTitle)`
  & .mce-content-body {
    & * {
      ${TEXT_TITLE_M}
      font-size: 1.25rem !important;
    }
  }
  margin-top: 6px;
`;

export const Description = styled(FullEventCardDescription)`
  display: block;
  & .mce-content-body {
    & * {
      /* This removes the multiline ellipsis */
      display: flex;
      ${TEXT_BODY_S_REGULAR}
      font-size: 0.875rem !important;
    }
  }
  margin-top: 16px;
`;

export const PrimaryInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Time = styled.span`
  ${TEXT_LABEL_L_BOLD}
  color: ${TEXT_200};
`;

export const StyledLabels = styled(Labels)`
  & label {
    ${mobileOnly`${TEXT_LABEL_M_BOLD}`};
  }
  margin-top: 12px;
`;

export const WhenWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 20px;
`;

export const BackArrowIcon = styled(Icon).attrs({
  name: 'arrowUp',
})`
  display: flex;
  rotate: 270deg;
  width: 16px;
  height: 16px;
  & svg {
    width: 16px;
    height: 16px;
    & path {
      fill: ${ACCENT_TERTIARY};
    }
  }
`;

export const GoBack = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  ${TEXT_LABEL_M_MEDIUM}
  color: ${TEXT_100};
  height: 100%;
`;

import React, { useState } from 'react';
import moment from 'moment';
import camelCase from 'lodash/camelCase';
import RealtimeDocument from 'components/core/RealtimeDocument';
import SpinnerCircle from 'components/ui/SpinnerCircle';
// @ts-ignore
import CloseButton from 'components/ui/CloseButton';
import Icon from 'components/ui/Icon';
import { DynamicTranslationType, useEndUserTranslation, TranslationKey } from 'hooks/use-translation';
import styled from 'styled-components';
import { TEXT_H5, mobileOnly } from 'style/mixins';
import { pickTextColorBasedOnBgColor } from 'url-utils';
import {
  ACCENT_PRIMARY,
  ADMIN_ALERT_WARNING,
  ADMIN_SURFACE_5,
  ADMIN_TEXT_200,
  SURFACE_3,
  HIGHLIGHT_PRIMARY,
  TEXT_100,
  ADMIN_TEXT_FONT_FAMILY_CONTENT_NORMAL,
  ALERT_WARNING,
} from 'style/constants';

interface OverlayHeaderProps {
  broadcastId: string;
  data: any;
  defaultState: any;
  duration?: number;
  isAdmin: boolean;
  onDismiss: (key: string) => void;
  overlayType?: string;
  showPreview: boolean;
  showTitle?: boolean;
  timeRemaining?: number;
  trackDismiss: (data: any) => void;
  views: number;
}

const Container = styled.div<{marginLeft: boolean, showPreview: boolean}>`
  width: 100%;
  ${({ marginLeft }) => marginLeft ? 'margin-left: 20px;' : null};
  ${({ showPreview }) => showPreview ? 'margin-top: 5px;' : 'display: flex;'};
  ${mobileOnly`
    margin: 0 0 0 0;
  `}
`;

const Preview = styled.div<{isAdmin: boolean, showTitle: boolean}>`
  font-family: Noto Sans, sans-serif;
  font-size: 14px;
  font-weight: 700;
  background-color: ${({ isAdmin }) => isAdmin ? ADMIN_ALERT_WARNING : ALERT_WARNING};
  color: black;
  text-transform: uppercase;
  padding: 4px 12px 3px 12px;
  border-radius: 4px;
  width: 108px;
  height: 27px;
  display: table;
  text-align: center;
  vertical-align: middle;
  ${({ showTitle }) => showTitle ? 'margin-bottom: 5px;' : null};
`;

const ContainerAdminOverlayEngagement = styled.div`
  background-color: black;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
  width: 100%;
  max-width: max-content;
  padding: 0 14px;
  height: 39px;
  align-items: center;
  margin-right: 2px;
  font-family: ${ADMIN_TEXT_FONT_FAMILY_CONTENT_NORMAL};
  ${mobileOnly`
    margin: 0px 2px 0px 2px;
    width: 170px;
    height: 20px;
    font-size: 9px;
  `}
`;

const ContainerViewed = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 8px;
  border-right: solid ${ADMIN_SURFACE_5} 1px;
  width: fit-content;
  justify-content: center;
`;

const ContainerEngaged = styled.div`
  display: flex;
  padding: 0;
  width: fit-content;
  justify-content: center;
  padding-left: 8px;
`;

const StyledIconViews = styled(Icon)<{ name: string }>`
  display: flex;
  align-items: center;
  & svg {
    width: ${({ name }) => name === 'cursorHand' ? '16.06px' : '17.18px'};
    height: ${({ name }) => name === 'cursorHand' ? '18.07px' : '9px'};
  }
  ${mobileOnly`
    & svg {
      width: 9px;
      height: 9px;
    }
  `}
`;

const InfoText = styled.div`
  padding-left: 8px;
  color: ${ADMIN_TEXT_200};
  font-weight: 500;
  ${mobileOnly`
    padding: 0px 2px;
  `}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;


const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  margin-top: 5px;
  justify-content: space-between;
`;

const Title = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  flex: unset;
  width: 100%;
`;

const TitleBox = styled.div`
  font-family: Noto Sans, sans-serif;
  font-size: 14px;
  font-weight: 700;
  background-color: ${ACCENT_PRIMARY};
  text-transform: uppercase;
  padding: 12px;
  border-radius: 4px;
  margin-right: 5px;
  width: max-content;
  display: flex;
  color: ${props => pickTextColorBasedOnBgColor(ACCENT_PRIMARY(props))};
  ${mobileOnly`
    padding: 2px 6px 2px 6px;
  `}
`;

const TimeContainer = styled.div`
  align-content: flex-end;
  align-self: center;
  padding-right: 8px;
  margin-top:auto;
  background-color: ${SURFACE_3};
  border-radius: 40px;
  padding: 5px 9px;
  ${TEXT_H5}
  display: flex;
  align-items: center;
  min-width: 72px;
  flex: 1 1 auto;
  ${mobileOnly`
    min-width: 45px;
    padding: 0px 0px 0px 2px;
    font-size: 9px;
    margin: 0px;
  `}
`;

const SpinnerCircleContainer = styled(SpinnerCircle)`
`;

const TimerLabel = styled.div`
  margin-left: 7px;
  font-size: 12px;
  color: ${TEXT_100};
  ${mobileOnly`
    font-size: 9px;
    margin: 0px;
    margin-left: 2px;
  `}
`;

const CloseButtonContainer = styled(CloseButton)`
  background-color: ${SURFACE_3};
  padding: 9.5px;
  border-radius: 4px;
  height: fit-content;
  margin-top: auto;
  margin-left: 8px;
  opacity: 1;
  & span {
    display: grid;
  }
  & svg {
    width: 8px;
    height: 8px;
    & > path {
      fill: ${HIGHLIGHT_PRIMARY};
    }
  }
  ${mobileOnly`
    & svg {
      width: 4px;
      height: 4px;
    }
    margin-left: 2px;
    padding: 8px
  `}
`;

const OverlayHeader = ({
  broadcastId,
  data,
  defaultState,
  duration,
  isAdmin,
  onDismiss,
  overlayType,
  showPreview,
  showTitle,
  trackDismiss,
  timeRemaining,
  views }: OverlayHeaderProps) => {
  const [state, setState] = useState(defaultState);
  const { endUserT } = useEndUserTranslation();

  const onValue = (doc: any) => {
    const { engaged } = doc?.data?.state || {};
    const { engaged: oldEngaged } = state;

    if (oldEngaged !== engaged) {
      setState({
        engaged,
      });
    }
  };

  const dismissOverlay = () => {
    const { key } = data;
    onDismiss(key);
    trackDismiss(data);
  };

  const viewPreview = () => {
    return showPreview ? (
      <Preview showTitle={showTitle || false} isAdmin={isAdmin}>
        {
          endUserT(
            [DynamicTranslationType.overlayPreview],
            ['PREVIEW'],
          )
        }
      </Preview>
    )
    :
    '';
  };

  const viewAdminOverlayEngagement = () => {
    if (showPreview || !isAdmin) {
      return null;
    }

    const { engaged = 0 } = state;
    const totalViews = Math.max(1, views, engaged);
    const engagedText = endUserT(
      [DynamicTranslationType.overlayEngaged],
      ['ENGAGED'],
    );

    return isAdmin && (
      <ContainerAdminOverlayEngagement >
        <ContainerViewed>
          <StyledIconViews name="eyeFilled" />
          <InfoText>{`${totalViews || 2} `}
            {
              endUserT(
                [DynamicTranslationType.overlayViewed],
                ['VIEWED'],
              )
            }
          </InfoText>
        </ContainerViewed>
        <ContainerEngaged>
          <StyledIconViews name="cursorHand" />
          <InfoText>
            {`${engaged} ${engagedText} (${totalViews ? Math.trunc((engaged / totalViews) * 100) : 1}%)`}
          </InfoText>
        </ContainerEngaged>
        <RealtimeDocument collection="objects" id={broadcastId} onValue={onValue} />
      </ContainerAdminOverlayEngagement>
    );
  };

  const viewTitle = () => {
    if (!showTitle) {
      return;
    }

    return (
      <TitleBox>
        {
          endUserT(
            [
              DynamicTranslationType.overlayTitle,
              { type: camelCase(overlayType!) },
            ],
            [overlayType?.toUpperCase() as TranslationKey],
          )
        }
      </TitleBox>
    );
  };

  const viewTime = () => {
    if(typeof(duration) === 'undefined' && typeof(timeRemaining) === 'undefined') {
      return null;
    }
    const seconds = timeRemaining && timeRemaining > 600 ? 600 : timeRemaining;
    // note: to preview mode the totalsendos=192 and the second=48
    return (
      <TimeContainer>
        <SpinnerCircleContainer
          second={seconds || 48}
          showSeconds={false}
          size={12}
          totalSeconds={showPreview && seconds === 0 ? 192 : duration || 0}
        />
        <TimerLabel>{moment.utc((seconds || 48) * 1000).format('mm:ss')}</TimerLabel>
      </TimeContainer>
    );
  };

  const onClick = () => {
    dismissOverlay();
  };

  return (
    <HeaderContainer>
      <Title>
        <Container marginLeft={overlayType === 'lower_third'} showPreview={showPreview}>
          {viewPreview()}
          {viewTitle()}
          {viewAdminOverlayEngagement()}
        </Container>
        {viewTime()}
        <CloseButtonContainer onClick={onClick} />
      </Title>
    </HeaderContainer>
  );
};

export default OverlayHeader;

import { connect } from 'react-redux';
import { isUserAdmin } from 'services/auth';
import { removeOverlay } from 'services/overlays';
import { trackOverlay } from 'services/insights';
import { addCartItem, openEcommerceChannelCheckoutView } from 'services/ecommerce';
import { isVideoOnlyMode } from 'services/user-layout';
import { getMoneyFormat } from 'services/shopify';
import { isMobileLayout } from 'services/device';
import ShopifyOverlay from './view';

const mapStateToProps = (state) => ({
  isAdmin: isUserAdmin(state),
  isMobileLayout: isMobileLayout(state),
  isVideoOnlyMode: isVideoOnlyMode(state),
  moneyFormat: getMoneyFormat(state),
});

const mapDispatchToProps = dispatch => ({
  addProductToCart: (data) => dispatch(addCartItem({
    productId: data.productId,
    quantity: 1,
    variantId: data.variantId,
  })),
  onDismiss: key => dispatch(removeOverlay(key)),
  showShopifyPanel: () => {
    dispatch(openEcommerceChannelCheckoutView());
  },
  trackDismiss: doc => dispatch(trackOverlay('dismiss', doc)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopifyOverlay);

import { connect } from 'react-redux';
import { getUserBlob } from 'services/auth';
import { trackPanel } from 'services/insights';
import { getStripePaymentMethods, getDefaultPayment } from 'services/billing';
import { getSavedTheme } from 'services/themes';
import InfoPage from './view';

const mapStateToProps = state => ({
  defaultPaymentId: getDefaultPayment(state),
  paymentMethods: getStripePaymentMethods(state),
  siteTheme: getSavedTheme(state),
  userData: getUserBlob(state),
});

const mapDispatchToProps = dispatch => ({
  onTrackPanel: (action, doc, extra) => dispatch(trackPanel(action, doc, extra)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoPage);

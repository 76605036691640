import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import { FacebookShareButton } from 'react-share';
import { getReferralUrl } from 'services/insights';
import { withEndUserT, DynamicTranslationType } from 'hooks/use-translation';
import { FACEBOOK_BLUE } from 'style/constants';
import { bw } from 'colors';
import { TITLE_FONT } from 'style/mixins';

const ClickableContainer = styled.div`
  cursor: pointer;
`;

const FlexWrapper = styled.div`
  background-color: ${FACEBOOK_BLUE};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  ${TITLE_FONT}
  height: 100%;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${bw(FACEBOOK_BLUE)};
`;

const PaddedIcon = styled(Icon)`
  padding: 5px;
`;

const ShareText = styled.div`
  padding-right: 5px;
`;

class FacebookShareOverlayCta extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    endUserT: PropTypes.func.isRequired,
    onEngagedOverlay: PropTypes.func.isRequired,
    referralId: PropTypes.string.isRequired,
    testId: PropTypes.string,
  };

  static defaultProps = {
    children: undefined,
    testId: undefined,
  };

  onBeforeShare = async () => {
    const { onEngagedOverlay } = this.props;
    onEngagedOverlay();
  };

  render() {
    const { children, endUserT, referralId, testId } = this.props;
    const referralUrl = getReferralUrl(referralId);

    return (
      <ClickableContainer data-testid={testId}>
        <FacebookShareButton
          beforeOnClick={this.onBeforeShare}
          url={referralUrl}
        >
          {
            children || (
              <FlexWrapper>
                <PaddedIcon name="facebook-squared" />
                <ShareText>
                  {
                    endUserT(
                      [DynamicTranslationType.overlayCtaShare],
                      ['SHARE'],
                    )
                  }
                </ShareText>
              </FlexWrapper>
            )
          }
        </FacebookShareButton>
      </ClickableContainer>
    );
  }
}

export default withEndUserT(FacebookShareOverlayCta);

import React, { PropsWithChildren, useDeferredValue } from 'react';
import { IFortniteLeaderboardBlockData } from '../../utils';
import useDefaultFilters from './use-default-filters';
import useFilters from './use-filters';
import { IBlockRegion } from 'services/app/models/ILandingPageContent';

type UseFilter = ReturnType<typeof useFilters>;
export type TFortniteLeaderboardContext = {
  blockWrapperRef: React.RefObject<HTMLDivElement>;
  eventId: string;
  eventWindowId: string;
  isBattleRoyale: UseFilter['isBattleRoyale'];
  isPanel: boolean;
  isRanked: UseFilter['isRanked'];
  loadingFilters: boolean;
  loggedUserLeaderboardPosition: UseFilter['loggedUserLeaderboardPosition'];
  onChangeGameType: UseFilter['onChangeGameType'];
  onChangeLeaderboardType: UseFilter['onChangeLeaderboardType'];
  query: string;
  seasons: UseFilter['seasons'];
  sessions: UseFilter['sessions'];
  setQuery: (query: string) => void;
  tournaments: UseFilter['tournaments'];
};

const FortniteLeaderboardContext = React.createContext<TFortniteLeaderboardContext | null>(null);

export const FortniteLeaderboardProvider: React.FC<{
  blockWrapperRef: React.RefObject<HTMLDivElement>;
  defaultFilters: IFortniteLeaderboardBlockData['defaultFilters'];
  region: IBlockRegion;
} & PropsWithChildren> = ({ children, defaultFilters, region, blockWrapperRef }) => {
  const [query, setQuery] = React.useState('');

  const isPanel = region === 'panel';
  const {
    eventId,
    eventWindowId,
    onChangeGameType,
    onChangeLeaderboardType,
    seasons,
    sessions,
    tournaments,
    isBattleRoyale,
    isRanked,
    loggedUserLeaderboardPosition,
    loadingFilters,
  } = useFilters(defaultFilters);

  useDefaultFilters({
    defaultFilters,
    onChangeSeason: seasons.onChange,
    onChangeGameType,
    onChangeLeaderboardType,
    onChangeSession: sessions.onChange,
    onChangeTournament: tournaments.onChange,
  });

  const value = React.useMemo(() => ({
    onChangeGameType,
    onChangeLeaderboardType,
    seasons,
    sessions,
    tournaments,
    isBattleRoyale,
    isRanked,
    isPanel,
    eventId,
    eventWindowId,
    loggedUserLeaderboardPosition,
    query,
    setQuery,
    blockWrapperRef,
    loadingFilters,
  }), [
    seasons,
    tournaments,
    eventId,
    sessions,
    eventWindowId,
    isBattleRoyale,
    isRanked,
    isPanel,
    loggedUserLeaderboardPosition,
    query,
    setQuery,
    blockWrapperRef,
    loadingFilters,
  ]);

  return (
    <FortniteLeaderboardContext.Provider
      value={value}
    >
      {children}
    </FortniteLeaderboardContext.Provider>
  );
};

export const useFortniteLeaderboardContext = () => {
  const context = React.useContext(FortniteLeaderboardContext);
  if (!context) {
    throw new Error('useFortniteLeaderboardContext must be used within a FortniteLeaderboardProvider');
  }
  return context;
};

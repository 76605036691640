import React from 'react';
import { IQuestsBlockData, IQuestsBlockProps } from './utils';
import usePageBlock from 'hooks/use-page-block';
import Header from './Header';
import { StyledQuickEditOverlay, Wrapper } from './styles';
import BackgroundImage from './BackgroundImage';
import Gallery from './Gallery';
import { useDispatch } from 'react-redux';
import { trackPanel } from 'services/insights';

const Quests: React.FC<IQuestsBlockProps> = ({
  item,
  onEdit,
  onRemove,
  region,
  panelDoc,
  onOpenBlockSettings,
  onMoveDown,
  onMoveUp,
}) => {
  const dispatch = useDispatch();
  const handleChangeData = (update: IQuestsBlockData) => {
    onEdit({ ...item, data: update });
  };

  const { handleChange, handleCloseSettings, handleOpenSettings, isSettingsOpen } = usePageBlock<'title.rawData' | 'description.rawData' | 'label.rawData' | 'questIds', IQuestsBlockData>({
    item: item.data,
    onChange: handleChangeData,
  });
  const handleOpenBlockSettings = onOpenBlockSettings || handleOpenSettings;

  const BlockSettings = React.useMemo(() => {
    if (region === 'panel') {
      return null;
    }

    return React.lazy(() => import('./BlockSettings'));
  }, [region]);

  const questEngage = (extra: unknown) => {
    if (region === 'panel' && panelDoc) {
      dispatch(trackPanel('engage', panelDoc, extra));
    }
  };

  return (
    <StyledQuickEditOverlay
      backgroundColor={item.data.background.color}
      onEdit={handleOpenBlockSettings}
      onRemove={onRemove}
      titleKey="ADMIN_LABEL_QUESTS"
      testId={`quests-block-${item.arrayId}`}
      onMoveDown={onMoveDown}
      onMoveUp={onMoveUp}
      region={region}
    >
      <Wrapper isScrollable={false} region={region} spacing={item.data.spacing}>
        <BackgroundImage background={item.data.background} />
        <Header
          description={item.data.description}
          onChangeDescriptionRaw={handleChange('description.rawData')}
          onChangeTitleRaw={handleChange('title.rawData')}
          onChangeLabelRaw={handleChange('label.rawData')}
          title={item.data.title}
          label={item.data.label}
          region={region}
        />
        <Gallery
          item={item.data}
          region={region}
          onChange={handleChangeData}
          questEngage={questEngage}
        />
      </Wrapper>
      {
        BlockSettings && (
          <React.Suspense fallback={null}>
            <BlockSettings
              item={item.data}
              onChange={handleChangeData}
              isOpen={isSettingsOpen}
              onClose={handleCloseSettings}
              region={region}
            />
          </React.Suspense>
        )
      }
    </StyledQuickEditOverlay>
  );
};

export default Quests;

import React from 'react';
import { useEventCalendarContext } from '../EventCalendarContext';
import type { DateData } from 'hooks/use-calendar';
import WeekCalendarHeader from './Header';
import DayColumn from './DayColumn';
import { WeekWrapper, Wrapper } from './styles';
import useEventCalendarApi from '../use-event-calendar-api';
import hash from 'json-stable-stringify';

const WeekCalendar: React.FC = () => {
  const { result, selectedDate, isSameDay, setEventsOnWeek, weekDays, setWeekDays } = useEventCalendarContext();
  const { getEventsBetweenTimestamp } = useEventCalendarApi();

  React.useEffect(() => {
    const visibleDaysWeekDays = result.visibleDaysMatrix.find(week => week.some(day => isSameDay(day, selectedDate)));
    if (!visibleDaysWeekDays) {
      return;
    }

    setWeekDays(visibleDaysWeekDays);
    handleFetchEvents(visibleDaysWeekDays);
  }, [hash(result.visibleDaysMatrix), selectedDate, isSameDay, setWeekDays]);

  const handleAdvanceWeek = React.useCallback(() => {
    if (!weekDays.length) {
      return;
    }

    const lastWeekDay = weekDays[weekDays.length - 1];
    const date = new Date(lastWeekDay.year, lastWeekDay.month, lastWeekDay.day);
    const nextWeekDays: DateData[] = [];

    for (let i = 1; i <= 7; i++) {
      const nextDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() + i);
      nextWeekDays.push({
        day: nextDay.getDate(),
        month: nextDay.getMonth(),
        year: nextDay.getFullYear(),
      });
    }

    setWeekDays(nextWeekDays);
    handleFetchEvents(nextWeekDays);
  }, [weekDays, setWeekDays]);

  const handleRetreatWeek = React.useCallback(() => {
    if (!weekDays.length) {
      return;
    }

    const firstWeekDay = weekDays[0];
    const date = new Date(firstWeekDay.year, firstWeekDay.month, firstWeekDay.day);
    const previousWeekDays: DateData[] = [];

    for (let i = 1; i <= 7; i++) {
      const previousDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - i);
      previousWeekDays.unshift({
        day: previousDay.getDate(),
        month: previousDay.getMonth(),
        year: previousDay.getFullYear(),
      });
    }

    setWeekDays(previousWeekDays);
    handleFetchEvents(previousWeekDays);
  }, [weekDays, setWeekDays]);

  const handleFetchEvents = React.useCallback((days: DateData[]) => {
    const firstDay = days[0];
    const lastDay = days[days.length - 1];

    const startTimestamp = new Date(firstDay.year, firstDay.month, firstDay.day).getTime();
    const endTimestamp = new Date(lastDay.year, lastDay.month, lastDay.day + 1).getTime();

    getEventsBetweenTimestamp(startTimestamp, endTimestamp).then(setEventsOnWeek);
  }, [getEventsBetweenTimestamp, setEventsOnWeek]);

  if (!weekDays.length) {
    return null;
  }

  return (
    <Wrapper>
      <WeekCalendarHeader onAdvanceWeek={handleAdvanceWeek} onRetreatWeek={handleRetreatWeek} weekDays={weekDays} />
      <WeekWrapper>
        {weekDays.map((weekDate, index) => (
          <DayColumn
            key={index}
            weekDate={weekDate}
            day={result.weekDaysLong[index]}
          />
        ))}
      </WeekWrapper>
    </Wrapper>
  );
};

export default WeekCalendar;

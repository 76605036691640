import InlineTextEditor from 'components/admin2/ui/InlineTextEditor';
import LiveBadge from 'components/ui/LiveBadge';
import { ACCENT_PRIMARY, FONT_FAMILY_TITLE, SURFACE_4, SURFACE_5, SURFACE_6, TEXT_100, TEXT_200 } from 'style/constants';
import { TEXT_LABEL_M_BOLD, TEXT_LABEL_S_BOLD, TEXT_LABEL_S_MEDIUM, TEXT_LABEL_XS_BOLD, TEXT_LABEL_XS_MEDIUM } from 'style/design-system/textStyles';
import { MULTI_LINE_ELLIPSIS, desktopOnly, mobileOnly } from 'style/mixins';
import styled from 'styled-components';
import Labels from '../Labels';
import { SHOW_MINIMAL_INFO_MAX_WIDTH } from '../../utils';

export const TimeContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const MinimizedInfo = styled.div`
  display: none;
  gap: 8px;
  align-items: center;
`;

export const SecondaryInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Time = styled.span`
  ${TEXT_LABEL_S_MEDIUM}
  color: ${TEXT_200};
`;

export const DateLabel = styled.label`
  ${TEXT_LABEL_S_MEDIUM}
  color: ${TEXT_200};
`;

export const Title = styled(InlineTextEditor)`
  pointer-events: none;
  width: 100%;

  & .mce-content-body {
    & * {
      font-family: ${FONT_FAMILY_TITLE};
      &:not(a):not(span) {
        ${MULTI_LINE_ELLIPSIS}
      }
      ${desktopOnly`
        ${TEXT_LABEL_S_BOLD}
        font-size: 0.75rem !important;
      `};
      ${mobileOnly`
        ${TEXT_LABEL_M_BOLD}
        font-size: 0.875rem !important;
      `};
    }
    color: ${TEXT_100};
  }
`;

export const MinimizedTitle = styled(Title)`
  display: none;
`;

export const MinimizedLiveBadge = styled(LiveBadge.Dot)`
  display: none;
`;

export const ExpandableFullEventCard = styled.div`
  position: fixed;
  z-index: 3;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.55);
  border: 1px solid ${SURFACE_5};
  border-radius: 10px;
`;

export const StyledLabels = styled(Labels)``;

export const Wrapper = styled.section<{ firstLabelColor?: string; }>`
  display: flex;
  justify-content: flex-start;
  border-radius: 5px;
  background-color: ${SURFACE_4};
  position: relative;
  height: fit-content;
  width: 100%;

  &:hover {
    outline: 1px solid ${ACCENT_PRIMARY};
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: ${props => props.firstLabelColor || SURFACE_6};
    border-radius: 5px 0 0 5px;
  }

  @media (max-width: ${SHOW_MINIMAL_INFO_MAX_WIDTH}px) {
    & ${SecondaryInfo}, ${Title} {
      display: none;
    }

    & ${MinimizedInfo}, ${MinimizedTitle}, ${MinimizedLiveBadge} {
      display: flex;
    }
  }

  @media (max-width: 1692px) {
    & ${Title} {
      & .mce-content-body {
        & * {
          ${TEXT_LABEL_XS_BOLD}
          font-size: 0.625rem !important;
        }
      }
    }

    & ${Time} {
      ${TEXT_LABEL_XS_MEDIUM}
    }

    & ${StyledLabels} label {
      ${TEXT_LABEL_XS_BOLD}
    }
  }
`;

export const ContentContainer = styled.div`
  padding: 8px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  margin-left: 4px;
  width: 100%;
`;

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import { IEmbedCode } from './utils';
import usePageBlock from 'hooks/use-page-block';
import BlockSettings from './BlockSettings';
import { StyledQuickEditOverlay, StyledIFrame } from './styles';
import { IPageBlockProps } from 'services/app/models/ILandingPageContent';

export interface IEmbedCodeItem {
  arrayId: string;
  data: IEmbedCode;
  kind: string;
}

type IEmbedCodeBlockProps = IPageBlockProps<IEmbedCodeItem>;

type EditableStringPath = 'url';

const EmbedBlock: React.FC<IEmbedCodeBlockProps> = ({
  item,
  onEdit,
  onRemove,
  onMoveDown,
  onMoveUp,
  pageId,
}) => {
  const isEditing = useSelector(isEditMode);
  const iframeId = `embed-iframe-${item.arrayId}`;

  useEffect(() => {
    const handleIframeMessage = (event: MessageEvent) => {
      if (!event?.data || event.data?.type !== 'resizeIframe') {
        return;
      }

      if (!event?.data?.iframeHeight || !event?.data?.blockId || event.data.blockId !== item.arrayId) {
        return;
      }

      const iframe = document.getElementById(iframeId);
      const newHeight = event.data.iframeHeight + 'px';
      if (iframe && iframe.style.height !== newHeight) {
        iframe.style.height = newHeight;
      }
    };

    window.addEventListener('message', handleIframeMessage);

    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);


  const handleChangeData = React.useCallback((updatedData: IEmbedCode) => {
    onEdit({ ...item, data: updatedData });
  }, [item, onEdit]);

  const { handleChange, handleCloseSettings, handleOpenSettings, isSettingsOpen } = usePageBlock<EditableStringPath, IEmbedCode>({
    item: item.data,
    onChange: handleChangeData,
  });

  useEffect(() => {
    if (isEditing && !item.data?.url) {
      handleOpenSettings();
    }
  }, [isEditing, item.data?.url, handleOpenSettings]);

  return (
    <StyledQuickEditOverlay
      onRemove={onRemove}
      titleKey="EMBED_CODE"
      onEdit={handleOpenSettings}
      testId={`embed-code-block-${item.arrayId}`}
      onMoveDown={onMoveDown}
      onMoveUp={onMoveUp}
    >
      <StyledIFrame id={iframeId} key={iframeId} src={item?.data?.url} />
      { isSettingsOpen && (
        <BlockSettings
          isOpen={isSettingsOpen}
          onClose={handleCloseSettings}
          item={item.data}
          onChange={handleChange('url')}
          pageId={pageId || ''}
          blockId={item.arrayId}
        />
      )
      }
    </StyledQuickEditOverlay>
  );
};

export default EmbedBlock;

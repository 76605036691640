import React from 'react';
import { IHeroBanner } from '../../utils';
import { GradientLayer, Image } from './styles';
import { useAdminTranslation } from 'hooks/use-translation';

interface IMobileImageProps extends Omit<IHeroBanner['background'], 'desktop' | 'href'> {
  imageHeight: number;
  imageRef: (node: HTMLDivElement | null) => void;
  onError: () => void;
}

const MobileImage: React.FC<IMobileImageProps> = ({ onError, bleed, gradientMask, mobile, imageRef, imageHeight }) => {
  const { t } = useAdminTranslation();
  return (
    <>
      <Image onError={onError} ref={imageRef} bleed={bleed} src={mobile} alt={t('LABEL_HERO_BANNER')} />
      {gradientMask && <GradientLayer imageHeight={imageHeight} />}
    </>
  );
};

export default MobileImage;

import styled, { css } from 'styled-components';
import Icon from 'components/ui/Icon';
import { ACCENT_PRIMARY, HIGHLIGHT_SECONDARY, SURFACE_2, SURFACE_4, SURFACE_5, TEXT_100, TEXT_300 } from 'style/constants';
import { TEXT_LABEL_M_BOLD, TEXT_LABEL_M_MEDIUM, TEXT_LABEL_S_MEDIUM, TEXT_LABEL_XS_BOLD } from 'style/design-system/textStyles';
import ReactSelect from 'react-select';
import { SINGLE_LINE_ELLIPSIS } from 'style/mixins';
import { Label } from 'components/page-blocks/EventCalendar/EventCard/Labels/styles';

export const FilterWrapper = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
  gap: 8px;
`;

export const FilterTitle = styled.span`
  ${TEXT_LABEL_M_BOLD}
  color: ${TEXT_100};
`;

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const FilterBy = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${TEXT_300};
  ${TEXT_LABEL_M_MEDIUM}
`;


export const AllOptionsLabel = styled.span<{ isSelected: boolean; }>`
  ${TEXT_LABEL_S_MEDIUM}
  color: ${TEXT_100} !important;
  ${({ isSelected }) => !isSelected && css`margin-left: 28px;`};
`;

export const StyledLabel = styled(Label)<{ isSelected: boolean; }>`
  ${({ isSelected }) => !isSelected && css`margin-left: 28px;`};
  cursor: pointer;
  ${TEXT_LABEL_XS_BOLD}
  ${SINGLE_LINE_ELLIPSIS}
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const CheckIcon = styled(Icon).attrs(props => ({
  color: ACCENT_PRIMARY(props),
  name: 'successCheckmark',
}))`
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 12.75px;
    height: 12.75px;
  }
`;


export const FilterIcon = styled(Icon).attrs({
  name: 'textAlignmentCenter',
})`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 18px;
    height: 18px;

    & path {
      fill: ${HIGHLIGHT_SECONDARY};
    }
  }
`;

export const StyledReactSelect = styled(ReactSelect).attrs(props => ({
  styles: {
    option: () => ({
      display: 'flex',
      height: '32px',
      padding: '0px 4px',
      alignItems: 'center',
      gap: '8px',
      alignSelf: 'stretch',
      cursor: 'pointer',
      '&:hover': {
        background: SURFACE_2(props),
      },
    }),
    menu: (base) => ({
      ...base,
      marginTop: '2px',
      background: SURFACE_4(props),
      borderRadius: '5px',
      border: `1px solid ${SURFACE_5(props)}`,
      zIndex: 2,
    }),
    menuList: () => ({}),
    control: () => ({
      cursor: 'pointer',
      display: 'flex',
      padding: '0px 4px 0px 14px',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '8px',
      alignSelf: 'stretch',
      background: SURFACE_4(props),
      height: '32px',
      borderRadius: '5px',
      '&:hover': {
        background: SURFACE_5(props),
      },
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0',
    }),
    dropdownIndicator: () => ({
      color: ACCENT_PRIMARY(props),
      display: 'flex',
    }),
    indicatorsContainer: () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    indicatorSeparator: () => ({}),
  },
  isMulti: false,
  isSearchable: false,
  controlShouldRenderValue: true,
}))`
  min-width: 110px;
`;

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { rgba } from 'colors';
import TranslatedText from 'components/i18n/TranslatedText';
import CloseButton from 'components/ui/CloseButton';
import Icon from 'components/ui/Icon';
import { SURFACE_3, SPACING_SMALL, TEXT_100 } from 'style/constants';
import { PADDING_SMALL } from 'style/mixins';

const Container = styled.div`
  align-items: stretch;
  background-color: ${props => rgba(SURFACE_3(props), 0.9)};
  color: ${TEXT_100};
  display: flex;
  flex-flow: row nowrap;
  & > :not(:first-child) {
    margin-left: ${SPACING_SMALL};
  }
`;

const LeftSidebar = styled.div`
  padding: ${SPACING_SMALL};
  padding-right: 0;
`;

const Message = styled.div`
  flex: 1 1 auto;
  padding: ${SPACING_SMALL} 0;
`;

const Loader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  ${PADDING_SMALL};
  padding-left: 0;
`;

export default class RafflePickingOverlay extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      key: PropTypes.string.isRequired,
    }).isRequired,
    onDismiss: PropTypes.func.isRequired,
    overlayType: PropTypes.string.isRequired,
    trackDismiss: PropTypes.func.isRequired,
  };

  dismissRaffle = () => {
    const { onDismiss, trackDismiss, data } = this.props;
    onDismiss(data.key);
    trackDismiss(data);
  };

  render() {
    const { overlayType } = this.props;
    return (
      <Container className={overlayType}>
        <LeftSidebar>
          <CloseButton onClick={this.dismissRaffle} />
        </LeftSidebar>
        <Message>
          <TranslatedText stringKey="RAFFLE_PICKING" />
        </Message>
        <Loader><Icon name="spin2" /></Loader>
      </Container>
    );
  }
}

import React from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { OptionRow } from 'components/admin-bridge/EditorModal';
import { IFAQData } from '../utils';
import { Section, SubSectionTitle } from './styles';
import { TEXT_ALIGNMENT_OPTIONS } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';
import usePageBlock from 'hooks/use-page-block';
import usePageBlockBackgroundColorOptions from 'components/admin-bridge/EditorModal/ModalComponents/BackgroundColorOptions/use-page-block-background-color-options';
import BackgroundColorOptions from 'components/admin-bridge/EditorModal/ModalComponents/BackgroundColorOptions';
import PageBlockSettings from 'components/admin-bridge/PageBlockSettings';

interface IBlockSettingsProps {
  isOpen: boolean;
  item: IFAQData;
  onChange: (item: IFAQData) => void;
  onClose: () => void;
}

type EditableStringPaths = 'alignment.horizontal' | 'backgroundColor' | 'faqCardColor';

const BlockSettings = ({
  item,
  isOpen,
  onClose,
  onChange,
}: IBlockSettingsProps) => {
  const { isCustomColor } = usePageBlockBackgroundColorOptions(item.backgroundColor.color);
  const { handleChange } = usePageBlock<EditableStringPaths, IFAQData>({
    item,
    onChange,
  });

  const handleChangeBackgroundColor = React.useCallback((value: string) => {
    const newItem: IFAQData = structuredClone(item);

    newItem.backgroundColor.color = value;
    if (isCustomColor(value)) {
      newItem.backgroundColor.customColor = value;
    }

    onChange(newItem);
  }, [item, onChange, isCustomColor]);

  const handleChangeFaqCardBackgroundColor = React.useCallback((value: string) => {
    const newItem: IFAQData = structuredClone(item);

    newItem.faqCardColor.color = value;
    if (isCustomColor(value)) {
      newItem.faqCardColor.customColor = value;
    }

    onChange(newItem);
  }, [item, onChange, isCustomColor]);

  return (
    <PageBlockSettings
      isOpen={isOpen}
      titleKey="ADMIN_LABEL_BLOCK_SETTINGS"
      onClose={onClose}
    >
      <Section gap={20}>
        <Section gap={16}>
          <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_ALIGNMENT" />
          <OptionRow
            options={TEXT_ALIGNMENT_OPTIONS}
            value={item.alignment.horizontal}
            onChange={handleChange('alignment.horizontal')}
            wrapText={true}
          />
        </Section>
        <Section gap={16}>
          <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_BACKGROUND_COLOR" />
          <BackgroundColorOptions
            lastCustomColor={item.backgroundColor.customColor}
            color={item.backgroundColor.color}
            onChange={handleChangeBackgroundColor}
          />
        </Section>
        <Section gap={16}>
          <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_FAQ_CARDS_COLOR" />
          <BackgroundColorOptions
            lastCustomColor={item.faqCardColor.customColor}
            color={item.faqCardColor.color}
            onChange={handleChangeFaqCardBackgroundColor}
          />
        </Section>
      </Section>
    </PageBlockSettings>
  );
};

export default BlockSettings;

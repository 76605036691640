import { OverflowBehavior } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';
import { mobileOnly } from 'style/mixins';
import styled, { css } from 'styled-components';

const SCROLLABLE_CSS = css`
  display: flex;
  & > * {
    min-width: calc(100% - 80px);
  }
`;

const NON_SCROLLABLE_CSS = css<{ imagesPerRow: number; overflowBehavior: OverflowBehavior; }>`
  display: grid;
  grid: ${({ overflowBehavior }) => overflowBehavior === 'stack' ? 'auto-flow' : '1fr'} / repeat(${({ imagesPerRow }) => imagesPerRow}, 1fr);
  grid-auto-rows: min-content;
  & > * {
    min-width: 0;
  }
`;

export const GalleryWrapper = styled.div<{
  imagesPerRow: number;
  isScrollable: boolean;
  overflowBehavior: OverflowBehavior;
}>`
  position: relative;
  ${({ overflowBehavior }) => css`
    gap: min(1vw, ${overflowBehavior === 'stack' ? 60 : 40}px);
    ${mobileOnly`
      ${overflowBehavior === 'stack' && 'gap: 40px;'}
      ${overflowBehavior === 'slider' && 'gap: 20px;'}
    `};
  `};
  ${({ isScrollable }) => isScrollable ? SCROLLABLE_CSS : NON_SCROLLABLE_CSS};
`;

/* eslint react/destructuring-assignment: off */
import React from 'react';
import PropTypes from 'prop-types';
import TranslatedText from 'components/i18n/TranslatedText';
import Link from 'components/ui/Link';
import Icon from 'components/ui/Icon';
import AdminOverlayEngagement from 'components/admin/AdminOverlayEngagement';
import {
  Container,
  WithImageContainer,
  CloseButtonContainer,
  WithImageCloseButtonContainer,
  StyledCloseButton,
  WithImageStyledCloseButton,
  TweetContainer,
  WithImageTweetContainer,
  Username,
  StyledTwitterIcon,
  RetweetButton,
  StyledLinkify,
  Wrapper,
} from './styles';

const BASE_RETWEET_URL = 'https://twitter.com/intent/retweet?tweet_id=';
const BASE_PROFILE_URL = 'https://twitter.com/';

export default class TweetOverlay extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      key: PropTypes.string,
      payload: PropTypes.object,
    }).isRequired,
    onDismiss: PropTypes.func.isRequired,
    onEngagedOverlay: PropTypes.func.isRequired,
    onShowPost: PropTypes.func.isRequired,
    overlayType: PropTypes.string.isRequired,
    renderer: PropTypes.shape({
      status: PropTypes.shape({}),
    }),
    trackDismiss: PropTypes.func.isRequired,
    trackEngage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    renderer: {},
  };

  dismissOverlay = (e) => {
    const { data: { key }, onDismiss, trackDismiss } = this.props;
    onDismiss(key);
    trackDismiss(this.props.data);
    e.stopPropagation();
  };

  openModal = (e) => {
    if (e.target !== e.currentTarget) {
      return;
    }

    const {
      data,
      onEngagedOverlay,
      onShowPost,
      trackEngage,
    } = this.props;
    const { payload: { data: { broadcastId, renderer } } } = data;

    const { status: { id, mediaUrl } } = renderer;
    const retweetUrl = `${BASE_RETWEET_URL}${id}`;

    onEngagedOverlay(broadcastId);
    onShowPost({ mediaUrl, overlayData: data, retweetUrl });
    trackEngage(this.props.data, {
      action: 'image',
    });
  };

  onRetweetClick = () => {
    const {
      data: { payload: { data: { broadcastId } } },
      onEngagedOverlay,
      trackEngage,
      data,
    } = this.props;
    trackEngage(data, {
      action: 'retweet',
    });
    onEngagedOverlay(broadcastId);
  };

  render() {
    const {
      data: {
        key,
        payload: {
          data: { broadcastId, renderer = {}, views },
        },
      },
    } = this.props;
    let { status: tweetData } = renderer;

    // fallback for broadcsts from old admin
    tweetData = tweetData || this.props.renderer?.status;
    if (!tweetData) {
      return null;
    }

    const { id, mediaUrl, text, user: { screenName } } = tweetData;
    const retweetUrl = `${BASE_RETWEET_URL}${id}`;
    const profileUrl = `${BASE_PROFILE_URL}${screenName}`;

    const ContainerComponent = mediaUrl ? WithImageContainer : Container;
    const containerProps = mediaUrl ? { onClick: this.openModal, url: mediaUrl } : {};

    const CloseButtonContainerComponent = mediaUrl ?
      WithImageCloseButtonContainer : CloseButtonContainer;
    const TweetContainerComponent = mediaUrl ? WithImageTweetContainer : TweetContainer;
    const CloseButtonComponent = mediaUrl ? WithImageStyledCloseButton : StyledCloseButton;

    const { overlayType } = this.props;

    return (
      <Wrapper>
        <AdminOverlayEngagement
          broadcastId={broadcastId}
          broadcastKey={key}
          showEngaged
          views={views}
        />
        <ContainerComponent {...containerProps} className={overlayType}>
          <CloseButtonContainerComponent onClick={this.dismissOverlay}>
            <CloseButtonComponent
              onClick={() => {}}
            />
          </CloseButtonContainerComponent>

          <TweetContainerComponent>
            <StyledTwitterIcon />
            <Link href={profileUrl}>
              { screenName && <Username>{ `@${screenName}` }</Username> }
            </Link>
            <StyledLinkify>{ text }</StyledLinkify>
          </TweetContainerComponent>
          {
            !mediaUrl && (
              <Link href={retweetUrl} onClick={e => this.onRetweetClick(e, retweetUrl)}>
                <RetweetButton>
                  <Icon name="retweet" />
                  <TranslatedText stringKey="RETWEET" />
                </RetweetButton>
              </Link>
            )
          }
        </ContainerComponent>
      </Wrapper>
    );
  }
}

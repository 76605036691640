import TranslatedText from 'components/i18n/TranslatedText';
import React from 'react';
import {
  Container,
  TopHeader,
  BottomHeader,
  SettingsLabel,
  TitleContainer,
  UserProfileLabel,
  LogoutButton,
  ProfileSettingsLabel,
  CloseButtonIcon,
  CloseIcon,
} from './styles';

interface ProfilePanelHeaderProps {
  onClose: () => void;
  onLogout: () => void;
}


const ProfilePanelHeader: React.FC<ProfilePanelHeaderProps> = ({ onClose, onLogout }) => {

  const handleLogout = () => {
    onClose();
    onLogout();
  };

  return (
    <Container>
      <TopHeader>
        <TitleContainer>
          <SettingsLabel>
            <TranslatedText stringKey={'ADMIN_LABEL_SETTINGS'} />
          </SettingsLabel>
          <UserProfileLabel>
            <TranslatedText stringKey={'USER_PROFILE_TITLE'} />
          </UserProfileLabel>
        </TitleContainer>
        <CloseButtonIcon onClick={onClose}>
          <CloseIcon name="cancel-thin" />
        </CloseButtonIcon>
      </TopHeader>
      <BottomHeader>
        <ProfileSettingsLabel>
          <TranslatedText stringKey={'USER_PROFILE_SETTINGS'} />
        </ProfileSettingsLabel>
        <LogoutButton onClick={handleLogout}>
          <TranslatedText stringKey={'ACTION_LOG_OUT'} />
        </LogoutButton>
      </BottomHeader>
    </Container>
  );
};

export default ProfilePanelHeader;

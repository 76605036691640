import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import defaultPersonImage from 'assets/default-person.svg';
import TranslatedText from 'components/i18n/TranslatedText';
import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';
import { getOrdinalSuffix } from 'shared/string-utils';
import { useDispatch } from 'hooks';
import { loginModal } from 'services/modals';
import {
  SPACING_MEDIUM,
  SPACING_X_SMALL,
  TEXT_100,
  SPACING_SMALL,
  ACCENT_PRIMARY,
  SURFACE_1,
  SURFACE_3,
} from 'style/constants';
import {
  BACKGROUND_ACTIVE,
  DIV_BUTTON,
  TEXT_H5_BOLD,
  TEXT_LABEL3,
  TEXT_H6,
  TEXT_H6_BOLD,
} from 'style/mixins';

const propTypes = {
  description: PropTypes.string,
  isLeaderboardLoaded: PropTypes.bool.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  isYourPointsLoaded: PropTypes.bool.isRequired,
  isYourRankLoaded: PropTypes.bool.isRequired,
  leaderboardData: PropTypes.arrayOf(PropTypes.object),
  tasks: PropTypes.arrayOf(PropTypes.shape({
    iconName: PropTypes.string,
    name: PropTypes.string,
    pointsValue: PropTypes.number,
    type: PropTypes.string,
  })),
  yourPoints: PropTypes.number,
  yourRank: PropTypes.number,
};

const defaultProps = {
  description: '',
  leaderboardData: null,
  tasks: [],
  yourPoints: null,
  yourRank: null,
};

const Container = styled.div`
  flex: 1;
  background-color: ${SURFACE_3};
`;

const Header = styled.div`
  align-items: center;
  height: ${props => props.reducePadding ? '96px' : '110px'};
  padding: 16px ${props => props.reducePadding ? '16px' : SPACING_MEDIUM};
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  border-bottom: 2px solid ${SURFACE_1};
`;

const HeaderText = styled.div`
  text-transform: uppercase;
  ${TEXT_H6}
  opacity: 0.7;
  margin-bottom: ${SPACING_X_SMALL};
`;

const StatsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

const YourRank = styled.div`
  font-size: 24px;
`;

const YourPoints = styled.div`
  ${TEXT_H5_BOLD}
  margin-top: 8px;
`;

const Body = styled.div`
  padding: ${SPACING_MEDIUM};
`;

const EarnPointsLabel = styled.div`
  ${TEXT_LABEL3}
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.33px;
  display: flex;
  align-items: center;
  color: ${TEXT_100};
`;

const EarnPointsDescription = styled.div`
  opacity: 0.7;
  ${TEXT_H6}
`;

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const BackButton = styled(Icon).attrs({
  name: 'left-open-big',
})`
  display: inline-block;
  font-size: 18px;
  line-height: 36px;
  margin-right: ${SPACING_MEDIUM};
  ${DIV_BUTTON};
  color: ${TEXT_100};
`;

const TasksOverview = styled.div`
  margin: 0 ${SPACING_SMALL};
`;

const TasksHeader = styled.div`
  margin-top: ${SPACING_MEDIUM};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${TEXT_100};
  margin-right: 5px;
`;

const HeaderLabel = styled.div`
  ${TEXT_H6_BOLD}
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const TaskRow = styled(Row)`
  align-items: center;
  min-height: 65px;
  margin-right: 5px;
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  color: TEXT_100(props),
}))`
  font-size: 1.5em;
  margin-right: 0.3em;
`;

const AchievementName = styled.div`
  max-width: 70%;
`;

const PointsValue = styled.div`
  margin-left: auto;
`;

const RankContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${props => props.highlight ? css`color: ${ACCENT_PRIMARY(props)};` : ''}
  padding-top: 5px;
`;

const Rank = styled.div`
  font-size: 24px;
  opacity: ${props => props.highlight ? 1 : 0.7};
  margin-top: 7px;
`;

const UserDataContainer = styled.div`
  width: 85%;
  display: flex;
  align-items: flex-start;
`;

const Username = styled.div`
  ${TEXT_LABEL3}
  letter-spacing: 0.7px;
  font-weight: bold;
  word-break: break-all;
`;

const Points = styled.div`
  ${TEXT_H6_BOLD}
  letter-spacing: 0.7px;
  opacity: ${props => props.highlight ? 1 : 0.7};
`;

const RankSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const EarnPointsButton = styled(Button)`
  border-radius: 3px;
  padding: 0 10px;
  min-width: 137px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 18px;
  text-transform: uppercase;
  ${DIV_BUTTON};
  ${BACKGROUND_ACTIVE};
  color: ${TEXT_100};
`;

const ProfilePic = styled.div`
  background-color: ${SURFACE_1};
  background-image: url(${props => props.src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
  border: 1px solid ${props => props.highlight ? ACCENT_PRIMARY(props) : SURFACE_1(props)};
  height: 30px;
  margin-right: 16px;
  min-width: 30px;
`;

const NoLeaderboard = styled.div`
  margin-top: 40px;
  opacity: 0.7;
  ${TEXT_LABEL3}
  letter-spacing: 0.7px;
  text-align: center;
  font-weight: bold;
`;

const LoginButton = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  ${BACKGROUND_ACTIVE};
  ${DIV_BUTTON};
`;

const OpaqueYourRank = styled(YourRank)`opacity: 0.7;`;
const OpaqueYourPoints = styled(YourPoints)`opacity: 0.7;`;

const formatRank = (numStr) => {
  const number = Number(numStr);
  return number.toLocaleString() + getOrdinalSuffix(number);
};

const formatPoints = numStr => Number(numStr).toLocaleString();

const renderYourRank = (yourPoints, yourRank) => {
  if (!(yourPoints && yourRank)) {
    return (
      <>
        <OpaqueYourRank>
          <TranslatedText stringKey="LABEL_NOT_RANKED" />
        </OpaqueYourRank>
        <OpaqueYourPoints>
          {
            yourPoints ? `${formatPoints(yourPoints)} ` : '0 '
          }
          <TranslatedText stringKey="LABEL_POINTS" />
        </OpaqueYourPoints>
      </>
    );
  }
  return (
    <>
      <YourRank>
        {
          formatRank(yourRank)
        }
      </YourRank>
      <YourPoints>
        {
          formatPoints(yourPoints)
        }
        {' '}
        <TranslatedText stringKey="LABEL_POINTS" />
      </YourPoints>
    </>
  );
};

const renderAchievementTasks = (tasks, setViewTasks, description) => (
  <TasksOverview>
    <Row>
      <BackButton onClick={() => setViewTasks(false)} />
      <TranslatedText component={EarnPointsLabel} stringKey="EARN_POINTS_LABEL" />
    </Row>
    {
      description ?
        <EarnPointsDescription>{description}</EarnPointsDescription> :
        <TranslatedText component={EarnPointsDescription} stringKey="EARN_POINTS_DESCRIPTION" />
    }
    <TasksHeader>
      <TranslatedText component={HeaderLabel} stringKey="LABEL_TASK" />
      <TranslatedText component={HeaderLabel} stringKey="LABEL_POINTS" />
    </TasksHeader>
    {
      tasks.map(({ _id, iconName, name, pointsValue }) => (
        <TaskRow key={_id}>
          <StyledIcon name={iconName} />
          <AchievementName>{name}</AchievementName>
          <PointsValue>{ pointsValue }</PointsValue>
        </TaskRow>
      ))
    }
  </TasksOverview>
);

const renderLeaderboard = (leaderboard, viewingUserRank) => {
  if (!leaderboard || leaderboard.length === 0) {
    return (
      <NoLeaderboard>
        <TranslatedText stringKey="DESC_LEADERBOARD_EMPTY" />
      </NoLeaderboard>
    );
  }
  return leaderboard.map(({ rank, points, image, username }) => {
    const isViewingUser = (rank === viewingUserRank);
    return (
      <RankContainer key={`${rank}:${username}`} highlight={isViewingUser}>
        <Rank highlight={isViewingUser}>
          {
            rank
          }
        </Rank>
        <UserDataContainer>
          <ProfilePic highlight={isViewingUser} src={image || defaultPersonImage} />
          <div>
            <Username>
              { username }
            </Username>
            <Points highlight={isViewingUser}>
              { formatPoints(points) }
              {' '}
              <TranslatedText stringKey="LABEL_POINTS" />
            </Points>
          </div>
        </UserDataContainer>
      </RankContainer>
    );
  });
};

const LeaderboardPanel = ({
  isYourRankLoaded,
  description,
  yourRank,
  isUserLoggedIn,
  isLeaderboardLoaded,
  leaderboardData,
  isYourPointsLoaded,
  tasks,
  yourPoints,
}) => {
  const dispatch = useDispatch();
  const [viewTasks, setViewTasks] = useState(false);

  return (
    <Container>
      {
        viewTasks ?
          renderAchievementTasks(tasks, setViewTasks, description) : (
            <>
              <Header reducePadding={!isUserLoggedIn}>
                {
                !isUserLoggedIn ? (
                  // eslint-disable-next-line max-len
                  <LoginButton onClick={() => dispatch(loginModal())}>
                    <TranslatedText stringKey="LABEL_LEADERBOARD_LOGIN_PROMPT" />
                  </LoginButton>
                ) : (
                  <>
                    <RankSection>
                      <HeaderText>
                        <TranslatedText stringKey="LABEL_YOUR_RANK" />
                      </HeaderText>
                      <StatsContainer>
                        {
                          isYourRankLoaded &&
                          isYourPointsLoaded &&
                          renderYourRank(yourPoints, yourRank, isUserLoggedIn)
                        }
                      </StatsContainer>
                    </RankSection>
                    <TranslatedText
                      component={EarnPointsButton}
                      componentProps={{
                        onClick: () => setViewTasks(true),
                      }}
                      stringKey="EARN_POINTS"
                    />
                  </>
                )
              }
              </Header>
              <Body>
                { isLeaderboardLoaded && renderLeaderboard(leaderboardData, yourRank) }
              </Body>
            </>
          )
      }
    </Container>
  );
};

LeaderboardPanel.propTypes = propTypes;
LeaderboardPanel.defaultProps = defaultProps;

export default LeaderboardPanel;

import usePageBlockBackgroundColorOptions from 'components/admin-bridge/EditorModal/ModalComponents/BackgroundColorOptions/use-page-block-background-color-options';
import { IShopifyBlockData } from '../utils';
import useEditPageBlock from 'hooks/use-page-block';
import React from 'react';

type EditableStringPaths = (
  'background.image.mobile' |
  'background.image.desktop' |
  'background.customColor' |
  'background.color' |
  'background.image.show' |
  'showProductDescription' |
  'showProductName' |
  'showProductDescription' |
  'showProductPrice' |
  'showProductCta' |
  'returnPolicy' |
  'termsOfUse' |
  'productsPerRow' |
  'overflowBehavior.desktop' |
  'overflowBehavior.mobile' |
  'aspectRatio.desktop' |
  'aspectRatio.mobile' |
  'aspectRatio.mobileEqualDesktop' |
  'shopifyCollectionHandle' |
  'productView'
);

const useShopifyBlockSettings = (item: IShopifyBlockData, onChange: (item: IShopifyBlockData) => void) => {
  const { isCustomColor } = usePageBlockBackgroundColorOptions(item.background.color);
  const { handleChange } = useEditPageBlock<EditableStringPaths, IShopifyBlockData>({
    item,
    onChange,
  });

  const handleClearDesktopBackground = React.useCallback(() => {
    handleChange('background.image.desktop')('');
  }, [handleChange]);

  const handleClearMobileBackground = React.useCallback(() => {
    handleChange('background.image.mobile')('');
  }, [handleChange]);

  const handleChangeBackgroundColor = React.useCallback((value: string) => {
    const newItem: IShopifyBlockData = structuredClone(item);

    newItem.background.color = value;
    if (isCustomColor(value)) {
      newItem.background.customColor = value;
    }

    onChange(newItem);
  }, [item, onChange, isCustomColor]);

  return {
    handleChange,
    handleClearDesktopBackground,
    handleClearMobileBackground,
    handleChangeBackgroundColor,
  };
};

export default useShopifyBlockSettings;

import React from 'react';
import {
  AllFiltersTitle,
  CheckIcon,
  Filter,
  FilterIcon,
  StyledLabel,
  FilterOptions,
  FilterTitle,
  Filters,
  MobileFilterText,
  MobileFilterWrapper,
  StyleButton,
} from './styles';
import { useAdminTranslation } from 'hooks/use-translation';
import { ISelectedSiteCategories, useEventCalendarContext } from '../../EventCalendarContext';
import MobileModalBottomSheet from 'components/mobile/MobileModalBottomSheet';
import { IFilterOption } from '../../WeekCalendar/Filters';

interface IOptionProps {
  filterTitle: string;
  option: IFilterOption;
  selectedSiteCategories: ISelectedSiteCategories;
  setSelectedSiteCategories: (filters: ISelectedSiteCategories) => void;
}

const Option: React.FC<IOptionProps> = ({ option, filterTitle, selectedSiteCategories, setSelectedSiteCategories }) => {
  const { t } = useAdminTranslation();
  const isSelected = selectedSiteCategories[filterTitle] && selectedSiteCategories[filterTitle].value === option.value;
  const title = !option.value ? t('ADMIN_LABEL_ALL') : option.value;

  const onChangeFilter = () => {
    setSelectedSiteCategories({
      ...selectedSiteCategories,
      [filterTitle]: option,
    });
  };

  return (
    <StyledLabel onClick={onChangeFilter} isSelected={isSelected} backgroundColor={option.color}>
      {isSelected && <CheckIcon />}
      {title}
    </StyledLabel>
  );
};

const MiniCalendarMobileFilters = () => {
  const { siteCategories, selectedSiteCategories, setSelectedSiteCategories } = useEventCalendarContext();
  const [localSelectedSiteCategories, setLocalSelectedSiteCategories] = React.useState(selectedSiteCategories);

  React.useEffect(() => {
    setLocalSelectedSiteCategories(selectedSiteCategories);
  }, [selectedSiteCategories]);

  const { t } = useAdminTranslation();
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpenFilterModal = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleCloseFilterModal = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSave = React.useCallback(() => {
    setSelectedSiteCategories(localSelectedSiteCategories);
    setIsOpen(false);
  }, [localSelectedSiteCategories, setSelectedSiteCategories]);

  return (
    <>
      <MobileFilterWrapper onClick={handleOpenFilterModal}>
        <MobileFilterText>Filter</MobileFilterText>
        <FilterIcon isOpen={isOpen} />
      </MobileFilterWrapper>

      <MobileModalBottomSheet
        isOpen={isOpen}
        onClose={handleCloseFilterModal}
        titleKey="ADMIN_LABEL_FILTER_BY"
      >
        <Filters>
          <AllFiltersTitle>{t('ADMIN_LABEL_CATEGORIES')}</AllFiltersTitle>
          {
            siteCategories.map((siteCategory, key) => {
              return (
                <Filter key={`${siteCategory.title}-${key}`}>
                  <FilterTitle>{siteCategory.title}</FilterTitle>
                  <FilterOptions>
                    {siteCategory.labels.map(option => (
                      <Option
                        key={siteCategory.title + option.value}
                        filterTitle={siteCategory.title}
                        setSelectedSiteCategories={setLocalSelectedSiteCategories}
                        selectedSiteCategories={localSelectedSiteCategories}
                        option={option}
                      />
                    ))}
                  </FilterOptions>
                </Filter>
              );
            })
          }
        </Filters>
        <StyleButton onClick={handleSave}>{t('ADMIN_LABEL_APPLY')}</StyleButton>
      </MobileModalBottomSheet>
    </>
  );
};

export default MiniCalendarMobileFilters;

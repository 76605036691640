import { TEXT_100, TEXT_200 } from 'style/constants';
import { TEXT_LABEL_M_BOLD, TEXT_TITLE_M, TEXT_TITLE_S } from 'style/design-system/textStyles';
import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  height: 56px;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const WeekControls = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const Title = styled.h5`
  margin: 0;
  color: ${TEXT_100};
  ${TEXT_TITLE_M}
`;

export const Timezone = styled.span`
  ${TEXT_TITLE_S}
  color: ${TEXT_200};
`;

export const SubHeader = styled.div`
  display: flex;
  padding: 12px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const SelectedDate = styled.div`
  display: flex;
  padding: 0px 10px;
  align-items: center;
  cursor: pointer;
  gap: 7px;
  align-self: stretch;
  ${TEXT_LABEL_M_BOLD}
  color: ${TEXT_200};
`;

import styled from 'styled-components';
import UploadZone from 'components/admin2/UploadZone';
import Label from 'components/admin2/ui/Label';

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledLabel = styled(Label)`
  margin-bottom: 16px;
`;

export const UploadZoneWrapper = styled.section`
  display: flex;
  flex-direction: column;
`;


export const DesktopBackgroundUploadZone = styled(UploadZone).attrs(() => ({
  canDrop: true,
  dimensionRequirements: {
    pixelHeight: 1080,
    pixelWidth: 1920,
  },
  height: 177,
  padding: '0',
}))``;

export const MobileBackgroundUploadZone = styled(UploadZone).attrs(() => ({
  canDrop: true,
  dimensionRequirements: {
    pixelHeight: 1200,
    pixelWidth: 750,
  },
  height: 177,
  padding: '0',
}))``;

import React from 'react';
import useCalendarEvent from '../use-calendar-event';
import { IEventCardProps } from '../../utils';
import { ContentContainer, ExpandableFullEventCard, MinimizedInfo, MinimizedLiveBadge, MinimizedTitle, SecondaryInfo, StyledLabels, Time, TimeContainer, Title, Wrapper } from './styles';
import FullEventCard from '../FullEventCard';
import LiveBadge from 'components/ui/LiveBadge';
import { getRectPositionCheckingBounds } from 'dom-utils';
import { PREDEFINED_TOOLBAR_BUTTONS } from 'components/ui/RichTextEditorV2/utils';
import { useSelector } from 'react-redux';
import { getIsRichTextEditorV3Enabled } from 'services/feature-gate/selectors';

const MiniEventCard: React.FC<IEventCardProps> = ({ event, onChange, onRemove, showActionsMenu }) => {
  const isRichTextEditorV3Enabled = useSelector(getIsRichTextEditorV3Enabled);
  const { titleRaw, titleRawV2 } = event;
  const useRawDataV2 = Boolean(isRichTextEditorV3Enabled && titleRawV2);
  const title = useRawDataV2 ? titleRawV2 : titleRaw;
  const { isLive, labels, when } = useCalendarEvent(event, true);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const positionMenu = React.useCallback((menu: HTMLDivElement | null) => {
    const menuContainer = containerRef.current;
    if (!menu || !menuContainer) {
      return;
    }
    const menuContainerRect = menuContainer.getBoundingClientRect();
    const safePosition = getRectPositionCheckingBounds({
      elem: menu,
      margin: 20,
      position: {
        x: menuContainerRect.right - menuContainer.clientWidth,
        y: menuContainerRect.top + menuContainer.clientHeight,
      },
    });
    menu.style.left = `${safePosition.x}px`;
    menu.style.top = `${safePosition.y}px`;
  }, [containerRef]);

  const handleMouseEnter = React.useCallback(() => {
    if (!isExpanded) {
      setIsExpanded(true);
    }
  }, [isExpanded]);

  const handleMouseLeave = React.useCallback(() => {
    if (isExpanded) {
      setIsExpanded(false);
    }
  }, [isExpanded]);

  return (
    <Wrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} firstLabelColor={labels[0]?.color} ref={containerRef}>
      <ContentContainer>
        <Title
          data={title}
          useRawDataV2={useRawDataV2}
          blockTitleKey="ADMIN_LABEL_TITLE"
          maxLines={3}
          toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.titleEditor}
          data-testid="calendarEventTitle"
        />

        {/* This content only shows up when window width is minimum */}
        <MinimizedInfo>
          <MinimizedTitle
            useRawDataV2={useRawDataV2}
            data={title}
            blockTitleKey="ADMIN_LABEL_TITLE"
            maxLines={1}
            toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.titleEditor}
            data-testid="calendarEventTitle"
          />
          {isLive && <MinimizedLiveBadge />}
        </MinimizedInfo>
        {/* This content only shows up when window width is minimum */}

        <SecondaryInfo>
          <TimeContainer>
            <Time>{when}</Time>
            {isLive && <LiveBadge.Default />}
          </TimeContainer>
          <StyledLabels labels={labels} />
        </SecondaryInfo>
      </ContentContainer>

      {
        isExpanded && (
          <ExpandableFullEventCard ref={positionMenu}>
            <FullEventCard
              readOnly={true}
              event={event}
              onChange={onChange}
              showActionsMenu={showActionsMenu}
              onRemove={onRemove}
            />
          </ExpandableFullEventCard>
        )
      }
    </Wrapper>
  );
};

export default MiniEventCard;

import React from 'react';
import set from 'lodash/set';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import { getCurrentPageId } from 'services/app/selectors';
import TranslatedText from 'components/i18n/TranslatedText';
import usePageBlock from 'hooks/use-page-block';
import { DynamicTranslationType, useEndUserInlineTextEditorTranslation } from 'hooks/use-translation';
import BlockSettings from './BlockSettings';
import { StyledQuickEditOverlay, NewFAQBlock, PlusIcon, RelativeWrapper, Title } from './styles';
import FAQBlock from './FAQBlock';
import { defaultFAQQuestion, IFAQData, IFAQItem } from './utils';
import { PREDEFINED_TOOLBAR_BUTTONS } from 'components/ui/RichTextEditorV2/utils';
import { getIsRichTextEditorV3Enabled } from 'services/feature-gate/selectors';
import { IPageBlockProps } from 'services/app/models/ILandingPageContent';

type IFAQProps = IPageBlockProps<IFAQItem>;

type EditableStringPaths =  'title.rawDataV2' | 'title.rawData' | `questions[${number}].question.${string}` | `questions[${number}].answer.${string}`;

const FAQ: React.FC<IFAQProps> = ({ item, onEdit, onMoveDown, onMoveUp, onRemove }) => {
  const [arrayIdToRemove, setArrayIdToRemove] = React.useState<string | null>(null);
  const isEditing = useSelector(isEditMode);
  const pageId = useSelector(getCurrentPageId);
  const { inlineTextEditorEndUserT } = useEndUserInlineTextEditorTranslation(isEditing);

  const localizedTitle = inlineTextEditorEndUserT(DynamicTranslationType.faqBlockTitle, item.data.title.rawData, { pageId, blockId: item.arrayId });
  const data = item.data;

  const handleUpdateData = React.useCallback((updated: IFAQData) => {
    onEdit({ ...item, data: updated });
  }, [item, onEdit]);

  const { handleChange, handleCloseSettings, handleOpenSettings, isSettingsOpen } = usePageBlock<EditableStringPaths, IFAQData>({
    item: item.data,
    onChange: handleUpdateData,
  });

  React.useEffect(() => {
    if (arrayIdToRemove !== null) {
      const newData: IFAQItem = structuredClone(item);
      const questions = newData.data.questions!.filter((question) => question.arrayId !== arrayIdToRemove);
      set(newData, 'data.questions', questions);
      onEdit(newData);
      setArrayIdToRemove(null);
    }
  }, [arrayIdToRemove, item, onEdit]);

  const handleAddNewFAQBlock = React.useCallback(() => {
    const newItem: IFAQItem = structuredClone(item);
    newItem.data.questions ? newItem.data.questions.push(defaultFAQQuestion()) : newItem.data.questions = [defaultFAQQuestion()];
    onEdit(newItem);
  }, [item, onEdit]);

  const handleRemoveFAQBlock = React.useCallback((arrayId: string) => () => {
    setArrayIdToRemove(arrayId);
  }, []);

  const isRichTextEditorV3Enabled = useSelector(getIsRichTextEditorV3Enabled);
  const rawDataV2 = data.title.rawDataV2;
  const useRawDataV2 = Boolean(isRichTextEditorV3Enabled && rawDataV2);
  const title = useRawDataV2 ? rawDataV2 : localizedTitle;
  const key = useRawDataV2 ? 'title.rawDataV2' : 'title.rawData' as const;

  return (
    <StyledQuickEditOverlay
      backgroundColor={data.backgroundColor.color}
      testId={`faq-${item.arrayId}`}
      titleKey="LABEL_FAQ_BLOCK"
      onEdit={handleOpenSettings}
      onRemove={onRemove}
      onMoveDown={onMoveDown}
      onMoveUp={onMoveUp}
    >
      <RelativeWrapper alignment={data.alignment}>
        <Title
          blockTitleKey="ADMIN_LABEL_BLOCK_TITLE"
          onChange={handleChange(key)}
          useRawDataV2={useRawDataV2}
          data={title}
          alignment={data.alignment}
          maxLength={50}
          toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.titleEditor}
          data-testid="faq-title"
          htmlTag="h2"
        />
        {
          (item.data.questions || []).map(({ question, answer, arrayId }, index) => (
            <FAQBlock
              key={index}
              question={question}
              answer={answer}
              onRemove={handleRemoveFAQBlock(arrayId)}
              alignment={data.alignment}
              backgroundColor={data.faqCardColor.color}
              onChangeAnswer={handleChange(`questions[${index}].answer.${useRawDataV2 ? 'rawDataV2' : 'rawData'}`)}
              onChangeQuestion={handleChange(`questions[${index}].question.${useRawDataV2 ? 'rawDataV2' : 'rawData'}`)}
              arrayId={arrayId}
              blockArrayId={item.arrayId}
              pageId={pageId}
            />
          ))
        }
        {isEditing && (
          <NewFAQBlock onClick={handleAddNewFAQBlock} backgroundColor={data.backgroundColor.color}>
            <PlusIcon />
            <TranslatedText stringKey="ADMIN_LABEL_ADD_MORE" />
          </NewFAQBlock>
        )}
      <BlockSettings
        isOpen={isSettingsOpen}
        onClose={handleCloseSettings}
        item={data}
        onChange={handleUpdateData}
      />
      </RelativeWrapper>
    </StyledQuickEditOverlay>
  );
};

export default FAQ;

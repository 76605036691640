import { FONT_FAMILY_TITLE, OPTIONAL_DYNAMIC_THEME_COLOR, TEXT_100 } from 'style/constants';
import { THEME_PADDING } from 'style/mixins';
import styled, { css } from 'styled-components';
import InlineTextEditor from 'components/admin2/ui/InlineTextEditor';
import QuickEditOverlay from '../../admin-bridge/QuickEditOverlay';
import { IBlockRegion } from 'services/app/models/ILandingPageContent';

export const StyledQuickEditOverlay = styled(QuickEditOverlay)<{ backgroundColor: string; region: IBlockRegion; }>`
  background-color: ${({ backgroundColor }) => OPTIONAL_DYNAMIC_THEME_COLOR(backgroundColor)};
  ${({ region }) => region === 'panel' && css`
    height: 100%;
  `};
`;

export const Wrapper = styled.div<{ isScrollable: boolean; region: IBlockRegion; }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${THEME_PADDING}
  ${({ region }) => region === 'panel' && css`
    padding: 12px;
    padding-top: 30px;
    overflow-x: hidden;
    height: 100%;
  `};
  ${({ isScrollable, region }) => isScrollable && region === 'landingPageBlock' && 'padding-right: 0 !important;'}
  position: relative;
`;

export const Title = styled(InlineTextEditor)`
  width: 100%;
  & .mce-content-body {
    color: ${TEXT_100};

    & * {
      font-family: ${FONT_FAMILY_TITLE};
    }
  }
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`;

import maestroApiRequest from 'services/maestro-api-client';
import { DropdownOption, GameType, LeaderboardType } from '../../utils';
import { EPICGAMES_SERVICE_BASE_URL } from 'config';
import { IFortniteLeaderboardItem } from 'models/IFortniteLeaderboard';

type BaseParams = {
  primaryToken: string;
  siteId: string;
};

type GetTournamentsParams = {
  gameType: GameType;
  season: string;
} & BaseParams;

export const getTournaments = async ({
  gameType,
  primaryToken,
  season,
  siteId,
}: GetTournamentsParams): Promise<DropdownOption[]> => {
  const response = await maestroApiRequest({
    primaryToken,
    siteId,
  }).get<DropdownOption[]>(`${EPICGAMES_SERVICE_BASE_URL}/fortnite/leaderboard/tournaments`, {
    params: {
      season,
      gameType,
    },
  });

  return response.data.map(t => ({
    ...t,
    label: t.label === 'Bambi Raptor' ? 'FNCS Global Championship 2024' : t.label,
  }));
};

type GetSeasonsParams = {
  gameType: GameType;
  leaderboardType: LeaderboardType;
} & BaseParams;

export const getSeasons = async ({
  gameType,
  primaryToken,
  siteId,
  leaderboardType,
}: GetSeasonsParams): Promise<DropdownOption[]> => {
  const response = await maestroApiRequest({
    primaryToken,
    siteId,
  }).get<DropdownOption[]>(`${EPICGAMES_SERVICE_BASE_URL}/fortnite/leaderboard/seasons`, {
    params: {
      gameType,
      leaderboardType,
    },
  });

  return response.data;
};

type GetSessionsParams = {
  eventId: string;
} & BaseParams;

export const getSessions = async ({
  eventId,
  primaryToken,
  siteId,
}: GetSessionsParams): Promise<DropdownOption[]> => {
  const response = await maestroApiRequest({
    primaryToken,
    siteId,
  }).get<DropdownOption[]>(`${EPICGAMES_SERVICE_BASE_URL}/fortnite/leaderboard/sessions`, {
    params: {
      eventId,
    },
  });

  return response.data;
};

type GetUserLeaderboardPositionParams = {
  eventId: string;
  eventWindowId?: string;
  gameType: GameType;
  leaderboardType: LeaderboardType;
} & BaseParams;

export const getUserLeaderboardPosition = async ({
  primaryToken,
  siteId,
  eventId,
  leaderboardType,
  gameType,
  eventWindowId,
}: GetUserLeaderboardPositionParams) => {
  const response = await maestroApiRequest({
    primaryToken,
    siteId,
  }).get<IFortniteLeaderboardItem | null>(`${EPICGAMES_SERVICE_BASE_URL}/fortnite/leaderboard/user-position`, {
    params: {
      eventId,
      leaderboardType,
      gameType,
      eventWindowId,
    },
  });

  return response.data;
};

export const getFilterByMostRecentEvent = async ({
  primaryToken,
  siteId,
  gameType,
}: BaseParams & { gameType: GameType; }) => {
  const response = await maestroApiRequest({
    primaryToken,
    siteId,
  }).get<{
    season: string;
    session: string;
    tournament: string;
  } | null>(`${EPICGAMES_SERVICE_BASE_URL}/fortnite/leaderboard/most-recent`, {
    params: {
      gameType,
    },
  });

  return response.data;
};

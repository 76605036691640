import styled from 'styled-components';
import { mobileOnly } from 'style/mixins';
import {
  SURFACE_4,
  SURFACE_2,
  CHAT_PADDING_LARGE_WITH_UNITS,
  CHAT_TEXTAREA_HEIGHT_WITH_UNITS,
  CHAT_TOOLBAR_HEIGHT_WITH_UNITS,
  TEXT_100,
  TEXT_200,
} from 'style/constants';

export const CHAT_INPUT_HEIGHT_PX = '80px';

export const ChatBox = styled.form`
  display: flex;
  flex-direction: column;
  height: ${CHAT_INPUT_HEIGHT_PX};
  background-color: ${SURFACE_2};
`;

export const ChatTextArea = styled.textarea`
  background-color: ${SURFACE_2};
  border: none;
  border-top: ${props => `1px solid ${SURFACE_4(props)}`};
  color: ${TEXT_200};
  height: ${CHAT_TEXTAREA_HEIGHT_WITH_UNITS};
  padding: ${CHAT_PADDING_LARGE_WITH_UNITS};
  position: relative;
  resize: none;
  width: 100%;
  &::placeholder {
    color: ${TEXT_200};
  }
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mobileOnly`
    justify-content: flex-end;
  `}
  height: ${CHAT_TOOLBAR_HEIGHT_WITH_UNITS};
  border-bottom: ${props => `1px solid ${SURFACE_4(props)}`};
`;

export const PostButton = styled.button`
  color: ${TEXT_100};
  cursor: pointer;
  padding: ${CHAT_PADDING_LARGE_WITH_UNITS};
  text-transform: uppercase;
`;

export const EmojiPickerButton = styled.div`
  color: ${TEXT_100};
  cursor: pointer;
  padding: ${CHAT_PADDING_LARGE_WITH_UNITS};
`;

import React from 'react';
import { LastUpdatedText, LeaderboardTypeButton, LeaderboardTypes, Table, Wrapper } from './styles';
import Filters from './Filters';
import { IFortniteLeaderboardBlockData, LeaderboardType, getLastUpdatedDateString } from '../utils';
import { useAdminTranslation } from 'hooks/use-translation';
import LoggedUserPosition from './LoggedUserPosition';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import { FortniteLeaderboardProvider, useFortniteLeaderboardContext } from './Context/Context';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';
import useFortniteLeaderboard from 'hooks/use-fortnite-leaderboard';
import { IBlockRegion } from 'services/app/models/ILandingPageContent';

type LeaderboardProps = {
  blockWrapperRef: React.RefObject<HTMLDivElement>;
  defaultFilters: IFortniteLeaderboardBlockData['defaultFilters'];
  region: IBlockRegion;
};

const LastUpdated = React.memo(() => {
  const isMobile = useSelector(isMobileLayout);
  const { isPanel, eventId, eventWindowId } = useFortniteLeaderboardContext();
  const [doc, loaded] = useFortniteLeaderboard({ eventId, eventWindowId });
  const { t } = useAdminTranslation();
  const short = isPanel || isMobile;

  if (!doc || !loaded || !doc.modified) {
    return null;
  }

  return <LastUpdatedText short={short}>{t('ADMIN_LABEL_LAST_UPDATED')}: {getLastUpdatedDateString(doc.modified)}</LastUpdatedText>;
});

const Leaderboard: React.FC = () => {
  const isMobile = useSelector(isMobileLayout);
  const { onChangeLeaderboardType, isRanked, isPanel } = useFortniteLeaderboardContext();
  const { t } = useAdminTranslation();

  const handleChangeLeaderboardType = (leaderboardType: LeaderboardType) => () => {
    onChangeLeaderboardType(leaderboardType);
  };

  const short = isPanel || isMobile;

  return (
    <Wrapper short={short}>
      <LeaderboardTypes short={short}>
        <LeaderboardTypeButton active={!isRanked} onClick={handleChangeLeaderboardType('general')}>{t('GENERAL')}</LeaderboardTypeButton>
        <LeaderboardTypeButton active={isRanked} onClick={handleChangeLeaderboardType('ranked')}>{t('RANKED')}</LeaderboardTypeButton>
      </LeaderboardTypes>
      <Filters />

      <LoggedUserPosition />

      <Table isRanked={isRanked} short={short}>
        <TableHeader />
        <TableBody />
      </Table>

      <LastUpdated />
    </Wrapper>
  );
};

export default React.memo(({ defaultFilters, region, blockWrapperRef }: LeaderboardProps) => (
  <FortniteLeaderboardProvider defaultFilters={defaultFilters} region={region} blockWrapperRef={blockWrapperRef}>
    <Leaderboard />
  </FortniteLeaderboardProvider>
));

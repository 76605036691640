import React from 'react';
import { GalleryWrapper } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import { OVERFLOW_BEHAVIOR } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';
import useChunkPagination from 'hooks/use-chunk-pagination';
import ShopifyBlockSlider from '../Slider';
import AddCardButton from './AddCardButton';
import { useShopifyBlockContext } from '../ShopifyBlockContext';
import ProductCard from '../ProductCard';
import ShopifyProductListModal from 'components/admin2/ShopifyProductListModal';
import { IProduct } from 'services/shopify/models';
import { moveItem } from 'hooks/use-drag-drop';
import DraggableProductCard from './DraggableProductCard';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { replaceEcommerceViews, setProductDetail } from 'services/ecommerce';
import { IProductComponentProps } from 'components/Ecommerce/ProductDetail';
import { EditorAction } from 'components/admin2/ui/EditableArea';

interface IGalleryProps {
  onChangeMultipleProductIds: (ids: string[]) => void;
}

const Gallery: React.FC<IGalleryProps> = ({ onChangeMultipleProductIds }) => {
  const isEditing = useSelector(isEditMode);
  const [isShopifyProductListModalOpen, setIsShopifyProductListModalOpen] = React.useState(false);
  const {
    productsPerRow,
    overflowBehavior,
    isScrollable,
    isRenderingOnPanel,
    isMultipleProductsProductView,
    products,
    onReorder,
    onRemoveProduct,
  } = useShopifyBlockContext();
  const chunkSize = overflowBehavior === OVERFLOW_BEHAVIOR.slider ? productsPerRow : 0;
  const pagination = useChunkPagination(products, chunkSize);
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = React.useState<IProduct | null>(null);

  if (pagination.pageCount && pagination.pageIndex >= pagination.pageCount) {
    pagination.goToPage(0);
  }

  let showAddMoreButton = false;
  if (isEditing && isMultipleProductsProductView) {
    switch(overflowBehavior) {
      case OVERFLOW_BEHAVIOR.slider: {
        showAddMoreButton = pagination.page?.length === productsPerRow;
        break;
      }
      case OVERFLOW_BEHAVIOR.stack: {
        showAddMoreButton = products.length % productsPerRow === 0;
        break;
      }
    }
  }

  const handleProductCardClick = (params: IProductComponentProps) => {
    if (!isEditing) {
      dispatch(replaceEcommerceViews(['productDetail']));
      dispatch(setProductDetail(params));
    }
  };

  const handleOpenProductListModal = React.useCallback(() => {
    if (!isMultipleProductsProductView || !isEditing) {
      return;
    }

    setIsShopifyProductListModalOpen(true);
  }, [isMultipleProductsProductView, isEditing]);

  const handleCloseShopifyProductListModal = React.useCallback(() => {
    setIsShopifyProductListModalOpen(false);
  }, []);

  const handleSelectMultipleProducts = React.useCallback((newProducts: IProduct[]) => {
    onChangeMultipleProductIds(newProducts.map(product => product.id));
    setIsShopifyProductListModalOpen(false);
  }, [onChangeMultipleProductIds]);

  const handleReplaceProduct = React.useCallback((newProduct: IProduct) => {
    if (!selectedProduct) {
      return;
    }
    const selectedProductIdx = products.findIndex((product) => product.id === selectedProduct.id);
    products[selectedProductIdx] = newProduct;
    handleSelectMultipleProducts(products);
  }, [selectedProduct, products, handleSelectMultipleProducts]);

  const handleAddCardClick = React.useCallback(() => {
    setSelectedProduct(null);
    return handleOpenProductListModal();
  }, [products, handleOpenProductListModal, setSelectedProduct]);

  const onReorderProductCard = React.useCallback((dragIndex: number, hoverIndex: number) => {
    // compute drag index taking into account the pagination
    const dragIndexCountingPageIndex = dragIndex + (pagination.pageIndex * chunkSize);
    const hoverIndexCountingPageIndex = hoverIndex + (pagination.pageIndex * chunkSize);
    onReorder(moveItem(products, dragIndexCountingPageIndex, hoverIndexCountingPageIndex));
  }, [pagination.pageIndex, chunkSize, products, onReorder]);

  const renderProductCard = React.useCallback((product: IProduct, index: number) => {
    const handleRemove = () => {
      onRemoveProduct(product.id);
    };

    const editorActions: EditorAction[] = [];
    if (isMultipleProductsProductView) {
      editorActions.push({
        confirmationMessageKey: 'REMOVE_SHOPIFY_BLOCK_PRODUCT_TITLE',
        icon: 'cancel-thin',
        onClick: handleRemove,
      });
    }

    return (
      <ProductCard
        product={product}
        editorActions={editorActions}
        horizontalView={false}
        handleOpenProductListModal={handleOpenProductListModal}
        handleProductCardClick={handleProductCardClick}
        testId={`shopifyProduct-${index}`}
        key={`shopifyProduct-${product.id}-${index}`}
        setSelectedProduct={setSelectedProduct}
      />
    );
  }, [handleOpenProductListModal, isMultipleProductsProductView]);

  const renderProductCards = React.useCallback((cards: IProduct[]) => {
    if (!isEditing) {
      return cards.map(renderProductCard);
    }

    return (
      <DndProvider backend={HTML5Backend}>
        {cards.map((product, index) => (
          <DraggableProductCard
            key={`${product.id}-${index}}`}
            index={index}
            id={product.id}
            moveItem={onReorderProductCard}
          >
            {renderProductCard(product, index)}
          </DraggableProductCard>
        ))}
      </DndProvider>
    );
  }, [isEditing, renderProductCard, onReorderProductCard]);

  return (
    <>
      <ShopifyBlockSlider
        isScrollable={isScrollable}
        onAddClick={handleAddCardClick}
        pagination={pagination}
        showAddMoreButton={showAddMoreButton}
        isRenderingOnPanel={isRenderingOnPanel}
      >
        {cards => {
          return (
            <GalleryWrapper
              productsPerRow={productsPerRow}
              isScrollable={isScrollable}
              overflowBehavior={overflowBehavior}
              isRenderingOnPanel={isRenderingOnPanel}
            >
              {renderProductCards(cards)}
              <AddCardButton
                onClick={handleAddCardClick}
                products={cards}
                pagination={pagination}
              />
            </GalleryWrapper>
          );
        }}
      </ShopifyBlockSlider>
      {isShopifyProductListModalOpen && (
        <ShopifyProductListModal
          onClose={handleCloseShopifyProductListModal}
          isMultipleSelection={!selectedProduct}
          onSelectMultipleProducts={handleSelectMultipleProducts}
          selectedProducts={products}
          selectedProduct={selectedProduct}
          onSelectProduct={handleReplaceProduct}
        />
      )}
    </>
  );
};

export default Gallery;

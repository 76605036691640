import styled from 'styled-components';
import { TEXT_LABEL_M_BOLD, TEXT_LABEL_S_BOLD, TEXT_LABEL_S_MEDIUM, TEXT_LABEL_XS_BOLD } from 'style/design-system/textStyles';
import { TEXT_100, TEXT_300 } from 'style/constants';
import { ContentContainer, Description, Title as EventCardTitle } from '../../EventCard/FullEventCard/styles';
import { desktopOnly, mobileOnly } from 'style/mixins';
import { DateLabel } from '../../EventCard/FullEventCard/DateTime/Date/styles';
import { Time } from '../../EventCard/FullEventCard/DateTime/Time/styles';
import { Label } from '../../EventCard/Labels/styles';
import { HEADER_HEIGHT } from '../Header/styles';
import { CALENDAR_MAX_HEIGHT } from '../Calendar/styles';

export const Wrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  gap: 16px;
  ${desktopOnly`height: calc(100% - ${HEADER_HEIGHT}px - ${CALENDAR_MAX_HEIGHT}px);`};
  ${mobileOnly`min-height: 30vh;`};
`;

export const Title = styled.div`
  display: flex;
  gap: 4px;
  ${TEXT_LABEL_M_BOLD}
  color: ${TEXT_100};
`;

export const Quantity = styled.span`
  color: ${TEXT_300};
`;

export const EventsWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  contain: content;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;

  & > section {
    min-height: unset;
    min-width: unset;
    max-width: unset;
    max-height: unset;

    & ${ContentContainer} {
      padding: 10px;
    }

    & ${EventCardTitle} {
      & .mce-content-body {
        & * {
          ${desktopOnly`
            ${TEXT_LABEL_S_BOLD}
            font-size: 0.75rem !important;
          `};
        }
      }
    }

    & ${Description} {
      display: none;
    }

    & ${DateLabel} {
      ${desktopOnly`${TEXT_LABEL_S_MEDIUM}`}
    }

    & ${Time} {
      ${desktopOnly`${TEXT_LABEL_S_BOLD}`}
    }

    & ${Label} {
      ${TEXT_LABEL_XS_BOLD}
    }
  }
`;

export const ShowAllButton = styled.div`
  display: flex;
  height: 40px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;

  ${TEXT_LABEL_M_BOLD}
  color: ${TEXT_100};
`;

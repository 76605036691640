import React from 'react';
import { AddNewCardButton, PlusIcon } from './styles';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import { ImageCardProps } from '../../ImageCard';
import { ChunkPaginationReturnType } from 'hooks/use-chunk-pagination';
import { OVERFLOW_BEHAVIOR } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';
import TranslatedText from 'components/i18n/TranslatedText';
import { useImageGalleryContext } from '../../ImageGalleryContext/ImageGalleryContext';

interface IAddCardButtonProps {
  pageCards: ImageCardProps[];
  pagination: ChunkPaginationReturnType<ImageCardProps>;
}

const AddCardButton: React.FC<IAddCardButtonProps> = ({ pagination, pageCards }) => {
  const inEditMode = useSelector(isEditMode);
  const { overflowBehavior, imageCards, imagesPerRow, background, addNewImageCard } = useImageGalleryContext();

  if (!inEditMode) {
    return null;
  }

  const shouldRender = (
    (
      // if the overflow behavior is to stack
      overflowBehavior === OVERFLOW_BEHAVIOR.stack &&
      // and the number of cards is not a multiple of the number of images per row
      imageCards.length % imagesPerRow !== 0
    ) ||
    // or the number of cards on the page is less than the number of images per row
    pageCards.length < imagesPerRow ||
    // or there are no pages
    !pagination.pageCount
  );

  if (!shouldRender) {
    return null;
  }

  return (
    <AddNewCardButton
      backgroundColor={background.color}
      onClick={addNewImageCard}
    >
      <PlusIcon />
      <TranslatedText stringKey="ADMIN_LABEL_ADD_MORE" />
    </AddNewCardButton>
  );
};

export default AddCardButton;

import { CTAButtonProps, DEFAULT_CTA_BEHAVIOR, DEFAULT_CTA_HEIGHT_SIZE } from 'components/admin-bridge/CTAEditorModal/utils';
import { Alignment } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';
import { injectT, TTranslator } from 'hooks/use-translation';
import { MAESTRO_BLACK } from 'style/constants';
import { getDefaultRichTextEditorData } from 'utils';
import { lazy, lazyFn } from 'utils/lazy';
import { getDefaultEditorState, getDefaultHeadingNode, getDefaultTextNode } from 'components/ui/RichTextEditorV3/getDefaultEditorState';

const createDefaultTextBannerData = (homeId: string, t: TTranslator): ITextBanner => ({
  alignment: {
    horizontal: Alignment.Center,
  },
  background: {
    color: 'surface1',
    customColor: MAESTRO_BLACK,
    image: {
      show: false,
      mobile: '',
      desktop: '',
    },
  },
  cta: {
    fontSize: 14,
    show: true,
    variant: 'solid',
    text: t('ADMIN_TEXT_BLOCK_DEFAULT_CTA_TEXT'),
    behavior: DEFAULT_CTA_BEHAVIOR(homeId),
    height: {
      desktop: DEFAULT_CTA_HEIGHT_SIZE,
      mobile: DEFAULT_CTA_HEIGHT_SIZE,
    },
  },
  subtitle: {
    rawData: getDefaultRichTextEditorData({
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempor id consectetur viverra et. Cras semper quam venenatis morbi.',
      fontSize: 20,
    }),
    rawDataV2: getDefaultEditorState([
      getDefaultHeadingNode({
        children: [
          getDefaultTextNode({
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempor id consectetur viverra et. Cras semper quam venenatis morbi.',
            fontSize: 20,
          }),
        ],
        tag: 'h2',
      }),
    ]),
    show: true,
  },
  title: {
    rawData: getDefaultRichTextEditorData({
      text: 'Lorem ipsum dolor sit amet consectetur.',
      fontSize: 44,
      typographicalEmphasis: 'BOLD',
    }),
    rawDataV2: getDefaultEditorState([
      getDefaultHeadingNode({
        children: [
          getDefaultTextNode({
            text: 'Lorem ipsum dolor sit amet consectetur.',
            fontSize: 44,
            textFormatType: 'bold',
          }),
        ],
        tag: 'h1',
      }),
    ]),
  },
  label: {
    rawData: getDefaultRichTextEditorData({
      text: 'Lorem ipsum dolor sit amet consectetur',
      fontSize: 20,
      typographicalEmphasis: 'BOLD',
    }),
    rawDataV2: getDefaultEditorState([
      getDefaultTextNode({
        text: 'Lorem ipsum dolor sit amet consectetur.',
        fontSize: 20,
        textFormatType: 'bold',
      }),
    ]),
    show: true,
  },
});

export const DEFAULT_TEXT_BANNER = injectT(
  (t) => lazyFn(
    () => (homeId: string): ITextBanner => (createDefaultTextBannerData(homeId, t)),
  ),
);

export interface ITextBanner {
  alignment: {
    horizontal: Alignment;
  },
  background: {
    color: string;
    customColor: string;
    image: {
      desktop: string;
      mobile: string;
      show: boolean;
    }
  }
  cta: CTAButtonProps & {
    show: boolean;
  },
  label: {
    rawData: string;
    rawDataV2?: string;
    show: boolean;
  },
  subtitle: {
    rawData: string;
    rawDataV2?: string;
    show: boolean;
  },
  title: {
    rawData: string;
    rawDataV2?: string;
  },
}

import React from 'react';
import { IImageGalleryItem } from '../utils';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import { getPageId } from 'services/app';
import { DynamicTranslationType, useEndUserInlineTextEditorTranslation, useEndUserTranslation } from 'hooks/use-translation';

interface IUseImageGalleryLocalizedStringsResponse {
  imageCards: IImageGalleryItem['data']['imageCards'];
  title: string;
}

/**
 * This hook is private to Image Gallery Context. Do not use it directly.
 */
const useImageGalleryLocalizedStrings = (item: IImageGalleryItem): IUseImageGalleryLocalizedStringsResponse => {
  const isEditing = useSelector(isEditMode);
  const pageId = useSelector(getPageId);
  const { inlineTextEditorEndUserT } = useEndUserInlineTextEditorTranslation(isEditing);
  const { endUserT } = useEndUserTranslation();

  const result = React.useMemo(() => {
    const imageCards = item.data.imageCards ?? [];

    if (isEditing) {
      return {
        title: item.data.title,
        imageCards,
      };
    }

    const baseParams = {
      pageId,
      blockId: item.arrayId,
    };

    const localizedTitle = inlineTextEditorEndUserT(DynamicTranslationType.imageGalleryTitle, item.data.title, baseParams);
    const localizedImageCards = imageCards.map((imageCard) => {
      const imageCardParams = {
        ...baseParams,
        imageCardId: imageCard.id,
      };

      return {
        ...imageCard,
        title: inlineTextEditorEndUserT(DynamicTranslationType.imageGalleryImageCardTitle, imageCard.title, imageCardParams),
        description: inlineTextEditorEndUserT(DynamicTranslationType.imageGalleryImageCardDescription, imageCard.description, imageCardParams),
        label: inlineTextEditorEndUserT(DynamicTranslationType.imageGalleryImageCardLabel, imageCard.label, imageCardParams),
        cta: {
          ...imageCard.cta,
          text: endUserT([
            DynamicTranslationType.imageGalleryImageCardCta, imageCardParams,
          ]) || imageCard.cta.text,
        },
      };
    });

    return {
      title: localizedTitle,
      imageCards: localizedImageCards,
    };
  }, [item.data.imageCards, item.data.title, isEditing, item.arrayId, pageId]);

  return result;
};

export default useImageGalleryLocalizedStrings;

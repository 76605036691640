import React from 'react';
import useCalendarEventLiveStatus from './use-calendar-event-live-status';
import { useEndUserTranslation } from 'hooks/use-translation';
import { IEventCardProps } from '../utils';
import { ILabel } from 'models/ICalendarEvent';

const useCalendarEvent = (item: IEventCardProps['event'], useLocaleTimezone: boolean) => {
  const isLive = useCalendarEventLiveStatus({
    startTimestamp: item.date.startTimestamp,
    endTimestamp: item.date.endTimestamp,
    useEndTime: item.date.useEndTime,
  });
  const { currentLocale } = useEndUserTranslation();

  const { startDate, startTime, endTime } = React.useMemo(() => {
    const initialDate = new Date(item.date.startTimestamp);
    const endDate = new Date(item.date.endTimestamp);

    const isSameDay = initialDate.getDate() === endDate.getDate();
    const isSameMonth = initialDate.getMonth() === endDate.getMonth();
    const isSameYear = initialDate.getFullYear() === endDate.getFullYear();

    const isSameDate = isSameDay && isSameMonth && isSameYear;

    let localeEndTime = '';
    const locale = useLocaleTimezone ? currentLocale : 'en-US';
    const timeZone = useLocaleTimezone ? Intl.DateTimeFormat().resolvedOptions().timeZone : item.date.timeZone;

    if (item.date.useEndTime) {
      localeEndTime = endDate.toLocaleTimeString(locale, {
        ...!isSameDate && {
          day: 'numeric',
          weekday: 'short',
        },
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone,
      });
    }

    const formattedStartDate = initialDate.toLocaleString(locale, {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      timeZone,
    });

    const formattedStartTime = initialDate.toLocaleString(locale, {
      ...!isSameDate && {
        day: 'numeric',
        weekday: 'short',
      },
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone,
    });


    return {
      startDate: formattedStartDate,
      startTime: formattedStartTime,
      endTime: localeEndTime,
    };
  }, [item.date, currentLocale, useLocaleTimezone]);

  const computedLabels = React.useMemo(() => {
    const uniqueLabels: ILabel[] = [];
    const burnedNames = new Set<string>();
    for (const { label } of (item.categories || []).filter(c => c.label.title)) {
      if (!burnedNames.has(label.title)) {
        uniqueLabels.push(label);
        burnedNames.add(label.title);
      }
    }

    return uniqueLabels;
  }, [item.categories]);

  return {
    labels: computedLabels,
    isLive,
    startDate,
    when: `${startTime}${ endTime ? ` - ${endTime}` : ''}`,
  };
};

export default useCalendarEvent;

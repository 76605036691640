import React from 'react';
import BlockSettings from './BlockSettings';
import { IShopifyBlockProps, IShopifyBlockData } from './utils';
import { StyledQuickEditOverlay, BackgroundImage, Title, Wrapper } from './styles';
import Gallery from './Gallery';
import usePageBlock from 'hooks/use-page-block';
import useShopifyBlock from './use-shopify-block';
import { ShopifyBlockProvider } from './ShopifyBlockContext';
import SingleProduct from './SingleProduct';
import { PREDEFINED_TOOLBAR_BUTTONS } from 'components/ui/RichTextEditorV2/utils';
import { useSelector } from 'react-redux';
import { getIsRichTextEditorV3Enabled } from 'services/feature-gate/selectors';

type EditableStringPaths = keyof IShopifyBlockData;

const ShopifyBlock: React.FC<IShopifyBlockProps> = ({ onEdit, onRemove, onMoveDown, onMoveUp, item, region, onOpenBlockSettings }) => {
  const handleChangeData = (update: IShopifyBlockData) => {
    onEdit({ ...item, data: update });
  };

  const { handleChange, handleCloseSettings, handleOpenSettings, isSettingsOpen } = usePageBlock<EditableStringPaths, IShopifyBlockData>({
    item: item.data,
    onChange: handleChangeData,
  });

  const { backgroundImage, isScrollable } = useShopifyBlock(item.data, region, handleChange('collectionProductsIds'), handleChange('multipleProductsIds'));

  const renderContent = React.useCallback(() => {
    if (item.data.productView === 'singleProduct') {
      return (
        <SingleProduct
          id={item.data.singleProductId}
          onChangeSingleProductId={handleChange('singleProductId')}
        />
      );
    }

    return <Gallery onChangeMultipleProductIds={handleChange('multipleProductsIds')} />;
  }, [item.data.productView, item.data.singleProductId, handleChange]);

  const handleOpenBlockSettings = onOpenBlockSettings || handleOpenSettings;

  const { titleRaw, titleRawV2 } = item.data;
  const isRichTextEditorV3Enabled = useSelector(getIsRichTextEditorV3Enabled);
  const useRawDataV2 = Boolean(isRichTextEditorV3Enabled && titleRawV2);
  const data = useRawDataV2 ? titleRawV2 : titleRaw;
  const key = useRawDataV2 ? 'titleRawV2' : 'titleRaw' as const;

  return (
    <ShopifyBlockProvider
      item={item.data}
      region={region}
      onChangeCollectionProductsIds={handleChange('collectionProductsIds')}
      onChangeMultipleProductsIds={handleChange('multipleProductsIds')}
    >
      <StyledQuickEditOverlay
        backgroundColor={item.data.background.color}
        onEdit={handleOpenBlockSettings}
        onRemove={onRemove}
        titleKey="ADMIN_OVERLAY_SHOPIFY_NAME"
        testId={`shopify-block-${item.arrayId}`}
        onMoveDown={onMoveDown}
        onMoveUp={onMoveUp}
        region={region}
      >
        <Wrapper isScrollable={isScrollable} region={region}>
          {Boolean(backgroundImage) && (
            <BackgroundImage
              src={backgroundImage}
              alt="Shopify Block Background Image"
            />
          )}
          {
            region === 'landingPageBlock' && (
            <Title
              blockTitleKey="ADMIN_LABEL_TITLE"
              onChange={handleChange(key)}
              useRawDataV2={useRawDataV2}
              data={data}
              data-testid="shopifyCollectionTitle"
              toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.titleEditor}
            />
          )}
          {renderContent()}
          <BlockSettings
            item={item.data}
            onChange={handleChangeData}
            isOpen={isSettingsOpen}
            onClose={handleCloseSettings}
            region="landingPageBlock"
          />
        </Wrapper>
      </StyledQuickEditOverlay>
    </ShopifyBlockProvider>
  );
};

export default ShopifyBlock;

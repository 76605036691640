import styled from 'styled-components';
import { ACCENT_PRIMARY, FONT_FAMILY_BODY, FONT_FAMILY_TITLE, OPTIONAL_DYNAMIC_THEME_COLOR, TEXT_100, TEXT_200 } from 'style/constants';
import { TEXT_BODY_S_REGULAR, TEXT_LABEL_L_MEDIUM, TEXT_TITLE_M } from 'style/design-system/textStyles';
import { desktopOnly, mobileOnly, THEME_PADDING, THEME_PADDING_DESKTOP } from 'style/mixins';
import { IImageAndText } from './utils';
import { createTextContrastOnBackgroundColor } from 'services/themes/utils';
import withDragDisabled from 'components/core/withDragDisabled';
import InlineTextEditor from 'components/admin2/ui/InlineTextEditor';
import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';
import CTAButtonEditorModal from 'components/admin-bridge/CTAEditorModal';
import { Alignment } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';

const getAlignSelfFromAlignment = (alignment: IImageAndText['alignment']) => {
  switch (alignment) {
    case Alignment.Left:
      return 'start';
    case Alignment.Right:
      return 'end';
    default:
      return 'center';
  }
};

export const CTAEditorModal = styled(CTAButtonEditorModal)<{ alignment: IImageAndText['alignment']; }>`
  cursor: pointer;
  width: fit-content;
  margin-top: 10px;
  align-self: ${({ alignment }) => getAlignSelfFromAlignment(alignment)};

  ${mobileOnly`
    & button {
      min-width: fit-content !important;
    }
  `};
`;

const getFlexDirectionByLayout = (layout: IImageAndText['layout']) => {
  switch (layout) {
    case 'right':
      return 'row-reverse';
    case 'stacked':
      return 'column-reverse';
    default:
      return 'unset';
  }
};

export const LayoutContainer = styled.div<{ layout: IImageAndText['layout']; }>`
  ${mobileOnly`
    flex-direction: column-reverse;
    gap: 40px;
  `}
  ${desktopOnly`
    gap: ${THEME_PADDING_DESKTOP};
    width: 100%;
    flex-direction: ${({ layout }) => getFlexDirectionByLayout(layout)};
    ${({ layout }) => layout !== 'stacked' && 'align-items: center;'}
    ${({ layout }) => layout === 'stacked' && 'max-width: 50vw;'}
  `}
  display: flex;
`;

export const StyledQuickEditOverlay = styled(QuickEditOverlay)<{ backgroundColor: string; }>`
  ${THEME_PADDING}
  background-color: ${({ backgroundColor }) => OPTIONAL_DYNAMIC_THEME_COLOR(backgroundColor)};
  display: flex;
  justify-content: center;
  position: relative;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  min-width: calc((100% - ${THEME_PADDING_DESKTOP}) / 2);
`;

export const Content = withDragDisabled(StyledContent);

export const Description = styled(InlineTextEditor)<{ alignment: IImageAndText['alignment']; }>`
  width: 100%;
  max-width: 100%;
  & .mce-content-body {
    & * {
      font-family: ${FONT_FAMILY_BODY};
      text-align: ${({ alignment }) => alignment} !important;
      ${mobileOnly`
        ${TEXT_BODY_S_REGULAR}
        font-size: 0.875rem !important;
      `};
    }
    color: ${TEXT_200};
  }
`;

export const Label = styled(InlineTextEditor)<{ alignment: IImageAndText['alignment']; backgroundColor: string; }>`
  width: 100%;
  max-width: 100%;
  & .mce-content-body {
    & * {
      font-family: ${FONT_FAMILY_TITLE};
      text-align: ${({ alignment }) => alignment} !important;
      ${mobileOnly`
        ${TEXT_LABEL_L_MEDIUM}
        font-size: 1rem !important;
      `};
    }
    color: ${props => {
      if (props.backgroundColor !== 'accentPrimary') {
        return ACCENT_PRIMARY;
      }
      const backgroundColor = OPTIONAL_DYNAMIC_THEME_COLOR(props.backgroundColor)(props);
      return createTextContrastOnBackgroundColor(backgroundColor, TEXT_100(props));
    }};
  }
`;

export const Title = styled(InlineTextEditor)<{ alignment: IImageAndText['alignment']; }>`
  width: 100%;
  max-width: 100%;
  & .mce-content-body {
    & * {
      font-family: ${FONT_FAMILY_TITLE};
      text-align: ${({ alignment }) => alignment} !important;
      ${mobileOnly`
        ${TEXT_TITLE_M}
        font-size: 1.25rem !important;
      `};
    }
    color: ${TEXT_100};
  }
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { desktopOnly, TEXT_H5 } from 'style/mixins';

import GoToChannelOverlayCta from 'components/overlays/GoToChannelOverlayCta';
import LoginOverlayCta from 'components/overlays/LoginOverlayCta';
import ShowGroupOverlayCta from 'components/overlays/ShowGroupOverlayCta';
import FacebookShareOverlayCta from 'components/overlays/FacebookShareOverlayCta';
import ShowPanelOverlayCta from 'components/overlays/ShowPanelOverlayCta';
import ShowPersonOverlayCta from 'components/overlays/ShowPersonOverlayCta';
import OpenLinkOverlayCta from 'components/overlays/OpenLinkOverlayCta';
import ShowPlayerStatsCta from 'components/overlays/ShowPlayerStatsCta';
import PlayVideoOverlayCta from 'components/overlays/PlayVideoOverlayCta';
import TweetShareOverlayCta from 'components/overlays/TweetShareOverlayCta';
import VideoOnlyOverrideCta from 'components/overlays/VideoOnlyOverrideCta';

const MessageWrapper = styled.div`
  border-radius: 4px;
  overflow: hidden;
  align-self: flex-end;
  margin-top: 14px;
  & * {
    ${TEXT_H5}
    text-transform: none;
  }
  ${desktopOnly`
    & * {
      font-size: 18px;
    }
    & i {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
    & > * {
      ${({ overlay }) => overlay === 'facebook_share' ? css`
        & > * > * {
          padding: 4px 14px;
        }
      ` : css`
        & > * {
          padding: 4px 14px;
        }
      `}
    }
  `}
`;

const CTA_MAP = {
  facebook_share: FacebookShareOverlayCta,
  go_to_channel: GoToChannelOverlayCta,
  login: LoginOverlayCta,
  open_link: OpenLinkOverlayCta,
  play_video: PlayVideoOverlayCta,
  show_group: ShowGroupOverlayCta,
  show_panel: ShowPanelOverlayCta,
  show_person: ShowPersonOverlayCta,
  show_player_stats: ShowPlayerStatsCta,
  tweet: TweetShareOverlayCta,
};

const WRAPPER_MAP = {
  message: MessageWrapper,
};

export default class OverlayCta extends React.Component {
  static propTypes = {
    broadcastId: PropTypes.string,
    broadcastKey: PropTypes.string.isRequired,
    children: PropTypes.node,
    ctaRenderer: PropTypes.shape({
      overlayCtaType: PropTypes.string,
    }),
    dismissAfterEngage: PropTypes.bool,
    isVideoOnlyMode: PropTypes.bool.isRequired,
    message: PropTypes.string,
    onDismiss: PropTypes.func.isRequired,
    onEngagedOverlay: PropTypes.func.isRequired,
    overlayId: PropTypes.string.isRequired,
    overlayType: PropTypes.string,
    rawData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    trackOverlay: PropTypes.func.isRequired,
  };

  static defaultProps = {
    broadcastId: '',
    children: undefined,
    ctaRenderer: undefined,
    dismissAfterEngage: true,
    message: '',
    overlayType: '',
  };

  handleEngaged = () => {
    const {
      broadcastKey,
      broadcastId,
      dismissAfterEngage,
      onDismiss,
      onEngagedOverlay,
      trackOverlay,
      rawData,
    } = this.props;

    if (broadcastId) {
      trackOverlay(rawData);
      onEngagedOverlay(broadcastId);
    }

    if (dismissAfterEngage) {
      onDismiss(broadcastKey);
    }
  };

  withWrapper(children) {
    const { ctaRenderer, overlayType } = this.props;
    const Wrapper = WRAPPER_MAP[overlayType];
    return Wrapper ? (
      <Wrapper overlay={ctaRenderer?.overlayCtaType}>
        {children}
      </Wrapper>
    ) : children;
  }

  render() {
    const {
      ctaRenderer,
      children,
      broadcastId,
      broadcastKey,
      isVideoOnlyMode,
      message,
      onDismiss,
      overlayId,
    } = this.props;

    const cta = CTA_MAP[ctaRenderer?.overlayCtaType];
    const Component = cta || 'span';
    const componentProps = cta ? {
      broadcastId,
      ctaRenderer,
      message,
      onDismiss: () => onDismiss(broadcastKey),
      onEngagedOverlay: this.handleEngaged,
      overlayId,
      referralId: `social_o_${broadcastId}`,
    } : {};

    return this.withWrapper(isVideoOnlyMode ?
      <VideoOnlyOverrideCta /> :
      <Component testId="overlayCtaButton" {...componentProps}>{ children }</Component>);
  }
}

import React from 'react';
import ImageEditorModal from './ImageEditorModal';
import { ICalendarEvent } from 'models/ICalendarEvent';
import { useAdminTranslation } from 'hooks/use-translation';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import { Image, NoImage } from './styles';
import TranslatedText from 'components/i18n/TranslatedText';

interface IEventImageProps {
  image: ICalendarEvent['image'];
  onChange: (item: ICalendarEvent['image']) => void;
  readOnly: boolean;
}

const EventImage: React.FC<IEventImageProps> = ({ readOnly, image, onChange }) => {
  const { t } = useAdminTranslation();
  const editMode = useSelector(isEditMode);

  const renderContent = React.useCallback(() => {
    if (!image.src) {
      return (
        <NoImage>
          {editMode && (
            <>
              <TranslatedText stringKey="ADMIN_LABEL_ADD_IMAGE" />
              <label>
                {t('ADMIN_IMAGE_EDITOR_RECOMMENDED_SIZE', { width: 750, height: 1080 })}
              </label>
            </>
          )}
        </NoImage>
      );
    }

    return (
      <Image src={image.src} alt="Event Calendar Image" />
    );
  }, [image.src, editMode]);

  return (
    <ImageEditorModal
      readOnly={readOnly}
      image={image}
      onChange={onChange}
    >
      {renderContent()}
    </ImageEditorModal>
  );
};

export default EventImage;

import styled, { css } from 'styled-components';
import { ACCENT_PRIMARY, HIGHLIGHT_PRIMARY, SURFACE_1, SURFACE_3, TEXT_100, TEXT_300 } from 'style/constants';
import Icon from 'components/ui/Icon';
import { TEXT_LABEL_L_BOLD, TEXT_LABEL_M_MEDIUM, TEXT_LABEL_S_BOLD } from 'style/design-system/textStyles';
import { HIDE_SCROLLBAR, desktopOnly } from 'style/mixins';
import { contrastOnBackground } from 'colors';
import { Label } from '../../EventCard/Labels/styles';
import Button from 'components/ui/Button';

export const StyledLabel = styled(Label)<{ isSelected: boolean; }>`
  height: 40px;
  min-width: 60px;
  ${({ isSelected }) => isSelected && css`
    background-color: ${SURFACE_1};
    color: ${props => contrastOnBackground(TEXT_100(props), SURFACE_1(props))};
  `};
`;

export const FilterOptions = styled.div`
  display: flex;
  width: calc(100% + 20px);
  gap: 3px;
  ${HIDE_SCROLLBAR}
  overflow-x: auto;
`;

export const FilterTitle = styled.span`
  ${TEXT_LABEL_M_MEDIUM}
  color: ${TEXT_100};
`;

export const Filter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

export const AllFiltersTitle = styled.span`
  ${TEXT_LABEL_L_BOLD}
  color: ${TEXT_100};
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
`;

export const MobileFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  ${desktopOnly`display: none;`};
`;

export const MobileFilterText = styled.span`
  color: ${TEXT_300};
  ${TEXT_LABEL_S_BOLD}
`;

export const FilterIcon = styled(Icon).attrs({
  name: 'textAlignmentCenter',
})<{ isOpen: boolean; }>`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: ${SURFACE_3};

  & svg {
    width: 20px;
    height: 20px;

    & path {
      fill: ${({ isOpen }) => isOpen ? ACCENT_PRIMARY : HIGHLIGHT_PRIMARY};
    }
  }
`;

export const CheckIcon = styled(Icon).attrs(props => ({
  color: HIGHLIGHT_PRIMARY(props),
  name: 'successCheckmark',
}))`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 1.5px;
  justify-content: center;
  align-items: center;

  & svg {
    width: 12.75px;
    height: 12.75px;
  }
`;

export const StyleButton = styled(Button)`
  width: 100%;
  margin-top: 40px;
  padding: 12px;
`;

// tslint:disable:no-console
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCartItem } from 'services/ecommerce';
import { isEditMode } from 'services/admin';
import { getShopCurrencyCode } from 'services/shopify/selectors';
import { IProduct } from 'services/shopify/models';
import { useAdminTranslation } from 'hooks/use-translation';
import { useProductVariant } from 'hooks/ecommerce/useProductVariant';
import { transformShopifyProductIntoEcommerceProduct } from 'hooks/ecommerce/useEcommerceCart';
import { useProductOptions } from 'hooks/ecommerce/useProductOptions';
import { MOCK_PRODUCT_ID } from '../utils';
import { AddIcon, Content, Description, EDITOR_ACTIONS_CSS, NoImage, OptionsSelectorWrapper, PriceAndOptionsContainer, ShippingInformation, StyledButton, StyledEditableArea, StyledPrice, Texts, Title } from './styles';
import { useShopifyBlockContext } from '../ShopifyBlockContext';
import { EditorAction } from 'components/admin2/ui/EditableArea';
import { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';
import { IProductComponentProps } from 'components/Ecommerce/ProductDetail';
import { IProduct as IEcommerceProduct } from 'components/Ecommerce/models';
import OptionsView from 'components/Ecommerce/sharedComponents/OptionsView';
import ProductImage from 'components/Ecommerce/sharedComponents/ProductImage';
import { getRectPositionCheckingBounds } from 'dom-utils';
import OptionsSelector from 'components/Ecommerce/sharedComponents/OptionsSelector';
import { NoImageText } from 'components/Ecommerce/sharedComponents/ProductImage/styles';
import useResizeObserver from 'hooks/use-resize-observer';

interface IProductCardProps {
  editorActions?: EditorAction[];
  handleOpenProductListModal: () => void;
  handleProductCardClick?: (params: IProductComponentProps) => void;
  horizontalView: boolean;
  product: IProduct;
  setSelectedProduct?: React.Dispatch<React.SetStateAction<IProduct | null>>;
  testId?: string;
}

const ProductCard: React.FC<IProductCardProps> = ({
  product,
  horizontalView,
  handleOpenProductListModal,
  handleProductCardClick,
  editorActions,
  testId,
  setSelectedProduct,
}) => {
  const { showProductCta, showProductDescription, background, showProductName, showProductPrice, aspectRatio, isRenderingOnPanel, productView, isScrollable } = useShopifyBlockContext();
  const { t } = useAdminTranslation();
  const isEditing = useSelector(isEditMode);
  const shopifyCurrencyCode = useSelector(getShopCurrencyCode);
  const dispatch = useDispatch();
  const ecommerceProduct: IEcommerceProduct = transformShopifyProductIntoEcommerceProduct(product, 1);
  const { options, selectedOptions, handleOptionChange, selectedVariant } = useProductOptions(ecommerceProduct);
  const { productVariant } = useProductVariant(ecommerceProduct, selectedOptions, selectedVariant);
  const [ctaStringKey, setCtaStringKey] = React.useState<TI18nKey>('ADMIN_ADD_TO_CART');
  const [imageRef, { height: imageHeight }] = useResizeObserver();
  const isSingleItemPanel = productView === 'singleProduct';

  const isMockProduct = product.id === MOCK_PRODUCT_ID;
  const isAvailableForSale = productVariant?.availableForSale;

  const handleClick = () => {
    if (isEditing && !isSingleItemPanel) {
      setSelectedProduct?.(product);
    }
    handleOpenProductListModal();
    handleProductCardClick?.({
      currency: shopifyCurrencyCode,
      product: transformShopifyProductIntoEcommerceProduct(product, 1),
    } as IProductComponentProps);
  };

  React.useEffect(() => {
    setCtaStringKey(isAvailableForSale ? 'ADMIN_ADD_TO_CART' : 'SOLD_OUT');
  }, [isAvailableForSale]);

  const renderImage = React.useCallback(() => {
    const images = productVariant?.images;
    if (!images || !images.length) {
      return (
        <NoImage onClick={handleClick} data-testid="shopifyProductImage" aspectRatio={aspectRatio}>
          {
            isEditing && isMockProduct ? (
              <>
                <AddIcon />
                {t('ADD_PRODUCT')}
              </>
            ) : <NoImageText>{t('SHOPIFY_NO_IMAGE')}</NoImageText>
          }
        </NoImage>
      );
    }
    return (
      <ProductImage
        aspectRatio={aspectRatio}
        images={images}
        source="ProductCard"
        isAvailableForSale={isAvailableForSale || false}
        isRenderingOnPanel={isRenderingOnPanel}
        onClick={handleClick}
        name={productVariant.name}
        imageRef={imageRef}
      />
    );
  }, [t, aspectRatio, isEditing, isMockProduct, productVariant?.images, isAvailableForSale, isRenderingOnPanel, handleClick, imageRef]);

  const handleAddProductToCart = React.useCallback(() => {
    if (!productVariant) {
      console.error('handleAddProductToCart failed because productVariant is null');
      return;
    }
    dispatch(addCartItem({
      productId: productVariant.productId,
      quantity: productVariant.quantity,
      variantId: productVariant.variantId,
    }));

    setCtaStringKey('ADMIN_CHANNEL_LIBRARY_ADDED');

    const timeout = setTimeout(() => {
      setCtaStringKey('ADMIN_ADD_TO_CART');
    }, 2000);

    return () => clearTimeout(timeout);
  }, [productVariant]);

  const setVariantsOptionsMenuPosition = React.useCallback((menu: HTMLDivElement, menuContainer: HTMLDivElement) => {
    const menuContainerRect = menuContainer.getBoundingClientRect();

    const safePosition = getRectPositionCheckingBounds({
      elem: menu,
      position: {
        x: menuContainerRect.x,
        y: menuContainerRect.y,
      },
      margin: 30,
    });

    // When is not scrollable (The regular behavior of the shopify block gallery),
    // we use the transform css to move the elements. An element inside a parent that uses transform makes it confusing
    // Not using the relative parent as reference. See: https://achrafkassioui.com/blog/position-fixed-and-CSS-transforms/
    if (!isScrollable) {
      menu.style.position = 'absolute';
      menu.style.top = `${menuContainerRect.height + 10}px`;
      menu.style.left = '0';
      menu.style.maxWidth = `${window.innerWidth - menuContainerRect.left - 10}px`;
    } else {
      menu.style.position = 'fixed';
      menu.style.top = `${safePosition.y + 30}px`;
      menu.style.left = `${Math.max(safePosition.x, 0)}px`;
    }

  }, [isScrollable]);

  const renderOptions = React.useCallback(() => {
    if (!options.length) {
      return null;
    }

    if (isSingleItemPanel) {
      return (
        <>
          <ShippingInformation>{t('SHIPPING_CHECKOUT_INFO')}</ShippingInformation>
          <OptionsSelectorWrapper>
            <OptionsSelector
              options={options}
              selectedOptions={productVariant?.selectedOptions || []}
              handleOptionChange={handleOptionChange}
            />
          </OptionsSelectorWrapper>
        </>
      );
    }

    return (
      <OptionsView
        options={options}
        selectedOptions={productVariant?.selectedOptions || []}
        handleOptionChange={handleOptionChange}
        isRenderingOnPanel={isRenderingOnPanel}
        setMenuPosition={setVariantsOptionsMenuPosition}
        variantName={productVariant?.name || ''}
      />
    );
  }, [
    options,
    productVariant?.selectedOptions,
    isRenderingOnPanel,
    setVariantsOptionsMenuPosition,
    handleOptionChange,
  ]);

  if (!productVariant) {
    return null;
  }

  return (
    <StyledEditableArea
      isEditing={isEditing}
      editorActions={editorActions}
      editorActionsCss={EDITOR_ACTIONS_CSS}
      blockTitleKey="ADMIN_LABEL_PRODUCT"
      horizontalView={horizontalView}
      productView={productView}
      onClick={handleOpenProductListModal}
      testId={testId}
    >
      {renderImage()}
      <Content imageHeight={imageHeight} horizontalView={horizontalView}>
        {
          (showProductName || showProductDescription) && (
            <Texts onClick={handleClick}>
              {showProductName && (
                <Title
                  isRenderingOnPanel={isRenderingOnPanel}
                  data-testid="shopifyProductName"
                  horizontalView={horizontalView}
                  productView={productView}
                >
                  {product.title}
                </Title>
              )}
              {showProductDescription && (
                <Description
                  isRenderingOnPanel={isRenderingOnPanel}
                  data-testid="shopifyProductDescription"
                  horizontalView={horizontalView}
                  productView={productView}
                >
                  {product.description}
                </Description>
              )}
            </Texts>
          )
        }
        {
          (showProductPrice || options.length > 0) && (
            <PriceAndOptionsContainer>
              {showProductPrice && (
                <StyledPrice
                  isRenderingOnPanel={isRenderingOnPanel}
                  productView={productView}
                  data-testid="shopifyProductPrice"
                  price={productVariant.price.toString()}
                />
              )}
              {renderOptions()}
            </PriceAndOptionsContainer>
          )
        }

        {showProductCta && (
          <StyledButton
            blockBackgroundColor={background.color}
            isRenderingOnPanel={isRenderingOnPanel}
            data-testid="shopifyProductAddToCart"
            horizontalView={horizontalView}
            disabled={!isAvailableForSale || ctaStringKey === 'ADMIN_CHANNEL_LIBRARY_ADDED' || isMockProduct}
            onClick={!isEditing ? handleAddProductToCart : undefined}
          >
            {t(ctaStringKey)}
          </StyledButton>
        )}
      </Content>
    </StyledEditableArea>
  );
};

export default ProductCard;

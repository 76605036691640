import React, { PropsWithChildren } from 'react';
import { StyledEditorWrapper, StyledUploadZone } from './styles';
import { ICalendarEvent } from 'models/ICalendarEvent';

interface IImageEditorModalProps extends PropsWithChildren {
  image: ICalendarEvent['image'];
  onChange: (item: ICalendarEvent['image']) => void;
  readOnly: boolean;
}

const ImageEditorModal: React.FC<IImageEditorModalProps> = ({
  image,
  onChange,
  children,
  readOnly,
}) => {
  const handleClearImage = React.useCallback(() => {
    onChange({ ...image, src: '' });
  }, [image, onChange]);

  const handleChangeImage = React.useCallback((src: string) => {
    onChange({ ...image, src });
  }, [image, onChange]);

  return (
    <StyledEditorWrapper
      readOnly={readOnly}
      blockTitleKey="ADMIN_LABEL_IMAGE"
      modalTitleKey="ADMIN_LABEL_IMAGE_SETTINGS"
      data-testid="event-image-editor-modal"
      modalContent={(
        <StyledUploadZone
          imagePreview={image.src}
          onClearImage={handleClearImage}
          onFileSubmit={handleChangeImage}
        />
      )}
    >
      {children}
    </StyledEditorWrapper>
  );
};

export default ImageEditorModal;

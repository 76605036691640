import { connect } from 'react-redux';
import { trackOverlay } from 'services/insights';
import { removeOverlay } from 'services/overlays';
import RafflePickingOverlay from './RafflePickingOverlay';

const mapDispatchToProps = dispatch => ({
  onDismiss: key => dispatch(removeOverlay(key)),
  trackDismiss: doc => dispatch(trackOverlay('dismiss', doc)),
});

export default connect(null, mapDispatchToProps)(RafflePickingOverlay);

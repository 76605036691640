import { TEXT_300, OPTIONAL_DYNAMIC_THEME_COLOR, FONT_FAMILY_BODY } from 'style/constants';
import { mobileOnly } from 'style/mixins';
import styled, { css } from 'styled-components';
import { IFAQData } from '../utils';
import InlineTextEditor from 'components/admin2/ui/InlineTextEditor';
import EditableArea from 'components/admin2/ui/EditableArea';

const EDITOR_ACTIONS_CSS = css`
  position: absolute;
  right: 10px;
  top: 20px;
`;


export const StyledEditableArea = styled(EditableArea).attrs(() => ({
  editorActionsCss: EDITOR_ACTIONS_CSS,
  blockTitleKey: 'ADMIN_LABEL_ADD_FAQ_CARD',
  borderStyle: 'solid',
}))`
  width: 100%;
`;


export const Wrapper = styled.div<{ alignment: IFAQData['alignment']; backgroundColor: string; }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignment }) => alignment.horizontal === 'left' ? 'flex-start' : alignment.horizontal === 'right' ? 'flex-end' : 'center'};

  gap: 10px;
  padding: 20px 30px;
  border-radius: 10px;
  background-color: ${({ backgroundColor }) => OPTIONAL_DYNAMIC_THEME_COLOR(backgroundColor)};

  ${mobileOnly`padding: 20px;`};
`;


export const Answer = styled(InlineTextEditor)<{ alignment: IFAQData['alignment']; background: string; }>`
  & .mce-content-body {
    color: ${TEXT_300};
    & * {
      text-align:  ${({ alignment }) => alignment.horizontal} !important;
      font-family: ${FONT_FAMILY_BODY};
    }
  }
`;

/* eslint react/destructuring-assignment: off */
/* eslint-disable no-restricted-imports */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ACCENT_PRIMARY, SURFACE_5, TEXT_CONTRAST_ON_ACCENT_PRIMARY } from 'style/constants';

import Icon from 'components/ui/Icon';
import TranslatedText from 'components/i18n/TranslatedText';
import ProfileImageSelector from 'components/panels/ProfilePanel/ProfileImageSelector';
import { PictureWrapper, Picture } from 'components/panels/ProfilePanel/styles';
import UpdatePassword from 'components/panels/ProfilePanel/UpdatePassword';
import { PROFILE_NAME_INPUT_CLASS } from 'injection-classes';
import Separator from 'components/panels/Separator';
import { ProfilePanelButton } from 'components/ui/Button/styles';
import { mobileOnly } from 'style/mixins';
import { MOBILE_TAB_BAR_HEIGHT } from 'components/mobile/MobileTabBar/styles';
import FormInput from 'components/ui/v2/Inputs';
import { withT } from 'hooks/use-translation';
import { Label } from '../ProfileImageSelector/view';

const Container = styled.div`
  padding: 20px;
  overflow: scroll;
  ${mobileOnly`
    padding-bottom: ${MOBILE_TAB_BAR_HEIGHT}px;
  `}
`;

const DoneButton = styled(ProfilePanelButton).attrs({
  'data-testid': 'upDoneButton',
})`
  width: 100px;
`;

export const BackButton = styled(Icon)`
  & > svg {
    width: 18px;
    height: 18px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EditNameIcon = styled(FormInput.InputIcon)`
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  ${props => props.disabled && css`pointer-events: none;`}
  color: ${(props) => props.disabled ? ACCENT_PRIMARY : SURFACE_5};
  &:hover {
    background-color: ${ACCENT_PRIMARY};
    svg {
      path {
        fill: ${TEXT_CONTRAST_ON_ACCENT_PRIMARY};
      }
    }
  }
`;

const EmailAndPasswordContainer = styled.div``;

const getInitialValues = (userBlob, userProfile, userAvatar) => {
  return {
    currentImage: userProfile.icon || userBlob?.image || userAvatar,
    name: userProfile.name || '',
  };
};

class EditPage extends React.Component {
  static propTypes = {
    onEngage: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    profileImages: PropTypes.arrayOf(PropTypes.object).isRequired,
    profileSettings: PropTypes.shape({
      changeName: PropTypes.bool,
      profileImages: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    thirdPartyAuthProviders: PropTypes.arrayOf(PropTypes.object).isRequired,
    toggleEditPage: PropTypes.func.isRequired,
    userAvatar: PropTypes.string.isRequired,
    userData: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
    userProfile: PropTypes.shape({
      accountId: PropTypes.string,
      icon: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  };

  state = {
    imageSelected: null,
    initialName: '',
    nameInputDisabled: true,
    ...getInitialValues(this.props.userData, this.props.userProfile, this.props.userAvatar),
  };

  nameInputRef = null;

  componentDidMount() {
    const { name } = this.state;

    this.setState({
      initialName: name,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.nameInputDisabled !== this.state.nameInputDisabled &&
      !this.state.nameInputDisabled
    ) {
      this.focusNameInput();
    }
  }

  setNameInputRef = (element) => {
    this.nameInputRef = element;
  };

  focusNameInput = () => {
    if (this.nameInputRef) {
      this.nameInputRef.focus();
    }
  };

  selectImage = (index) => {
    this.setState({
      imageSelected: index,
    });
  };

  handleSave = () => {
    const { onEngage, toggleEditPage } = this.props;

    onEngage(
      {
        data: {
          kind: 'profile',
        },
      },
      {
        action: 'submit_edit_profile',
      },
    );

    this.updateProfile();

    toggleEditPage(false);
  };

  handleTextChange = (value) => {
    this.setState({
      name: value,
    });
  };

  handleClickUpdateNameButton = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      nameInputDisabled: !prevState.nameInputDisabled,
    }));
  };

  updateProfile = ({ justUpdateName } = {}) => {
    const { name, imageSelected, initialName } = this.state;
    const { onSave, userProfile, profileImages } = this.props;
    const selectedImage = profileImages[imageSelected];
    const trimmedName = name.trim();

    const icon = justUpdateName ?
      userProfile.icon :
      selectedImage?.url || selectedImage?.image || userProfile.icon;

    onSave({
      accountId: userProfile.accountId,
      icon: icon || undefined,
      name: trimmedName || initialName,
    });
  };

  render() {
    const { currentImage, imageSelected, name, nameInputDisabled } = this.state;
    const {
      profileImages,
      profileSettings,
      toggleEditPage,
      thirdPartyAuthProviders,
      userData,
      t,
    } = this.props;
    const selectedImage = profileImages[imageSelected];

    const isThirdPartyAuth = thirdPartyAuthProviders?.length > 0;

    return (
      <Container>
        <ButtonWrapper>
          <BackButton admin name="adminbarLt" onClick={toggleEditPage} />
          <DoneButton onClick={this.handleSave}>
            <TranslatedText stringKey="DONE" />
          </DoneButton>
        </ButtonWrapper>
        <PictureWrapper>
          <Picture src={selectedImage?.url || currentImage} />
        </PictureWrapper>
        <FormInput.Root error={!name.trim()}>
          <FormInput.FieldSet>
            <FormInput.Legend>{t('NAME')}</FormInput.Legend>
            <FormInput.TextInput
              ref={this.setNameInputRef}
              className={PROFILE_NAME_INPUT_CLASS}
              data-testid="upNameInput"
              disabled={!profileSettings.changeName || nameInputDisabled}
              name="name"
              onChange={this.handleTextChange}
              onFocus={this.handleFocus}
              value={name}
            />
            <EditNameIcon
              data-testid="upPencilButton"
              disabled={!profileSettings.changeName}
              name={nameInputDisabled ? 'pencil' : 'checkmark'}
              onClick={this.handleClickUpdateNameButton}
            />
          </FormInput.FieldSet>
          {!name.trim() && <FormInput.SupportingText>{t('EMPTY_NAME')}</FormInput.SupportingText>}
        </FormInput.Root>
        <EmailAndPasswordContainer>
          <ProfileImageSelector
            currentImage={currentImage}
            images={profileImages}
            selected={imageSelected}
            selectImage={this.selectImage}
          />
          {!isThirdPartyAuth && (
          <>
            <Separator />
            <Label>
              {t('EMAIL')}
            </Label>
            <FormInput.FieldSet>
              <FormInput.Legend>{t('EMAIL')}</FormInput.Legend>
              <FormInput.InputIcon name="mail" />
              <FormInput.TextInput
                disabled
                name="email"
                readOnly
                type="email"
                value={userData.email}
              />
            </FormInput.FieldSet>
            <Separator />
            <UpdatePassword />
          </>
          )}
        </EmailAndPasswordContainer>
      </Container>
    );
  }
}

export default withT(EditPage);

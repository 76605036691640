import styled, { css } from 'styled-components';
import { ADMIN_TEXT_300, ADMIN_SURFACE_3, ADMIN_HIGHLIGHT_PRIMARY } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';
import Icon from 'components/ui/Icon';
import VideoLink from 'components/ui/VideoLink';
import { desktopOnly, THEME_PADDING_DESKTOP } from 'style/mixins';

export const Wrapper = styled.div<{ isEditing: boolean; }>`
  min-width: calc((100% - ${THEME_PADDING_DESKTOP}) / 2);
  aspect-ratio: 16 / 9;
  ${desktopOnly`
    display: flex;
    justify-content: center;
  `};

  ${({ isEditing }) => isEditing && css`pointer-events: none;`}

  & #video-overlay {
    width: 100%;
  }
`;

export const NoVideo = styled.div`
  aspect-ratio: 16 / 9;
  border-radius: 10px;
  background-color: ${ADMIN_SURFACE_3};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  column-gap: 10px;

  ${ADMIN_TEXT_LABEL_M_MEDIUM}
  color: ${ADMIN_TEXT_300};
`;

export const AddIcon = styled(Icon)`
  & svg {
    width: 34px;
    height: 34px;

    & path {
      fill: ${ADMIN_HIGHLIGHT_PRIMARY}
    }
  }
`;

export const StyledVideoLink = styled(VideoLink)`
  width: 100%;
`;

import { connect } from 'react-redux';
import { showModal } from 'services/modals';
import { engageOverlayCta, removeOverlay } from 'services/overlays';
import { trackOverlay } from 'services/insights';
import TweetOverlay from './view';

const mapDispatchToProps = dispatch => ({
  onDismiss: key => dispatch(removeOverlay(key)),
  onEngagedOverlay: broadcastId => dispatch(engageOverlayCta(broadcastId)),
  onShowPost: data => dispatch(showModal({ data, kind: 'twitter' })),
  trackDismiss: doc => dispatch(trackOverlay('dismiss', doc)),
  trackEngage: (doc, extra) => dispatch(trackOverlay('engage', doc, extra)),
});

export default connect(null, mapDispatchToProps)(TweetOverlay);

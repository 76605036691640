import { contrastOnBackground } from 'colors';
import { OPTIONAL_DYNAMIC_THEME_COLOR, SURFACE_6, TEXT_100 } from 'style/constants';
import { TEXT_LABEL_S_BOLD, TEXT_LABEL_XS_BOLD } from 'style/design-system/textStyles';
import { HIDE_SCROLLBAR, mobileOnly } from 'style/mixins';
import styled from 'styled-components';

export const LabelsWrapper = styled.section`
  display: flex;
  gap: 3px;
  ${HIDE_SCROLLBAR}
  overflow-x: auto;
`;

export const Label = styled.label<{ backgroundColor: string; }>`
  text-wrap: nowrap;
  width: fit-content;
  display: flex;
  padding: 2px 8px;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: ${props => OPTIONAL_DYNAMIC_THEME_COLOR(props.backgroundColor) || SURFACE_6};
  color: ${props => contrastOnBackground(TEXT_100(props), props.backgroundColor || SURFACE_6(props))};

  ${TEXT_LABEL_S_BOLD}

  ${mobileOnly`${TEXT_LABEL_XS_BOLD}`}
`;

import React from 'react';
import type { DateData } from 'hooks/use-calendar';
import { useEventCalendarContext } from '../../EventCalendarContext';
import { CreateEventButton, Day, DayColumnWrapper, DayEventsWrapper, DayHeader } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import { useAdminTranslation } from 'hooks/use-translation';
import MiniEventCard from '../../EventCard/MiniEventCard';
import { SHOW_MINIMAL_INFO_MAX_WIDTH, getNewEventCard } from '../../utils';
import { dismissModal, showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import { Dot } from '../styles';
import useInfiniteScroll from 'hooks/use-infinite-scroll';
import { getWidth } from 'services/device';

interface IDayColumnProps {
  day: string;
  weekDate: DateData;
}

const MINIMUM_EVENTS_TO_SHOW = 8;

const DayColumn: React.FC<IDayColumnProps> = ({ weekDate, day }) => {
  const {
    getEventsForDay,
    filterEvents,
    isSameDay,
    isToday,
    selectedDate,
    selectedSiteCategories,
    upsertEvent,
    removeEvent,
    eventsOnWeek,
  } = useEventCalendarContext();
  const isEditing = useSelector(isEditMode);
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const ref = React.useRef<HTMLDivElement>(null);
  const [itemsQuantity, setItemsQuantity] = React.useState(MINIMUM_EVENTS_TO_SHOW);
  const showingMinimalInfo = useSelector(getWidth) <= SHOW_MINIMAL_INFO_MAX_WIDTH;

  const onReachBottom = React.useCallback(() => {
    if (showingMinimalInfo) {
      return;
    }

    setItemsQuantity((prev) => prev + 3);
  }, [showingMinimalInfo]);

  useInfiniteScroll({ onReachBottom, refElement: ref });

  React.useEffect(() => {
    setItemsQuantity(MINIMUM_EVENTS_TO_SHOW);
  }, [eventsOnWeek]);

  const dayEvents = React.useMemo(() => getEventsForDay(eventsOnWeek, weekDate), [eventsOnWeek, weekDate, getEventsForDay]);
  const events = React.useMemo(() => {
    const filteredEvents = filterEvents(dayEvents, selectedSiteCategories);

    if (showingMinimalInfo) {
      return filteredEvents;
    }

    return filteredEvents.slice(0, itemsQuantity);
  }, [dayEvents, filterEvents, itemsQuantity, selectedSiteCategories, showingMinimalInfo]);

  const isSelectedDate = isSameDay(weekDate, selectedDate);
  const today = isToday(weekDate);

  const handleCloseEventSettings = React.useCallback(() => {
    dispatch(dismissModal(ModalKinds.calendarBlockEventSettings));
  }, []);

  const handleOpenEventSettings = React.useCallback((startDateTimestamp: number) => {
    dispatch(showModal({
      kind: ModalKinds.calendarBlockEventSettings,
      data: {
        locked: true,
        onDismiss: handleCloseEventSettings,
        onSave: upsertEvent,
        calendarEvent: getNewEventCard(startDateTimestamp),
      },
    }));
  }, [upsertEvent, handleCloseEventSettings]);

  const handleCreateEvent = React.useCallback(() => {
    const date = new Date();
    date.setDate(weekDate.day);
    date.setMonth(weekDate.month);
    date.setFullYear(weekDate.year);
    handleOpenEventSettings(date.getTime());
  }, [handleOpenEventSettings, weekDate.day, weekDate.month, weekDate.year]);

  return (
    <DayColumnWrapper isToday={today} isSelectedDate={isSelectedDate}>
      <DayHeader>
        {today && <Dot />}
        {day}
        <Day>{weekDate.day}</Day>
      </DayHeader>
      <DayEventsWrapper ref={ref}>
        {
          events.map((event, i) => (
            <MiniEventCard
              readOnly={true}
              key={event._id || i}
              showActionsMenu={isEditing}
              event={event}
              onChange={upsertEvent}
              onRemove={removeEvent}
            />
          ))
        }
        {
          isEditing && (
            <CreateEventButton onClick={handleCreateEvent}>
              {t('ADMIN_LABEL_CREATE_EVENT')}
            </CreateEventButton>
          )
        }
      </DayEventsWrapper>
    </DayColumnWrapper>
  );
};

export default DayColumn;

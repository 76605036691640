import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import hash from 'json-stable-stringify';
import { getCollectionsCatalog, getIsShopifyConnected, getShop, getShopRecurringChargeInfo } from 'services/shopify/selectors';
import {
  CheckIcon,
  InputWrapper,
  OpenShopButton,
  InstructionsLink,
  StoreConnectedWrapper,
  ShopifyIcon,
  RecurringChargeHighlightedText,
  WarningIcon,
  Spacer,
} from './styles';
import { useAdminTranslation } from 'hooks/use-translation';
import { useRealtimeDocument } from 'hooks';
import { getSiteId } from 'services/app/selectors';
import { MAESTRO_SHOPIFY_APP_URL } from 'shared/constants';
import { navigateToAdminBarAction } from 'services/admin/actions';
import { ActionKey } from 'services/admin/constants';
import { Section, SectionTitle } from 'components/admin-bridge/EditorModal/ModalComponents/Section';
import TextInput from 'components/admin2/TextInput';
import { Dropdown } from 'components/admin2/ui/Dropdown';
import { IShopifyBlockData, PRODUCT_VIEW_LABEL_MAP, PRODUCT_VIEW_OPTIONS, ProductViewKeys } from '../utils';
import { IShop } from 'services/shopify/models';

interface IShopInfoProps {
  item: IShopifyBlockData;
  onChangeCollection: (collectionHandle: string) => void;
  onChangeProductView: (productView: ProductViewKeys) => void;
}

const ShopInfo: React.FC<IShopInfoProps> = ({ item, onChangeCollection, onChangeProductView }) => {
  const isShopifyConnected = useSelector(getIsShopifyConnected);
  const collections = useSelector(getCollectionsCatalog);
  const [collection, setCollection] = useState({ value: '', label: '', handle: '' });
  const [showRecurringChargeBanner, setShowRecurringChargeBanner] = useState(false);
  const shop = useSelector(getShop);
  const siteId = useSelector(getSiteId);
  const shopRecurringChargeInfo = useSelector(getShopRecurringChargeInfo);
  const [realTimeRecurringChargeInfo, loaded] = useRealtimeDocument<IShop>('shopifyappinstalls', siteId);

  const { t } = useAdminTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (shopRecurringChargeInfo.active === true || realTimeRecurringChargeInfo?.recurringChargeActivated === true) {
      setShowRecurringChargeBanner(false);
      return;
    }
    setShowRecurringChargeBanner(true);
  }, [loaded, hash(realTimeRecurringChargeInfo), hash(shopRecurringChargeInfo)]);

  const productViewsWithDataTestIds = useMemo(() => {
    return PRODUCT_VIEW_OPTIONS.map((productView, index) => ({
      ...productView,
      'data-testid': `shopifyProductView-${index}`,
    }));
  }, []);

  const collectionsWithDataTestIds = useMemo(() => {
    return collections.map((target, index) => ({
      ...target,
      'data-testid': `shopifyCollection-${index}`,
    }));
  }, [collections]);

  useEffect(() => {
    const newCollection = collections.find((c) => c.handle === item.shopifyCollectionHandle);
    if (newCollection) {
      setCollection(newCollection);
    }
  }, [item.shopifyCollectionHandle, collections]);

  const handleOpenThirdPartyTab = useCallback(() => {
    window.open(MAESTRO_SHOPIFY_APP_URL, '_blank');
    dispatch(navigateToAdminBarAction({ actionKey: ActionKey.thirdParty }));
  }, [dispatch]);

  const handleOpenRecurringChargeConfirmation = useCallback(() => {
    window.open(shopRecurringChargeInfo.confirmationUrl, '_blank');
    dispatch(navigateToAdminBarAction({ actionKey: ActionKey.thirdParty }));
  }, [dispatch]);

  const handleChangeCollection = useCallback(({ handle }: typeof collections[number]) => {
    onChangeCollection(handle);
  }, [onChangeCollection]);

  const handleChangeProductView = useCallback(({ value }: typeof PRODUCT_VIEW_OPTIONS[number]) => {
    onChangeProductView(value);
  }, [onChangeProductView]);

  if (!isShopifyConnected) {
    return (
      <Section gap={16}>
        <SectionTitle>{t('ADMIN_LABEL_SHOPIFY_STORE')}</SectionTitle>
        <StoreConnectedWrapper>
          <div>
            {t('ADMIN_CARD_SHOPIFY_NOT_CONNECTED_DESCRIPTION')}
            {t('ADMIN_CARD_SHOPIFY_NOT_CONNECTED_INSTRUCTIONS_TEXT')}
            <InstructionsLink
              href="https://support.maestro.io/knowledge/shopify-install-the-maestro-integration"
              target="_blank"
            >
              {t('ADMIN_CARD_SHOPIFY_NOT_CONNECTED_INSTRUCTIONS_LINK')}
            </InstructionsLink>
          </div>
          <OpenShopButton onClick={handleOpenThirdPartyTab}>
            <ShopifyIcon />
            {t('ADMIN_CARD_SHOPIFY_NOT_CONNECTED_INSTALL_APP')}
          </OpenShopButton>
        </StoreConnectedWrapper>
      </Section>
    );
  }

  if (showRecurringChargeBanner) {
    return (
      <Section gap={16}>
        <SectionTitle>{t('ADMIN_LABEL_SHOPIFY_STORE')}</SectionTitle>
        <StoreConnectedWrapper>
          <div>
            <RecurringChargeHighlightedText>
              <WarningIcon />
              {t('ADMIN_CARD_SHOPIFY_RECURRING_CHARGE_MISSING_STEP')}
            </RecurringChargeHighlightedText>
            <Spacer/>
            {t('ADMIN_CARD_SHOPIFY_RECURRING_CHARGE_MISSING_STEP_DESCRIPTION_1')}
            <Spacer/>
            <RecurringChargeHighlightedText>
              {shop}
            </RecurringChargeHighlightedText>
            <Spacer/>
            {t('ADMIN_CARD_SHOPIFY_RECURRING_CHARGE_MISSING_STEP_DESCRIPTION_2')}
          </div>
          <OpenShopButton onClick={handleOpenRecurringChargeConfirmation}>
            {t('ADMIN_CARD_SHOPIFY_RECURRING_CHARGE_CONFIRMATION_LINK')}
          </OpenShopButton>
        </StoreConnectedWrapper>
      </Section>
    );
  }

  return (
    <Section gap={20}>
      <InputWrapper>
        <TextInput
          prefixInputIcon="shopifyV2"
          labelKey="ADMIN_LABEL_SHOPIFY_STORE"
          padding="0"
          value={shop}
          disabled={true}
        />
        <CheckIcon />
      </InputWrapper>

      <Section gap={10}>
        <SectionTitle>{t('ADMIN_PRODUCT_VIEW')}</SectionTitle>
        <Dropdown
          onChange={handleChangeProductView}
          options={productViewsWithDataTestIds}
          value={{ value: item.productView, label: PRODUCT_VIEW_LABEL_MAP[item.productView] }}
          padding="0"
          admin={true}
          isSearchable={true}
          dataTestId="shopifyProductView"
        />
      </Section>

      {
        item.productView === 'collection' && (
        <Section gap={10}>
          <SectionTitle>{t('ADMIN_CARD_SHOPIFY_SELECT_COLLECTION')}</SectionTitle>
          <Dropdown
            onChange={handleChangeCollection}
            options={collectionsWithDataTestIds}
            value={collection}
            placeholderKey="ADMIN_CARD_SHOPIFY_SELECT_COLLECTION"
            padding="0"
            admin={true}
            isSearchable={true}
            dataTestId="shopifySelectCollection"
          />
        </Section>
        )
      }
    </Section>
  );
};

export default ShopInfo;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BACKGROUND_ACTIVE, TITLE_FONT } from 'style/mixins';
import { withEndUserT, DynamicTranslationType } from 'hooks/use-translation';

const ClickableContainer = styled.div`
  cursor: pointer;
`;

const FlexWrapper = styled.div`
  ${BACKGROUND_ACTIVE}
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 5px;
  ${TITLE_FONT}
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
`;

class ShowGroupOverlayPanel extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    ctaRenderer: PropTypes.shape({
      groupId: PropTypes.string,
      text: PropTypes.string,
    }).isRequired,
    endUserT: PropTypes.func.isRequired,
    onEngagedOverlay: PropTypes.func.isRequired,
    onOpenPanel: PropTypes.func.isRequired,
    overlayId: PropTypes.string.isRequired,
    testId: PropTypes.string,
  };

  static defaultProps = {
    children: undefined,
    testId: undefined,
  };

  onOpenPanelClick = () => {
    const {
      ctaRenderer: { groupId },
      onEngagedOverlay,
      onOpenPanel,
    } = this.props;

    onEngagedOverlay();

    if (groupId) {
      onOpenPanel({
        doc: {
          renderer: {
            groups: [
              { id: groupId },
            ],
            panelType: 'peopleGroups',
          },
        },
      });
    }
  };

  render() {
    const { children, ctaRenderer: { text }, endUserT, overlayId, testId } = this.props;

    return (
      <ClickableContainer data-testid={testId} onClick={this.onOpenPanelClick}>
        {
          children || (
            <FlexWrapper>
              {
                endUserT([
                  DynamicTranslationType.overlayCtaText,
                  { overlayId },
                ]) || text
              }
            </FlexWrapper>
          )
        }
      </ClickableContainer>
    );
  }
}

export default withEndUserT(ShowGroupOverlayPanel);

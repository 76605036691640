import React from 'react';
import { ILabel } from 'models/ICalendarEvent';
import { Label, LabelsWrapper } from './styles';

type LabelProps = {
  labels: ILabel[];
};

const Labels: React.FC<LabelProps> = ({ labels, ...rest }) => {
  if (!labels.length) {
    return null;
  }

  return (
    <LabelsWrapper {...rest}>
      {labels.map((label, index) => (
        <Label backgroundColor={label.color} key={index}>{label.title}</Label>
      ))}
    </LabelsWrapper>
  );
};

export default Labels;

import styled from 'styled-components';
import { ADMIN_BUTTON_BORDER_RADIUS, HIGHLIGHT_PRIMARY, SURFACE_2, SURFACE_3, TEXT_200 } from 'style/constants';
import { avatarWidth } from '../shared/styles';
import withConfirmation from 'components/core/withConfirmation';

const DeleteButton = styled.button`
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 24px;
  height: 24px;
  overflow: hidden;
  background: ${SURFACE_3};
  color: transparent;
  border-radius: ${ADMIN_BUTTON_BORDER_RADIUS};

  & svg {
    width: 10px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    & > path {
      fill: ${HIGHLIGHT_PRIMARY};
    }
  }
`;

export const DeleteButtonWithConfirmation = withConfirmation(DeleteButton);

export const MessageItemWrapper = styled.div`
  position: relative;
  padding: 0 0 0 ${avatarWidth + 8}px;
  width: 100%;
  box-sizing: border-box;
  min-height: 20px;
  margin-bottom: 10px;

  &:hover {
    background: ${SURFACE_2};

    &::before {
      content: '';
      position: absolute;
      top: -4px;
      left: -4px;
      height: calc(100%);
      width: calc(100%);
      border-radius: ${ADMIN_BUTTON_BORDER_RADIUS};
      border: 4px solid ${SURFACE_2};
      pointer-events: none;
    }

    ${DeleteButton} {
      display: block;
    }
  }
`;

export const AdminBadge = styled.span`
  margin-left: 5px;
  font-size: 8px;
  color: ${TEXT_200};
`;

import React from 'react';
import DateTimeEditor from '../DateTimeEditorModal';
import { DateLabel } from './styles';
import { ICalendarEvent } from 'models/ICalendarEvent';

interface IEventDateProps {
  date: ICalendarEvent['date'];
  onChange: (item: ICalendarEvent['date']) => void;
  readOnly: boolean;
  startDate: string;
}

const EventDate: React.FC<IEventDateProps> = ({ readOnly, date, startDate, onChange }) => {
  return (
    <DateTimeEditor
      readOnly={readOnly}
      date={date}
      onChange={onChange}
      blockTitleKey="ADMIN_COUNTDOWN_DATE"
    >
      <DateLabel>{startDate}</DateLabel>
    </DateTimeEditor>
  );
};

export default EventDate;

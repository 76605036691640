import React from 'react';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';
import { IImageGalleryItem } from '../utils';
import { IMAGE_LAYOUT, OVERFLOW_BEHAVIOR } from '../../../admin-bridge/EditorModal/ModalComponents/OptionRow';
import useImageGalleryLocalizedStrings from './use-image-gallery-localized-strings';

/**
 * This hook is private to Image Gallery Context. Do not use it directly.
 */
const useImageGallery = (item: IImageGalleryItem) => {
  const isMobile = useSelector(isMobileLayout);

  const imageCards = item.data.imageCards ?? [];

  const [overflowBehavior, imagesPerRow, aspectRatio] = React.useMemo(() => {
    if (isMobile) {
      return [
        item.data.overflowBehavior.mobile,
        item.data.overflowBehavior.mobile === OVERFLOW_BEHAVIOR.slider ? imageCards.length : 1,
        item.data.aspectRatio.mobileEqualDesktop ? item.data.aspectRatio.desktop : item.data.aspectRatio.mobile,
      ];
    }

    return [
      item.data.overflowBehavior.desktop,
      item.data.imagesPerRow,
      item.data.aspectRatio.desktop,
    ];
  }, [isMobile, imageCards.length, item.data.overflowBehavior, item.data.imagesPerRow ,item.data.aspectRatio]);

  const backgroundImage = React.useMemo(() => {
    if (!item.data.background.image.show) {
      return undefined;
    }

    if (isMobile && item.data.background.image.mobile) {
      return item.data.background.image.mobile;
    }

    if (!isMobile && item.data.background.image.desktop) {
      return item.data.background.image.desktop;
    }

    return undefined;
  }, [
    isMobile,
    item.data.background.image.mobile,
    item.data.background.image.desktop,
    item.data.background.image.show,
  ]);


  const isScrollable = React.useMemo(() => (
    isMobile &&
    item.data.overflowBehavior.mobile === OVERFLOW_BEHAVIOR.slider &&
    imageCards.length > 1
  ), [isMobile, item.data.overflowBehavior.mobile, imageCards.length]);

  const contentInside = React.useMemo(() => item.data.imageLayout === IMAGE_LAYOUT.contentInside, [item.data.imageLayout]);
  const contentBelow = React.useMemo(() => item.data.imageLayout === IMAGE_LAYOUT.contentBelow, [item.data.imageLayout]);

  const hasTextContent = React.useMemo(() => (
      item.data.showCta ||
      item.data.showTitle ||
      item.data.showDescription ||
      item.data.showLabel
  ), [item.data.showCta, item.data.showTitle, item.data.showDescription, item.data.showLabel]);

  return {
    ...item.data,
    overflowBehavior,
    imagesPerRow,
    aspectRatioConfig: item.data.aspectRatio,
    aspectRatio,
    backgroundImage,
    isScrollable,
    contentInside,
    contentBelow,
    hasTextContent,
    ...useImageGalleryLocalizedStrings(item),
  };
};

export default useImageGallery;

import React, { ReactNode } from 'react';
import { ChunkPaginationReturnType } from 'hooks/use-chunk-pagination';
import { AddMoreButton } from './styles';
import IconButton from 'components/admin2/ui/IconButton';
import { ExternalPageSlider } from 'components/ui/PageSlider';
import { IProduct } from 'services/shopify/models';

interface IShopifyBlockSliderProps {
  children: (pageItems: IProduct[], pageIndex: number, pageCount: number) => ReactNode;
  isRenderingOnPanel: boolean;
  isScrollable: boolean;
  onAddClick: () => void;
  pagination: ChunkPaginationReturnType<IProduct>;
  showAddMoreButton: boolean;
}

const ShopifyBlockSlider: React.FC<IShopifyBlockSliderProps> = ({ isRenderingOnPanel, showAddMoreButton, children, isScrollable, pagination, onAddClick }) => {
  // We always render stack view on panel, so we dont need the page slider.
  // Page slider uses transform: translateX() to slide the pages, but this causes
  // further issues with fixed elements
  if (isRenderingOnPanel) {
    return <>{children(pagination.page, pagination.pageIndex, pagination.pageCount)}</>;
  }

  return (
    <ExternalPageSlider
      absoluteControls={true}
      controlsChildren={showAddMoreButton && (
        <IconButton
          button={AddMoreButton}
          icon="plus"
          iconSpacing={6}
          labelKey="ADMIN_LABEL_ADD_MORE"
          onClick={onAddClick}
        />
      )}
      controlsCss="right: 10px;"
      pagination={pagination}
      isScrollable={isScrollable}
      renderChildrenWithoutItems={true}
    >
      {children}
    </ExternalPageSlider>
  );
};

export default ShopifyBlockSlider;

import styled from 'styled-components';
import TextArea from 'components/admin2/TextArea';
import { Button as AdminButton } from 'components/admin2/ui/Button';
import { ADMIN_TEXT_LABEL_M_BOLD } from 'style/design-system/textStyles';

export const StyledTextArea = styled(TextArea)`
  height: 250px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Button = styled(AdminButton)`
  ${ADMIN_TEXT_LABEL_M_BOLD};
  width: 81px;
  height: 40px;
  padding: 0px, 24px;
  border-radius: 100px;
`;

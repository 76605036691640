import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BACKGROUND_ACTIVE } from 'style/mixins';
import { rgba } from 'colors';
import CloseButton from 'components/ui/CloseButton';
import AdminOverlayEngagement from 'components/admin/AdminOverlayEngagement';
import { TEXT_100 } from 'style/constants';

export const StyledCloseButton = styled(CloseButton)`
  color: ${props => rgba(TEXT_100(props), 0.5)};
`;

export const ClickableContainer = styled.div.attrs(() => ({
  type: 'button',
}))`
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  height: 250px;
  width: 235px;
  ${BACKGROUND_ACTIVE}
`;

const LeftSideBar = styled.div`
  width: 35px;
  font-size: 18px;
  font-weight: lighter;
  text-align: center;
  padding-top: 8.5px; // Should this be changed?
`;

const ThumbnailImage = styled.img`
  width: 200px;
  height: 250px;
`;

export default class InstagramOverlay extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      key: PropTypes.string.isRequired,
      payload: PropTypes.object,
    }).isRequired,
    onDismiss: PropTypes.func.isRequired,
    onEngagedOverlay: PropTypes.func.isRequired,
    onShowPost: PropTypes.func.isRequired,
    overlayType: PropTypes.string.isRequired,
    trackDismiss: PropTypes.func.isRequired,
    trackEngage: PropTypes.func.isRequired,
  };

  dismissOverlay = () => {
    const { data, onDismiss, trackDismiss } = this.props;

    onDismiss(data.key);
    trackDismiss(data);
  };

  openModal = () => {
    const {
      data,
      data: { payload: { data: { broadcastId, post } } },
      onEngagedOverlay,
      onShowPost,
      trackEngage,
    } = this.props;

    onEngagedOverlay(broadcastId);
    trackEngage(data);

    onShowPost(post);
  };

  render() {
    const {
      data: {
        key,
        payload: {
          data: { broadcastId, post, views },
        },
      },
    } = this.props;

    const imgUrl = post?.images?.standardResolution?.url;
    const { overlayType } = this.props;

    return (
      <Container>
        <AdminOverlayEngagement
          broadcastId={broadcastId}
          broadcastKey={key}
          showEngaged
          views={views}
        />
        <ContentContainer className={overlayType}>
          <LeftSideBar>
            <StyledCloseButton onClick={this.dismissOverlay} />
          </LeftSideBar>
          <ClickableContainer onClick={this.openModal}>
            { imgUrl && <ThumbnailImage src={imgUrl} /> }
          </ClickableContainer>
        </ContentContainer>
      </Container>
    );
  }
}

import PropTypes from 'prop-types';
import React from 'react';
import { persistenceService } from 'services/persistence';
import RealtimeDocument from 'components/core/RealtimeDocument';
import KeyedListMapper from 'components/core/KeyedListMapper';
import CountTo from 'components/ui/CountTo';
import shortid from 'shortid';
import {
  MOBILE_LANDSCAPE_MEDIA,
} from 'style/constants';
import { getOverlayPanelDoc } from 'services/overlays';
import { PollTypes } from 'services/polls/models';
import { matchMedia } from 'dom-utils';
import { withEndUserT, DynamicTranslationType } from 'hooks/use-translation'
import OverlayHeader from '../OverlayHeader';
import {
  Answer,
  AnswersContainer,
  AnswerContainer,
  AnswerDiv,
  ContainerSpacing,
  Container,
  Circle,
  BackgroundColorContent,
  BarColor,
  Top,
  TextWrapper,
  ImageContainer,
  PercentageContainer,
  ProgressBarContainer,
  Radio,
  ResultsHeader,
  StyledImage,
  StyledAnswerButton,
  StyledIcon,
  StatsDiv,
} from './styles';

const computeInitialState = async ({ data, onlyResults }) => {
  const answerIndex = onlyResults ?
    await persistenceService('session').read(data.payload.data.questionId) : null;

  return {
    answered: onlyResults,
    answerIndex,
    answers: onlyResults ? data.payload.data.state : null,
    engaged: 0,
    openAnswersOnMobile: false,
  };
};

class PollOverlay extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      duration: PropTypes.number,
      key: PropTypes.string,
      payload: PropTypes.object,
      timeRemaining: PropTypes.number,
    }).isRequired,
    endUserT: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isLandscape: PropTypes.bool.isRequired,
    isMobileLayout: PropTypes.bool.isRequired,
    mobileOpenPollPanel: PropTypes.func.isRequired,
    onAddMessageOverlay: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
    onEngageMultipleChoiceOverlay: PropTypes.func.isRequired,
    onlyResults: PropTypes.bool,
    overlayType: PropTypes.string.isRequired,
    renderer: PropTypes.shape({
      overlayType: PropTypes.string,
      poll: PropTypes.shape({
        _id: PropTypes.string,
        answers: PropTypes.array,
        correctAnswerId: PropTypes.string,
        name: PropTypes.string,
        open: PropTypes.bool,
        question: PropTypes.string,
        type: PropTypes.string,
      }),
    }).isRequired,
    siteId: PropTypes.string.isRequired,
    trackDismiss: PropTypes.func.isRequired,
    trackEngage: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    onlyResults: false,
  };

  state = {};

  async componentDidMount() {
    const initialState = await computeInitialState(this.props);
    this.setState(initialState);
  }

  onValue = (doc) => {
    const { engaged } = doc?.data?.state || {};
    const { engaged: oldEngaged } = this.state;

    if (oldEngaged !== engaged) {
      this.setState({
        engaged,
      });
    }
  };

  onReceiveAnswers = (doc) => {
    if (!doc?.data?.state) { return; }
    this.setState({
      answers: doc.data.state,
    });
  };

  getPollData = () => {
    const { renderer } = this.props;
    const { type, answers } = renderer.poll;
    const correctAnswer = answers.filter((answer) => answer.correct);

    return {
      correctAnswer,
      pollType: type,
    };
  };

  onSelectAnswer = (index, broadcastId, answerText, answerId) => {
    const {
      data, onEngageMultipleChoiceOverlay, renderer, trackEngage, siteId, userId,
    } = this.props;
    const { poll: { _id: pollId } } = renderer;
    const { answered } = this.state;

    if (answered) {
      return;
    }

    const {
      pollType,
      correctAnswer,
    } = this.getPollData();

    const pollResponse = {
      answerId,
      pollId,
      siteId,
      userId,
    };

    const broadcastPayload = { broadcastId, index: Number(index) };

    onEngageMultipleChoiceOverlay(pollResponse, broadcastPayload);

    this.setState({
      answered: true,
      answerIndex: index,
    });

    const extra = {
      action: `answer_${pollType}`,
      poll: pollId,
      response: answerText,
    };

    if (pollType === PollTypes.Poll) {
      trackEngage(data, extra);
    }
    if ([PollTypes.Trivia, PollTypes.Prediction].includes(pollType)) {
      if (correctAnswer.length > 0) {
        extra.correct = correctAnswer.map(c => c._id).includes(answerId);
      }
      trackEngage(data, extra);
    }
  };

  viewTitle = (onlyResults) => {
    const { pollType } = this.state;

    if (pollType === PollTypes.Trivia) {
      return 'trivia';
    }

    if (pollType === PollTypes.Prediction) {
      return 'prediction';
    }

    // eslint-disable-next-line react/destructuring-assignment
    if (this.props?.data?.payload?.renderer?.overlayType) {
      // eslint-disable-next-line react/destructuring-assignment
      return this.props?.data?.payload.renderer.overlayType;
    }

    if (onlyResults) {
      return 'poll_results';
    }

    return 'poll';
  };

  renderImage = (index, answerId) => {
    const { renderer } = this.props;
    const image = renderer.poll?.answers[index]?.imageUrl;
    const {
      correctAnswer,
    } = this.getPollData();

    if (image) {
      if (correctAnswer.find(answer => answer._id === answerId)) {
        return <ImageContainer><StyledImage border src={image} /></ImageContainer>;
      }
      return <ImageContainer><StyledImage src={image} /></ImageContainer>;
    }
    return '';
  };

  isTriviaAnswerOk = (answerId) => {
    const {
      correctAnswer,
      pollType,
    } = this.getPollData();
    const { answered } = this.state;
    if (pollType !== PollTypes.Trivia) {
      return null;
    }

    if (answered && correctAnswer.length > 0) {
      if (correctAnswer.find(answer => answer._id === answerId)) {
        return true;
      }
      return false;
    }

    return null;
  };

  renderAnswerIcon = (index) => {
    const { pollType, answered, answerIndex } = this.state;
    const { renderer } = this.props;
    const { poll } = renderer;
    const intTriviaAnswer = poll.answers.findIndex((answer) => answer.correct);
    if (pollType === PollTypes.Trivia && answered) {
      if (intTriviaAnswer === index) {
        return <Circle isOk><StyledIcon name="correctCircle" nomargin /></Circle>;
      }
      if (answerIndex === index) {
        return <Circle><StyledIcon name="wrongCircle" nomargin /></Circle>;
      }
    }
    return null;
  };

  renderAnswersContainer = (isPreview) => {
    const { data, onlyResults, renderer, endUserT } = this.props;
    const { broadcastId } = data.payload.data;
    const { poll } = renderer;
    const { answerIndex, answered, answers, pollType } = this.state;

    const pollResults = poll.answers.map((answer, i) => {
      if (answers) {
        answer.count = answers[`${i + 1}`] || 0;
        answer.percent = Math.round((answer.count / (answers.engaged || 1)) * 100);
      }
      return answer;
    });

    if (!pollResults || !pollResults.length) {
      return null;
    }

    const showPercent = (!poll.open || poll.type !== PollTypes.Prediction)
    return (
      <AnswersContainer>
        <KeyedListMapper keyField="text" list={pollResults}>
          {
            (listKey, answer, index) => {
              const isAnswerOk = this.isTriviaAnswerOk(answer._id);
              const showImage = this.renderImage(index);
              return (
                <AnswerDiv
                  key={listKey}
                  answered={answered}
                  className={answerIndex === index && 'overlay-selected-item'}
                  isAnswerOk={isAnswerOk}
                  onClick={() => !isPreview &&
                              this.onSelectAnswer(index, broadcastId, answer.answer, answer._id)}
                  selected={answerIndex === index}
                >
                  <BackgroundColorContent />
                  <Answer isActive={answerIndex === index} isAnswerOk={isAnswerOk}>
                    { !onlyResults && (pollType === PollTypes.Trivia && answerIndex === index ?
                      <StyledIcon name="correctCircle" /> :
                      <Radio checked={answerIndex === index} />) }
                    { showImage }
                    <AnswerContainer showImage={showImage !== ''}>
                      <TextWrapper isActive={answerIndex === index}>
                        {
                          endUserT([
                            DynamicTranslationType.multipleChoiceAnswer,
                            {
                              answerId: answer._id,
                              multipleChoiceId: poll._id,
                              multipleChoiceType: poll.type,
                            },
                          ]) || answer.answer
                        }
                      </TextWrapper>
                      {
                    answers && showPercent && (
                      <StatsDiv answered={answerIndex === index}>
                        <span>
                          <CountTo
                            target={answer.percent}
                          >
                            { renderPct => (
                              <PercentageContainer>
                                <span>{`${Math.trunc(renderPct)}%`}</span>
                                <ProgressBarContainer><BarColor width={`${renderPct}%`} /></ProgressBarContainer>
                              </PercentageContainer>
                            ) }
                          </CountTo>
                          { ` (${answer.count})` }
                        </span>
                      </StatsDiv>
                    )
                    }
                    </AnswerContainer>
                  </Answer>
                  { this.renderAnswerIcon(index) }
                </AnswerDiv>
              );
            }
          }
        </KeyedListMapper>
        {
          !onlyResults && answered && broadcastId && (
            <RealtimeDocument
              collection="objects"
              id={broadcastId}
              onValue={this.onReceiveAnswers}
            />
          )
        }
      </AnswersContainer>
    );
  };

  dismissOverlay = () => {
    const { data, onDismiss, trackDismiss } = this.props;
    const { key } = data;
    onDismiss(key);
    trackDismiss(data);
  };

  onOpenPanelClick = (broadcastId) => {
    const {
      isLandscape,
      isMobileLayout,
      onAddMessageOverlay,
      endUserT,
    } = this.props;
    this.togglePanel(broadcastId);

    if (isMobileLayout && isLandscape && matchMedia(MOBILE_LANDSCAPE_MEDIA).matches) {
      const message = endUserT(
        [DynamicTranslationType.overlayRotatePhone],
        ['ADMIN_OVERLAYS_ROTATE_YOUR_PHONE'],
      );
      const overlayPayload = {
        key: shortid.generate(),
        payload: {
          data: {
            duration: 15,
            kind: 'message',
            message,
            trackName: 'Message: Please rotate your phone',
          },
          removeOnPortrait: true,
        },
      };

      onAddMessageOverlay(overlayPayload);
    }
  };

  togglePanel = (broadcastId) => {
    const { data, mobileOpenPollPanel, trackEngage, renderer } = this.props;
    const { poll: { _id: pollId, type } } = renderer;
    const { openAnswersOnMobile } = this.state;
    const doc = getOverlayPanelDoc(data, broadcastId, pollId);

    mobileOpenPollPanel(doc);
    this.setState({ openAnswersOnMobile: !openAnswersOnMobile });

    const extra = {
      action: 'open_panel',
      options: type,
      pollId,
    };
    trackEngage(data, extra);
  };

  render() {
    const {
      data,
      endUserT,
      onlyResults,
      renderer,
      isAdmin,
      onDismiss,
      trackDismiss,
    } = this.props;
    const { openAnswersOnMobile } = this.state;
    const { key, payload, timeRemaining } = data;
    const { broadcastId, views } = payload.data;
    const { poll, overlayType: kind } = renderer;
    const isResult = kind?.includes('_results');
    const { overlayType } = this.props;
    const { duration } = payload.data || null;
    const showPreview = key?.startsWith('preview');
    this.state = { ...this.state, ...this.getPollData() };

    return (
      <ContainerSpacing open={openAnswersOnMobile}>
        <OverlayHeader
          broadcastId={broadcastId}
          data={data}
          defaultState={{ engaged: 0 }}
          duration={duration}
          isAdmin={isAdmin}
          onDismiss={onDismiss}
          overlayType={this.viewTitle(onlyResults)}
          showPreview={showPreview}
          showTitle
          timeRemaining={timeRemaining}
          trackDismiss={trackDismiss}
          views={views}
        />
        <Container className={overlayType}>
          <Top>
            <ResultsHeader>
              {
                endUserT([
                  DynamicTranslationType.multipleChoiceQuestion,
                  { multipleChoiceId: poll._id, multipleChoiceType: poll.type },
                ]) || poll.question
              }
            </ResultsHeader>
          </Top>
          { this.renderAnswersContainer(showPreview) }
        </Container>
        <StyledAnswerButton
          onClick={() => this.onOpenPanelClick(broadcastId)}
        >
          {
            isResult ?
              endUserT(
                [DynamicTranslationType.multipleChoiceOverlayResults],
                ['SEE_RESULTS'],
              ) :
              endUserT(
                [DynamicTranslationType.multipleChoiceOverlayAnswer],
                ['ANSWER'],
              )
          }
        </StyledAnswerButton>
      </ContainerSpacing>
    );
  }
}

export default withEndUserT(PollOverlay);

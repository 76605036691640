import styled, { css } from 'styled-components';
import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';
import { ADMIN_ACCENT_PRIMARY, OPTIONAL_DYNAMIC_THEME_COLOR } from 'style/constants';
import { THEME_PADDING, desktopOnly, mobileOnly } from 'style/mixins';
import FullEditor from 'components/ui/RichTextEditorV2/FullEditor';
import RichTextEditorV3 from 'components/ui/RichTextEditorV3';

export const StyledRichTextEditorV3 = styled(RichTextEditorV3)<{ isEditing: boolean; sticking: boolean; toolbarHeight: number; }>`
  ${desktopOnly`max-width: 60vw;`}
  align-self: center;
  ${props => props.isEditing && css`outline: 1px solid ${ADMIN_ACCENT_PRIMARY};`}
  ${props => props.sticking && css`margin-top: ${props.toolbarHeight}px;`}

  & .mce-content-body {
    min-height: 200px;
  }
`;

export const StyledFullEditor = styled(FullEditor)<{ sticking: boolean; toolbarHeight: number; }>`
  ${desktopOnly`max-width: 60vw;`}
  align-self: center;
  ${props => props.isEditMode && css`outline: 1px solid ${ADMIN_ACCENT_PRIMARY}`}

  & .tox-tinymce {
    & iframe {
      ${props => props.sticking && css`margin-top: ${props.toolbarHeight}px;`}
    }
  }
`;

export const ToolbarPositionRef = styled.div<{ isEditMode: boolean; }>`
  ${props => props.isEditMode && css`outline: 1px solid ${ADMIN_ACCENT_PRIMARY};`}
  width: 100%;
  ${desktopOnly`max-width: 60vw;`}
`;

export const Image = styled.img`
  aspect-ratio: 2 / 1;
  object-fit: cover;
  width: 100%;
  ${desktopOnly`max-width: 60vw;`}
  ${mobileOnly`
    position: absolute;
    top: 0;
  `}
  z-index: 1;
  margin-bottom: 20px;
`;

export const StyledQuickEditOverlay = styled(QuickEditOverlay)<{ backgroundColor: string; headerColor: string; imageHeight: number; ready: boolean; }>`
  opacity: 0;
  ${({ ready }) => ready && css`opacity: 1;`}

  background-color: ${({ backgroundColor }) => OPTIONAL_DYNAMIC_THEME_COLOR(backgroundColor)};
  ${THEME_PADDING}
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mobileOnly`padding-top: ${props => props.imageHeight}px;`}

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${props => props.imageHeight}px;
    background-color: ${({ headerColor }) => OPTIONAL_DYNAMIC_THEME_COLOR(headerColor)};
  }
`;

import DatetimePicker from 'components/admin2/DatetimePicker';
import { TimezoneSelect } from 'components/admin2/DatetimePicker';
import withTooltip from 'components/core/withTooltip';
import Icon from 'components/ui/Icon';
import { ADMIN_TEXT_100, ADMIN_TEXT_200, ADMIN_TEXT_300 } from 'style/constants';
import { ADMIN_TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';
import EditorWrapper from 'components/admin-bridge/EditorWrapper';
import styled from 'styled-components';
import { css } from 'styled-components';
import type { TranslationKey } from 'hooks/use-translation';

export const StyledEditorWrapper = styled(EditorWrapper)<{ readOnly: boolean; }>`
  ${({ readOnly }) => readOnly && css`pointer-events: none;`};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Label = styled.label`
  ${ADMIN_TEXT_LABEL_S_MEDIUM}
  color: ${ADMIN_TEXT_100};
`;

export const StartTimeLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


export const Toggle = styled.div`
  display: flex;
  align-items: center;
`;

export const EndTimeSwitchLabel = styled.span`
  color: ${ADMIN_TEXT_300};
  ${ADMIN_TEXT_LABEL_S_MEDIUM}
`;

export const DateTimeContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  name: 'info',
  color: ADMIN_TEXT_200(props),
}))`
  display: flex;
  margin-left: 8px;
  margin-right: 10px;
  color: ${({ color }) => color};
  & > svg {
    width: 12px;
    height: 12px;
  }
`;

export const InfoIcon = withTooltip(StyledIcon, {
  admin: true,
  tooltipCss: 'right: 103px;',
  tooltipArrowCss: 'left: 91%',
  useAbsolutePosition: true,
});

export const StyledDateTimePicker = styled(DatetimePicker).attrs({
  calendarPosition: 'top',
  hideClearButton: true,
  timeSelectKey: '' as TranslationKey,
  timeSelectIcon: '',
  noLabel: true,
  hideTimezone: true,
  padding: '0',
})`
  & > div > div, & > div > div > div {
    padding: 0;
  }
`;

export const StyledTimezoneSelect = styled(TimezoneSelect)`
  &:first-child {
    padding: 0;
  }
`;

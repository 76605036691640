import { EVENT_SERVICE_BASE_URL } from 'config';
import { EventsMinimalInfoDateMap, ICalendarEvent } from 'models/ICalendarEvent';
import { useSelector } from 'react-redux';
import { getSiteId } from 'services/app';
import { getPrimaryToken } from 'services/auth';
import maestroApiRequest from 'services/maestro-api-client';
import { flattenCalls } from 'utils';
import Axios from 'axios';
import { EventCategoryLabel, IEventCategory } from 'models/IEventCategory';

const useEventCalendarApi = () => {
  const siteId = useSelector(getSiteId)!;
  const primaryToken = useSelector(getPrimaryToken)!;

  const getEventsInfoBetweenTimestamp = async (startTimestamp: number, endTimestamp: number): Promise<EventsMinimalInfoDateMap> => {
    const response = await maestroApiRequest({
      primaryToken,
      siteId,
    }).get<EventsMinimalInfoDateMap>(`${EVENT_SERVICE_BASE_URL}/minimal-info`, {
      params: {
        startTimestamp,
        endTimestamp,
      },
    });

    return response.data;
  };

  const getEventsBetweenTimestamp = async (startTimestamp: number, endTimestamp: number): Promise<ICalendarEvent[]> => {
    const response = await maestroApiRequest({
      primaryToken,
      siteId,
    }).get<ICalendarEvent[]>(EVENT_SERVICE_BASE_URL, {
      params: {
        startTimestamp,
        endTimestamp,
      },
    });

    return response.data;
  };

  const createEvent = async (event: ICalendarEvent): Promise<ICalendarEvent> => {
    const response = await maestroApiRequest({
      primaryToken,
      siteId,
    }).post<ICalendarEvent>(EVENT_SERVICE_BASE_URL, event);

    return response.data;
  };

  const updateEvent = async (event: ICalendarEvent): Promise<void> => {
    await maestroApiRequest({
      primaryToken,
      siteId,
    }).put<ICalendarEvent>(`${EVENT_SERVICE_BASE_URL}/${event._id}`, event);
  };

  const deleteEvent = async (eventId: string): Promise<void> => {
    await maestroApiRequest({
      primaryToken,
      siteId,
    }).delete(`${EVENT_SERVICE_BASE_URL}/${eventId}`);
  };

  const getCategoryLabels = async (categoryTitle: string): Promise<EventCategoryLabel[]> => {
    const response = await maestroApiRequest({
      primaryToken,
      siteId,
    }).get<EventCategoryLabel[]>(`${EVENT_SERVICE_BASE_URL}/labels/${categoryTitle}`);

    return response.data;
  };

  const getSiteCategories = async (captureCancellator?: (fn: () => void) => void): Promise<IEventCategory[]> => {
    const response = await maestroApiRequest({
      primaryToken,
      siteId,
    }).get<IEventCategory[]>(`${EVENT_SERVICE_BASE_URL}/categories`, {
      cancelToken: captureCancellator && new Axios.CancelToken(captureCancellator),
    });

    return response.data;
  };

  return {
    getCategoryLabels,
    getSiteCategories,
    createEvent,
    updateEvent: flattenCalls(updateEvent),
    deleteEvent: flattenCalls(deleteEvent),
    getEventsBetweenTimestamp,
    getEventsInfoBetweenTimestamp,
  };
};

export default useEventCalendarApi;

import EditorWrapper from 'components/admin-bridge/EditorWrapper';
import UploadZone from 'components/admin2/UploadZone';
import styled from 'styled-components';

export const StyledEditorWrapper = styled(EditorWrapper)<{ readOnly: boolean; }>`
  min-width: 30%;
  width: 30%;
  display: flex;
  ${({ readOnly }) => readOnly && 'pointer-events: none;'};
`;

export const StyledUploadZone = styled(UploadZone).attrs(() => ({
  canDrop: true,
  dimensionRequirements: {
    pixelHeight: 1080,
    pixelWidth: 750,
  },
  height: 177,
}))`padding: 0;`;

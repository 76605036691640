import React from 'react';
import { GalleryWrapper } from './styles';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import { OVERFLOW_BEHAVIOR } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';
import useChunkPagination from 'hooks/use-chunk-pagination';
import KeyedListMapper from 'components/core/KeyedListMapper';
import ImageGallerySlider from '../Slider';
import ImageCard, { ImageCardProps } from '../ImageCard';
import AddCardButton from './AddCardButton';
import { useImageGalleryContext } from '../ImageGalleryContext/ImageGalleryContext';

const Gallery: React.FC = () => {
  const inEditMode = useSelector(isEditMode);
  const { imagesPerRow, overflowBehavior, isScrollable, imageCards, addNewImageCard, removeImageCard } = useImageGalleryContext();
  const pagination = useChunkPagination(imageCards, overflowBehavior === OVERFLOW_BEHAVIOR.slider ? imagesPerRow : 0);

  const addNewPage = () => {
    if (overflowBehavior === OVERFLOW_BEHAVIOR.stack) {
      addNewImageCard();
      return;
    }
    const { goToPage, goToPageUnchecked, pageCount, pages } = pagination;
    const lastPageIndex = pageCount - 1;
    const lastPage = pages[Math.max(lastPageIndex, 0)];
    const lastPageIsFull = lastPage.length === imagesPerRow;
    if (lastPageIsFull) {
      addNewImageCard();
      goToPageUnchecked(pageCount);
    } else {
      goToPage(lastPageIndex);
    }
  };

  const handleRemoveImageCard = (id: string) => {
    if (pagination.page.length === 1) {
      pagination.goToPrevPage();
    }
    removeImageCard(id);
  };

  if (pagination.pageCount && pagination.pageIndex >= pagination.pageCount) {
    pagination.goToPage(0);
  }

  let showAddMoreButton = false;
  if (inEditMode) {
    switch(overflowBehavior) {
      case OVERFLOW_BEHAVIOR.slider: {
        showAddMoreButton = pagination.page?.length === imagesPerRow;
        break;
      }
      case OVERFLOW_BEHAVIOR.stack: {
        showAddMoreButton = imageCards.length % imagesPerRow === 0;
        break;
      }
    }
  }

  return (
    <ImageGallerySlider
      isScrollable={isScrollable}
      onAddNewImageCard={addNewPage}
      pagination={pagination}
      showAddMoreButton={showAddMoreButton}
    >
      {cards => {
        return (
          <GalleryWrapper
            key={cards.length}
            imagesPerRow={imagesPerRow}
            isScrollable={isScrollable}
            overflowBehavior={overflowBehavior}
          >
            <KeyedListMapper list={cards as ImageCardProps[]} keyField="id">
            {(_, imageCardProps) => {
              return (
                <ImageCard
                  key={imageCardProps.id}
                  imageCard={imageCardProps}
                  onRemove={handleRemoveImageCard}
                />
              );
            }}
            </KeyedListMapper>
            <AddCardButton
              pageCards={cards}
              pagination={pagination}
            />
          </GalleryWrapper>
        );
      }}
    </ImageGallerySlider>
  );
};

export default Gallery;

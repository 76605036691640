import React from 'react';
import { useSelector } from 'react-redux';
import { getSiteId } from 'services/app';
import { getIsEpicGamesOauth, getPrimaryToken } from 'services/auth';
import { DropdownOption, GameType, IFortniteLeaderboardBlockData, LeaderboardType } from '../../utils';
import { getFilterByMostRecentEvent, getSeasons, getSessions, getTournaments, getUserLeaderboardPosition } from './api';
import { IFortniteLeaderboardItem } from 'models/IFortniteLeaderboard';
import { getIsPreviewingFortniteLeaderboardV2 } from 'services/admin/selectors';

type Filter = Record<GameType, {
  season: string;
  session: string;
  tournament: string;
}>;

const useFilters = (defaultFilters: IFortniteLeaderboardBlockData['defaultFilters']) => {
  const [loadingFilters, setLoadingFilters] = React.useState(false);
  const [leaderboardType, setLeaderboardType] = React.useState(defaultFilters.leaderboardType);
  const [gameType, setGameType] = React.useState<Record<LeaderboardType, GameType>>({
    ranked: 'battle-royale',
    general: 'battle-royale',
    [defaultFilters.leaderboardType]: defaultFilters.gameType,
  });
  const [filters, setFilters] = React.useState<Record<LeaderboardType, Filter>>({
    ranked: {
      'battle-royale': {
        season: '',
        session: '',
        tournament: '',
      },
      'zero-build': {
        season: '',
        session: '',
        tournament: '',
      },
    },
    general: {
      'battle-royale': {
        season: '',
        session: '',
        tournament: '',
      },
      'zero-build': {
        season: '',
        session: '',
        tournament: '',
      },
    },
    [defaultFilters.leaderboardType]: {
      'battle-royale': {
        season: '',
        session: '',
        tournament: '',
      },
      'zero-build': {
        season: '',
        session: '',
        tournament: '',
      },
      [defaultFilters.gameType]: {
        season: defaultFilters.season,
        session: defaultFilters.session,
        tournament: defaultFilters.tournament,
      },
    },
  });

  const filter = filters[leaderboardType][gameType[leaderboardType]];

  const [tournaments, setTournaments] = React.useState<DropdownOption[]>([]);
  const [sessions, setSessions] = React.useState<DropdownOption[]>([]);
  const [seasons, setSeasons] = React.useState<DropdownOption[]>([]);

  const [loggedUserLeaderboardPosition, setLoggedUserLeaderboardPosition] = React.useState<IFortniteLeaderboardItem | null>(null);

  const primaryToken = useSelector(getPrimaryToken) || '';
  const siteId = useSelector(getSiteId);
  const isEpicGamesOauth = useSelector(getIsEpicGamesOauth);
  const isPreviewing = useSelector(getIsPreviewingFortniteLeaderboardV2);

  const isRanked = leaderboardType === 'ranked';

  const onChangeSeason = async (newSeason: string) => {
    if (!newSeason || filter.season === newSeason) {
      return;
    }

    const game = gameType[leaderboardType];

    setFilters(prev => ({
      ...prev,
      [leaderboardType]: {
        ...prev[leaderboardType],
        [game]: {
          ...prev[leaderboardType][game],
          season: newSeason,
        },
      },
    }));

    if (isRanked) {
      await loadLoggedUserLeaderboardPosition({ targetSeason: newSeason });
      return;
    }

    const result = await getTournaments({
      season: newSeason,
      gameType: game,
      primaryToken,
      siteId,
    });

    setTournaments(result);
  };

  // Load most recent season if no season is selected in ranked leaderboard
  React.useEffect(() => {
    if (!filter.season && seasons.length && isRanked && !isPreviewing) {
      onChangeSeason(seasons[seasons.length - 1].value);
    }
  }, [filter.season, seasons, isRanked, isPreviewing]);

  // Load most recent leaderboard in general leaderboard
  React.useEffect(() => {
    if (!filter.season && !filter.tournament && !filter.session && !isRanked && !isPreviewing) {
      (async () => {
        const result = await getFilterByMostRecentEvent({
          primaryToken,
          siteId,
          gameType: gameType[leaderboardType],
        });

        if (result) {
          await onChangeSeason(result.season);
          await onChangeTournament(result.tournament);
          await onChangeSession(result.session);
        }
      })();
    }
  }, [filter, isRanked, gameType, isPreviewing]);

  const loadFiltersOptions = async (targetLeaderboardType: LeaderboardType, targetGameType: GameType) => {
    setLoadingFilters(true);
    const newSeason = filters[targetLeaderboardType][targetGameType].season;
    const newTournament = filters[targetLeaderboardType][targetGameType].tournament;
    const newSession = filters[targetLeaderboardType][targetGameType].session;

    const newSeasons = await getSeasons({
      gameType: targetGameType,
      primaryToken,
      siteId,
      leaderboardType: targetLeaderboardType,
    });
    setSeasons(newSeasons);

    if (targetLeaderboardType === 'general') {
      if (newSeason && newTournament) {
        const newTournaments = await getTournaments({
          season: newSeason,
          gameType: targetGameType,
          primaryToken,
          siteId,
        });

        setTournaments(newTournaments);
      }

      if (newTournament && newSession) {
        const newSessions = await getSessions({
          eventId: newTournament,
          primaryToken,
          siteId,
        });

        setSessions(newSessions);
      }
    }

    setLoadingFilters(false);
  };

  const onChangeGameType = async (newGameType: GameType) => {
    if (gameType[leaderboardType] === newGameType) {
      return;
    }

    setGameType(prev => ({
      ...prev,
      [leaderboardType]: newGameType,
    }));

    if (loggedUserLeaderboardPosition) {
      setLoggedUserLeaderboardPosition(null);
    }

    await loadFiltersOptions(leaderboardType, newGameType);
  };

  const onChangeLeaderboardType = async (newLeaderboardType: LeaderboardType) => {
    if (leaderboardType === newLeaderboardType) {
      return;
    }

    setLeaderboardType(newLeaderboardType);
    if (gameType[newLeaderboardType] !== gameType[leaderboardType]) {
      setGameType(prev => ({
        ...prev,
        [newLeaderboardType]: gameType[newLeaderboardType],
      }));
    }

    if (loggedUserLeaderboardPosition) {
      setLoggedUserLeaderboardPosition(null);
    }

    await loadFiltersOptions(newLeaderboardType, gameType[newLeaderboardType]);
  };

  const onChangeTournament = async (newTournament: string) => {
    if (!newTournament || filter.tournament === newTournament) {
      return;
    }

    const game = gameType[leaderboardType];

    setFilters(prev => ({
      ...prev,
      [leaderboardType]: {
        ...prev[leaderboardType],
        [game]: {
          ...prev[leaderboardType][game],
          tournament: newTournament,
          session: '',
        },
      },
    }));

    if (loggedUserLeaderboardPosition) {
      setLoggedUserLeaderboardPosition(null);
    }

    const result = await getSessions({
      eventId: newTournament,
      primaryToken,
      siteId,
    });

    if (result.length === 1 && !isPreviewing) {
      onChangeSession(result[0].value);
    }

    setSessions(result);
  };

  const onChangeSession = async (newSession: string) => {
    if (!newSession || filter.session === newSession) {
      return;
    }

    const game = gameType[leaderboardType];

    setFilters(prev => ({
      ...prev,
      [leaderboardType]: {
        ...prev[leaderboardType],
        [game]: {
          ...prev[leaderboardType][game],
          session: newSession,
        },
      },
    }));

    await loadLoggedUserLeaderboardPosition({ targetSeason: filter.season, targetSession: newSession });
  };

  const loadLoggedUserLeaderboardPosition = async ({ targetSession, targetSeason }: { targetSeason: string; targetSession?: string; }) => {
    if (!isEpicGamesOauth || isPreviewing) {
      if (loggedUserLeaderboardPosition) {
        setLoggedUserLeaderboardPosition(null);
      }
      return;
    }

    const game = gameType[leaderboardType];

    const result = await getUserLeaderboardPosition({
      eventId: getEventId(isRanked, game, targetSeason, filter.tournament),
      gameType: game,
      leaderboardType,
      primaryToken,
      siteId,
      ...targetSession && {
        eventWindowId: getEventWindowId(isRanked, targetSeason, targetSession),
      },
    });

    setLoggedUserLeaderboardPosition(result);
  };

  React.useEffect(() => {
    if (isRanked && !isPreviewing) {

      if (defaultFilters.season) {
        loadLoggedUserLeaderboardPosition({ targetSeason: defaultFilters.season });
      }

    } else {

      if (defaultFilters.season && defaultFilters.tournament && defaultFilters.session) {
        loadLoggedUserLeaderboardPosition({ targetSeason: defaultFilters.season, targetSession: defaultFilters.session });
      }

    }
  }, []);

  React.useEffect(() => {
    loadFiltersOptions(leaderboardType, gameType[leaderboardType]);
  }, []);

  React.useEffect(() => {
    return () => {
      if (loggedUserLeaderboardPosition) {
        setLoggedUserLeaderboardPosition(null);
      }
    };
  }, [loggedUserLeaderboardPosition]);

  const getEventId = React.useCallback((ranked: boolean, leaderboardGameType: GameType, targetSeason: string, targetTournament: string) => {
    if (ranked) {
      if (!targetSeason) {
        return '';
      }

      const rankingType: 'br' | 'zb' = leaderboardGameType === 'battle-royale' ? 'br' : 'zb';
      return `epicgames_ranked_${rankingType}_season${targetSeason}`;
    }

    return targetTournament;
  }, []);

  const getEventWindowId = React.useCallback((ranked: boolean, targetSeason: string, targetSession: string) => {
    if (ranked) {
      if (!targetSeason) {
        return '';
      }
      // Division 17 is the highest division in the game, and we only show them in the ranked leaderboard
      return 'division_17';
    }

    return targetSession;
  }, []);

  return {
    seasons: {
      options: seasons,
      selected: seasons.find(s => s.value === filter.season),
      onChange: onChangeSeason,
    },
    tournaments: {
      options: tournaments,
      selected: tournaments.find(t => t.value === filter.tournament),
      onChange: onChangeTournament,
    },
    sessions: {
      options: sessions,
      selected: sessions.find(s => s.value === filter.session),
      onChange: onChangeSession,
    },
    isRanked,
    isBattleRoyale: gameType[leaderboardType] === 'battle-royale',
    onChangeGameType,
    onChangeLeaderboardType,
    eventId: getEventId(isRanked, gameType[leaderboardType], filter.season, filter.tournament),
    eventWindowId: getEventWindowId(isRanked, filter.season, filter.session),
    loggedUserLeaderboardPosition,
    loadingFilters,
    filter,
    gameType: gameType[leaderboardType],
  };
};

export default useFilters;

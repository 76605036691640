import { connect } from 'react-redux';

import { openPanel } from 'services/custom-panels';
import ShowPersonOverlayCta from './view';

const mapDispatchToProps = dispatch => ({
  onOpenPanel: payload => dispatch(openPanel(payload)),
});

export default connect(null, mapDispatchToProps)(ShowPersonOverlayCta);

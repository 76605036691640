import { useAdminTranslation } from 'hooks/use-translation';
import React from 'react';
import { useFortniteLeaderboardContext } from './Context/Context';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';

const TableHeader = () => {
  const isMobile = useSelector(isMobileLayout);
  const { isRanked, isPanel } = useFortniteLeaderboardContext();
  const { t } = useAdminTranslation();

  const short = isMobile || isPanel;

  return (
    <thead>
      {
        isRanked ? (
          <tr>
            <th>{short ? '#' : t('PLACE')}</th>
            <th>{short ? 'D' : t('DIVISION')}</th>
            <th>{t('PLAYER_NAME')}</th>
          </tr>
        ) : (
          <tr>
            <th>{short ? '#' : t('PLACE')}</th>
            <th>{t('PLAYER_NAME')}</th>
            <th>{short ? 'P' : t('LABEL_POINTS')}</th>
            <th>VRs</th>
            <th>{short ? 'M' : t('MATCHES')}</th>
            <th>{short ? 'E' : t('ELIMS')}</th>
          </tr>
        )
      }
    </thead>
  );
};

export default TableHeader;

import styled from 'styled-components';
import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';
import { THEME_PADDING, mobileOnly } from 'style/mixins';

export const StyledQuickEditOverlay = styled(QuickEditOverlay)`
  ${THEME_PADDING}
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mobileOnly`padding-top: ${props => props.imageHeight}px;`}

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
`;

export const StyledIFrame = styled.iframe`
  width: 100%;
  height: auto;
`;

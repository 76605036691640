import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EntitlementCard from 'components/admin2/ui/EntitlementCard';
import { SubscriptionRecurrence } from 'models/ISubscription';
import { getAccountEntitlements, getWatchedEntitlements } from 'services/gate/selectors';
import { cancelSubscription, ScheduleType, getEntitlementLoadingId } from 'services/billing';
import { showAdminErrorModal, showModal } from 'services/modals';
import { GrantedMethod, IAccountEntitlement, AccountEntitlementStatus } from 'services/gate/models';
import TranslatedText from 'components/i18n/TranslatedText';
import { HeaderContainer, StyledMethodTitle, EmptyEntitlement, Wrapper } from './styles';
import moment from 'moment';
import { isUserAdmin } from 'services/auth';
import Separator from 'components/panels/Separator';
import { USER_PROFILE_TICKETS, USER_PROFILE_SUBSCRIPTIONS } from 'injection-classes';
import { ModalKinds } from 'services/modals/types';
import { useAdminTranslation } from 'hooks/use-translation';

const EntitlementsContainer = () => {
  const dispatch = useDispatch();
  const entitlementLoadingId = useSelector(getEntitlementLoadingId);
  const pendingEntitlements = [...(new Set((useSelector(getWatchedEntitlements) || []).filter((entitlement) => (
    entitlement?._id
  ))))];
  const activeEntitlements = (useSelector(getAccountEntitlements) || []).filter((entitlement) => (
    entitlement.status !== AccountEntitlementStatus.canceled));
  const { t } = useAdminTranslation();

  const entitlements = [...activeEntitlements, ...pendingEntitlements];
  const isAdmin = useSelector(isUserAdmin);

  const sortFunc = (a: IAccountEntitlement, b: IAccountEntitlement) => {
    if (a.status === b.status) {
      return 0;
    }
    if (a.status === AccountEntitlementStatus.active) {
      return -1;
    }
    return 1;
  };

  const tickets = entitlements.filter(({ entitlement }) => {
    return (
      !entitlement.recurrence ||
      entitlement.recurrence === SubscriptionRecurrence.once
    );
  });

  const subscriptions = entitlements.filter(({ entitlement }) => {
    return entitlement.recurrence && entitlement.recurrence !== SubscriptionRecurrence.once;
  }).sort(sortFunc);

  const hasTickets = !!tickets.length;
  const hasSubscriptions = !!subscriptions.length;

  const dispatchCancelSubscription = (accountEntitlement: IAccountEntitlement) => {
    dispatch(
      cancelSubscription({
        accountEntitlementId: accountEntitlement._id!,
        refund: false,
        scheduled: ScheduleType.EndOfBilling,
        subscriptionId: accountEntitlement.entitlement._id!,
      }),
    );
  };

  const dispatchShowModal = (accountEntitlement: IAccountEntitlement) => {
    const cancelDate = (
      accountEntitlement.grantedMethod === GrantedMethod.purchased ?
        moment.unix(accountEntitlement.currentPeriodEnd || Date.now() / 1000).format('MMM DD, YYYY') :
        undefined);
    const subtitle = (
      cancelDate ?
        t('ADMIN_LABEL_CANCEL_SUBSCRIPTION_CONFIRMATION_SUBTITLE') :
        t('ADMIN_LABEL_CANCEL_SUBSCRIPTION_CONFIRMATION_IMMEDIATE'));
    dispatch(
      showModal({
        data: {
          onConfirmClick: () => dispatchCancelSubscription(accountEntitlement),
          promptStringKey: 'ADMIN_LABEL_CANCEL_SUBSCRIPTION_CONFIRMATION',
          subtitle: cancelDate ? subtitle + ' ' + cancelDate : subtitle,
        },
        kind: ModalKinds.confirmation,
      }),
    );
  };

  const onCancelSubscription = (accountEntitlement: IAccountEntitlement) => {
    if (!accountEntitlement._id || !accountEntitlement.entitlement._id) {
      dispatch(showAdminErrorModal('Unable to cancel subscription'));
      return;
    }

    dispatchShowModal(accountEntitlement);
  };

  if (isAdmin) return null;

  return (
    <>
      <Wrapper className={USER_PROFILE_SUBSCRIPTIONS}>
        <HeaderContainer>
          <TranslatedText
            component={StyledMethodTitle}
            stringKey="ADMIN_SETTINGS_BILLING_SUBSCRIPTIONS_HEADER"
          />
        </HeaderContainer>
        {hasSubscriptions ? (
          <>
            {subscriptions.map((ent) => (
              <EntitlementCard
                key={ent._id}
                accountEntitlement={ent}
                onCancelSubscription={onCancelSubscription}
                loading={!!entitlementLoadingId && entitlementLoadingId === ent._id}
              />
            ))}
          </>
        ) : (
          <EmptyEntitlement>
            <TranslatedText stringKey="ADMIN_SETTINGS_BILLING_SUBSCRIPTIONS_EMPTY" />
          </EmptyEntitlement>
        )}
        <Separator />
      </Wrapper>
      <Wrapper className={USER_PROFILE_TICKETS}>
        <HeaderContainer>
          <TranslatedText
            component={StyledMethodTitle}
            stringKey="ADMIN_SETTINGS_BILLING_TICKETS_HEADER"
          />
        </HeaderContainer>
        {hasTickets ? (
          <>
            {tickets.map((ent) => (
              <EntitlementCard key={ent._id} accountEntitlement={ent} />
            ))}
          </>
        ) : (
          <EmptyEntitlement>
            <TranslatedText stringKey="ADMIN_SETTINGS_BILLING_TICKETS_EMPTY" />
          </EmptyEntitlement>
        )}
        <Separator />
      </Wrapper>
    </>
  );
};

export default EntitlementsContainer;

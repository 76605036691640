import { ACCENT_PRIMARY, SURFACE_2, SURFACE_5 } from 'style/constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${SURFACE_2};
  border-radius: 10px;
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${ACCENT_PRIMARY};
`;

export const WeekWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  flex: 1;
  border-radius: 10px;
  gap: 2px;
  background-color: ${SURFACE_5};
`;

import { Alignment, Layout } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow/types';
import { IPageBlockProps } from 'services/app/models/ILandingPageContent';
import { MAESTRO_BLACK } from 'style/constants';

export interface IVideoSpotlight {
  alignment: Alignment;
  background: {
    color: string;
    customColor: string;
    image: {
      desktop: string;
      mobile: string;
      show: boolean;
    }
  }
  description: {
    show: boolean;
  }
  layout: Layout;
  playerBehavior: 'inline' | 'videoPage';
  tags: {
    show: boolean;
  }
  title: {
    show: boolean;
  }
  uploadDate: {
    show: boolean;
  }
  // We use the videoId to get the realtime document
  videoId: string;
}

interface IVideoSpotlightItem {
  arrayId: string;
  data: IVideoSpotlight;
  kind: string;
}

export type IVideoSpotlightProps = IPageBlockProps<IVideoSpotlightItem>;

export const DEFAULT_TITLE = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus quam ullamcorper facilisi tortor, leo gravida accumsan, nunc.';
export const DEFAULT_DESCRIPTION = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Arcu, metus, et tortor massa sapien proin. At dignissim amet, ante urna tempus, vitae pellentesque nisi, sit. Justo malesuada nisi, phasellus orci dictumst nunc.';
export const DEFAULT_TAGS = ['loremipsum', 'loremipsum', 'loremipsum', 'loremipsum', 'loremipsum'];
export const DEFAULT_UPLOAD_DATE = '10 Days Ago';

export const DEFAULT_VIDEO_SPOTLIGHT: IVideoSpotlight = {
  videoId: '',
  tags: {
    show: true,
  },
  uploadDate: {
    show: true,
  },
  title: {
    show: true,
  },
  description: {
    show: true,
  },
  background: {
    color: 'surface1',
    customColor: MAESTRO_BLACK,
    image: {
      show: false,
      mobile: '',
      desktop: '',
    },
  },
  alignment: Alignment.Left,
  layout: 'left',
  playerBehavior: 'videoPage',
};

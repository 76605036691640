import React from 'react';
import { useSelector } from 'react-redux';
import { MobileEventPageWrapper, Wrapper } from './styles';
import { isMobileLayout } from 'services/device';
import MiniCalendarHeader from './Header';
import MobileEventPage from './MobileEventPage';
import Calendar from './Calendar';
import SelectedDateEvents from './SelectedDateEvents';
import { useEventCalendarContext } from '../EventCalendarContext';
import { ICalendarEvent } from 'models/ICalendarEvent';

interface IMiniCalendarProps {
  mobileSelectedEvent: ICalendarEvent | null;
  setMobileSelectedEvent: (event: ICalendarEvent | null) => void;
}

const MiniCalendar: React.FC<IMiniCalendarProps> = ({ setMobileSelectedEvent, mobileSelectedEvent }) => {
  const [isMobileCalendarOpen, setIsMobileCalendarOpen] = React.useState<boolean>(true);
  const { result, timestampDateFormat } = useEventCalendarContext();
  const isMobile = useSelector(isMobileLayout);

  const toggleMobileCalendarVisibility = React.useCallback(() => {
    if (!isMobile) {
      return;
    }
    setIsMobileCalendarOpen(prev => !prev);
  }, [isMobile]);

  const handleClearMobileSelectedEvent = React.useCallback(() => {
    setMobileSelectedEvent(null);
  }, []);

  return (
    <>
      <Wrapper hasSelectedEvent={Boolean(mobileSelectedEvent)}>
        <MiniCalendarHeader
          isMobileCalendarOpen={isMobileCalendarOpen}
          onToggle={toggleMobileCalendarVisibility}
        >
          {result.monthsLong[timestampDateFormat.month]} {timestampDateFormat.year}
        </MiniCalendarHeader>
        <Calendar isMobileCalendarOpen={isMobileCalendarOpen} />
        <SelectedDateEvents onMobileSelectEvent={setMobileSelectedEvent} />
      </Wrapper>
      <div style={{ textAlign: 'right' }}>
        <MobileEventPageWrapper hasSelectedEvent={Boolean(mobileSelectedEvent)}>
          {mobileSelectedEvent && <MobileEventPage event={mobileSelectedEvent!} onClose={handleClearMobileSelectedEvent} />}
        </MobileEventPageWrapper>
      </div>
    </>
  );
};

export default MiniCalendar;

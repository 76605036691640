import styled from 'styled-components';
import { ADMIN_SURFACE_6, ADMIN_TEXT_400 } from 'style/constants';

export const Separator = styled.hr`
  background-color: ${ADMIN_SURFACE_6};
  border: none;
  color: ${ADMIN_TEXT_400};
  height: 1px;
  margin: 10px 5px;
`;

import React from 'react';
import ProductCard from '../ProductCard';
import { useSelector } from 'react-redux';
import IState from 'services/state';
import { getProductById } from 'services/shopify/selectors';
import { MOCK_PRODUCT, MOCK_PRODUCTS } from '../utils';
import { IProduct } from 'services/shopify/models';
import ShopifyProductListModal from 'components/admin2/ShopifyProductListModal';
import { isMobileLayout } from 'services/device';
import { isEditMode } from 'services/admin';
import { useShopifyBlockContext } from '../ShopifyBlockContext';

interface ISingleProductProps {
  id: string;
  onChangeSingleProductId: (id: string) => void;
}

const SingleProduct: React.FC<ISingleProductProps> = ({ id, onChangeSingleProductId }) => {
  const { isRenderingOnPanel, isShopifyPanelActive } = useShopifyBlockContext();
  const [isShopifyProductListModalOpen, setIsShopifyProductListModalOpen] = React.useState(false);
  const isMobile = useSelector(isMobileLayout);
  const isEditing = useSelector(isEditMode);
  const singleProduct = useSelector((state: IState) => getProductById(state, id));
  const finalSingleProduct = React.useMemo(() => {
    if (isShopifyPanelActive || !isRenderingOnPanel) {
      return singleProduct || MOCK_PRODUCT;
    }
    return singleProduct || MOCK_PRODUCTS[0];
  }, [singleProduct, isShopifyPanelActive, isRenderingOnPanel]);

  const handleOpenProductListModal = React.useCallback(() => {
    if (!isEditing) {
      return;
    }
    setIsShopifyProductListModalOpen(true);
  }, [isEditing]);

  const handleCloseProductListModal = React.useCallback(() => {
    setIsShopifyProductListModalOpen(false);
  }, []);

  const handleSelectProduct = React.useCallback((product: IProduct) => {
    onChangeSingleProductId(product.id);
    setIsShopifyProductListModalOpen(false);
  }, [onChangeSingleProductId]);

  return (
    <>
      <ProductCard
        product={finalSingleProduct}
        horizontalView={(!isMobile && !isRenderingOnPanel)}
        handleOpenProductListModal={handleOpenProductListModal}
      />
      {isShopifyProductListModalOpen && (
        <ShopifyProductListModal
          onClose={handleCloseProductListModal}
          onSelectProduct={handleSelectProduct}
          isMultipleSelection={false}
          selectedProducts={singleProduct ? [singleProduct] : []}
        />
      )}
    </>
  );
};

export default SingleProduct;

import { ITextBanner } from 'components/page-blocks/TextBanner/TextBanner.utils';
import Link from 'components/ui/Link';
import { mobileOnly } from 'style/mixins';
import styled, { css } from 'styled-components';

export interface IImageProps {
  bleed: boolean;
  hasContentOnHeroBanner?: boolean;
}

export const ABSOLUTE_CENTER = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const ImageWrapper = styled.div<{ isEditing: boolean; }>`
  position: relative;
  overflow: visible;
`;

export const AbsoluteCenterWrapper = styled.section`
  ${ABSOLUTE_CENTER};
  height: 100%;
  width: 100%;
`;

export const RelativeWrapper = styled.div<{ alignment: ITextBanner['alignment']; }>`
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: ${({ alignment }) => alignment.horizontal === 'left' ? 'flex-start' : alignment.horizontal === 'right' ? 'flex-end' : 'center'};
  justify-content: center;
  padding: 30px;
  position: relative;
  min-height: 400px;

  ${mobileOnly`
    height: auto;
    min-height: 325px;
    padding-inline: 40px;
  `};
`;

export const ClickableArea = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

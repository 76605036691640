import styled from 'styled-components';
import UploadZone from 'components/admin2/UploadZone';
import { Dropdown } from 'components/admin2/ui/Dropdown';
import { ADMIN_TEXT_TITLE_S } from 'style/design-system/textStyles';
import { ADMIN_TEXT_100 } from 'style/constants';

export const BlockPanelSettingsWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const BlockPanelSettingsTitle = styled.span`
  ${ADMIN_TEXT_TITLE_S}
  color: ${ADMIN_TEXT_100};
`;

export const StyledDropdown = styled(Dropdown)`
  flex: 1;
`;

export const DesktopBackgroundUploadZone = styled(UploadZone).attrs(() => ({
  canDrop: true,
  dimensionRequirements: {
    pixelHeight: 1080,
    pixelWidth: 1920,
  },
  height: 177,
  padding: '0',
}))``;

export const MobileBackgroundUploadZone = styled(UploadZone).attrs(() => ({
  canDrop: true,
  dimensionRequirements: {
    pixelHeight: 1200,
    pixelWidth: 750,
  },
  height: 177,
  padding: '0',
}))``;

import { CONTENT_INNER_CONTAINER_ID } from 'global-ids';
import { HEADER_ID } from 'global-ids';
import React from 'react';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import { TOOLBAR_HEIGHT_PX } from 'style/constants';

const useToolbarPosition = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const isEditing = useSelector(isEditMode);
  const [sticking, setSticking] = React.useState(false);
  const [toolbarHeight, setToolbarHeight] = React.useState(0);

  const getHeaderHeight = React.useCallback(() => {
    const header = document.getElementById(HEADER_ID);
    if (!header) {
      return 0;
    }

    return header.clientHeight;
  }, []);

  React.useEffect(() => {
    if (!isEditing) {
      return;
    }

    const handleScroll = () => {
      const wrapper = ref.current;
      if (!wrapper) {
        return;
      }
      const toolbar1 = wrapper.querySelector('.tox-editor-header') as Element & { style: CSSStyleDeclaration; };
      const toolbar2 = wrapper.querySelector('.editor-toolbar') as Element & { style: CSSStyleDeclaration; };
      const toolbar = toolbar1 || toolbar2;
      if (!toolbar) {
        return;
      }

      const wrapperRect = wrapper.getBoundingClientRect();
      const toolbarRect = toolbar.getBoundingClientRect();
      const wrapperThreshold = 200 + toolbarRect.height;

      if (toolbarHeight !== toolbarRect.height) {
        setToolbarHeight(toolbarRect.height);
      }

      const topContentHeight = getHeaderHeight() + TOOLBAR_HEIGHT_PX;

      const shouldStick = (
        wrapperRect.top < topContentHeight &&
        wrapperRect.bottom >= wrapperThreshold &&
        wrapperRect.height > wrapperThreshold
      );

      if (sticking !== shouldStick) {
        setSticking(shouldStick);
      }

      if (shouldStick) {
        toolbar.style.position = 'fixed';
        toolbar.style.zIndex = '2';
        toolbar.style.top = `${topContentHeight}px`;
        toolbar.style.width = '60vw';
      } else {
        toolbar.style.position = 'unset';
        toolbar.style.zIndex = '0';
      }
    };

    const mainContent = document.getElementById(CONTENT_INNER_CONTAINER_ID);
    if (!mainContent) {
      return;
    }

    mainContent.addEventListener('scroll', handleScroll);
    return () => {
      mainContent.removeEventListener('scroll', handleScroll);
    };
  }, [isEditing, toolbarHeight, sticking]);

  return { ref, sticking, toolbarHeight };
};

export default useToolbarPosition;

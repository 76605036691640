import TranslatedText from 'components/i18n/TranslatedText';
import { IVideo } from 'models';
import React from 'react';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import { IVideoSpotlight } from '../utils';
import InlineVideoPlayer from './playerBehavior/Inline';
import VideoPage from './playerBehavior/VideoPage';
import { AddIcon, NoVideo, Wrapper } from './styles';

interface IVideoProps {
  arrayId: string;
  item: IVideoSpotlight;
  loaded: boolean;
  video: IVideo | null;
}

const Video: React.FC<IVideoProps> = ({ item, arrayId, loaded, video }) => {
  const isEditing = useSelector(isEditMode);
  const isDefaultBlock = !item.videoId;
  const hasVideo = loaded && video;

  const renderVideo = React.useCallback(() => {
    if (isDefaultBlock) {
      return (
        <NoVideo>
          {isEditing && (
            <>
              <AddIcon name="addVideo" />
              <TranslatedText stringKey="ADMIN_LABEL_ADD_A_VIDEO" />
            </>
          )}
        </NoVideo>
      );
    }

    if (!hasVideo) {
      return;
    }

    if (item.playerBehavior === 'inline') {
      return <InlineVideoPlayer video={video} arrayId={arrayId} />;
    }

    return <VideoPage thumbnail={video.thumbnail || ''} videoId={item.videoId} />;
  }, [isDefaultBlock, hasVideo, item.playerBehavior, video, isEditing, arrayId]);

  return (
    <Wrapper isEditing={isEditing}>
      {renderVideo()}
    </Wrapper>
  );
};

export default Video;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BACKGROUND_ACTIVE, TITLE_FONT } from 'style/mixins';
import VideoLink from 'components/ui/VideoLink';
import { withEndUserT, DynamicTranslationType } from 'hooks/use-translation';

const FlexWrapper = styled.div`
  ${BACKGROUND_ACTIVE}
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 5px;
  ${TITLE_FONT}
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
`;

class PlayVideoOverlayCta extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    ctaRenderer: PropTypes.shape({
      text: PropTypes.string,
      videoId: PropTypes.string,
    }).isRequired,
    endUserT: PropTypes.func.isRequired,
    onEngagedOverlay: PropTypes.func.isRequired,
    overlayId: PropTypes.string.isRequired,
    testId: PropTypes.string,
  };

  static defaultProps = {
    children: undefined,
    testId: undefined,
  };

  render() {
    const {
      children,
      ctaRenderer: {
        text,
        videoId,
      },
      endUserT,
      onEngagedOverlay,
      overlayId,
      testId,
    } = this.props;

    // TODO: probably don't need this logic, can just render the VideoLink
    const LinkComponent = videoId ? VideoLink : 'div';
    const linkProps = videoId ?
      { onClick: onEngagedOverlay, plain: true, tag: videoId } :
      {};

    return (
      <LinkComponent data-testid={testId} {...linkProps}>
        {
          children || (
            <FlexWrapper>
              {
                endUserT([
                  DynamicTranslationType.overlayCtaText,
                  { overlayId },
                ]) || text
              }
            </FlexWrapper>
          )
        }
      </LinkComponent>
    );
  }
}

export default withEndUserT(PlayVideoOverlayCta);

import { connect } from 'react-redux';
import { isLandscape, isMobileLayout } from 'services/device';
import { engageMultipleChoiceOverlay, receiveOverlay, removeOverlay } from 'services/overlays';
import { trackOverlay } from 'services/insights';
import { openPanel } from 'services/custom-panels';
import { isUserAdmin, getUserId } from 'services/auth';
import { createPollResponse } from 'services/polls';
import { SITE_ID } from 'config';

import PollOverlay from './view';

const mapDispatchToProps = dispatch => ({
  mobileOpenPollPanel: doc => dispatch(openPanel(doc)),
  onAddMessageOverlay: payload => dispatch(receiveOverlay(payload)),
  onDismiss: key => dispatch(removeOverlay(key)),
  onEngageMultipleChoiceOverlay: (pollResponse, payload) => {
    dispatch(createPollResponse(pollResponse));
    dispatch(engageMultipleChoiceOverlay(payload));
  },
  trackDismiss: (doc, extra) => dispatch(trackOverlay('dismiss', doc, extra)),
  trackEngage: (doc, extra) => dispatch(trackOverlay('engage', doc, extra)),
});

const mapStateToProps = state => ({
  isAdmin: isUserAdmin(state),
  isLandscape: isLandscape(state),
  isMobileLayout: isMobileLayout(state),
  siteId: SITE_ID,
  userId: getUserId(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(PollOverlay);

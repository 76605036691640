import { connect } from 'react-redux';
import { getUserProfileState, updateUserProfileState, getUserAvatar } from 'services/user-profile';
import { getSiteProfileImages, getSiteProfileSettings } from 'services/app/selectors';
import { getUserBlob, getThirdPartyAuthProviders } from 'services/auth';
import { trackPanel } from 'services/insights';
import EditPage from './view';

const mapStateToProps = state => ({
  profileImages: getSiteProfileImages(state),
  profileSettings: getSiteProfileSettings(state),
  thirdPartyAuthProviders: getThirdPartyAuthProviders(state),
  userAvatar: getUserAvatar(state),
  userData: getUserBlob(state),
  userProfile: getUserProfileState(state),
});

const mapDispatchToProps = dispatch => ({
  onEngage: (doc, extra) => dispatch(trackPanel('engage', doc, extra)),
  onSave: data => dispatch(updateUserProfileState(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPage);

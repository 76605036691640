import React from 'react';
import { ControlsWrapper, Header, SelectedDate, SubHeader, Timezone, Title, WeekControls } from './styles';
import type { DateData } from 'hooks/use-calendar';
import { useEventCalendarContext } from '../../EventCalendarContext';
import { Dot } from '../styles';
import Filters from '../Filters';
import { CircledButton } from 'components/ui/ScrollSlider/styles';
import { useAdminTranslation } from 'hooks/use-translation';

interface IWeekCalendarHeaderProps {
  onAdvanceWeek: () => void;
  onRetreatWeek: () => void;
  weekDays: DateData[];
}

const WeekCalendarHeader: React.FC<IWeekCalendarHeaderProps> = ({ weekDays, onRetreatWeek, onAdvanceWeek }) => {
  const { result, setSelectedDate } = useEventCalendarContext();
  const { t } = useAdminTranslation();

  const userTimezone = React.useMemo(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentTimezoneOffset = new Date().getTimezoneOffset();
    const hoursOffset = Math.abs(Math.floor(currentTimezoneOffset / 60));
    const minutesOffset = Math.abs(currentTimezoneOffset % 60);

    // Format the offset in (GTM ±hh:mm) format
    const gmtOffset = `GMT${currentTimezoneOffset < 0 ? '+' : '-'}${hoursOffset.toString().padStart(2, '0')}:${minutesOffset.toString().padStart(2, '0')}`;

    return `${gmtOffset} ${userTimeZone}`;
  }, []);

  const title = React.useMemo(() => {
    if (!weekDays.length) {
      return '';
    }

    const visibleMonths = weekDays.reduce((acc: number[], day: DateData) => {
      if (!acc.includes(day.month)) {
        acc.push(day.month);
      }
      return acc;
    }, []);

    const minYear = weekDays[0].year;
    const firstDay = weekDays[0].day;
    const lastDay = weekDays[weekDays.length - 1].day;

    if (visibleMonths.length === 1) {
      return `${result.monthsLong[visibleMonths[0]]} ${firstDay}-${lastDay}, ${minYear}`;
    }

    return `${result.monthsLong[visibleMonths[0]]} ${firstDay}- ${result.monthsLong[visibleMonths[1]]} ${lastDay}, ${minYear}`;
  }, [result.monthsLong, weekDays]);

  const handleSelectToday = React.useCallback(() => {
    const today = new Date();
    const todayDateFormat = {
      day: today.getDate(),
      month: today.getMonth(),
      year: today.getFullYear(),
    };

    setSelectedDate(todayDateFormat);
  }, [setSelectedDate]);

  const todayString = React.useMemo(() => {
    const today = new Date();
    return `${t('ADMIN_LABEL_TODAY')}: ${result.monthsLong[today.getMonth()]} ${today.getDate()}`;
  }, [t, result.monthsLong]);

  return (
    <>
      <Header>
        <ControlsWrapper>
          <Title>{title}</Title>
          <WeekControls>
            <CircledButton
              active={true}
              name="adminbarLt"
              onClick={onRetreatWeek}
            />
            <CircledButton
              active={true}
              name="adminbarGt"
              onClick={onAdvanceWeek}
            />
          </WeekControls>
        </ControlsWrapper>
        <Timezone>{userTimezone}</Timezone>
      </Header>
      <SubHeader>
        <SelectedDate onClick={handleSelectToday}>
          <Dot />
          {todayString}
        </SelectedDate>
        <Filters />
      </SubHeader>
    </>
  );
};

export default WeekCalendarHeader;

import React from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { StyledUploadZone } from './styles';
import usePageBlock from 'hooks/use-page-block';
import usePageBlockBackgroundColorOptions from 'components/admin-bridge/EditorModal/ModalComponents/BackgroundColorOptions/use-page-block-background-color-options';
import BackgroundColorOptions from 'components/admin-bridge/EditorModal/ModalComponents/BackgroundColorOptions';
import { Section, SubSectionTitle } from 'components/admin-bridge/EditorModal/ModalComponents/Section';
import { IRichText } from '../utils';
import PageBlockSettings from 'components/admin-bridge/PageBlockSettings';

interface IBlockSettingsProps {
  isOpen: boolean;
  item: IRichText;
  onChange: (item: IRichText) => void;
  onClose: () => void;
}

type EditableStringPaths = 'coverImage' | 'coverImage.show' | 'background.color' | 'header.color';

const BlockSettings = ({
  item,
  isOpen,
  onClose,
  onChange,
}: IBlockSettingsProps) => {
  const { isCustomColor } = usePageBlockBackgroundColorOptions(item.background.color);
  const { handleChange } = usePageBlock<EditableStringPaths, IRichText>({
    item,
    onChange,
  });

  const handleChangeBackgroundColor = React.useCallback((value: string) => {
    const newItem: IRichText = structuredClone(item);

    newItem.background.color = value;
    if (isCustomColor(value)) {
      newItem.background.customColor = value;
    }

    onChange(newItem);
  }, [item, onChange, isCustomColor]);

  const handleChangeHeaderColor = React.useCallback((value: string) => {
    const newItem: IRichText = structuredClone(item);

    newItem.header.color = value;
    if (isCustomColor(value)) {
      newItem.header.customColor = value;
    }

    onChange(newItem);
  }, [item, onChange, isCustomColor]);

  const handleClearCoverImage = React.useCallback(() => {
    handleChange('coverImage')('');
  }, [handleChange]);

  return (
    <PageBlockSettings
      isOpen={isOpen}
      titleKey="ADMIN_LABEL_BLOCK_SETTINGS"
      onClose={onClose}
    >
      <Section gap={20}>
        <StyledUploadZone
          imagePreview={item.coverImage}
          onClearImage={handleClearCoverImage}
          onFileSubmit={handleChange('coverImage')}
        />
        <Section gap={16}>
          <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_HEADER_COLOR" />
          <BackgroundColorOptions
            lastCustomColor={item.header.customColor}
            color={item.header.color}
            onChange={handleChangeHeaderColor}
          />
        </Section>
        <Section gap={16}>
          <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_BACKGROUND_COLOR" />
          <BackgroundColorOptions
            lastCustomColor={item.background.customColor}
            color={item.background.color}
            onChange={handleChangeBackgroundColor}
          />
        </Section>
      </Section>
    </PageBlockSettings>
  );
};

export default BlockSettings;

import { SURFACE_2, TEXT_300 } from 'style/constants';
import {  ADMIN_TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';
import styled from 'styled-components';

export const Image = styled.img`
  object-fit: cover;
  border-radius: 5px 0 0 5px;
  aspect-ratio: 2 / 3;
  max-height: 100%;
  width: 100%;
`;

export const NoImage = styled.div`
  border-radius: 5px 0 0 5px;
  aspect-ratio: 2 / 3;
  width: 100%;
  cursor: pointer;

  ${ADMIN_TEXT_LABEL_S_MEDIUM}
  color: ${TEXT_300};
  background-color: ${SURFACE_2};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 10px;
`;

import { Section, SectionTitle } from 'components/admin-bridge/EditorModal/ModalComponents/Section';
import TranslatedText from 'components/i18n/TranslatedText';
import React from 'react';
import { GeneralProps } from '.';
import { Checkbox } from 'components/admin2/ui/Checkbox';

const Display = ({ handleChange, item }: GeneralProps) => {
  return (
    <Section gap={16}>
      <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_DISPLAY" />
      <Section gap={16}>
        <Checkbox
          checked={item.label.show}
          labelKey="ADMIN_LABEL"
          onCheckedChange={handleChange('label.show')}
          testId="questsLabelCheckbox"
        />
        <Checkbox
          checked={item.title.show}
          labelKey="ADMIN_LABEL_TITLE"
          onCheckedChange={handleChange('title.show')}
          testId="questsTitleCheckbox"
        />
        <Checkbox
          checked={item.description.show}
          labelKey="ADMIN_LABEL_DESCRIPTION"
          onCheckedChange={handleChange('description.show')}
          testId="questsDescriptionCheckbox"
        />
      </Section>
    </Section>
  );
};

export default Display;

import React from 'react';
import { GeneralProps } from '.';
import { Section, SectionTitle, SubSectionTitle } from 'components/admin-bridge/EditorModal/ModalComponents/Section';
import TranslatedText from 'components/i18n/TranslatedText';
import OptionRow, { OVERFLOW_BEHAVIOR_OPTIONS } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';
import Slider from 'components/admin-bridge/EditorModal/ModalComponents/Slider';

const OverflowBehavior = ({ handleChange, item }: GeneralProps) => {

  return (
    <Section gap={20}>
      {/* overflow behavior */}
      <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_OVERFLOW_BEHAVIOR" />
      <Section gap={16}>
        <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_DESKTOP" />
        <OptionRow
          onChange={handleChange('overflowBehavior.desktop')}
          options={OVERFLOW_BEHAVIOR_OPTIONS}
          value={item.overflowBehavior.desktop}
        />
      </Section>

      {/* Quests per row */}
      <Section gap={16}>
        <TranslatedText component={SubSectionTitle} stringKey="QUESTS_PER_ROW" />
        <Slider
          min={2}
          max={6}
          onChange={handleChange('questsPerRow')}
          showStepIndicators={true}
          step={1}
          value={item.questsPerRow}
        />
      </Section>
      {/* Quests per row */}

      {/* Mobile overflow behavior */}
      <Section gap={16}>
        <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_MOBILE" />
        <OptionRow
          onChange={handleChange('overflowBehavior.mobile')}
          options={OVERFLOW_BEHAVIOR_OPTIONS}
          value={item.overflowBehavior.mobile}
        />
      </Section>
      {/* Mobile overflow behavior */}
    </Section>
  );
};

export default OverflowBehavior;

import { Alignment } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';
import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';
import InlineTextEditor from 'components/admin2/ui/InlineTextEditor';
import { FONT_FAMILY_TITLE, OPTIONAL_DYNAMIC_THEME_COLOR, TEXT_100 } from 'style/constants';
import { THEME_PADDING, mobileOnly } from 'style/mixins';
import styled from 'styled-components';

export const StyledQuickEditOverlay = styled(QuickEditOverlay)<{ backgroundColor: string; hasMobileSelectedEvent: boolean; }>`
  background-color: ${({ backgroundColor }) => OPTIONAL_DYNAMIC_THEME_COLOR(backgroundColor)};
  height: 960px;
  ${THEME_PADDING}
  ${mobileOnly`
    padding: 0;
    ${({ hasMobileSelectedEvent }) => hasMobileSelectedEvent ? `height: unset;` : `height: 100%;`};
  `};
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Calendars = styled.div`
  display: flex;
  height: 100%;
`;

export const Title = styled(InlineTextEditor)<{ alignment: Alignment; }>`
  width: 100%;
  margin-bottom: 20px;

  & .mce-content-body {
    color: ${TEXT_100};
    & * {
      text-align:  ${({ alignment }) => alignment} !important;
      font-family: ${FONT_FAMILY_TITLE};
    }
  }

  ${mobileOnly`display: none;`};
`;

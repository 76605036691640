import styled, { css } from 'styled-components';
import { Button } from 'components/admin2/ui/Button';
import {
  SURFACE_3,
  CHAT_TEXTAREA_HEIGHT,
  CHAT_TOOLBAR_HEIGHT,
  ADMIN_SURFACE_2,
  TEXT_100,
  SURFACE_1,
} from 'style/constants';
import {
  TEXT_H5,
} from 'style/mixins';

const SHOW_MORE_MESSAGES_HEIGHT_WITH_UNITS = '40px';

const coverScreen = css`
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
`;

export const Container = styled.div<{ keyboardOpen: boolean }>`
  ${TEXT_H5}
  background-color: ${SURFACE_3};
  color: ${TEXT_100};
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden !important;
  ${props => (props.keyboardOpen ? coverScreen : '')}
`;

export const MessagesContainer = styled.div`
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: thin;
  padding: 12px;
`;

export const ShowMoreMessages = styled.div<{ isAdmin: boolean }>`
  justify-content: center;
  align-items: center;
  cursor: pointer;
  bottom: ${props => (
    props.isAdmin ? (
      // admins have a toolbar to post and another below to export/clear
      CHAT_TEXTAREA_HEIGHT + 2 * CHAT_TOOLBAR_HEIGHT
    ) : (
      CHAT_TEXTAREA_HEIGHT + CHAT_TOOLBAR_HEIGHT
    )
  )}px;
  display: flex;
  position: absolute;
  height: ${SHOW_MORE_MESSAGES_HEIGHT_WITH_UNITS};
  width: 100%;
  margin-bottom: 10px;
  padding: 0 10px;
  left: 0;
  & > span {
    background-color: ${SURFACE_1};
    color: ${TEXT_100};
    padding: 9px;
    border-radius: 20px;
    text-align: center;
    width: 100%;
  }
`;

export const ModalContainer = styled.div`
  padding: 35px 29px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: ${ADMIN_SURFACE_2};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
font-weight: 500;
font-size: 18px;
text-align: center;
line-height: 19.12px;
`;

export const StyledButton = styled(Button)`
  min-width: 81px;
  min-height: 40px;
  margin: 20px;
`;

import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';
import { desktopOnly, THEME_PADDING } from 'style/mixins';
import styled, { css } from 'styled-components';
import { OPTIONAL_DYNAMIC_THEME_COLOR } from 'style/constants';
import { IQuestsBlockData, ObjectFit } from './utils';
import { GridTextAlignmentOptions } from 'components/admin-bridge/EditorModal/ModalComponents/GridTextAlignment/types';
import { supportsMaskImageCSS } from 'utils';
import { IBlockRegion } from 'services/app/models/ILandingPageContent';

export const Wrapper = styled.div<{ isScrollable: boolean; region: IBlockRegion; spacing: IQuestsBlockData['spacing']; }>`
  display: flex;
  flex-direction: column;
  ${THEME_PADDING}
  ${({ spacing }) => desktopOnly`
    padding-top: ${spacing.top}px;
    padding-bottom: ${spacing.bottom}px;
    padding-left: ${spacing.left}px;
    padding-right: ${spacing.right}px;
  `}

  ${({ region }) => region === 'panel' && css`
    padding: 12px !important;
    padding-top: 30px !important;
    padding-right: 0 !important;
    overflow-x: hidden;
    height: 100%;
  `};
`;
export const StyledQuickEditOverlay = styled(QuickEditOverlay)<{ backgroundColor: string; region: IBlockRegion;  }>`
  background-color: ${({ backgroundColor }) => OPTIONAL_DYNAMIC_THEME_COLOR(backgroundColor)};
  ${({ region }) => region === 'panel' && css`height: 100%;`};
`;

const GRID_TEXT_ALIGNMENT_OBJECT_POSITION_MAP: Record<GridTextAlignmentOptions, string> = {
  bottomCenter: 'bottom center',
  bottomLeft: 'bottom left',
  bottomRight: 'bottom right',
  center: 'center',
  centerLeft: 'center left',
  centerRight: 'center right',
  topCenter: 'top center',
  topLeft: 'top left',
  topRight: 'top right',
};

export const Image = styled.img<{ display: ObjectFit; position: GridTextAlignmentOptions; }>`
  inset: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: ${({ display }) => display};
  object-position: ${({ position }) => GRID_TEXT_ALIGNMENT_OBJECT_POSITION_MAP[position]};
`;

export const OpacityLayer = styled.div<{ backgroundColor: string; overlayOpacity: number; }>`
  position: absolute;
  inset: 0;
  opacity: ${({ overlayOpacity }) => `${overlayOpacity}%`};
  background-color: ${({ backgroundColor }) => OPTIONAL_DYNAMIC_THEME_COLOR(backgroundColor)};
`;

export const GradientLayer = styled.div<{ backgroundColor: string; }>`
  position: absolute;
  inset: 0;
  opacity: 0.8;
  background: linear-gradient(90deg, ${({ backgroundColor }) => OPTIONAL_DYNAMIC_THEME_COLOR(backgroundColor)} 0%, rgba(0,0,0,0) 100%);
  ${() => supportsMaskImageCSS && css`mask-image: linear-gradient(rgb(0, 0, 0) 50%, rgba(0,0,0,0))`};
`;

export const BlurLayer = styled.div`
  position: absolute;
  inset: 0;
  backdrop-filter: blur(5px);
`;

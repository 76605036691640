import styled from 'styled-components';
import { ADMIN_TEXT_400, ADMIN_TEXT_300, ADMIN_SURFACE_6 } from 'style/constants';
import { ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_LABEL_XS_MEDIUM } from 'style/design-system/textStyles';

export const FormTitle = styled.div`
  ${ADMIN_TEXT_BODY_S_BOLD}
  color: ${ADMIN_TEXT_300};
  letter-spacing: 0em;
  margin: 12px 0 9px 0;
`;

export const HeaderContainer = styled.div`
  ${ADMIN_TEXT_BODY_S_BOLD}
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: ${ADMIN_TEXT_300};
  letter-spacing: 0em;
  margin-bottom: 10px;
`;

export const StyledMethodTitle = styled.div`
  ${ADMIN_TEXT_BODY_S_BOLD}
  text-transform: uppercase;
`;

export const EmptyEntitlement = styled.div`
  ${ADMIN_TEXT_LABEL_XS_MEDIUM}
  color: ${ADMIN_TEXT_400};
  text-transform: uppercase;
  text-align: center;
  border: 1px solid ${ADMIN_SURFACE_6};
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  opacity: 0.4;
  margin: 10px 0px;
`;

export const Wrapper = styled.div``;

import CTAEditorModal from 'components/admin-bridge/CTAEditorModal';
import Dropdown from 'components/admin2/ui/Dropdown';
import { CTAButton as Button } from 'components/ui/CTAButton';
import { TEXT_200, OPTIONAL_DYNAMIC_THEME_COLOR, ACCENT_PRIMARY, TEXT_100, FONT_FAMILY_TITLE, FONT_FAMILY_BODY } from 'style/constants';
import { mobileOnly, desktopOnly, THEME_PADDING } from 'style/mixins';
import styled from 'styled-components';
import { ITextBanner } from './TextBanner.utils';
import { createTextContrastOnBackgroundColor } from 'services/themes/utils';
import { TEXT_BODY_M_REGULAR, TEXT_HEADLINE_S, TEXT_LABEL_L_MEDIUM } from 'style/design-system/textStyles';
import InlineTextEditor from 'components/admin2/ui/InlineTextEditor';
import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';

export const StyledQuickEditOverlay = styled(QuickEditOverlay)<{ backgroundColor: string; }>`
  background-color: ${({ backgroundColor }) => OPTIONAL_DYNAMIC_THEME_COLOR(backgroundColor)};
`;

export const RelativeWrapper = styled.div<{ alignment: ITextBanner['alignment']; }>`
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: ${({ alignment }) => alignment.horizontal === 'left' ? 'flex-start' : alignment.horizontal === 'right' ? 'flex-end' : 'center'};
  justify-content: center;
  position: relative;
  ${THEME_PADDING}
  padding-top: 100px;
  padding-bottom: 100px;
`;

export const StyledButton = styled(Button)`
  min-width: 158px;
`;

export const StyledCTAEditorModal = styled(CTAEditorModal)`
  cursor: pointer;
  margin-top: 10px;
`;

export const FontSizeDropdown = styled(Dropdown).attrs({
  padding: '0',
  labelPadding: '0 0 10px 0',
})`width: max-content;` as typeof Dropdown;

export const Title = styled(InlineTextEditor)<{ alignment: ITextBanner['alignment']; }>`
  ${desktopOnly`max-width: 60%;`}

  & .mce-content-body {
    & * {
      text-align:  ${({ alignment }) => alignment.horizontal} !important;
      font-family: ${FONT_FAMILY_TITLE};
      ${mobileOnly`
        ${TEXT_HEADLINE_S}
        font-size: 2rem !important;
      `};
    }
    color: ${TEXT_100};
  }
`;

export const Label = styled(InlineTextEditor)<{
  alignment: ITextBanner['alignment'];
  backgroundColor: string;
}>`
  ${desktopOnly`max-width: 60%;`}

  & .mce-content-body {
    & * {
      text-align:  ${({ alignment }) => alignment.horizontal} !important;
      font-family: ${FONT_FAMILY_TITLE};
      ${mobileOnly`
        ${TEXT_LABEL_L_MEDIUM}
        font-size: 1rem !important;
      `};
    }
    color: ${props => {
      if (props.backgroundColor !== 'accentPrimary') {
        return ACCENT_PRIMARY;
      }
      const backgroundColor = OPTIONAL_DYNAMIC_THEME_COLOR(props.backgroundColor)(props);
      return createTextContrastOnBackgroundColor(backgroundColor, TEXT_100(props));
    }};
  }
`;

export const SubTitle = styled(InlineTextEditor)<{ alignment: ITextBanner['alignment']; }>`
  ${desktopOnly`max-width: 60%;`}

  & .mce-content-body {
    & * {
      text-align:  ${({ alignment }) => alignment.horizontal} !important;
      font-family: ${FONT_FAMILY_BODY};
      ${mobileOnly`
        ${TEXT_BODY_M_REGULAR}
        font-size: 1rem !important;
      `}
    }
    color: ${TEXT_200};
  }
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`;

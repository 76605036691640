import { connect } from 'react-redux';

import { openPanel } from 'services/custom-panels';
import { isLandscape, isMobileLayout } from 'services/device';
import { receiveOverlay } from 'services/overlays';
import ShowPanelOverlayCta from './view';

const mapDispatchToProps = dispatch => ({
  onAddMessageOverlay: payload => dispatch(receiveOverlay(payload)),
  onOpenPanel: payload => dispatch(openPanel(payload)),
});

const mapStateToProps = state => ({
  isLandscape: isLandscape(state),
  isMobileLayout: isMobileLayout(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowPanelOverlayCta);

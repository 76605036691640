import Icon from 'components/admin2/Icon';
import {
  ACCENT_PRIMARY,
  SURFACE_4,
  TEXT_100,
  SURFACE_5,
  OPTIONAL_DYNAMIC_THEME_COLOR,
  FONT_FAMILY_TITLE,
} from 'style/constants';
import {
  THEME_PADDING,
} from 'style/mixins';
import styled from 'styled-components';
import { IFAQData } from './utils';
import InlineTextEditor from 'components/admin2/ui/InlineTextEditor';
import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';
import { ADMIN_TEXT_BODY_XS_BOLD } from 'style/design-system/textStyles';

export const StyledQuickEditOverlay = styled(QuickEditOverlay)<{ backgroundColor: string; }>`
  background-color: ${({ backgroundColor }) => OPTIONAL_DYNAMIC_THEME_COLOR(backgroundColor)};
`;

export const RelativeWrapper = styled.div<{ alignment: IFAQData['alignment']; }>`
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: ${({ alignment }) => alignment.horizontal === 'left' ? 'flex-start' : alignment.horizontal === 'right' ? 'flex-end' : 'center'};
  ${THEME_PADDING}
  position: relative;
`;


export const NewFAQBlock = styled.div<{ backgroundColor: string; }>`
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid ${({ backgroundColor }) => backgroundColor === 'surface4' ? SURFACE_5 : SURFACE_4};
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${TEXT_100};
  ${ADMIN_TEXT_BODY_XS_BOLD}
  width: 100%;
  text-transform: uppercase;
  & svg path {
    fill: ${TEXT_100};
  }

  &:hover {
    color: ${ACCENT_PRIMARY};

    & path {
      fill: ${ACCENT_PRIMARY};
    }
  }
`;

export const Title = styled(InlineTextEditor)<{ alignment: IFAQData['alignment']; }>`
  & .mce-content-body {
    color: ${TEXT_100};
    & * {
      font-family: ${FONT_FAMILY_TITLE};
      text-align:  ${({ alignment }) => alignment.horizontal} !important;
    }
  }
`;

export const PlusIcon = styled(Icon).attrs(() => ({
  name: 'plus',
}))`
  margin-right: 6px;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 16px;
    height: 16px;
  }
`;


import styled, { css } from 'styled-components';
import { SURFACE_2 } from 'style/constants';
import { desktopOnly, mobileOnly } from 'style/mixins';
import { SHOW_MINIMAL_INFO_MAX_WIDTH } from '../utils';


export const Wrapper = styled.div<{ hasSelectedEvent: boolean; }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${SURFACE_2};

  ${desktopOnly`
    width: 324px;
    max-width: 25vw;
    border-radius: 10px;
    margin-left: 10px;

    @media (max-width: ${SHOW_MINIMAL_INFO_MAX_WIDTH}px) {
      display: none;
    }
  `};

  ${mobileOnly`
    transition: width 0.3s ease-in-out;
    transition: height 0.3s ease-in-out;
    overflow: hidden;
    width: ${({ hasSelectedEvent }) => hasSelectedEvent ? '0' : '100vw'};
    height: ${({ hasSelectedEvent }) => hasSelectedEvent ? '0' : '100%'};
  `};
`;

export const MobileEventPageWrapper = styled.aside<{ hasSelectedEvent: boolean; }>`
  width: 0;
  overflow: hidden;
  display: flex;
  height: 100%;
  transition: width 0.3s ease-in-out;

  ${props => props.hasSelectedEvent && css`width: 100vw;`};
`;

import React from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { ASPECT_RATIO_LABEL_MAP, IShopifyBlockData } from '../utils';
import { Section, SectionTitle, SubSectionTitle } from 'components/admin-bridge/EditorModal/ModalComponents/Section';
import {
  UploadZoneWrapper,
  DesktopBackgroundUploadZone,
  MobileBackgroundUploadZone,
  StyledLabel,
  ToggleWrapper,
} from './styles';
import { OptionRow } from 'components/admin-bridge/EditorModal';
import { OVERFLOW_BEHAVIOR_OPTIONS } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow/types';
import ToggleSwitch from 'components/admin2/ui/ToggleSwitch';
import { Checkbox } from 'components/admin2/ui/Checkbox';
import BackgroundColorPicker from 'components/admin-bridge/EditorModal/ModalComponents/BackgroundColorOptions';
import Dropdown from 'components/admin2/ui/Dropdown';
import Slider from 'components/admin-bridge/EditorModal/ModalComponents/Slider';
import TextInput from 'components/admin2/TextInput';
import ShopInfo from './ShopInfo';
import useShopifyBlockSettings from './use-shopify-block-settings';
import { ASPECT_RATIO_OPTIONS } from '../utils';
import PageBlockSettings from 'components/admin-bridge/PageBlockSettings';

interface IBlockSettingsProps {
  isOpen: boolean;
  item: IShopifyBlockData;
  onChange: (item: IShopifyBlockData) => void;
  onClose: () => void;
}

const BlockSettings: React.FC<IBlockSettingsProps> = ({ item, onChange, isOpen, onClose }) => {
  const {
    handleChangeBackgroundColor,
    handleClearDesktopBackground,
    handleClearMobileBackground,
    handleChange,
  } = useShopifyBlockSettings(item, onChange);

  return (
    <PageBlockSettings
      isOpen={isOpen}
      titleKey="ADMIN_LABEL_SHOPIFY_BLOCK_SETTINGS"
      onClose={onClose}
    >
      <Section gap={30}>
        <ShopInfo
          item={item}
          onChangeCollection={handleChange('shopifyCollectionHandle')}
          onChangeProductView={handleChange('productView')}
        />

        {/* Display */}
        <Section gap={16}>
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_DISPLAY" />
          <Section gap={15}>
            <Checkbox
              checked={item.showProductName}
              labelKey="ADMIN_LABEL_PRODUCT_NAME"
              onCheckedChange={handleChange('showProductName')}
              testId="shopifyProductNameCheckbox"
            />
            <Checkbox
              checked={item.showProductDescription}
              labelKey="ADMIN_LABEL_DESCRIPTION"
              onCheckedChange={handleChange('showProductDescription')}
              testId="shopifyProductDescriptionCheckbox"
            />
            <Checkbox
              checked={item.showProductPrice}
              labelKey="ADMIN_LABEL_PRICE"
              onCheckedChange={handleChange('showProductPrice')}
              testId="shopifyProductPriceCheckbox"
            />
            <Checkbox
              checked={item.showProductCta}
              labelKey="ADMIN_LABEL_CALL_TO_ACTION"
              onCheckedChange={handleChange('showProductCta')}
              testId="shopifyProductCTACheckbox"
            />
          </Section>
        </Section>

        <Section gap={20}>
          <TextInput
            prefixInputIcon="linkOutline"
            padding="0"
            labelKey="ADMIN_LABEL_RETURN_POLICY"
            placeholderKey="ADMIN_LABEL_ENTER_A_URL"
            type="url"
            onChange={handleChange('returnPolicy')}
            value={item.returnPolicy}
            inputTestId="shopifyProductReturnPolicy"
          />

          <TextInput
            prefixInputIcon="linkOutline"
            padding="0"
            labelKey="TERMS_OF_USE"
            placeholderKey="ADMIN_LABEL_ENTER_A_URL"
            type="url"
            onChange={handleChange('termsOfUse')}
            value={item.termsOfUse}
            inputTestId="shopifyProductTermOfUse"
          />
        </Section>

        {/* Aspect Ratios */}
        <Section gap={20}>
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_IMAGE_ASPECT_RATIO" />
          <Section gap={16}>
            <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_DESKTOP" />
            <Dropdown
              onChange={handleChange('aspectRatio.desktop')}
              options={ASPECT_RATIO_OPTIONS}
              value={{ value: item.aspectRatio.desktop, label: ASPECT_RATIO_LABEL_MAP[item.aspectRatio.desktop] }}
              padding="0"
              admin={true}
              isSearchable={false}
            />
          </Section>
          <ToggleWrapper>
            <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_SAME_AS_DESKTOP" />
            <ToggleSwitch checked={item.aspectRatio.mobileEqualDesktop} onChange={handleChange('aspectRatio.mobileEqualDesktop')} />
          </ToggleWrapper>
          {!item.aspectRatio.mobileEqualDesktop && (
            <Section gap={16}>
              <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_MOBILE" />
              <Dropdown
                onChange={handleChange('aspectRatio.mobile')}
                options={ASPECT_RATIO_OPTIONS}
                value={{ value: item.aspectRatio.mobile, label: ASPECT_RATIO_LABEL_MAP[item.aspectRatio.mobile] }}
                padding="0"
                admin={true}
                isSearchable={false}
              />
            </Section>
          )}
        </Section>

        {/* Desktop overflow behavior */}
        <Section gap={20}>
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_OVERFLOW_BEHAVIOR" />
          <Section gap={16}>
            <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_DESKTOP" />
            <OptionRow
              onChange={handleChange('overflowBehavior.desktop')}
              options={OVERFLOW_BEHAVIOR_OPTIONS}
              value={item.overflowBehavior.desktop}
            />
          </Section>
        </Section>

        {/* Products per row */}
        <Section gap={10}>
          <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_PRODUCTS_PER_ROW" />
          <Slider
            min={2}
            max={6}
            onChange={handleChange('productsPerRow')}
            showStepIndicators={true}
            step={1}
            value={item.productsPerRow}
          />
        </Section>

        {/* Mobile overflow behavior */}
        <Section gap={16}>
          <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_MOBILE" />
          <OptionRow
            onChange={handleChange('overflowBehavior.mobile')}
            options={OVERFLOW_BEHAVIOR_OPTIONS}
            value={item.overflowBehavior.mobile}
          />
        </Section>


        {/* Background images */}
        <Section gap={20}>
          <ToggleWrapper>
            <TranslatedText component={SectionTitle} stringKey="ADMIN_SETTINGS_DESIGN_PAGE_BACKGROUND_IMAGE" />
            <ToggleSwitch checked={item.background.image.show} onChange={handleChange('background.image.show')} />
          </ToggleWrapper>
          {
            item.background.image.show && (
              <>
                <UploadZoneWrapper>
                  <StyledLabel padding="0" labelKey="ADMIN_LABEL_DESKTOP" />
                  <DesktopBackgroundUploadZone
                    imagePreview={item.background.image.desktop}
                    onClearImage={handleClearDesktopBackground}
                    onFileSubmit={handleChange('background.image.desktop')}
                  />
                </UploadZoneWrapper>
                <UploadZoneWrapper>
                  <StyledLabel padding="0" labelKey="ADMIN_LABEL_MOBILE" />
                  <MobileBackgroundUploadZone
                    imagePreview={item.background.image.mobile}
                    onClearImage={handleClearMobileBackground}
                    onFileSubmit={handleChange('background.image.mobile')}
                  />
                </UploadZoneWrapper>
              </>
            )
          }

          {/* Background color */}
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_BACKGROUND_COLOR" />
          <BackgroundColorPicker
            lastCustomColor={item.background.customColor}
            color={item.background.color}
            onChange={handleChangeBackgroundColor}
          />
        </Section>
      </Section>
    </PageBlockSettings>
  );
};

export default BlockSettings;

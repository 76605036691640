import React from 'react';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin/selectors';
import Image from './Image';
import { ContentGradient, ImageCard, BehaviorModal } from './styles';
import useBehaviorModal from './use-behavior-modal';
import TextContent from './TextContent';
import { useImageGalleryContext } from '../ImageGalleryContext/ImageGalleryContext';
import { CtaBehaviorData, CTAButtonProps } from 'components/admin-bridge/CTAEditorModal/utils';
import { SubSectionTitle } from 'components/admin-bridge/EditorModal/ModalComponents/Section';
import BehaviorDropdown from 'components/admin-bridge/CTAEditorModal/Content/BehaviorDropdown';
import BehaviorAction from 'components/ui/CTAButton/BehaviorAction';
import { useAdminTranslation } from 'hooks/use-translation';
import Behaviors from 'components/admin-bridge/CTAEditorModal/Behaviors';

export interface ImageCardProps {
  behavior: CtaBehaviorData;
  cta: CTAButtonProps;
  description: string;
  descriptionV2?: string;
  id: string;
  image: {
    desktop: string;
    mobile: string;
  }
  label: string;
  labelV2?: string;
  title: string;
  titleV2?: string;
}

interface GlobalImageCardProps {
  imageCard: ImageCardProps;
}

interface ImageCardEvents {
  onRemove: (id: string) => void;
}

export default function({
  onRemove,
  imageCard,
}: GlobalImageCardProps & ImageCardEvents) {
  const [behavior, setBehavior] = React.useState(imageCard.behavior);
  const isEditing = useSelector(isEditMode);

  const { t } = useAdminTranslation();
  const { imageCardRef, container, modalOpen, setModalOpen, toggleBehaviorModal } = useBehaviorModal();
  const { hasTextContent, contentBelow, contentInside, gridTextAlignment, editImageCard } = useImageGalleryContext();

  React.useEffect(() => {
    setBehavior(imageCard.behavior);
  }, [imageCard.behavior]);

  const closeBehaviorModal = () => {
    if (JSON.stringify(imageCard.behavior) !== JSON.stringify(behavior)) {
      editImageCard({
        ...imageCard,
        behavior,
      });
    }
    setModalOpen(false);
  };

  const handleRemove = () => {
    onRemove(imageCard.id);
  };

  return (
    <ImageCard
      isEditing={isEditing}
      blockTitleKey="ADMIN_LABEL_IMAGE_CARD"
      editorActions={[{
        icon: 'linkOutline',
        onClick: toggleBehaviorModal,
        tooltipKey: 'ACTION_ADD_LINK',
        useAbsolutePosition: true,
      }, {
        confirmationMessageKey: 'ADMIN_PROMPT_CONFIRM_IMAGE_CARD_DELETION',
        descriptionTextKey: 'ADMIN_LABEL_UNDO_WARNING',
        icon: 'trash',
        onClick: handleRemove,
        tooltipKey: 'ACTION_DELETE_IMAGE_CARD',
        useAbsolutePosition: true,
      }]}
      hasLink={!isEditing && (behavior.type === 'goToPage' || behavior.type === 'openLink')}
      inEditMode={isEditing}
      editorWrapperRef={imageCardRef}
      testId={`image-gallery-image-card-${imageCard.id}`}
    >
      <BehaviorModal
        initialPosition={container ? { x: container.x, y: container.y } : undefined}
        isOpen={modalOpen}
        onClose={closeBehaviorModal}
      >
        <SubSectionTitle>{t('CARD_ACTION')}</SubSectionTitle>
        <BehaviorDropdown behavior={behavior} onChange={setBehavior} />
        <Behaviors behavior={behavior} onChange={setBehavior} />
      </BehaviorModal>
      <BehaviorAction behavior={behavior}>
        <Image imageCard={imageCard}>
          {contentInside && hasTextContent && (
            <ContentGradient gridTextAlignment={gridTextAlignment}>
              <TextContent imageCard={imageCard} />
            </ContentGradient>
          )}
        </Image>
      </BehaviorAction>
      {contentBelow && <TextContent imageCard={imageCard} />}
    </ImageCard>
  );
}

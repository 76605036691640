import { connect } from 'react-redux';
import { removeOverlay } from 'services/overlays';
import { trackOverlay } from 'services/insights';
// import { isMobileLayout } from 'services/device';
import HighlightOverlay from './view';

const mapDispatchToProps = dispatch => ({
  onDismiss: key => dispatch(removeOverlay(key)),
  trackDismiss: doc => dispatch(trackOverlay('dismiss', doc)),
});

// const mapStateToProps = state => ({
//   isMobile: isMobileLayout(state),
// });

export default connect(null, mapDispatchToProps)(HighlightOverlay);

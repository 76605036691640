import React, { ReactNode } from 'react';
import { ImageCardProps } from '../ImageCard';
import { ChunkPaginationReturnType } from 'hooks/use-chunk-pagination';
import { AddMoreButton } from './styles';
import IconButton from 'components/admin2/ui/IconButton';
import { ExternalPageSlider } from 'components/ui/PageSlider';

interface IImageGallerySliderProps {
  children: (pageItems: ImageCardProps[], pageIndex: number, pageCount: number) => ReactNode;
  isScrollable: boolean;
  onAddNewImageCard: () => void;
  pagination: ChunkPaginationReturnType<ImageCardProps>;
  showAddMoreButton: boolean;
}

const ImageGallerySlider: React.FC<IImageGallerySliderProps> = ({ showAddMoreButton, children, pagination, isScrollable, onAddNewImageCard }) => {
  return (
    <ExternalPageSlider
      absoluteControls={true}
      controlsChildren={showAddMoreButton && (
        <IconButton
          button={AddMoreButton}
          icon="plus"
          iconSpacing={6}
          labelKey="ADMIN_LABEL_ADD_MORE"
          onClick={onAddNewImageCard}
        />
      )}
      controlsCss="right: 10px;"
      pagination={pagination}
      isScrollable={isScrollable}
      renderChildrenWithoutItems={true}
    >
      {children}
    </ExternalPageSlider>
  );
};

export default ImageGallerySlider;

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useAdminTranslation } from 'hooks/use-translation';
import { useApis } from 'contexts/ApisContext';
import { flattenCalls } from 'utils';
import { useSelector } from 'hooks';
import { getPrimaryToken } from 'services/auth';
import { getSiteId } from 'services/app/selectors';
import { Section, SectionTitle } from 'components/admin-bridge/EditorModal/ModalComponents/Section';
import PageBlockSettings from 'components/admin-bridge/PageBlockSettings';
import { IEmbedCode, generateAdditionalScript } from '../utils';
import { Footer, Button, StyledTextArea } from './styles';

interface IBlockSettingsProps {
  blockId: string;
  isOpen: boolean;
  item: IEmbedCode;
  onChange: (url: string) => void;
  onClose: () => void;
  pageId: string;
}

const BlockSettings = ({
  item,
  isOpen,
  onClose,
  onChange,
  pageId,
  blockId,
}: IBlockSettingsProps) => {
  const primaryToken = useSelector(getPrimaryToken)!;
  const siteId = useSelector(getSiteId);
  const [textFieldValue, setTextFieldValue] = useState('');
  const { page } = useApis();
  const { t } = useAdminTranslation();

  const embedBlockIframe = useMemo(
    () => flattenCalls(
      page.embedBlockIframe,
    ),
    [page],
  );

  const fetchEmbedBlockCode = useMemo(
    () => flattenCalls(
      page.fetchEmbedBlockCode,
    ),
    [page],
  );

  useEffect(
    () => {
      if (!item.url || textFieldValue.trim()) {
        return;
      }

      (async () => {
        try {
          const htmlData = await fetchEmbedBlockCode(item.url);
          const index = htmlData.indexOf(generateAdditionalScript(blockId));
          if (index === -1) {
            throw new Error('Provided url is not from Maestro embed.');
          }
          setTextFieldValue(htmlData.substring(0, index + 1));
        } catch (err) {
          // tslint:disable-next-line:no-console
          console.error('Failure when fetching iframe data:', err);
          setTextFieldValue(`<iframe style="width:100%;height:100%" src="${item.url}"></iframe>`);
        }
      })();
    },
    [],
  );

  const handleSubmit = async () => {
    try {
      const url = await embedBlockIframe({
        primaryToken,
        siteId,
        pageId,
        blockId,
        htmlData: `${textFieldValue.trim()}\n${generateAdditionalScript(blockId)}`,
      });
      if (!url) {
        throw new Error(`Couldn't generate embed code url`);
      }
      onChange(url);
      onClose();
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error('Failure when submitting form:', err);
    }
  };

  const handleChange = useCallback((text: string) => {
    setTextFieldValue(text);
  }, []);

  return (
    <PageBlockSettings
      isOpen={isOpen}
      titleKey="EMBED_CODE_BLOCK_MODAL_HEADER"
      onClose={onClose}
    >
      <Section gap={10}>
        <Section gap={5}>
          <SectionTitle>{t('EMBED_CODE_BLOCK_MODAL_SUB_HEADER')}</SectionTitle>
          <StyledTextArea
            value={textFieldValue}
            onChange={handleChange}
          />
        </Section>
        <Footer>
          <Button disabled={!textFieldValue.trim()} onClick={handleSubmit}>
            {t('ADMIN_ACTION_SAVE')}
          </Button>
        </Footer>
      </Section>
    </PageBlockSettings>
  );
};

export default BlockSettings;
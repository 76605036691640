import { bw } from 'colors';
import styled from 'styled-components';
import { BACKGROUND_ACTIVE, TEXT_P1, TEXT_H2, TEXT_H5, TEXT_H6 } from 'style/mixins';
import { ACCENT_PRIMARY, SURFACE_1, SURFACE_3, TEXT_100 } from 'style/constants';

export const Award = styled.div`
  color: white;
  ${TEXT_H2}
  margin-right: 8px;
`;

export const AwardImage = styled.div`
  background: url(${props => props.src});
  background-position: center;
  background-size: cover;
  width: 50px;
  height: 50px;
`;

export const ClaimCode = styled.div`
  background: ${SURFACE_3};
  ${TEXT_P1}
  padding: 10px 15px;
`;

export const ClaimTime = styled.div`
  ${TEXT_H6}
  opacity: 0.5;
  margin-top: 8px;
  & > * {
    display: inline-block;
    padding-right: 5px;
  }
`;

export const RewardsWrapper = styled.div`
  flex: 1;
  overflow: auto;
  background: ${SURFACE_3};
  padding: 10px;
`;

export const RewardDesc = styled.div`
  align-items: center;
  display: flex;
  justify-content: start;
  padding: 10px;
`;

export const RewardItem = styled.div`
  background: ${SURFACE_1};
  border: ${props => props.active ? '1px' : '0'} solid ${ACCENT_PRIMARY};
  margin-bottom: 8px;
`;

export const RewardHeader = styled.div`
  ${TEXT_P1}
  background: ${(props) => props.active ? ACCENT_PRIMARY(props) : SURFACE_1(props)};
  color: ${(props) => props.active ? TEXT_100(props) : bw(SURFACE_1(props))};
  padding: 15px;
  &:hover {
    ${BACKGROUND_ACTIVE}
    cursor: pointer;
  }
`;

export const RewardTitle = styled.div`
  ${TEXT_H5}
  text-transform: uppercase;
`;

export const RewardText = styled.div`
  opacity: 0.5;
`;

export const Reward = styled.div`
  text-transform: none;
`;

export const Title = styled.div`
  font-size: 18px;
  padding: 12px 0 20px;
  & > * {
    padding-left: 5px;
  }
`;

export const TitleText = styled.span`
  color: ${TEXT_100};
`;

import VideoLink from 'components/ui/VideoLink';
import { OPTIONAL_DYNAMIC_THEME_COLOR } from 'style/constants';
import { desktopOnly, mobileOnly, THEME_PADDING, THEME_PADDING_DESKTOP } from 'style/mixins';
import styled from 'styled-components';
import { IVideoSpotlight } from './utils';
import EditableArea from 'components/admin2/ui/EditableArea';
import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';

const getFlexDirectionByLayout = (layout: IVideoSpotlight['layout']) => {
  switch (layout) {
    case 'right':
      return 'row-reverse';
    case 'stacked':
      return 'column';
    default:
      return 'unset';
  }
};

export const StyledQuickEditOverlay = styled(QuickEditOverlay)<{ backgroundColor: string; }>`
  ${THEME_PADDING}
  position: relative;
  display: flex;
  background-color: ${({ backgroundColor }) => OPTIONAL_DYNAMIC_THEME_COLOR(backgroundColor)};
  justify-content: center;
`;

export const MainEditor = styled(EditableArea)<{ hasTextContent: boolean; layout: IVideoSpotlight['layout']; }>`
  display: flex;
  width: 100%;
  ${mobileOnly`
    flex-direction: column;
    gap: 40px;
  `}
  ${desktopOnly`
    gap: ${THEME_PADDING_DESKTOP};
    flex-direction: ${({ layout }) => getFlexDirectionByLayout(layout)};
    ${({ layout }) => layout !== 'stacked' && 'align-items: center;'}
    ${({ layout }) => layout === 'stacked' && 'width: min-content; min-width: 50vw;'}
    ${({ layout, hasTextContent }) => !hasTextContent && layout === 'stacked' &&  'gap: 0;'}
  `};
`;

export const StyledVideoLink = styled(VideoLink)`
  aspect-ratio: 16 / 9;
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`;

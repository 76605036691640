import React from 'react';
import { CalendarDay, CalendarDayEventDot, CalendarDayEventDots, CalendarGrid, CalendarWeek, PlusIcon, WeekDaysGrid, WeekText, WeekTextWrapper, Wrapper } from './styles';
import type { DateData } from 'hooks/use-calendar';
import { useEventCalendarContext } from '../../EventCalendarContext';
import useEventCalendarApi from '../../use-event-calendar-api';
import hash from 'json-stable-stringify';

interface ICalendarProps {
  isMobileCalendarOpen: boolean;
}

interface IMiniCalendarDayProps {
  dayIndex: number;
  weekDay: DateData;
  weekIndex: number;
}

const MiniCalendarDay: React.FC<IMiniCalendarDayProps> = ({ weekDay, weekIndex, dayIndex }) => {
  const { isToday, miniCalendarEvents, result, selectedDate, timestampDateFormat, isSameDay, setSelectedDate } = useEventCalendarContext();

  const { eventsQuantity, labels } = React.useMemo(() => {
    const date = new Date(new Date(weekDay.year, weekDay.month, weekDay.day));
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const parsedDate = new Date(date.getTime() - userTimezoneOffset).getTime();

    const quantity = miniCalendarEvents[parsedDate]?.quantity || 0;
    const firstLabelColors = (miniCalendarEvents[parsedDate]?.firstLabelColors || []).slice(0, 2);

    return {
      eventsQuantity: quantity,
      labels: firstLabelColors,
    };
  }, [hash(weekDay), miniCalendarEvents]);

  const isSelectedDayInWeek = React.useCallback((index: number) => {
    return result.visibleDaysMatrix[index].some(day => {
      return day.day === selectedDate.day && day.month === selectedDate.month;
    });
  }, [result.visibleDaysMatrix, selectedDate]);

  const isCurrentMonth = React.useCallback((week: DateData) => {
    return week.month === timestampDateFormat.month && week.year === timestampDateFormat.year;
  }, [timestampDateFormat]);

  const onClick = React.useCallback(() => {
    setSelectedDate(weekDay);
  }, [hash(weekDay), setSelectedDate]);

  return (
    <CalendarDay
      onClick={onClick}
      isCurrentMonth={isCurrentMonth(weekDay)}
      isSelectedDay={isSameDay(weekDay, selectedDate)}
      isSelectedDayInWeek={isSelectedDayInWeek(weekIndex)}
      isWeekend={dayIndex === 0 || dayIndex === 6}
      isToday={isToday(weekDay)}
      key={weekDay.day + weekDay.month + weekIndex}
      style={{
        gridColumn: dayIndex + 1,
        gridRow: weekIndex + 1,
      }}
    >
      {weekDay.day}
      {
        eventsQuantity > 0 && (
          <CalendarDayEventDots>
            {labels.map((labelColor, index) => <CalendarDayEventDot key={index} firstLabelColor={labelColor} />)}
            {eventsQuantity > 2 && <PlusIcon />}
          </CalendarDayEventDots>
        )
      }
    </CalendarDay>
  );
};

const Calendar: React.FC<ICalendarProps> = ({ isMobileCalendarOpen }) => {
  const { setMiniCalendarEvents, result } = useEventCalendarContext();
  const { getEventsInfoBetweenTimestamp } = useEventCalendarApi();

  React.useEffect(() => {
    const firstDay = result.visibleDaysMatrix[0][0];
    const lastDay = result.visibleDaysMatrix[result.visibleDaysMatrix.length - 1][6];

    const startTimestamp = new Date(firstDay.year, firstDay.month, firstDay.day).getTime();
    const endTimestamp = new Date(lastDay.year, lastDay.month, lastDay.day + 1).getTime();

    getEventsInfoBetweenTimestamp(startTimestamp, endTimestamp).then(setMiniCalendarEvents);
  }, [hash(result.visibleDaysMatrix)]);

  return (
    <Wrapper isMobileCalendarOpen={isMobileCalendarOpen}>
      <WeekDaysGrid>
        {result.weekDays.map((day, index) => (
          <WeekTextWrapper key={index}>
            <WeekText>{day}</WeekText>
          </WeekTextWrapper>
        ))}
      </WeekDaysGrid>
      <CalendarGrid>
        {
          result.visibleDaysMatrix.map((weekDays, weekIndex) => (
            <CalendarWeek key={weekIndex}>
              {weekDays.map((dayAndMonth, dayIndex) => (
                <MiniCalendarDay
                  key={dayIndex}
                  weekDay={dayAndMonth}
                  weekIndex={weekIndex}
                  dayIndex={dayIndex}
                />
              ))}
            </CalendarWeek>
          ))
        }
      </CalendarGrid>
    </Wrapper>
  );
};

export default Calendar;

import qs from 'qs';

export function getAttrsFromIframeTag(iframeTag:string):any{
    const parser = new DOMParser();
    const iframeDoc = parser.parseFromString(iframeTag, 'text/html');
    const el = iframeDoc.getElementsByTagName('iframe')[0];
    const attributes:{ [x:string]:any } = {};
    if (!el) { return (attributes); }
    for (const attribute of el.attributes as unknown as any[]) {
      attributes[attribute.nodeName] = attribute.nodeValue;
    }
    return attributes;
}

export function addSearchParamsToUrl(urlString:string,params:any){
  try{
    const url = new URL(urlString);
    url.search = qs.stringify({ ...qs.parse(url.search), ...params });
    return url.toString();
  }catch(e){
    return '';
  }

}

import { IVideo, IVideoMetadata, PlayerTypes } from 'models';
import React, { useCallback } from 'react';
import { DEFAULT_DESCRIPTION, DEFAULT_TAGS, DEFAULT_TITLE, DEFAULT_UPLOAD_DATE, IVideoSpotlight } from '../utils';
import { Content, Description, VideoTags, Title, UploadDate } from './styles';
import moment from 'moment';
import { fetchMetadata } from 'services/video/api';
import { useSelector } from 'react-redux';
import { getSiteId } from 'services/app';
import { getPrimaryToken } from 'services/auth';
import { DynamicTranslationType, useEndUserTranslation } from 'hooks/use-translation';

interface ITextContentProps {
  item: IVideoSpotlight;
  loaded: boolean;
  video: IVideo | null;
}

const TextContent: React.FC<ITextContentProps> = ({ item, video, loaded }) => {
  const { endUserT } = useEndUserTranslation();
  const siteId = useSelector(getSiteId);
  const primaryToken = useSelector(getPrimaryToken);
  const [uploadDate, setUploadDate] = React.useState('');
  const hasVideo = loaded && video;
  const isDefaultBlock = !item.videoId;

  React.useEffect(() => {
    if (isDefaultBlock) {
      setUploadDate(DEFAULT_UPLOAD_DATE);
      return;
    }

    if (!hasVideo) {
      return;
    }

    // If the video is not youtube or twitch video, we can use the created date
    if (video.created && video.player && ![PlayerTypes.youtube, PlayerTypes.twitch].includes(video.player)) {
      setUploadDate(moment(new Date(video.created)).fromNow());
      return;
    }

    if (!siteId || !primaryToken || !video.url) {
      return;
    }

    fetchMetadata<IVideoMetadata>({
      primaryToken,
      siteId,
      url: video.url,
    })
    .then((response) => {
      if (response.publishedDate) {
        setUploadDate(moment(new Date(response.publishedDate)).fromNow());
      }
    })
    .catch(() => {
      if (video.created) {
        setUploadDate(moment(new Date(video.created)).fromNow());
      }
    });
  }, [isDefaultBlock, hasVideo, video, siteId, primaryToken]);

  const renderTitle = useCallback(() => {
    if (!item.title.show) {
      return null;
    }

    let title;
    if (isDefaultBlock) {
      title = DEFAULT_TITLE;
    }
    if (hasVideo) {
      title = endUserT([
        DynamicTranslationType.videoTitle,
        { videoId: video._id! },
      ]) || video.title;
    }

    return <Title data-testid="videoItemTitle">{title}</Title>;
  }, [isDefaultBlock, item.title.show, video?.title, hasVideo, endUserT]);

  const renderDescription = useCallback(() => {
    if (!item.description.show) {
      return null;
    }

    let description;
    if (isDefaultBlock) {
      description = DEFAULT_DESCRIPTION;
    }
    if (hasVideo) {
      description = endUserT([
        DynamicTranslationType.videoDescription,
        { videoId: video._id! },
      ]) || video.description;
    }

    return <Description maxLines={8}>{description}</Description>;
  }, [isDefaultBlock, item.description.show, video?.description, hasVideo, endUserT]);

  const renderTags = useCallback(() => {
    if (!item.tags.show) {
      return null;
    }

    let tags;
    if (isDefaultBlock) {
      tags = DEFAULT_TAGS;
    }
    if (hasVideo) {
      tags = (
        (video.tags || []).map(
          (tag) => (
            endUserT([
              DynamicTranslationType.videoTag,
              { tag },
            ]) || tag
          ),
        )
      );
    }

    return (
      <VideoTags
        alignment={item.alignment}
        listTags={tags || []}
      />
    );
  }, [isDefaultBlock, item.tags.show, item.alignment, video?.tags, hasVideo, endUserT]);

  const renderUploadDate = useCallback(() => {
    if (!item.uploadDate.show) {
      return null;
    }

    return <UploadDate>{uploadDate}</UploadDate>;
  }, [item.uploadDate.show, uploadDate]);

  return (
    <Content alignment={item.alignment}>
      {renderTitle()}
      {renderDescription()}
      {renderTags()}
      {renderUploadDate()}
    </Content>
  );
};

export default TextContent;

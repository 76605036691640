import React, { ReactNode } from 'react';
import { ChunkPaginationReturnType } from 'hooks/use-chunk-pagination';
import { AddMoreBadge, StyledExternalPageSlider } from './styles';
import IconButton from 'components/admin2/ui/IconButton';

interface IQuestsBlockSliderProps {
  children: (pageItems: string[], pageIndex: number, pageCount: number) => ReactNode;
  isScrollable: boolean;
  onAddClick: () => void;
  pagination: ChunkPaginationReturnType<string>;
  showAddMoreButton: boolean;
}

const QuestsBlockSlider: React.FC<IQuestsBlockSliderProps> = ({ showAddMoreButton, children, isScrollable, pagination, onAddClick }) => {
  return (
    <StyledExternalPageSlider
      absoluteControls={true}
      controlsChildren={showAddMoreButton && (
        <IconButton
          button={AddMoreBadge}
          icon="plus"
          iconSpacing={6}
          labelKey="ADMIN_ACTION_ADD_QUEST"
          onClick={onAddClick}
        />
      )}
      controlsCss="right: 10px;"
      pagination={pagination}
      isScrollable={isScrollable}
      renderChildrenWithoutItems={true}
    >
      {children}
    </StyledExternalPageSlider>
  );
};

export default QuestsBlockSlider;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CloseButton from 'components/ui/CloseButton';
import AdminOverlayEngagement from 'components/admin/AdminOverlayEngagement';
import { BACKGROUND_ACTIVE } from 'style/mixins';
import {
  SURFACE_1,
} from 'style/constants';
import TranslatedText from 'components/i18n/TranslatedText';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

// maintains aspect ratio based on the width
const Wrapper = styled.div`
  background-image: url(${props => props.background});
  background-size: cover;
  flex-grow: 1;
  height: 0;
  width: 40%;
  padding-bottom: 20%;
  position: relative;
`;

const Header = styled.div`
  background: linear-gradient(180deg, ${SURFACE_1} 0%, rgba(0, 0, 0, 0) 100%);
  font-size: 22px;
  padding: 18px;
  vertical-align: bottom;
`;

const Close = styled(CloseButton)`
  float: right;
  cursor: pointer;
`;

const BuyButton = styled.div`
  ${BACKGROUND_ACTIVE}
  bottom: 0;
  cursor: pointer;
  left: 0;
  padding: 20px;
  position: absolute;
`;
export default class CommerceOverlay extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      key: PropTypes.string.isRequired,
      payload: PropTypes.shape({
        data: PropTypes.shape({
          broadcastId: PropTypes.string,
          product: PropTypes.object,
          views: PropTypes.number,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    onDismiss: PropTypes.func.isRequired,
    onEngagedOverlay: PropTypes.func.isRequired,
    onOpenPanel: PropTypes.func.isRequired,
    overlayType: PropTypes.string.isRequired,
    trackDismiss: PropTypes.func.isRequired,
    trackEngage: PropTypes.func.isRequired,
  };

  handleDismiss = () => {
    const { onDismiss, data, data: { key }, trackDismiss } = this.props;

    trackDismiss(data);
    onDismiss(key);
  };

  showPanel = () => {
    const {
      data,
      data: {
        payload: {
          data: { product, broadcastId },
        },
      },
      onEngagedOverlay,
      onOpenPanel,
      trackEngage,
    } = this.props;

    trackEngage(data);
    onEngagedOverlay(broadcastId);
    onOpenPanel({
      doc: {
        data: {
          kind: 'commerce',
          product,
        },
      },
    });
  };

  render() {
    const {
      data: {
        key,
        payload: {
          data: { broadcastId, views, product },
        },
      },
    } = this.props;

    if (!product) {
      return null;
    }

    const { image, name } = product.data;
    const { overlayType } = this.props;
    return (
      <Container>
        <AdminOverlayEngagement
          broadcastId={broadcastId}
          broadcastKey={key}
          showEngaged
          views={views}
        />
        <Wrapper background={image} className={overlayType}>
          <Header>
            <span>{ name }</span>
            <Close onClick={this.handleDismiss} />
          </Header>
          <BuyButton onClick={this.showPanel}>
            <TranslatedText stringKey="ACTION_BUY_NOW" />
          </BuyButton>
        </Wrapper>
      </Container>
    );
  }
}

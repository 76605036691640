import shortid from 'shortid';
import { MAESTRO_BLACK } from 'style/constants';
import { Alignment } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';
import { getDefaultRichTextEditorData } from 'utils';
import { injectT } from 'hooks/use-translation';
import { lazy } from 'utils/lazy';
import { getDefaultEditorState, getDefaultHeadingNode, getDefaultTextNode } from 'components/ui/RichTextEditorV3/getDefaultEditorState';

interface IQuestion {
  answer: {
    rawData: string;
    rawDataV2?: string;
  }
  arrayId: string;
  question: {
    rawData: string;
    rawDataV2?: string;
  }
}

export interface IFAQData {
  alignment: {
    horizontal: Alignment;
  },
  backgroundColor: {
    color: string;
    customColor: string;
  }
  faqCardColor: {
    color: string;
    customColor: string;
  }
  questions?: IQuestion[];
  title: {
    rawData: string;
    rawDataV2?: string;
  }
}

export interface IFAQItem {
  arrayId: string;
  data: IFAQData;
  kind: string;
}



export const DEFAULT_FAQ = injectT(
  (t) => lazy<IFAQData>(
    () => ({
      alignment: {
        horizontal: Alignment.Left,
      },
      backgroundColor: {
        color: 'surface1',
        customColor: MAESTRO_BLACK,
      },
      faqCardColor: {
        color: 'surface2',
        customColor: MAESTRO_BLACK,
      },
      questions: [
        {
          answer: {
            rawData: getDefaultRichTextEditorData({
              fontSize: 14,
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque sit nunc fringilla lacus. Facilisis eget malesuada gravida consequat pellentesque euismod cursus.',
            }),
            rawDataV2: getDefaultEditorState([
              getDefaultTextNode({
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque sit nunc fringilla lacus. Facilisis eget malesuada gravida consequat pellentesque euismod cursus.',
                fontSize: 14,
              }),
            ]),
          },
          arrayId: shortid.generate(),
          question: {
            rawData: getDefaultRichTextEditorData({
              fontSize: 20,
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing mauris pulvinar feugiat at maecenas sed enim tristique?',
            }),
            rawDataV2: getDefaultEditorState([
              getDefaultHeadingNode({
                tag: 'h3',
                children: [
                  getDefaultTextNode({
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing mauris pulvinar feugiat at maecenas sed enim tristique?',
                    fontSize: 20,
                  }),
                ],
              }),
            ]),
          },
        },
        {
          answer: {
            rawData: getDefaultRichTextEditorData({
              fontSize: 14,
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut imperdiet tincidunt a aliquam facilisis. Felis, iaculis tincidunt neque, leo tristique turpis risus, pulvinar. Ipsum, libero urna, maecenas sapien et mattis eget. Sollicitudin pulvinar varius  Amet pretium leo volutpat eu sit enim risus posuere suspendisse. Platea aliquam id laoreet in condimentum dictum. Ornare lacinia est integer pharetra eget.',
            }),
            rawDataV2: getDefaultEditorState([
              getDefaultTextNode({
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut imperdiet tincidunt a aliquam facilisis. Felis, iaculis tincidunt neque, leo tristique turpis risus, pulvinar. Ipsum, libero urna, maecenas sapien et mattis eget. Sollicitudin pulvinar varius  Amet pretium leo volutpat eu sit enim risus posuere suspendisse. Platea aliquam id laoreet in condimentum dictum. Ornare lacinia est integer pharetra eget.',
                fontSize: 14,
              }),
            ]),
          },
          arrayId: shortid.generate(),
          question: {
            rawData: getDefaultRichTextEditorData({
              fontSize: 20,
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing mauris pulvinar feugiat?',
            }),
            rawDataV2: getDefaultEditorState([
              getDefaultHeadingNode({
                tag: 'h3',
                children: [
                  getDefaultTextNode({
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing mauris pulvinar feugiat?',
                    fontSize: 20,
                  }),
                ],
              }),
            ]),
          },
        },
        {
          answer: {
            rawData: getDefaultRichTextEditorData({
              fontSize: 14,
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Accumsan libero sed porta diam nulla. Eget enim morbi arcu sollicitudin. Sed netus nec, facilisi donec. Tincidunt mollis egestas viverra diam. Consequat vestibulum sapien nunc feugiat tristique hendrerit ut.',
            }),
            rawDataV2: getDefaultEditorState([
              getDefaultTextNode({
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Accumsan libero sed porta diam nulla. Eget enim morbi arcu sollicitudin. Sed netus nec, facilisi donec. Tincidunt mollis egestas viverra diam. Consequat vestibulum sapien nunc feugiat tristique hendrerit ut.',
                fontSize: 14,
              }),
            ]),
          },
          arrayId: shortid.generate(),
          question: {
            rawData: getDefaultRichTextEditorData({
              fontSize: 20,
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Felis aliquam tellus ultricies nisi phasellus mauris felis at ornare ultricies.',
            }),
            rawDataV2: getDefaultEditorState([
              getDefaultHeadingNode({
                tag: 'h3',
                children: [
                  getDefaultTextNode({
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Felis aliquam tellus ultricies nisi phasellus mauris felis at ornare ultricies.',
                    fontSize: 20,
                  }),
                ],
              }),
            ]),
          },
        },
      ],
      title: {
        rawData: getDefaultRichTextEditorData({
          fontSize: 22,
          text: t('ADMIN_FAQ_TITLE'),
          typographicalEmphasis: 'BOLD',
        }),
        rawDataV2: getDefaultEditorState([
          getDefaultHeadingNode({
            tag: 'h2',
            children: [
              getDefaultTextNode({
                text: t('ADMIN_FAQ_TITLE'),
                fontSize: 22,
                textFormatType: 'bold',
              }),
            ],
          }),
        ]),
      },
    }),
  ),
);


export const defaultFAQQuestion = (): IQuestion => ({
  arrayId: shortid.generate(),
  answer: {
    rawData: getDefaultRichTextEditorData({
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Accumsan libero sed porta diam nulla. Eget enim morbi arcu sollicitudin. Sed netus nec, facilisi donec. Tincidunt mollis egestas viverra diam. Consequat vestibulum sapien nunc feugiat tristique hendrerit ut.',
      fontSize: 14,
    }),
    rawDataV2: getDefaultEditorState([
      getDefaultTextNode({
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Accumsan libero sed porta diam nulla. Eget enim morbi arcu sollicitudin. Sed netus nec, facilisi donec. Tincidunt mollis egestas viverra diam. Consequat vestibulum sapien nunc feugiat tristique hendrerit ut.',
        fontSize: 14,
      }),
    ]),
  },
  question: {
    rawData: getDefaultRichTextEditorData({
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Felis aliquam tellus ultricies nisi phasellus mauris felis at ornare ultricies.',
     fontSize: 20,
    }),
    rawDataV2: getDefaultEditorState([
      getDefaultHeadingNode({
        tag: 'h3',
        children: [
          getDefaultTextNode({
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Felis aliquam tellus ultricies nisi phasellus mauris felis at ornare ultricies.',
            fontSize: 20,
          }),
        ],
      }),
    ]),
  },
});

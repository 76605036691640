import styled from 'styled-components';
import { ACCENT_PRIMARY } from 'style/constants';
import Label from 'components/admin2/ui/Label';
import Icon from 'components/ui/Icon';
import ExpandableCard from 'components/admin2/ui/ExpandableCard';

export const ChangePasswordSuccessConfirmation = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-family: Noto Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 4px;
`;

export const SuccessLabel = styled(Label)`
  margin-left: 5px;

  div {
    padding: 0;
  }

  div > label > div {
    font-weight: 500;
  }
`;

export const CheckIcon = styled(Icon)`
  svg {
    width: 16px;
    height: 16px;
  }

  circle {
    fill: ${ACCENT_PRIMARY} !important;
  }
`;

export const CustomExpandableCard = styled(ExpandableCard)`
  height: unset !important;
  margin-top: 10px;

  pre {
    font-size: 14px;
  }

  > div {
    min-height: unset !important;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  position: relative;
`;

export const Container = styled.div`
  margin-top: 4px;
`;

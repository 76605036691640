import { Alignment } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';
import { getDefaultEditorState, getDefaultTextNode } from 'components/ui/RichTextEditorV3/getDefaultEditorState';
import { SITE_ID } from 'config';
import { ICalendarEvent, IEventCategory } from 'models/ICalendarEvent';
import shortid from 'shortid';
import { MAESTRO_BLACK } from 'style/constants';
import { getDefaultRichTextEditorData } from 'utils';

export const FULL_EVENT_CARD_MAX_WIDTH = 600;
export const FULL_EVENT_CARD_MIN_WIDTH = 510;
export const FULL_EVENT_CARD_MAX_HEIGHT = 300;
export const FULL_EVENT_CARD_MIN_HEIGHT = 267;
export const SHOW_MINIMAL_INFO_MAX_WIDTH = 1474;



export type ILabelSuggestion = {
  color: string;
  label: string;
  value: string;
};

export type ICategorySuggestion = {
  label: string;
  value: string;
};

export interface EventSettingsPayload {
  calendarEvent: ICalendarEvent;
  locked: boolean;
  onDismiss: () => void;
  onSave: (calendarEvent: ICalendarEvent) => void;
}

export interface IEventCardProps {
  event: ICalendarEvent;
  onChange?: (event: ICalendarEvent) => void;
  onClick?: () => void;
  onRemove?: (event: ICalendarEvent) => void;
  readOnly: boolean;
  showActionsMenu: boolean;
}

export interface IEventCalendar {
  background: {
    color: string;
    customColor: string;
  }
  textAlignment: Alignment;
  title: {
    rawData: string;
    rawDataV2?: string;
    show: boolean;
  }
}

export const EMPTY_EVENT_CATEGORY: IEventCategory = {
  id: '',
  label: {
    id: '',
    color: '',
    title: '',
  },
  title: '',
};


export const getNewEventCard = (startDateTimestamp: number): ICalendarEvent => ({
  siteId: SITE_ID,
  image: {
    src: '',
    show: true,
  },
  date: {
    useEndTime: false,
    endTimestamp: startDateTimestamp,
    startTimestamp: startDateTimestamp,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
  titleRaw: getDefaultRichTextEditorData({
    text: 'Lorem ipsum dolor sit',
    fontSize: 20,
  }),
  titleRawV2: getDefaultEditorState([
    getDefaultTextNode({
      text: 'Lorem ipsum dolor sit',
      fontSize: 20,
    }),
  ]),
  descriptionRaw: getDefaultRichTextEditorData({
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempor id consectetur viverra et. Cras semper quam venenatis morbi.',
    fontSize: 12,
  }),
  descriptionRawV2: getDefaultEditorState([
    getDefaultTextNode({
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempor id consectetur viverra et. Cras semper quam venenatis morbi.',
      fontSize: 12,
    }),
  ]),
  categories: [EMPTY_EVENT_CATEGORY],
});

export const getDefaultEventCalendar = (): IEventCalendar => ({
  background: {
    color: 'surface1',
    customColor: MAESTRO_BLACK,
  },
  textAlignment: Alignment.Left,
  title: {
    rawData: getDefaultRichTextEditorData({
      text: 'Calendar',
      fontSize: 20,
    }),
    rawDataV2: getDefaultEditorState([
      getDefaultTextNode({
        text: 'Calendar',
        fontSize: 20,
      }),
    ]),
    show: true,
  },
});

import InlineTextEditor from 'components/admin2/ui/InlineTextEditor';
import { BODY_FONT_FAMILY, TEXT_100, TEXT_200, TITLE_FONT_FAMILY } from 'style/constants';
import styled, { css } from 'styled-components';
import { mobileOnly } from 'style/mixins';
import { IBlockRegion } from 'services/app/models/ILandingPageContent';

export const Title = styled(InlineTextEditor)<{ region: IBlockRegion; }>`
  & .mce-content-body {
    color: ${TEXT_100};

    & * {
      font-size: 3.75rem;
      line-height: 4rem;
      font-family: ${TITLE_FONT_FAMILY}, sans-serif;
      text-align: center !important;

      ${({ region }) => region === 'panel' && css`
        font-size: 1.5rem !important;
        line-height: 1.5rem;
        font-family: ${TITLE_FONT_FAMILY}, sans-serif;
      `}

      ${mobileOnly`
        font-size: 2.5rem !important;
        line-height: 2.75rem;
        font-family: ${TITLE_FONT_FAMILY}, sans-serif;
      `};
    }
  }
`;

export const Description = styled(InlineTextEditor)<{ region: IBlockRegion; }>`
  & .mce-content-body {
    color: ${TEXT_200};

    & * {
      font-size: 1.25rem;
      line-height: 2rem;
      font-family: ${BODY_FONT_FAMILY}, sans-serif;
      text-align: center !important;

      ${({ region }) => region === 'panel' && css`
        font-size: 0.75rem !important;
        line-height: 1rem;
        font-family: ${BODY_FONT_FAMILY}, sans-serif;
      `}

      ${mobileOnly`
        font-size: 1rem !important;
        line-height: 1.5rem;
        font-family: ${BODY_FONT_FAMILY}, sans-serif;
      `};
    }
  }
`;

export const Wrapper = styled.header<{ region: IBlockRegion; }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 60px;

  ${({ region }) => region === 'panel' && css`
    margin-bottom: 20px;
    margin-inline: 6px;
  `};

  ${mobileOnly`
    margin-bottom: 40px;
  `}
`;

import IQuest from 'models/IQuest';
import React from 'react';
import { GalleryWrapper, StyledQuestCard } from './styles';
import { OverflowBehavior } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';
import { QuestEngageMetadata } from 'components/objects/QuestCard';
import EditableArea from 'components/admin2/ui/EditableArea';
import { useRealtimeDocument } from 'hooks';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import { IBlockRegion } from 'services/app/models/ILandingPageContent';

type VisibleQuestsProps = {
  isScrollable: boolean;
  onRemoveQuest: (questId: string) => () => void;
  overflowBehavior: OverflowBehavior;
  questEngage: (extra: QuestEngageMetadata) => void;
  questIds: string[];
  questsPerRow: number;
  region: IBlockRegion;
};

type VisibleQuestProps = {
  onRemoveQuest: (questId: string) => () => void;
  questEngage: (extra: QuestEngageMetadata) => void;
  questId: string;
  region: IBlockRegion;
};

const VisibleQuest = ({ questId, onRemoveQuest, questEngage, region }: VisibleQuestProps) => {
  const isEditing = useSelector(isEditMode);
  const [quest] = useRealtimeDocument<IQuest>('quest', questId);

  if (!quest) {
    return null;
  }

  return (
    <EditableArea
      isEditing={isEditing}
      blockTitleKey="ADMIN_LABEL_QUEST"
      editorActions={[
        {
          confirmationMessageKey: 'ADMIN_PROMPT_CONFIRM_QUESTS_BLOCK_QUEST_CARD_DELETION',
          icon: 'cancel-thin',
          onClick: onRemoveQuest(quest._id!),
          useAbsolutePosition: true,
          tooltipKey: 'ACTION_DELETE',
        },
      ]}
    >
      <StyledQuestCard
        item={quest}
        questEngage={questEngage}
        enableInsights={true}
        region={region}
      />
    </EditableArea>
  );
};

const VisibleQuests = ({ region, questIds, isScrollable, onRemoveQuest, overflowBehavior, questEngage, questsPerRow }: VisibleQuestsProps) => {
  return (
    <GalleryWrapper
      questsPerRow={questsPerRow}
      isScrollable={isScrollable}
      overflowBehavior={overflowBehavior}
    >
      {questIds.map(questId => (
        <VisibleQuest
          region={region}
          key={questId}
          onRemoveQuest={onRemoveQuest}
          questEngage={questEngage}
          questId={questId}
        />
      ))}
    </GalleryWrapper>
  );
};

export default VisibleQuests;

import styled from 'styled-components';
import { ADMIN_TEXT_200, ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_LABEL_L_BOLD, ADMIN_TEXT_LABEL_S_BOLD } from 'style/design-system/textStyles';
import Label from 'components/admin2/ui/Label';
import UploadZone from 'components/admin2/UploadZone';

export const SectionTitle = styled.div`
  ${ADMIN_TEXT_LABEL_L_BOLD};
  color: ${ADMIN_TEXT_100};
  text-transform: capitalize;
`;

export const SubSectionTitle = styled.div`
  ${ADMIN_TEXT_LABEL_S_BOLD};
  color: ${ADMIN_TEXT_200};
  text-transform: capitalize;
`;

export const Section = styled.div<{gap: number}>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap}px;
`;

export const UploadZoneWrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled(Label)`
  margin-bottom: 16px;
`;

export const DesktopBackgroundUploadZone = styled(UploadZone).attrs(() => ({
  canDrop: true,
  dimensionRequirements: {
    pixelHeight: 1080,
    pixelWidth: 1920,
  },
  height: 177,
  padding: '0',
}))``;

export const MobileBackgroundUploadZone = styled(UploadZone).attrs(() => ({
  canDrop: true,
  dimensionRequirements: {
    pixelHeight: 1200,
    pixelWidth: 750,
  },
  height: 177,
  padding: '0',
}))``;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

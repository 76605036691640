import React, { PropsWithChildren } from 'react';
import useEditPageBlock from 'hooks/use-page-block';
import { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';
import { ContentWrapper, EndTimeSwitchLabel, Field, Fields, InfoIcon, Label, StartTimeLabelWrapper, StyledDateTimePicker, StyledEditorWrapper, StyledTimezoneSelect, Toggle } from './styles';
import { useAdminTranslation } from 'hooks/use-translation';
import AdminSwitch from 'components/admin2/ui/ToggleSwitch';
import { ICalendarEvent } from 'models/ICalendarEvent';

interface IDateTimeEditorProps extends PropsWithChildren {
  blockTitleKey: TI18nKey;
  date: ICalendarEvent['date'];
  onChange: (item: ICalendarEvent['date']) => void;
  readOnly: boolean;
}

type EditableStringPaths = 'endTimestamp' | 'startTimestamp' | 'timeZone' | 'useEndTime';

const DateTimeEditor: React.FC<IDateTimeEditorProps> = ({
  date,
  onChange,
  children,
  blockTitleKey,
  readOnly,
}) => {
  const { t } = useAdminTranslation();
  const { handleChange } = useEditPageBlock<EditableStringPaths, ICalendarEvent['date']>({
    item: date,
    onChange,
  });

  const handleChangeTimezone = React.useCallback((result: { timezone: string; }) => {
    handleChange('timeZone')(result.timezone);
  }, [handleChange]);

  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const startTime = React.useMemo(() => {
    if (localTimezone === date.timeZone) {
      return date.startTimestamp;
    }

    const dateInLocalTimezone = new Date(date.startTimestamp);
    const result = new Date(dateInLocalTimezone.toLocaleString('en-US', { timeZone: date.timeZone }));
    return result.getTime();
  }, [date.startTimestamp, date.timeZone, localTimezone]);

  const endTime = React.useMemo(() => {
    if (localTimezone === date.timeZone) {
      return date.endTimestamp;
    }

    const dateInLocalTimezone = new Date(date.endTimestamp);
    const result = new Date(dateInLocalTimezone.toLocaleString('en-US', { timeZone: date.timeZone }));
    return result.getTime();
  }, [date.endTimestamp, date.timeZone, localTimezone]);

  return (
    <StyledEditorWrapper
      readOnly={readOnly}
      blockTitleKey={blockTitleKey}
      modalTitleKey="ADMIN_CALENDAR_BLOCK_CHOOSE_DATE_AND_TIME"
      data-testid="dateEventEditor"
      modalContent={(
        <ContentWrapper>
          <Fields>
            <Field>
              <StartTimeLabelWrapper>
                <Label>{t('ADMIN_LABEL_START_TIME')}</Label>
                <Toggle>
                  <EndTimeSwitchLabel>{t('ADMIN_LABEL_END_TIME')}</EndTimeSwitchLabel>
                  <InfoIcon tooltipKey="ADMIN_CALENDAR_BLOCK_END_TIME_TOOLTIP" />
                  <AdminSwitch
                    checked={date.useEndTime}
                    data-testid="event-end-time-switch"
                    onChange={handleChange('useEndTime')}
                  />
                </Toggle>
              </StartTimeLabelWrapper>
                <StyledDateTimePicker
                  data-testid="calendarEventStartTimeInput"
                  onTimeChange={handleChange('startTimestamp')}
                  timestamp={startTime}
                  timezone={date.timeZone}
                />
            </Field>
            {
              date.useEndTime && (
                <Field>
                  <Label>{t('ADMIN_LABEL_END_TIME')}</Label>
                  <StyledDateTimePicker
                    data-testid="calendarEventEndTimeInput"
                    onTimeChange={handleChange('endTimestamp')}
                    timestamp={endTime}
                    timezone={date.timeZone}
                  />
                </Field>
              )
            }
            <Field>
              <StyledTimezoneSelect
                data-testid="timezoneInput"
                onChange={handleChangeTimezone}
                timezone={date.timeZone}
              />
            </Field>
          </Fields>
        </ContentWrapper>
      )}
    >
      {children}
    </StyledEditorWrapper>
  );
};

export default DateTimeEditor;

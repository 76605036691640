import { OverflowBehavior } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';
import { mobileOnly } from 'style/mixins';
import styled, { css } from 'styled-components';

const SCROLLABLE_CSS = css`
  display: flex;
  & > * {
    min-width: calc(100% - 80px);
  }
`;

const NON_SCROLLABLE_CSS = css<{ isRenderingOnPanel: boolean; overflowBehavior: OverflowBehavior; productsPerRow: number; }>`
  display: grid;
  grid: ${({ overflowBehavior }) => overflowBehavior === 'stack' ? 'auto-flow' : '1fr'} / repeat(${({ productsPerRow }) => productsPerRow}, 1fr);
  grid-auto-rows: min-content;
  & > * {
    min-width: 0;
  }
  @media screen and (min-width: 897px) and (max-width: 1160px) {
    ${({ isRenderingOnPanel, overflowBehavior }) => isRenderingOnPanel && css`
      grid: ${overflowBehavior === 'stack' ? 'auto-flow' : '1fr'} / repeat(1, 1fr);
    `}
  }
`;

export const GalleryWrapper = styled.div<{
  isRenderingOnPanel: boolean,
  isScrollable: boolean;
  overflowBehavior: OverflowBehavior;
  productsPerRow: number;
}>`
  position: relative;
  ${({ overflowBehavior }) => css`
    gap: min(1vw, ${overflowBehavior === 'stack' ? 60 : 40}px);
    ${mobileOnly`
      ${overflowBehavior === 'stack' && 'gap: 40px;'}
      ${overflowBehavior === 'slider' && 'gap: 20px;'}
    `};
  `};
  ${({ isScrollable }) => isScrollable ? SCROLLABLE_CSS : NON_SCROLLABLE_CSS};
`;

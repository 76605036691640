import React from 'react';
import { GameType, IFortniteLeaderboardBlockData, LeaderboardType } from '../../utils';

type PreviousFilters = {
  defaultFilters: IFortniteLeaderboardBlockData['defaultFilters'];
  onChangeGameType: (gameType: GameType) => void;
  onChangeLeaderboardType: (type: LeaderboardType) => void;
  onChangeSeason: (value: string) => void;
  onChangeSession: (value: string) => void;
  onChangeTournament: (value: string) => void;
};

const useDefaultFilters = ({
  defaultFilters,
  onChangeSeason,
  onChangeGameType,
  onChangeLeaderboardType,
  onChangeSession,
  onChangeTournament,
}: PreviousFilters) => {
  const [previous, setPrevious] = React.useState(defaultFilters);

  if (previous.leaderboardType !== defaultFilters.leaderboardType) {
    setPrevious(prev => ({
      ...prev,
      leaderboardType: defaultFilters.leaderboardType,
    }));
    onChangeLeaderboardType(defaultFilters.leaderboardType);
  }

  if (previous.gameType !== defaultFilters.gameType) {
    setPrevious(prev => ({
      ...prev,
      gameType: defaultFilters.gameType,
    }));
    onChangeGameType(defaultFilters.gameType);
  }

  if (previous.season !== defaultFilters.season) {
    setPrevious(prev => ({
      ...prev,
      season: defaultFilters.season,
    }));
    onChangeSeason(defaultFilters.season);
  }

  if (previous.tournament !== defaultFilters.tournament) {
    setPrevious(prev => ({
      ...prev,
      tournament: defaultFilters.tournament,
    }));
    onChangeTournament(defaultFilters.tournament);
  }

  if (previous.session !== defaultFilters.session) {
    setPrevious(prev => ({
      ...prev,
      session: defaultFilters.session,
    }));
    onChangeSession(defaultFilters.session);
  }
};

export default useDefaultFilters;

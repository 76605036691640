/* eslint react/destructuring-assignment: off */
import React from 'react';
import PropTypes from 'prop-types';
import { Emoji } from 'emoji-mart/dist-es';
import Icon from 'components/ui/Icon';
import { SYSTEM_MESSAGE } from 'services/chat/models';
import { getChatMessageKey } from 'services/chat/api';
import { DynamicTranslationType } from 'hooks/use-translation';
import { withEndUserTranslationWithConditionalLocalization } from 'components/objects/Panel/utils';
import {
  ChatBox,
  ChatTextArea,
  Toolbar,
  PostButton,
  EmojiPickerButton,
} from './styles';

export default withEndUserTranslationWithConditionalLocalization(
  class ChatForm extends React.Component {
    static propTypes = {
      androidKeyboardCallback: PropTypes.func.isRequired,
      chatChannel: PropTypes.string.isRequired,
      checkUserName: PropTypes.func,
      clearEmoji: PropTypes.func.isRequired,
      emoji: PropTypes.string,
      emojiPickerOpen: PropTypes.bool.isRequired,
      isChatModerator: PropTypes.bool,
      isCurrentUserAdmin: PropTypes.bool,
      isEditMode: PropTypes.bool.isRequired,
      isLoggedIn: PropTypes.bool.isRequired,
      isMobileLayout: PropTypes.bool.isRequired,
      isPreview: PropTypes.bool,
      onEmojiPickerClick: PropTypes.func.isRequired,
      onEngage: PropTypes.func.isRequired,
      onInvalidMessage: PropTypes.func.isRequired,
      onSendMessage: PropTypes.func.isRequired,
      onSubmit: PropTypes.func,
      panelDoc: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
      previewMessage: PropTypes.string,
    };

    static defaultProps = {
      checkUserName: () => {},
      emoji: null,
      isChatModerator: false,
      isCurrentUserAdmin: false,
      isPreview: false,
      onSubmit: () => {},
      previewMessage: undefined,
    };

    constructor(props) {
      super(props);
      this.textArea = React.createRef();
    }

    state = {
      inputText: '',
      lastMessage: '',
      lastMessageDate: 0,
    };

    componentDidUpdate(prevProps) {
      if (this.props.emoji !== prevProps.emoji) {
        this.addEmoji(this.props.emoji);
      }
    }

    onSubmit = (e) => {
      e.preventDefault();

      const {
        chatChannel,
        isChatModerator,
        isCurrentUserAdmin,
        isLoggedIn,
        onEngage,
        onInvalidMessage,
        onSendMessage,
        panelDoc,
        onSubmit,
      } = this.props;

      const { inputText, lastMessage, lastMessageDate } = this.state;

      const text = inputText.trim().replace(/\s\s+/g, ' ');

      if (!text) {
        return;
      }

      // TODO: Localize these messages using stringkey
      if (!(isCurrentUserAdmin || isChatModerator)) {
        if (text === lastMessage) {
          onInvalidMessage(SYSTEM_MESSAGE.CHAT_DUPLICATE_MESSAGE);
          return;
        }

        if (text.length > 240) {
          onInvalidMessage(SYSTEM_MESSAGE.CHAT_LONG_MESSAGE);
          return;
        }

        if (lastMessageDate + 1000 > Date.now()) {
          onInvalidMessage(SYSTEM_MESSAGE.CHAT_TOO_FAST);
          return;
        }
      }

      const messageKey = getChatMessageKey();

      onSubmit({ key: messageKey, message: text });

      onSendMessage({
        channel: chatChannel,
        key: messageKey,
        text,
      });

      if (isLoggedIn) {
        onEngage(panelDoc, { action: 'send message', message: text });
      }

      this.setState({ inputText: '', lastMessage: text, lastMessageDate: Date.now() });
    };

    onTextChange = (e) => {
      this.setState({ inputText: e.target.value });
    };

    onKeyDown = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        this.onSubmit(e);
      }
    };

    hideKeyboard = () => {
      this.props.androidKeyboardCallback(false);
    };

    showKeyboard = () => {
      this.props.checkUserName();
      this.props.androidKeyboardCallback(true);
    };

    addEmoji = (emoji) => {
      if (emoji) {
        this.setState(prevState => ({ inputText: prevState.inputText + emoji }));
        this.props.clearEmoji();
        this.textArea?.current?.focus();
      }
    };

    render() {
      const {
        emojiPickerOpen,
        isEditMode,
        isMobileLayout,
        onEmojiPickerClick,
        isPreview,
        previewMessage,
        endUserT,
      } = this.props;

      return (
        <ChatBox onSubmit={this.onSubmit}>
          <ChatTextArea
            data-testid="newMessageInput"
            forwardRef={this.textArea}
            onBlur={this.hideKeyboard}
            onChange={this.onTextChange}
            onFocus={this.showKeyboard}
            onKeyDown={this.onKeyDown}
            placeholder={endUserT([DynamicTranslationType.chatTypeMessage], ['TYPE_A_MESSAGE'], isEditMode)}
            type="text"
            value={isPreview ? previewMessage : this.state.inputText}
          />
          <Toolbar>
            {
              isMobileLayout ? '' : (
                <EmojiPickerButton data-testid="addNewEmoji" onClick={onEmojiPickerClick}>
                  {
                    emojiPickerOpen ?
                      <Icon name="down-open-thin" /> :
                      <Emoji emoji="grinning" size={20} />
                  }
                </EmojiPickerButton>
              )
            }
            <PostButton data-testid="chatPostMessage" type="submit">
              {endUserT([DynamicTranslationType.chatPostMessage], ['POST'], isEditMode)}
            </PostButton>
          </Toolbar>
        </ChatBox>
      );
    }
  },
);

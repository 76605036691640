import React, {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSilencedUsers, toggleUserSilence, fetchSilencedUsers } from 'services/chat';
import { useAdminTranslation } from 'hooks/use-translation';
import { getCurrentChannelId } from 'services/app';
import { getStudioSessionReady, inviteGuestToStudio } from 'services/livestream';
import Icon from 'components/ui/Icon';
import fallbackAvatar from 'assets/default-person.svg';
import {
  ButtonWithConfirmation,
  ChatStarIcon,
  ChatStarIconDiv,
  UserAdminOptions,
  UserAvatar,
  UserAvatarWrapper,
  UserName,
} from './styles';

export type UserInfoProps = {
  chatId: string;
  isPrivileged?: boolean;
  profilePictureUrl?: string;
  showModerationOptions?: boolean;
  userId?: string;
  userName: string;
};

export const UserInfo: FC<UserInfoProps> = ({
  userId,
  chatId,
  isPrivileged,
  profilePictureUrl,
  showModerationOptions,
  userName,
}) => {
  const dispatch = useDispatch();

  const { t } = useAdminTranslation();

  const silencedUsers = useSelector(getSilencedUsers);
  const isUserSilenced = useMemo(
    () => silencedUsers.some(
      (user) => user?.userId === userId,
    ),
    [silencedUsers, userId],
  );

  const studioReady = useSelector(getStudioSessionReady);

  const channelId = useSelector(getCurrentChannelId)!;

  const [isDisplayingPopup, setIsDisplayingPopup] = useState(false);

  const canShowPopup = !isPrivileged && showModerationOptions;

  const adminPopupAccessibilityId = useRef('chatPopup-' + makeUniqueId()).current;

  const popupContainerRef = useRef<HTMLDivElement>(null);

  const toggleAdminPopup = useCallback(
    () => {
      setTimeout(
        () => setIsDisplayingPopup((value) => !value),
        100,
      );
    },
    [],
  );

  useEffect(
    () => {
      if (!isDisplayingPopup)
        return;

      const listener = (ev: MouseEvent) => {
        if (!popupContainerRef.current || popupContainerRef.current === ev.target)
          return;

        for (const node of popupContainerRef.current.childNodes) {
          if (node === ev.target)
            return;
        }

        setIsDisplayingPopup(false);
      };

      window.addEventListener('click', listener);

      return () => window.removeEventListener('click', listener);
    },
    [isDisplayingPopup],
  );

  const ariaProps = useMemo(
    () => {
      if (!canShowPopup)
        return {};

      return {
        'aria-controls': adminPopupAccessibilityId,
        'aria-role': 'button',
        onClick: toggleAdminPopup,
      };
    },
    [canShowPopup, toggleAdminPopup],
  );

  const handleSilenceUser = useCallback<MouseEventHandler>(
    (ev) => {
      ev.preventDefault();

      if (!userId) {
        // tslint:disable-next-line:no-console
        console.warn(`Chat message has no 'userId'. Can't silence it.`);
        return;
      }

      dispatch(
        toggleUserSilence({
          channel: chatId,
          isSilenced: !isUserSilenced,
          userId,
          userType: 'account',
        }),
      );
      dispatch(
        fetchSilencedUsers({ channelId }),
      );
    },
    [userId, chatId, isUserSilenced, channelId],
  );

  const handleInviteToStudio = useCallback(
    () => {
      if (!userId)
        return;

      dispatch(
        inviteGuestToStudio(userId),
      );
    },
    [userId],
  );

  const [avatarUrl, onAvatarError] = useImgFallback(profilePictureUrl, fallbackAvatar);

  return (
    <>
      <UserAvatarWrapper
        isPrivileged={Boolean(isPrivileged)}
        {...ariaProps}
      >
        <UserAvatar
          src={avatarUrl}
          alt={userName}
          onError={onAvatarError}
        />
          {
            isPrivileged && (
              <ChatStarIconDiv>
                <ChatStarIcon />
              </ChatStarIconDiv>
            )
          }
      </UserAvatarWrapper>
      <UserName {...ariaProps}>
        {userName}
      </UserName>
      <UserAdminOptions
        id={adminPopupAccessibilityId}
        aria-hidden={!isDisplayingPopup}
        ref={popupContainerRef}
      >
        <div className="avatarNameContainer">
          <UserAvatar
            src={profilePictureUrl}
            alt={userName}
          />
          {userName}
        </div>
        <ButtonWithConfirmation
          className="bgcolor-surface3"
          action={handleSilenceUser}
          messageKey="SILENCE_CHAT_USER_PROMPT"
        >
          <Icon name="userX" />
          {
            isUserSilenced
              ? t('CHAT_UNSILENCE_USER')
              : t('CHAT_SILENCE_USER')
          }
        </ButtonWithConfirmation>
        {studioReady && (
          <ButtonWithConfirmation
            className="bgcolor-accentPrimary"
            action={handleInviteToStudio}
            messageKey="CONFIRM_INVITE_TO_STAGE"
            descriptionTextKey="INVITE_TO_STAGE_EXPLANATION"
          >
            <Icon name="userPlus" />
            {t('STUDIO_INVITE_TO_STAGE')}
          </ButtonWithConfirmation>
        )}
      </UserAdminOptions>
    </>
  );
};

const makeUniqueId = () => Math.random().toString(16).substring(2, 8);

const useImgFallback = (originalImgSrc: string | undefined | null, fallbackImgSrc: string) => {
  const [src, setSrc] = useState(originalImgSrc || fallbackImgSrc);

  const handleImgError = useCallback(
    () => setSrc(fallbackImgSrc),
    [fallbackImgSrc],
  );

  return [src, handleImgError] as const;
};

import React from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { ASPECT_RATIO_LABEL_MAP, ASPECT_RATIO_OPTIONS, AspectRatio, IShopifyBlockData } from '../utils';
import { Section, SectionTitle, SubSectionTitle } from 'components/admin-bridge/EditorModal/ModalComponents/Section';
import {
  ToggleWrapper,
  ShopifyBlockPanelBackgroundColorPickerWrapper,
  BlockPanelSettingsWrapper,
  BlockPanelSettingsTitle,
} from './styles';
import ToggleSwitch from 'components/admin2/ui/ToggleSwitch';
import { Checkbox } from 'components/admin2/ui/Checkbox';
import BackgroundColorPicker from 'components/admin-bridge/EditorModal/ModalComponents/BackgroundColorOptions';
import Dropdown from 'components/admin2/ui/Dropdown';
import TextInput from 'components/admin2/TextInput';
import ShopInfo from './ShopInfo';
import useShopifyBlockSettings from './use-shopify-block-settings';
import { useAdminTranslation } from 'hooks/use-translation';

interface IBlockSettingsProps {
  item: IShopifyBlockData;
  onChange: (item: IShopifyBlockData) => void;
}

const BlockPanelSettings = ({
  item,
  onChange,
}: IBlockSettingsProps) => {
  const { t } = useAdminTranslation();
  const {
    handleChange,
    handleChangeBackgroundColor,
  } = useShopifyBlockSettings(item, onChange);

  return (
    <BlockPanelSettingsWrapper>
      <BlockPanelSettingsTitle>{t('ADMIN_LABEL_SHOPIFY_BLOCK_SETTINGS')}</BlockPanelSettingsTitle>
      <Section gap={30}>
        <ShopInfo
          item={item}
          onChangeCollection={handleChange('shopifyCollectionHandle')}
          onChangeProductView={handleChange('productView')}
        />

        {/* Display */}
        <Section gap={16}>
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_DISPLAY" />
          <Section gap={15}>
            <Checkbox
              checked={item.showProductName}
              labelKey="ADMIN_LABEL_PRODUCT_NAME"
              onCheckedChange={handleChange('showProductName')}
              testId="shopifyProductNameCheckbox"
            />
            <Checkbox
              checked={item.showProductDescription}
              labelKey="ADMIN_LABEL_DESCRIPTION"
              onCheckedChange={handleChange('showProductDescription')}
              testId="shopifyProductDescriptionCheckbox"
            />
            <Checkbox
              checked={item.showProductPrice}
              labelKey="ADMIN_LABEL_PRICE"
              onCheckedChange={handleChange('showProductPrice')}
              testId="shopifyProductPriceCheckbox"
            />
            <Checkbox
              checked={item.showProductCta}
              labelKey="ADMIN_LABEL_CALL_TO_ACTION"
              onCheckedChange={handleChange('showProductCta')}
              testId="shopifyProductCTACheckbox"
            />
          </Section>
        </Section>

        <Section gap={20}>
          <TextInput
            prefixInputIcon="linkOutline"
            padding="0"
            labelKey="ADMIN_LABEL_RETURN_POLICY"
            placeholderKey="ADMIN_LABEL_ENTER_A_URL"
            type="url"
            onChange={handleChange('returnPolicy')}
            value={item.returnPolicy}
            inputTestId="shopifyProductReturnPolicy"
          />

          <TextInput
            prefixInputIcon="linkOutline"
            padding="0"
            labelKey="TERMS_OF_USE"
            placeholderKey="ADMIN_LABEL_ENTER_A_URL"
            type="url"
            onChange={handleChange('termsOfUse')}
            value={item.termsOfUse}
            inputTestId="shopifyProductTermOfUse"
          />
        </Section>

        {/* Aspect Ratios */}
        <Section gap={20}>
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_IMAGE_ASPECT_RATIO" />
          <Section gap={16}>
            <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_DESKTOP" />
            <Dropdown
              onChange={handleChange('aspectRatio.desktop')}
              options={ASPECT_RATIO_OPTIONS}
              value={{ value: item.aspectRatio.desktop, label: ASPECT_RATIO_LABEL_MAP[item.aspectRatio.desktop] }}
              padding="0"
              admin={true}
              isSearchable={false}
            />
          </Section>
          <ToggleWrapper>
            <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_SAME_AS_DESKTOP" />
            <ToggleSwitch checked={item.aspectRatio.mobileEqualDesktop} onChange={handleChange('aspectRatio.mobileEqualDesktop')} />
          </ToggleWrapper>
          {!item.aspectRatio.mobileEqualDesktop && (
            <Section gap={16}>
              <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_MOBILE" />
              <Dropdown
                onChange={handleChange('aspectRatio.mobile')}
                options={ASPECT_RATIO_OPTIONS}
                value={{ value: item.aspectRatio.mobile, label: ASPECT_RATIO_LABEL_MAP[item.aspectRatio.mobile] }}
                padding="0"
                admin={true}
                isSearchable={false}
              />
            </Section>
          )}
        </Section>

        <Section gap={20}>
          {/* Background color */}
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_BACKGROUND_COLOR" />
          <ShopifyBlockPanelBackgroundColorPickerWrapper>
            <BackgroundColorPicker
              lastCustomColor={item.background.customColor}
              color={item.background.color}
              onChange={handleChangeBackgroundColor}
            />
          </ShopifyBlockPanelBackgroundColorPickerWrapper>
        </Section>
      </Section>
    </BlockPanelSettingsWrapper>
  );
};

export default BlockPanelSettings;

import React from 'react';
import { IHeroBanner } from '../../utils';
import { GradientLayer, Image } from './styles';
import { useAdminTranslation } from 'hooks/use-translation';

interface IDesktopImageProps extends Omit<IHeroBanner['background'], 'mobile' | 'href'> {
  hasContentOnHeroBanner: boolean;
  imageHeight: number;
  imageRef: (node: HTMLDivElement | null) => void;
  onError: () => void;
}

const DesktopImage: React.FC<IDesktopImageProps> = ({ onError, bleed, gradientMask, desktop, imageRef, imageHeight, hasContentOnHeroBanner }) => {
  const { t } = useAdminTranslation();
  return (
    <>
      <Image
        onError={onError}
        ref={imageRef}
        bleed={bleed}
        src={desktop}
        alt={t('LABEL_HERO_BANNER')}
        hasContentOnHeroBanner={hasContentOnHeroBanner}
        data-testid="heroBannerImage"
      />
      {gradientMask && <GradientLayer imageHeight={imageHeight} />}
    </>
  );
};

export default DesktopImage;

import React from 'react';
import { ICalendarEvent } from 'models/ICalendarEvent';
import DateTimeEditor from '../DateTimeEditorModal';
import { Time } from './styles';

interface IEventTimeProps {
  date: ICalendarEvent['date'];
  onChange: (item: ICalendarEvent['date']) => void;
  readOnly: boolean;
  when: string;
}

const EventTime: React.FC<IEventTimeProps> = ({ readOnly, date, when, onChange }) => {
  return (
    <DateTimeEditor
      readOnly={readOnly}
      date={date}
      onChange={onChange}
      blockTitleKey="ACCESS_GATE_ADMIN_LABEL_HOUR"
    >
      <Time>{when}</Time>
    </DateTimeEditor>
  );
};

export default EventTime;

import React from 'react';
import { CurrentUserPosition } from './styles';
import { useFortniteLeaderboardContext } from './Context/Context';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';

const LoggedUserPosition = () => {
  const { isRanked, isPanel, loggedUserLeaderboardPosition } = useFortniteLeaderboardContext();
  const isMobile = useSelector(isMobileLayout);
  const short = isPanel || isMobile;

  if (!loggedUserLeaderboardPosition) {
    return null;
  }

  return (
    <table style={{ zIndex: short ? 0 : 1 }}>
      <tbody>
        <CurrentUserPosition isRanked={isRanked} short={short} item={loggedUserLeaderboardPosition} />
      </tbody>
    </table>
  );
};

export default LoggedUserPosition;

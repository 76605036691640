import React, { FC, useMemo } from 'react';
import Linkify from 'react-linkify';
import GraphemeSplitter from 'grapheme-splitter';
import { NimbleEmoji, getEmojiDataFromNative } from 'emoji-mart/dist-es';
import Link from 'components/ui/Link';
import { parseInnerHTML, replaceWords } from 'utils';
import allEmojis from 'emoji-mart/data/all';
import { MessageContent } from './styles';

const splitter = new GraphemeSplitter();

export type MessageBodyProps = {
  allowLinks?: boolean;
  filteredWordsList?: string[];
  html?: string;
  text?: string;
};

export const MessageBody: FC<MessageBodyProps> = ({
  allowLinks,
  filteredWordsList = [],
  html,
  text = '',
}) => {
  const body = useMemo(
    () => {
      if (html) {
        return parseInnerHTML(replaceWords(html, filteredWordsList, '*'));
      }

      const emojis = splitter.splitGraphemes(text);
      const isEmojis = emojis.every(
        (char) => (
          getEmojiDataFromNative(char, 'apple', allEmojis) !== null
          || char === ' '
        ),
      );

      if (isEmojis) {
        return emojis.map(
          (emoji, i) => (
            emoji === ' '
              ? null
              : (
                <NimbleEmoji
                  key={i}
                  data={allEmojis}
                  emoji={getEmojiDataFromNative(emoji, 'apple', allEmojis)}
                  size={15}
                />
              )
          ),
        );
      }

      return replaceWords(text, filteredWordsList, '*');
    },
    [filteredWordsList, html, text],
  );

  const jsx = useMemo(
    () => {
      if (!allowLinks)
        return body;

      return <Linkify component={Link}>{body}</Linkify>;
    },
    [body, allowLinks],
  );

  return <MessageContent>{jsx}</MessageContent>;
};

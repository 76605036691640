import Icon from 'components/ui/Icon';
import { createTextContrastOnBackgroundColor } from 'services/themes/utils';
import { ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_4, ADMIN_SURFACE_5, OPTIONAL_DYNAMIC_THEME_COLOR } from 'style/constants';
import { ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';
import { FLEX_CENTER } from 'style/mixins';
import styled from 'styled-components';
import { css } from 'styled-components';

export const AddNewCardButton = styled.div<{ backgroundColor: string; }>`
  ${FLEX_CENTER}
  flex-direction: column;
  gap: 10px;
  ${ADMIN_TEXT_BODY_S_MEDIUM};
  text-transform: uppercase;
  color: ${ADMIN_ACCENT_PRIMARY};
  border: 1px dashed ${({ backgroundColor }) => backgroundColor === 'surface5' ? ADMIN_SURFACE_4 : ADMIN_SURFACE_5};
  border-radius: 5px;
  cursor: pointer;
  transition: border-color 0.3s, color 0.3s;
  ${({ backgroundColor, ...props }) => {
    const background = OPTIONAL_DYNAMIC_THEME_COLOR(backgroundColor)(props);
    const hoverColor = createTextContrastOnBackgroundColor(background);
    return css`
    &:hover {
      border-color: ${hoverColor};
      color: ${hoverColor};
      & svg path {
        fill: ${hoverColor};
      }
    }
    `;
  }}
`;

export const PlusIcon = styled(Icon).attrs({
  name: 'plus',
})`
  & svg path {
    transition: fill 0.3s;
    fill: ${ADMIN_ACCENT_PRIMARY};
  }
`;

import React, { useRef } from 'react';
import useFortniteLeaderboard from 'hooks/use-fortnite-leaderboard';
import { useFortniteLeaderboardContext } from './Context/Context';
import TableBodyRow from './TableBodyRow';
import { DEFAULT_LEADERBOARD_ITEMS } from '../utils';
import useInfiniteScroll from 'hooks/use-infinite-scroll';
import { IFortniteLeaderboardItem } from 'models/IFortniteLeaderboard';
import { NoResults, StyledPageNavigation, StyledTableBody } from './styles';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';
import useDeferredValue from 'hooks/use-deferred-value';
import useSwipeable from 'hooks/use-swipeable';
import { useAdminTranslation } from 'hooks/use-translation';

const TableBody: React.FC = () => {
  const ref = useRef(null);
  const isMobile = useSelector(isMobileLayout);
  const { t } = useAdminTranslation();

  const { eventId, eventWindowId, isPanel, isRanked, query, blockWrapperRef } = useFortniteLeaderboardContext();

  const deferredQuery = useDeferredValue(query, 300);

  const [leaderboard] = useFortniteLeaderboard({
    eventId,
    eventWindowId,
  });

  const short = isPanel || isMobile;
  const isPaginated = !isPanel && isMobile;
  const ITEMS_LENGTH = isPaginated ? 10 : 20;

  const [page, setPage] = React.useState(1);
  const [items, setItems] = React.useState<IFortniteLeaderboardItem[]>([]);
  const [prevEventId, setPrevEventId] = React.useState('');
  const [prevEventWindowId, setPrevEventWindowId] = React.useState('');

  const parsedItems = deferredQuery ? (leaderboard?.items || []).filter(item => item.players.some(p => p.displayName.toLocaleLowerCase().includes(deferredQuery.toLocaleLowerCase()))) : items;

  if (((prevEventWindowId !== eventWindowId) || (isRanked && eventId !== prevEventId)) && leaderboard?.items?.length) {
    setItems(leaderboard.items.slice(0, ITEMS_LENGTH));
    setPrevEventWindowId(eventWindowId);

    if (isRanked) {
      setPrevEventId(eventId);
    }

    if (page !== 1) {
      setPage(1);
    }
  }

  if ((!eventWindowId || (isRanked && !eventId)) && parsedItems.length) {
    setItems([]);
    setPrevEventWindowId('');
    setPrevEventId('');

    if (page !== 1) {
      setPage(1);
    }
  }

  const totalPages = Math.ceil((leaderboard?.items?.length || 0) / ITEMS_LENGTH);

  const onNextPage = () => {
    if (page + 1 > totalPages) {
      return;
    }

    if (!isPaginated) {
      return;
    }

    const newItems = leaderboard?.items || [];
    setPage(prev => prev + 1);
    setItems(newItems.slice(ITEMS_LENGTH * page, ITEMS_LENGTH * (page + 1)));
  };

  const onPreviousPage = () => {
    if (page - 1 <= 0) {
      return;
    }

    if (!isPaginated) {
      return;
    }

    const newItems = leaderboard?.items || [];
    setPage(prev => prev - 1);
    setItems(newItems.slice(ITEMS_LENGTH * (page - 2), ITEMS_LENGTH * (page - 1)));
  };

  const goToPage = (index: number) => {
    const newItems = leaderboard?.items || [];
    setPage(index + 1);
    setItems(newItems.slice(ITEMS_LENGTH * index, ITEMS_LENGTH * (index + 1)));
  };

  const addMoreItems = () => {
    const newItems = leaderboard?.items || [];

    if (ITEMS_LENGTH * page + ITEMS_LENGTH > newItems.length) {
      return;
    }

    setPage(prev => prev + 1);
    setItems(newItems.slice(0, ITEMS_LENGTH * page + ITEMS_LENGTH));
  };

  useInfiniteScroll({
    refElement: isPanel ? blockWrapperRef : ref,
    onReachBottom: addMoreItems,
    offsetPx: 200,
  });

  const handlers = useSwipeable<HTMLTableSectionElement>({
    onSwipedLeft: onNextPage,
    onSwipedRight: onPreviousPage,
  });

  if (!parsedItems.length && deferredQuery) {
    return (
      <NoResults>
        {t('NO_RESULTS_FOR_X', { x: deferredQuery })}
      </NoResults>
    );
  }

  return (
    <>
      <StyledTableBody ref={ref} {...handlers} short={short}>
        {(!parsedItems.length ? DEFAULT_LEADERBOARD_ITEMS : parsedItems).map(item => <TableBodyRow key={item.teamId ? item.teamId : Math.random().toString()} item={item} />)}
      </StyledTableBody>
      {
        isPaginated && !deferredQuery && (
        <StyledPageNavigation
          admin={false}
          onChange={goToPage}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
          page={page - 1}
          pages={totalPages}
        />
      )}
    </>
  );
};

export default TableBody;

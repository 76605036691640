import styled, { css } from 'styled-components';
import { ACCENT_PRIMARY, OPTIONAL_DYNAMIC_THEME_COLOR, SURFACE_4, SURFACE_6, TEXT_100, TEXT_300, TEXT_400 } from 'style/constants';
import { TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';
import { rgba } from 'colors';
import { desktopHover, desktopOnly, mobileOnly } from 'style/mixins';
import Icon from 'components/ui/Icon';

export const CALENDAR_MAX_HEIGHT = 237;

export const Wrapper = styled.div<{ isMobileCalendarOpen: boolean; }>`
  display: flex;
  flex-direction: column;
  padding: 2px 10px 10px 10px;
  border-top: 1px solid ${SURFACE_4};
  ${desktopOnly`max-height: ${CALENDAR_MAX_HEIGHT}px;`};

  ${mobileOnly`
    overflow: hidden;
    height: ${({ isMobileCalendarOpen }) => isMobileCalendarOpen ? `${CALENDAR_MAX_HEIGHT}px;` : '0'};
    transition: height 0.3s ease-in-out;
  `};
`;

export const WeekDaysGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(1, 1fr);
`;

export const CalendarGrid = styled.div`
  display: grid;
  grid-template-rows: repeat(6, 1fr);
`;


export const WeekTextWrapper = styled.div`
  display: flex;
  padding: 6px;
  height: 32px;
  align-items: center;
  justify-content: center;
`;

export const WeekText = styled.label`
  ${TEXT_LABEL_S_MEDIUM}
  color: ${TEXT_300};
`;

export const CalendarWeek = styled.div`
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  contain: content;
`;

export const CalendarDay = styled.div<{
  isCurrentMonth: boolean;
  isSelectedDay: boolean;
  isSelectedDayInWeek: boolean;
  isToday: boolean;
  isWeekend: boolean;
}>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 6px;
  height: 32px;
  align-items: center;
  justify-content: center;
  position: relative;
  ${TEXT_LABEL_S_MEDIUM}
  color: ${TEXT_100};
  ${({ isWeekend }) => isWeekend && css`color: ${TEXT_300}`};
  ${({ isCurrentMonth }) => !isCurrentMonth && css`color: ${TEXT_400}`};
  ${({ isSelectedDayInWeek, ...props }) => isSelectedDayInWeek && css`background-color: ${rgba(ACCENT_PRIMARY(props), 0.2)}`};
  ${({ isToday }) => isToday && css`color: ${ACCENT_PRIMARY};`};
  ${({ isSelectedDay }) => isSelectedDay && css`
    color: ${TEXT_100};
    background-color: ${ACCENT_PRIMARY};
    border-radius: 4px;
  `};

  ${desktopHover`
    border-radius: 4px;
    border: 1px solid ${ACCENT_PRIMARY};
  `};
`;

export const CalendarDayEventDots = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const CalendarDayEventDot = styled.div<{ firstLabelColor: string; }>`
  width: 4px;
  height: 4px;
  border-radius: 1px;
  background-color: ${props => OPTIONAL_DYNAMIC_THEME_COLOR(props.firstLabelColor) || SURFACE_6};
`;

export const PlusIcon = styled(Icon).attrs({
  name: 'plus-thin',
})`
  width: 4px;
  height: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 4px;
    height: 4px;
    & path {
      fill: ${TEXT_100};
    }
  }
`;

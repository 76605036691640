import Icon from 'components/ui/Icon';
import { ACCENT_PRIMARY, TEXT_200 } from 'style/constants';
import { TEXT_LABEL_M_BOLD } from 'style/design-system/textStyles';
import { desktopOnly, mobileOnly } from 'style/mixins';
import styled from 'styled-components';

export const HEADER_HEIGHT = 56;

export const Wrapper = styled.header`
  display: flex;
  height: ${HEADER_HEIGHT}px;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
`;

export const HeaderText = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin: 0;
  ${TEXT_LABEL_M_BOLD}
  color: ${TEXT_200};
`;

export const Arrows = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  ${mobileOnly`display: none;`};
`;


export const MobileCollapsibleIcon = styled(Icon)<{ isOpen: boolean; }>`
  ${desktopOnly`display: none;`};
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  transition: transform 0.2s;
  transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0deg')});

  & svg {
    width: 10px;
    height: fit-content;
    & path {
      fill: ${ACCENT_PRIMARY};
    }
  }
  cursor: pointer;
`;

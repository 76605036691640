import React from 'react';
import { useSelector } from 'react-redux';
import { IHeroBannerItem } from './utils';
import { isEditMode } from 'services/admin';
import { DynamicTranslationType, useEndUserInlineTextEditorTranslation, useEndUserTranslation } from 'hooks/use-translation';
import { getPageId } from 'services/app';
import { getIsRichTextEditorV3Enabled } from 'services/feature-gate/selectors';

interface IUseHeroBannerLocalizeStringsResponse {
  description: string;
  primaryCtaText: string;
  secondaryCtaText: string;
  title: string;
}

const useHeroBannerLocalizeStrings = (heroBannerItem: IHeroBannerItem): IUseHeroBannerLocalizeStringsResponse => {
  const isEditing = useSelector(isEditMode);
  const pageId = useSelector(getPageId);
  const { inlineTextEditorEndUserT } = useEndUserInlineTextEditorTranslation(isEditing);
  const { endUserT } = useEndUserTranslation();
  const isRichTextEditorV3Enabled = useSelector(getIsRichTextEditorV3Enabled);

  const title = isRichTextEditorV3Enabled && heroBannerItem.data.title.rawDataV2 ? heroBannerItem.data.title.rawDataV2 : heroBannerItem.data.title.rawData;
  const description = isRichTextEditorV3Enabled && heroBannerItem.data.description.rawDataV2 ? heroBannerItem.data.description.rawDataV2 : heroBannerItem.data.description.rawData;

  const result = React.useMemo(() => {
    if (isEditing) {
      return {
        title,
        description,
        primaryCtaText: heroBannerItem.data.primaryCta.text,
        secondaryCtaText: heroBannerItem.data.secondaryCta.text,
      };
    }

    const baseParams = {
      blockId: heroBannerItem.arrayId,
      pageId,
    };

    return {
      title: inlineTextEditorEndUserT(DynamicTranslationType.heroBannerTitle, title, baseParams),
      description: inlineTextEditorEndUserT(DynamicTranslationType.heroBannerDescription, description, baseParams),
      secondaryCtaText: endUserT([DynamicTranslationType.heroBannerSecondaryCta, baseParams]) || heroBannerItem.data.secondaryCta.text,
      primaryCtaText: endUserT([DynamicTranslationType.heroBannerPrimaryCta, baseParams]) || heroBannerItem.data.primaryCta.text,
    };
  }, [heroBannerItem, isEditing, pageId, endUserT]);

  return result;
};

export default useHeroBannerLocalizeStrings;

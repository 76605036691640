import { rgba } from 'colors';
import { ACCENT_PRIMARY, SURFACE_2, SURFACE_3, SURFACE_4, TEXT_100, TEXT_300 } from 'style/constants';
import { TEXT_LABEL_M_BOLD, TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';
import styled from 'styled-components';
import { css } from 'styled-components';
import Button from 'components/admin2/ui/Button';

export const DayColumnWrapper = styled.div<{ isSelectedDate: boolean; isToday: boolean; }>`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;

  ${({ isSelectedDate }) => isSelectedDate && css`
    border-left: 1px solid ${ACCENT_PRIMARY};
    border-right: 1px solid ${ACCENT_PRIMARY};

    & ${DayHeader} {
      background-color: ${SURFACE_4};
    }

    & ${DayEventsWrapper} {
      background-color: ${SURFACE_3};
    }
  `};

  ${({ isToday, isSelectedDate }) => isToday && isSelectedDate && css`
    & ${DayHeader} {
      background-color: ${props => rgba(ACCENT_PRIMARY(props), 0.2)};
    }
  `};
`;

export const DayHeader = styled.div`
  display: flex;
  padding: 10px 10px 12px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background-color: ${SURFACE_3};
  color: ${TEXT_100};
  ${TEXT_LABEL_M_BOLD}
`;

export const Day = styled.span`
  color: ${TEXT_300};
`;

export const CreateEventButton = styled(Button)`
  display: none;
  min-height: 44px;
  padding: 8px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px dashed ${TEXT_300};
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }

  ${TEXT_LABEL_S_MEDIUM}
  color: ${TEXT_300} !important;
  width: 100%;
`;


export const DayEventsWrapper = styled.div`
  display: flex;
  padding: 10px 6px 16px 6px;
  background-color: ${SURFACE_2};
  flex-direction: column;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  align-self: stretch;
  overflow-y: auto;
  overflow-x: hidden;

  &:hover {
    & ${CreateEventButton} {
      display: flex;
    }
  }
`;

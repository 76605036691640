import styled from 'styled-components';
import Linkify from 'react-linkify';
import { bw, rgba } from 'colors';

import { TWITTER_THEME, LINK_MIXIN, TEXT_LABEL3 } from 'style/mixins';
import CloseButton from 'components/ui/CloseButton';
import Icon from 'components/ui/Icon';
import { ACCENT_PRIMARY, SURFACE_3, SURFACE_2 } from 'style/constants';

export const Container = styled.div`
  background-color: ${props => rgba(SURFACE_3(props), 0.9)};
  display: flex;
  ${TEXT_LABEL3}
`;

export const WithImageContainer = styled(Container)`
  background-image: ${props => `url("${props.url}")`};
  background-position: center;
  background-size: cover;
  height: 200px;
  position: relative;
  width: 500px;
`;

export const CloseButtonContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  font-size: 18px;
  justify-content: center;
  width: 45px;
`;

export const WithImageCloseButtonContainer = styled.div`
  background-color: ${SURFACE_2};
  cursor: pointer;
  padding-top: 5px;
  text-align: center;
  width: 35px;
`;

export const StyledCloseButton = styled(CloseButton)`
  color: ${props => rgba(bw(SURFACE_3(props)), 0.5)};
`;

export const WithImageStyledCloseButton = styled(StyledCloseButton)`
  color: ${props => rgba(bw(SURFACE_2(props)), 0.5)};
`;

export const TweetContainer = styled.div`
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
`;

export const WithImageTweetContainer = styled(TweetContainer)`
  background-color: ${props => rgba(SURFACE_3(props), 0.7)};
  color: ${props => bw(SURFACE_2(props))};
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 35px;
`;

export const Username = styled.span`
  padding-right: 5px;
`;

export const StyledTwitterIcon = styled(Icon).attrs({
  name: 'twitter',
})`
  color: ${ACCENT_PRIMARY};
`;

export const RetweetButton = styled.div`
  text-transform: uppercase;
  font-size: 18px;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  ${TWITTER_THEME}
`;

export const StyledLinkify = styled(Linkify).attrs({
  properties: {
    target: '_blank',
  },
})`
  white-space: pre-wrap;
  & > a {
    ${LINK_MIXIN}
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

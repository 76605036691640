import shortid from 'shortid';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';

export const dateString = { day: 'numeric', month: 'long', year: 'numeric' };
export const dateWithTimezone = { day: 'numeric', month: 'long', timeZoneName: 'short', year: 'numeric' };

export const scheduleItemToEvent = (item, groupByDate) => ({
  arrayId: shortid.generate(),
  id: item.video?._id || item.playlist?._id,
  kind: 'event',
  name: item.name || item.video?.data?.name || item.playlist?.data?.name || 'Video',
  showStartTime: Boolean(groupByDate),
  startTime: item.startTime,
});

// expand today, next group after today, or first group as fallback
const getGroupCollapsedByDefault = (groupDate, groupedEvents) => {
  const groupDateString = new Date(groupDate).toLocaleDateString([], dateString);
  const allDates = Object.keys(groupedEvents);
  const todaysDate = new Date().toLocaleDateString([], dateString);
  const groupForToday = allDates.find(d => d === todaysDate);
  const nextGroup = allDates.find(datestring => new Date(datestring).getTime() > Date.now());
  const isToday = groupDateString === todaysDate;
  const isFirst = groupDate === allDates[0];
  const isNext = groupDate === nextGroup;

  if (isToday) {
    return false;
  }
  if (!groupForToday) {
    if (nextGroup && isNext) {
      return false;
    }
    if (!nextGroup && isFirst) {
      return false;
    }
  }
  return true;
};

// move single event reference to array and standardize id field
export const transformEventReferences = (ev) => {
  if (isEmpty(ev.reference)) {
    return ev;
  }
  const event = { ...ev };
  const { reference = {}, references } = event;
  const referenceId = reference.personId || reference.groupId;
  if (reference.type && referenceId && !references) {
    delete event.reference;
    return {
      ...event,
      references: [{ id: referenceId, type: reference.type }],
    };
  }
  return event;
};

const showStartTimeOnEvent = showStartTime => event => ({
  ...event,
  kind: 'event',
  showStartTime,
});

const groupEventsByDate = (events, showStartTime = false) => {
  const groupedEvents = groupBy(
    events,
    ev => new Date(ev.startTime).toDateString(),
  );

  return Object.entries(groupedEvents).reduce((groupList, [groupDate, list]) => {
    const collapseByDefault = getGroupCollapsedByDefault(groupDate, groupedEvents);
    const eventList = list
      .map(transformEventReferences)
      .map(showStartTimeOnEvent(showStartTime));

    return [
      ...groupList,
      {
        arrayId: shortid.generate(),
        collapseByDefault,
        date: new Date(groupDate).toLocaleDateString([], dateWithTimezone),
        events: eventList,
        groupDate,
        kind: 'group',
      },
    ];
  }, []);
};

const buildChannelSchedule = (events, groupByDate) => {
  const oneMonthInMs = 4 * 7 * 24 * 60 * 60 * 1000;
  const now = Date.now();
  const oneMonthFromNow = now + oneMonthInMs;

  const getDailyEventsForPeriod = (event, period) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const dailyEvents = [];
    const cutoff = new Date().getTime() + period;
    let startTime = new Date(event.startTime).getTime();

    while (startTime < cutoff) {
      if (startTime > now) {
        dailyEvents.push(
          scheduleItemToEvent({
            ...event,
            startTime,
          }, groupByDate),
        );
      }
      startTime += oneDay;
    }
    return dailyEvents;
  };

  const getWeeklyEventsForPeriod = (event, period) => {
    const weeklyEvents = [];
    const oneWeek = 7 * 24 * 60 * 60 * 1000;
    const cutoff = new Date().getTime() + period;
    let nextStart = new Date(event.startTime).getTime();

    while (nextStart < cutoff) {
      if (nextStart > now) {
        weeklyEvents.push(scheduleItemToEvent({
          ...event,
          startTime: nextStart,
        }, groupByDate));
      }
      nextStart += oneWeek;
    }
    return weeklyEvents;
  };

  return events.reduce((list, event) => {
    switch (event.repeat) {
      case 'daily': return list.concat(getDailyEventsForPeriod(event, oneMonthInMs));
      case 'weekly': return list.concat(getWeeklyEventsForPeriod(event, oneMonthInMs));
      case 'none': {
        const eventStart = new Date(event.startTime).getTime();
        if (eventStart > now && eventStart < oneMonthFromNow) {
          return [...list, scheduleItemToEvent(event, groupByDate)];
        }
        break;
      }
      default:
        return list;
    }
    return list;
  }, []);
};

export const formatChannelEvents = (channels = {}, groupByDate) => {
  const allEvents = Object.values(channels).reduce((acc, channelDoc) => {
    const channelEvents = channelDoc.data?.content?.live?.schedule;
    return channelEvents ?
      acc.concat(buildChannelSchedule(channelEvents, groupByDate)) :
      acc;
  }, []);
  const sortedEvents = orderBy(allEvents, ev => new Date(ev.startTime).getTime(), ['asc']);

  return groupByDate ?
    { channels, sortedEvents: groupEventsByDate(sortedEvents, true) } :
    { channels, sortedEvents };
};

export const formatManualEvents = (allEvents, groupByDate) => {
  const sortedEvents = orderBy(allEvents, 'startTime');
  return groupByDate ?
    groupEventsByDate(sortedEvents, true) :
    sortedEvents
      .map(transformEventReferences)
      .map(showStartTimeOnEvent(false));
};

import React, { useCallback, useState } from 'react';
import RealtimeDocument from 'components/core/RealtimeDocument';
import { FORTNITE_SITE_ID } from 'config';
import epicDefaultImg from 'assets/epic-championship-series.png';
import defaultImg from 'assets/default-person.svg';
import InsightsViewable from 'components/insights/InsightsViewable';
import { PEOPLE_ID } from 'components/objects/PanelV2/constants';
import styled from 'styled-components';
import { offset } from 'colors';
import { SURFACE_3, SURFACE_1 } from 'style/constants';
import Icon from 'components/ui/Icon';
import {
  TEXT_H5,
  LINE_WRAP,
  BREAK_WORD,
  BACKGROUND_ACTIVE,
  PASSIVE_TEXT,
} from 'style/mixins';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import KeyedListMapper from 'components/core/KeyedListMapper';
import TranslatedText from 'components/i18n/TranslatedText';
import { TranslationKey } from 'hooks/use-translation';
import { useDispatch, useSelector } from 'react-redux';
import { getSiteId } from 'services/app/selectors';
import { trackPanel } from 'services/insights/actions';
import { DynamicTranslationType, useEndUserTranslation } from 'hooks/use-translation';
import Link from 'components/ui/Link';

export interface IPerson {
  id: string;
  personBio: string;
  personImageUrl: string;
  personLinks: {
    generatedKey: string;
    linkIconName: string;
    linkText: string;
    linkUrl: string;
  }[];
  personName: string;
  personStats: {
    labelKey: TranslationKey;
    value: any;
  }[];
  personTitle: string;
}

interface IRenderer {
  panelType: typeof PEOPLE_ID;
  personId: string;
}

interface IPersonPanelFromRenderer {
  hidden: boolean;
  renderer: IRenderer;
}

const PersonPanelFromRenderer: React.FC<IPersonPanelFromRenderer> = ({
  hidden,
  renderer,
}) => {
  const dispatch = useDispatch();
  const siteId = useSelector(getSiteId);
  const { endUserT } = useEndUserTranslation();

  const { personId } = renderer;
  const [rtDoc, setRtDoc] = useState<{ renderer: IPerson } | null>(null);
  const [loading, setLoading] = useState(true);

  const defaultPersonImage =
    siteId === FORTNITE_SITE_ID ? epicDefaultImg : defaultImg;

  const personDoc = rtDoc?.renderer;

  const handleLoading = useCallback((doc, loaded) => {
    setLoading(!loaded);
    setRtDoc(doc || {});
  }, []);

  const onEngage = useCallback((doc: any, extra: any) => {
    dispatch(trackPanel('engage', doc, extra));
  }, []);

  if (!personId) {
    return null;
  }

  const renderStats = (personStats: IPerson['personStats']) => {
    return (
      <StatsSection>
        <KeyedListMapper keyField="labelKey" list={personStats}>
          {(key, { labelKey, value }) => (
            <Stat key={key}>
              <StatLabel>
                <TranslatedText stringKey={labelKey} />
              </StatLabel>
              <StatValue>{value}</StatValue>
            </Stat>
          )}
        </KeyedListMapper>
      </StatsSection>
    );
  };

  return (
    <RealtimeDocument
      collection="objects"
      id={personId}
      onValue={handleLoading}
    >
      {rtDoc?.renderer && (
        <InsightsViewable
          doc={{ renderer: rtDoc?.renderer }}
          extra={{
            overrides: {
              class: 'person',
            },
            person: personId,
          }}
          kingdom="panel"
          visible={!hidden}
        />
      )}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <PersonPanelContainer>
          <ImageTitleContainer
            data-testid="personPanelCtaImage"
            src={personDoc?.personImageUrl || defaultPersonImage}
          >
            {personDoc && Boolean(personDoc?.personName) && (
              <Name data-testid="personNameTitle">
                <span>
                  {endUserT(
                    [
                      DynamicTranslationType.personName,
                      { personId: personDoc.id },
                    ],
                    ) || personDoc?.personName
                  }
                </span>
              </Name>
            )}
          </ImageTitleContainer>
          {personDoc && personDoc.personLinks?.length > 0 && (
            <LinksWrapper>
              <KeyedListMapper keyField="linkUrl" list={personDoc.personLinks}>
                {(key, { linkIconName, linkUrl }) =>
                  linkIconName ? (
                    <LinkBox
                      key={key}
                      href={linkUrl}
                      // tslint:disable-next-line: jsx-no-lambda
                      onClick={() =>
                        onEngage(
                          { renderer },
                          {
                            action: 'click link',
                            linkUrl,
                            overrides: { class: 'person' },
                          },
                        )
                      }
                    >
                      <Icon name={linkIconName} />
                    </LinkBox>
                  ) : null
                }
              </KeyedListMapper>
            </LinksWrapper>
          )}
          {personDoc && Boolean(personDoc?.personBio) && (
            <Bio data-testid="personNameDescription">
              {endUserT(
                [
                  DynamicTranslationType.personBio,
                  { personId: personDoc.id },
                ],
                ) || personDoc.personBio
              }
            </Bio>
          )}
          {personDoc && Array.isArray(personDoc?.personStats) && renderStats(personDoc.personStats)}
        </PersonPanelContainer>
      )}
    </RealtimeDocument>
  );
};

export default PersonPanelFromRenderer;

export const PersonPanelContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  pointer-events: all;
`;

export const ImageTitleContainer = styled.div<{ src: string }>`
  position: relative;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 100%;
  padding-bottom: 100%;
`;

export const Name = styled.div`
  ${TEXT_H5}
  position: absolute;
  bottom: 20px;
  padding: 10px 16px;
  text-transform: uppercase;
  background-color: ${SURFACE_3};
`;

export const Bio = styled.div`
  ${TEXT_H5}
  padding: 8px;
  border-bottom: 1px solid ${(props) => offset(SURFACE_1(props), -100)};
  overflow: visible !important;
  ${LINE_WRAP}
  ${BREAK_WORD}
`;

export const LinkBox = styled(Link)`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${BACKGROUND_ACTIVE}
  margin: 10px 10px 0 0;
`;

export const LinksWrapper = styled.div`
  padding: 0 0 10px 10px;
  display: flex;
  flex-shrink: 0;
  flex-flow: row wrap;
  align-items: center;
  border-bottom: 1px solid ${(props) => offset(SURFACE_1(props), -100)};
`;

const StatsSection = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-left: 16px;
  padding-right: 30px;
`;

const Stat = styled.div`
  width: 100px;
  margin: 14px 30px 14px 2px;
`;

const StatLabel = styled.div`
  ${TEXT_H5}
  ${PASSIVE_TEXT};
  text-transform: uppercase;
  max-width: 90px;
  margin-bottom: 5px;
`;

const StatValue = styled.div`
  font-size: 24px;
  line-height: 29px;
`;

import { INVISIBLE_CHARACTER } from 'components/ui/RichTextEditorV2/use-rich-text-editor';
import { getDefaultEditorState, getDefaultTextNode } from 'components/ui/RichTextEditorV3/getDefaultEditorState';
import { isLightColor } from 'services/themes/utils';
import { MAESTRO_BLACK, MAESTRO_WHITE } from 'style/constants';
import { getDefaultRichTextEditorData } from 'utils';

export interface IRichText {
  background: {
    color: string;
    customColor: string;
  }
  contentRaw: string;
  contentRawV2?: string;
  coverImage: string;
  header: {
    color: string;
    customColor: string;
  }
}

export const getDefaultRichTextBlock = (surface1: string): IRichText => {
  const color = isLightColor(surface1) ? MAESTRO_BLACK : MAESTRO_WHITE;

  return {
    background: {
      color: 'surface1',
      customColor: MAESTRO_BLACK,
    },
    coverImage: '',
    header: {
      color: 'surface4',
      customColor: MAESTRO_BLACK,
    },
    contentRaw: getDefaultRichTextEditorData({ text: INVISIBLE_CHARACTER, color }),
    contentRawV2: getDefaultEditorState([
      getDefaultTextNode({
        text: '',
      }),
    ]),
  };
};

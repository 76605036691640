import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BACKGROUND_ACTIVE, TITLE_FONT } from 'style/mixins';
import { withEndUserT, DynamicTranslationType } from 'hooks/use-translation';

const ClickableContainer = styled.div`
  cursor: pointer;
`;

const FlexWrapper = styled.div`
  ${BACKGROUND_ACTIVE}
  display: flex;
  flex-grow: 1;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 5px;
  ${TITLE_FONT}
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
`;

class LoginOverlayCta extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    endUserT: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    onDismiss: PropTypes.func.isRequired,
    onEngagedOverlay: PropTypes.func.isRequired,
    onLogInClick: PropTypes.func.isRequired,
    testId: PropTypes.string,
  };

  static defaultProps = {
    children: undefined,
    testId: undefined,
  };

  onLogInClick = () => {
    const {
      loggedIn,
      onDismiss,
      onEngagedOverlay,
      onLogInClick,
    } = this.props;

    if (!loggedIn) {
      onLogInClick();
    }

    onEngagedOverlay();
    onDismiss();
  };

  render() {
    const { children, endUserT, testId } = this.props;

    return (
      <ClickableContainer data-testid={testId} onClick={this.onLogInClick}>
        {
          children || (
            <FlexWrapper>
              {
                endUserT(
                  [DynamicTranslationType.overlayCtaLogin],
                  ['ACTION_LOG_IN'],
                )
              }
            </FlexWrapper>
          )
        }
      </ClickableContainer>
    );
  }
}

export default withEndUserT(LoginOverlayCta);

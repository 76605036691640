import React from 'react';
import { EventsWrapper, Quantity, ShowAllButton, Title, Wrapper } from './styles';
import FullEventCard from '../../EventCard/FullEventCard';
import { useEventCalendarContext } from '../../EventCalendarContext';
import { useAdminTranslation } from 'hooks/use-translation';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';
import { ICalendarEvent } from 'models/ICalendarEvent';
import useEventCalendarApi from '../../use-event-calendar-api';

interface ISelectedDateEventsProps {
  onMobileSelectEvent: (event: ICalendarEvent) => void;
}

const MINIMUM_EVENTS_TO_SHOW = 3;

const SelectedDateEvents: React.FC<ISelectedDateEventsProps> = ({ onMobileSelectEvent }) => {
  const [events, setEvents] = React.useState<ICalendarEvent[]>([]);
  const [showAllEvents, setShowAllEvents] = React.useState(false);
  const { t } = useAdminTranslation();
  const { filterEvents, selectedDate, selectedSiteCategories } = useEventCalendarContext();
  const { getEventsBetweenTimestamp } = useEventCalendarApi();
  const isMobile = useSelector(isMobileLayout);

  React.useEffect(() => {
    const date = new Date(selectedDate.year, selectedDate.month, selectedDate.day);
    const startTimestamp = new Date(date.setHours(0, 0, 0, 0)).getTime();
    const endTimestamp = new Date(date.setHours(23, 59, 59, 999)).getTime();

    getEventsBetweenTimestamp(startTimestamp, endTimestamp).then(setEvents);
  }, [selectedDate]);

  React.useEffect(() => {
    setShowAllEvents(false);
  }, [selectedDate]);

  const filteredEvents = React.useMemo(() => {
    if (!isMobile) {
      return events;
    }

    return filterEvents(events, selectedSiteCategories);
  }, [events, filterEvents, selectedSiteCategories, isMobile]);

  const visibleEvents = React.useMemo(() => {
    // we only crop events on mobile
    if (showAllEvents || !isMobile) {
      return filteredEvents;
    }

    return filteredEvents.slice(0, MINIMUM_EVENTS_TO_SHOW);
  }, [filteredEvents, showAllEvents, isMobile]);

  const handleSelectEvent = (event: ICalendarEvent) => () => {
    if (!isMobile) {
      return;
    }
    onMobileSelectEvent(event);
  };

  const renderTitle = React.useCallback(() => {
    const today = new Date();
    const date = new Date(selectedDate.year, selectedDate.month, selectedDate.day);
    const isToday = today.getDate() === date.getDate() && today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear();

    if (isToday) {
      return t('ADMIN_LABEL_TODAYS_UPCOMING_EVENTS');
    }

    const weekDay = date.toLocaleString('en-us', { weekday: 'long' });
    return t('ADMIN_LABEL_DAY_UPCOMING_EVENTS', {
      weekDay,
      date: date.getDate(),
    });
  }, [selectedDate, t]);

  const handleShowEvents = React.useCallback(() => {
    setShowAllEvents(true);
  }, []);

  return (
    <Wrapper>
      <Title>
        {renderTitle()}
        <Quantity>({filteredEvents.length})</Quantity>
      </Title>
      <EventsWrapper>
        {visibleEvents.map(event => (
          <FullEventCard
            readOnly={true}
            onClick={handleSelectEvent(event)}
            key={event._id}
            event={event}
            showActionsMenu={false}
          />
        ))}
        {visibleEvents.length === MINIMUM_EVENTS_TO_SHOW && !showAllEvents && isMobile && (
          <ShowAllButton onClick={handleShowEvents}>{t('ADMIN_LABEL_SHOW_ALL')}</ShowAllButton>
        )}
      </EventsWrapper>
    </Wrapper>
  );
};

export default SelectedDateEvents;

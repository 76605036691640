import { connect } from 'react-redux';
import { engageOverlayCta, removeOverlay } from 'services/overlays';
import { openPanel } from 'services/custom-panels';
import { trackOverlay } from 'services/insights';

import LowerThirdOverlay from './view';

const mapDispatchToProps = dispatch => ({
  onDismiss: key => dispatch(removeOverlay(key)),
  onEngagedOverlay: broadcastId => dispatch(engageOverlayCta(broadcastId)),
  onOpenPanel: payload => dispatch(openPanel(payload)),
  trackDismiss: doc => dispatch(trackOverlay('dismiss', doc)),
  trackEngage: doc => dispatch(trackOverlay('engage', doc)),
});

export default connect(null, mapDispatchToProps)(LowerThirdOverlay);

import React from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { OptionRow } from 'components/admin-bridge/EditorModal';
import { DesktopBackgroundUploadZone, MobileBackgroundUploadZone, Section, SectionTitle, StyledLabel, SubSectionTitle, ToggleWrapper, UploadZoneWrapper } from './styles';
import { TEXT_ALIGNMENT_OPTIONS } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';
import { ITextBanner } from '../TextBanner.utils';
import { Checkbox } from 'components/admin2/ui/Checkbox';
import usePageBlock from 'hooks/use-page-block';
import usePageBlockBackgroundColorOptions from 'components/admin-bridge/EditorModal/ModalComponents/BackgroundColorOptions/use-page-block-background-color-options';
import BackgroundColorOptions from 'components/admin-bridge/EditorModal/ModalComponents/BackgroundColorOptions';
import ToggleSwitch from 'components/admin2/ui/ToggleSwitch';
import PageBlockSettings from 'components/admin-bridge/PageBlockSettings';

interface IBlockSettingsProps {
  isOpen: boolean;
  item: ITextBanner;
  onChange: (item: ITextBanner) => void;
  onClose: () => void;
}

type EditableStringPaths = (
  'background.image.show' |
  'background.image.mobile' |
  'background.image.desktop' |
  'label.show' |
  'cta.show' |
  'subtitle.show' |
  'alignment.horizontal' |
  'background.color'
);

const BlockSettings = ({
  item,
  isOpen,
  onClose,
  onChange,
}: IBlockSettingsProps) => {
  const { isCustomColor } = usePageBlockBackgroundColorOptions(item.background.color);
  const { handleChange } = usePageBlock<EditableStringPaths, ITextBanner>({
    item,
    onChange,
  });

  const handleClearDesktopBackground = React.useCallback(() => {
    handleChange('background.image.desktop')('');
  }, [handleChange]);

  const handleClearMobileBackground = React.useCallback(() => {
    handleChange('background.image.mobile')('');
  }, [handleChange]);

  const handleChangeBackgroundColor = React.useCallback((value: string) => {
    const newItem: ITextBanner = structuredClone(item);

    newItem.background.color = value;
    if (isCustomColor(value)) {
      newItem.background.customColor = value;
    }

    onChange(newItem);
  }, [item, onChange, isCustomColor]);

  return (
    <PageBlockSettings
      isOpen={isOpen}
      titleKey="ADMIN_LABEL_TEXT_BLOCK_SETTINGS"
      onClose={onClose}
    >
      <Section gap={20}>
        <Section gap={24}>
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_SHOW" />
          <Section gap={15}>
            <Checkbox
              checked={item.label.show}
              labelKey="ADMIN_LABEL"
              onCheckedChange={handleChange('label.show')}
            />
            <Checkbox
              checked={true}
              disabled={true}
              labelKey="ADMIN_LABEL_TITLE"
            />
            <Checkbox
              checked={item.subtitle.show}
              labelKey="ADMIN_LABEL_SUBTITLE"
              onCheckedChange={handleChange('subtitle.show')}
            />
            <Checkbox
              checked={item.cta.show}
              labelKey="ADMIN_LABEL_CALL_TO_ACTION"
              onCheckedChange={handleChange('cta.show')}
            />
          </Section>
        </Section>
        <Section gap={16}>
          <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_ALIGNMENT" />
          <OptionRow
            options={TEXT_ALIGNMENT_OPTIONS}
            value={item.alignment.horizontal}
            onChange={handleChange('alignment.horizontal')}
          />
        </Section>
        <Section gap={20}>
          <ToggleWrapper>
            <TranslatedText component={SectionTitle} stringKey="ADMIN_SETTINGS_DESIGN_PAGE_BACKGROUND_IMAGE" />
            <ToggleSwitch checked={item.background.image.show} onChange={handleChange('background.image.show')} />
          </ToggleWrapper>
          {
            item.background.image.show && (
              <>
                <UploadZoneWrapper>
                  <StyledLabel padding="0" labelKey="ADMIN_LABEL_DESKTOP" />
                  <DesktopBackgroundUploadZone
                    imagePreview={item.background.image.desktop}
                    onClearImage={handleClearDesktopBackground}
                    onFileSubmit={handleChange('background.image.desktop')}
                  />
                </UploadZoneWrapper>
                <UploadZoneWrapper>
                  <StyledLabel padding="0" labelKey="ADMIN_LABEL_MOBILE" />
                  <MobileBackgroundUploadZone
                    imagePreview={item.background.image.mobile}
                    onClearImage={handleClearMobileBackground}
                    onFileSubmit={handleChange('background.image.mobile')}
                  />
                </UploadZoneWrapper>
              </>
            )
          }
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_BACKGROUND_COLOR" />
          <BackgroundColorOptions
            lastCustomColor={item.background.customColor}
            color={item.background.color}
            onChange={handleChangeBackgroundColor}
          />
        </Section>
      </Section>
    </PageBlockSettings>
  );
};

export default BlockSettings;

import React from 'react';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';
import { IShopifyBlockData, MOCK_PRODUCT, MOCK_PRODUCTS } from './utils';
import { OVERFLOW_BEHAVIOR } from '../../admin-bridge/EditorModal/ModalComponents/OptionRow';
import { getCollection } from 'services/shopify/api';
import { getShop } from 'services/shopify';
import { getProductsByIds, getShopAccessToken } from 'services/shopify/selectors';
import { IProduct } from 'services/shopify/models';
import IState from 'services/state';
import { getActivePanelsV2 } from 'services/user-layout/selectors';
import { SHOPIFY_BLOCK_PANEL_ID } from 'components/objects/PanelV2/constants';
import useShopifyBlockAspectRatio from './use-shopify-block-aspect-ratio';
import { IBlockRegion } from 'services/app/models/ILandingPageContent';

const loadShopifyCollection = async (handle: string, shop: string, storefrontAccessToken: string) => {
  try {
    const data = await getCollection(shop, storefrontAccessToken, handle);
    const { collectionByHandle: returnedCollection } = data;
    return { err: null, returnedCollection };
  } catch (err) {
    return { err, returnedCollection: null };
  }
};

const useShopifyBlock = (item: IShopifyBlockData, region: IBlockRegion, onChangeCollectionProductsIds: (ids: string[]) => void, onChangeMultipleProductsIds: (ids: string[]) => void) => {
  const shop = useSelector(getShop);
  const storefrontAccessToken = useSelector(getShopAccessToken);
  const [collectionProducts, setCollectionProducts] = React.useState<IProduct[]>([]);
  const isMobile = useSelector(isMobileLayout);
  const multipleProductsIds = item.multipleProductsIds || [];
  const multipleProducts = useSelector((state: IState) => getProductsByIds(state, multipleProductsIds));
  const activePanels = useSelector(getActivePanelsV2);
  const isCollectionProductView = item.productView === 'collection';
  const isMultipleProductsProductView = item.productView === 'multipleProducts';
  const isSingleProductView = item.productView === 'singleProduct';
  const isRenderingOnPanel = region === 'panel';
  const isShopifyPanelActive = React.useMemo(()=>{
    return activePanels.find(panel => panel?.renderer?.panelType === SHOPIFY_BLOCK_PANEL_ID);
  }, [activePanels]);

  React.useEffect(() => {
    if (!isCollectionProductView || !item.shopifyCollectionHandle || !shop || !storefrontAccessToken) {
      return;
    }

    loadShopifyCollection(item.shopifyCollectionHandle, shop, storefrontAccessToken)
    .then(result => {
      if (!result.err && result.returnedCollection) {
        const newProducts: IProduct[] = result.returnedCollection.products.edges.map((edge: any) => edge.node);
        setCollectionProducts(newProducts);
      }
    });
  }, [isCollectionProductView, item.shopifyCollectionHandle, shop, storefrontAccessToken]);

  const backgroundImage = React.useMemo(() => {
    if (!item.background.image.show) {
      return undefined;
    }

    if (isMobile && item.background.image.mobile) {
      return item.background.image.mobile;
    }

    if (!isMobile && item.background.image.desktop) {
      return item.background.image.desktop;
    }

    return undefined;
  }, [
    isMobile,
    item.background.image.mobile,
    item.background.image.desktop,
    item.background.image.show,
  ]);

  const onReorder = React.useCallback((newProducts: IProduct[]) => {
    const newIds = newProducts.map(product => product.id);
    if (isCollectionProductView) {
      onChangeCollectionProductsIds(newIds);
    } else {
      onChangeMultipleProductsIds(newIds);
    }
  }, [onChangeCollectionProductsIds, onChangeMultipleProductsIds, isCollectionProductView]);

  const sortedCollectionProducts = React.useMemo(() => {
    const collectionProductsIds = item.collectionProductsIds || [];
    return collectionProducts.sort((a, b) => {
      const aIndex = collectionProductsIds.indexOf(a.id);
      const bIndex = collectionProductsIds.indexOf(b.id);
      return aIndex - bIndex;
    });
  }, [collectionProducts, item.collectionProductsIds]);

  const sortedMultipleProducts = React.useMemo(() => {
    const ids = item.multipleProductsIds || [];
    return multipleProducts.sort((a, b) => {
      const aIndex = ids.indexOf(a.id);
      const bIndex = ids.indexOf(b.id);
      return aIndex - bIndex;
    });
  }, [multipleProducts, item.multipleProductsIds]);


  const products = isCollectionProductView ? sortedCollectionProducts : sortedMultipleProducts;

  const [overflowBehavior, productsPerRow] = React.useMemo(() => {
    if (isRenderingOnPanel) {
      return [
        OVERFLOW_BEHAVIOR.stack,
        2,
      ];
    }

    if (isMobile) {
      return [
        item.overflowBehavior.mobile,
        item.overflowBehavior.mobile === OVERFLOW_BEHAVIOR.slider ? products.length : 1,
      ];
    }

    return [
      item.overflowBehavior.desktop,
      item.productsPerRow,
    ];
  }, [isRenderingOnPanel, isMobile, products.length, item.overflowBehavior, item.productsPerRow]);

  const isScrollable = React.useMemo(() => {
    if (isRenderingOnPanel) {
      return false;
    }

    return (isMobile && item.overflowBehavior.mobile === OVERFLOW_BEHAVIOR.slider && products.length > 1);
  }, [isRenderingOnPanel, isMobile, item.overflowBehavior.mobile, products.length]);

  const onRemoveProduct = React.useCallback((productId: string) => {
    if (!isMultipleProductsProductView) {
      return;
    }

    const newIds = multipleProductsIds.filter(id => id !== productId);
    onChangeMultipleProductsIds(newIds);
  }, [isMultipleProductsProductView, multipleProductsIds, onChangeMultipleProductsIds]);

  const { finalProducts, isUsingMockProducts } = React.useMemo((): { finalProducts: IProduct[], isUsingMockProducts: boolean } => {
    if (!products.length && !multipleProductsIds.length && !item.shopifyCollectionHandle && !isShopifyPanelActive) {
      return {
        finalProducts: isRenderingOnPanel ? MOCK_PRODUCTS : [MOCK_PRODUCT],
        isUsingMockProducts: true,
      };
    }
    return {
      finalProducts: products,
      isUsingMockProducts: false,
    };
  }, [products, multipleProductsIds, item.shopifyCollectionHandle, isRenderingOnPanel, isShopifyPanelActive]);

  const aspectRatio = useShopifyBlockAspectRatio(item, region);

  return {
    ...item,
    products: finalProducts,
    overflowBehavior,
    productsPerRow,
    aspectRatio,
    isMultipleProductsProductView,
    backgroundImage,
    isScrollable,
    isRenderingOnPanel,
    isUsingMockProducts,
    isShopifyPanelActive,
    onReorder,
    onRemoveProduct,
  };
};

export default useShopifyBlock;

import { CTAButtonProps, DEFAULT_CTA_BEHAVIOR, DEFAULT_CTA_HEIGHT_SIZE } from 'components/admin-bridge/CTAEditorModal/utils';
import { Alignment, ImageDesign, Layout } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow/types';
import { getDefaultEditorState, getDefaultHeadingNode, getDefaultTextNode } from 'components/ui/RichTextEditorV3/getDefaultEditorState';
import { injectT, TTranslator } from 'hooks/use-translation';
import { IPageBlockProps } from 'services/app/models/ILandingPageContent';
import { MAESTRO_BLACK } from 'style/constants';
import { getDefaultRichTextEditorData } from 'utils';
import { lazy, lazyFn } from 'utils/lazy';
export interface ICTA extends CTAButtonProps {
  show: boolean;
}

interface ITextEditor {
  rawData: string;
  rawDataV2?: string;
  show: boolean;
}

export interface IImageAndText {
  alignment: Alignment;
  background: {
    color: string;
    customColor: string;
    image: {
      desktop: string;
      mobile: string;
      show: boolean;
    }
  }
  cta: ICTA;
  description: ITextEditor;
  image: {
    objectFit: ImageDesign;
    src: string;
  }
  label: ITextEditor;
  layout: Layout;
  title: Omit<ITextEditor, 'show'>;
}

interface IImageAndTextItem {
  arrayId: string;
  data: IImageAndText;
  kind: string;
}

export type IImageAndTextProps = IPageBlockProps<IImageAndTextItem>;

const createDefaultImageAndTextData = (homeId: string, t: TTranslator): IImageAndText => ({
  title: {
    rawData: getDefaultRichTextEditorData({
      text: 'Lorem ipsum dolor sit amet tortor consectetur. Quisque turpis ipsum dolor sit amet',
      typographicalEmphasis: 'BOLD',
      fontSize: 32,
    }),
    rawDataV2: getDefaultEditorState([
      getDefaultHeadingNode({
        tag: 'h2',
        children: [
          getDefaultTextNode({
            text: 'Lorem ipsum dolor sit amet tortor consectetur. Quisque turpis ipsum dolor sit amet',
            fontSize: 32,
            textFormatType: 'bold',
          }),
        ],
      }),
    ]),
  },
  label: {
    rawData: getDefaultRichTextEditorData({
      text: t('ADMIN_LABEL'),
      typographicalEmphasis: 'BOLD',
      fontSize: 20,
    }),
    rawDataV2: getDefaultEditorState([
      getDefaultTextNode({
        text: t('ADMIN_LABEL'),
        fontSize: 20,
        textFormatType: 'bold',
      }),
    ]),
    show: true,
  },
  description: {
    rawData: getDefaultRichTextEditorData({
      text: 'Lorem ipsum dolor sit amet consectetur. Diam amet dolor egestas risus elit sed congue eget velit. Viverra amet ut amet at justo. A lacus augue consectetur turpis ultrices lectus fusce. Mattis mollis proin cras at et proin phasellus pellentesque porta. Sit id blandit sem quam curabitur nisl.',
      fontSize: 18,
    }),
    rawDataV2: getDefaultEditorState([
      getDefaultTextNode({
        text: 'Lorem ipsum dolor sit amet consectetur. Diam amet dolor egestas risus elit sed congue eget velit. Viverra amet ut amet at justo. A lacus augue consectetur turpis ultrices lectus fusce. Mattis mollis proin cras at et proin phasellus pellentesque porta. Sit id blandit sem quam curabitur nisl.',
        fontSize: 18,
      }),
    ]),
    show: true,
  },
  cta: {
    fontSize: 14,
    show: true,
    variant: 'solid',
    text: t('ADMIN_IMAGE_AND_TEXT_BLOCK_DEFAULT_CTA'),
    behavior: DEFAULT_CTA_BEHAVIOR(homeId),
    height: {
      desktop: DEFAULT_CTA_HEIGHT_SIZE,
      mobile: DEFAULT_CTA_HEIGHT_SIZE,
    },
  },
  background: {
    color: 'surface1',
    customColor: MAESTRO_BLACK,
    image: {
      show: false,
      mobile: '',
      desktop: '',
    },
  },
  alignment: Alignment.Left,
  layout: 'left',
  image: {
    src: '',
    objectFit: 'cover',
  },
});

export const DEFAULT_IMAGE_AND_TEXT = injectT(
  (t) => lazyFn(
    () => (homeId: string): IImageAndText => (createDefaultImageAndTextData(homeId, t)),
  ),
);

import React from 'react';
import useCalendarEvent from '../../EventCard/use-calendar-event';
import { BackArrowIcon, Body, Date, Description, GoBack, Header, Image, StyledLabels, Time, Title, WhenWrapper, Wrapper } from './styles';
import LiveBadge from 'components/ui/LiveBadge';
import { ICalendarEvent } from 'models/ICalendarEvent';
import { PREDEFINED_TOOLBAR_BUTTONS } from 'components/ui/RichTextEditorV2/utils';

interface IMobileEventPageProps {
  event: ICalendarEvent;
  onClose: () => void;
}

const MobileEventPage: React.FC<IMobileEventPageProps> = ({ event, onClose }) => {
  const { isLive, labels, startDate, when } = useCalendarEvent(event, true);

  return (
    <Wrapper>
      <Header>
        <GoBack onClick={onClose}>
          <BackArrowIcon />
          Back
        </GoBack>
      </Header>
      <Body hasImage={!!event.image.src}>
        {event.image.src && <Image alt="" src={event.image.src} />}
        <Date>{startDate}</Date>
        <Title readOnly={true} data={event.titleRaw} toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.titleEditor} />
        <Description readOnly={true} data={event.descriptionRaw} toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.bodyEditor} />
        <WhenWrapper>
          <Time>{when}</Time>
          {isLive && <LiveBadge.Default />}
        </WhenWrapper>
        <StyledLabels labels={labels} />
      </Body>
    </Wrapper>
  );
};

export default MobileEventPage;

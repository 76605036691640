import React from 'react';
import LeaderboardPanelView from 'components/panels/LeaderboardPanel/LeaderboardPanelView';

const MOCK_LEADERBOARD = [
  {
    points: '23890923',
    username: 'Big Bad Mitch',
  },
  {
    points: '11973279',
    username: 'Natty the Tort',
  },
  {
    points: '10484849',
    username: 'Hambone',
  },
  {
    points: '9893383',
    username: 'Wukong',
  },
  {
    points: '8947126',
    username: 'Simply Sara',
  },
  {
    points: '3325666',
    username: 'Toby Rollins',
  },
  {
    points: '2146789',
    username: 'Smithy McNougat',
  },
  {
    points: '1004034',
    username: 'Domenator9000',
  },
  {
    points: '872923',
    username: 'yngprpn',
  },
  {
    points: '790923',
    username: 'piggy smalls',
  },
];

const MOCK_YOUR_DATA = {
  points: '11973279',
  rank: '10844',
};

const LeaderboardMockPanel = () => {
  return (
    <LeaderboardPanelView
      isLeaderboardLoaded
      isYourRankLoaded
      leaderboardData={MOCK_LEADERBOARD}
      yourRankData={MOCK_YOUR_DATA}
    />
  );
};

export default LeaderboardMockPanel;

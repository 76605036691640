import useDragDrop from 'hooks/use-drag-drop';
import React, { PropsWithChildren } from 'react';

interface IDraggableProductCard extends PropsWithChildren {
  id: string;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
}

const DraggableProductCard =  ({ id, children, index, moveItem }: IDraggableProductCard) => {
  const { handlerId, ref } = useDragDrop({
    id,
    index,
    type: 'SHOPIFY_BLOCK_PRODUCTS',
    canReorder(current, monitor, dragIndex, hoverIndex) {
      // Determine rectangle on screen
      const hoverBoundingRect = current?.getBoundingClientRect();
      // Get horizontal middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the left
      const hoverClientX = (clientOffset?.x || 0) - hoverBoundingRect.left;

      // Only perform the move when the mouse has crossed half of the items width
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging leftwards and rightwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return false;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return false;
      }

      return true;
    },
    onReorder: moveItem,
  });

  return (
    <div ref={ref} data-handler-id={handlerId}>
      {children}
    </div>
  );
};

export default DraggableProductCard;

import React from 'react';
import { DynamicTranslationType, useEndUserInlineTextEditorTranslation } from 'hooks/use-translation';
import { IFAQData } from '../utils';
import { Title } from '../styles';
import { Answer, StyledEditableArea, Wrapper } from './styles';
import { isEditMode } from 'services/admin';
import { useSelector } from 'react-redux';
import { PREDEFINED_TOOLBAR_BUTTONS } from 'components/ui/RichTextEditorV2/utils';
import { getIsRichTextEditorV3Enabled } from 'services/feature-gate/selectors';

interface IFAQBlockProps {
  alignment: IFAQData['alignment'];
  answer: NonNullable<IFAQData['questions']>[0]['answer'];
  arrayId: string;
  backgroundColor: string;
  blockArrayId: string;
  onChangeAnswer: (answer: string) => void;
  onChangeQuestion: (question: string) => void;
  onRemove: () => void;
  pageId: string;
  question: NonNullable<IFAQData['questions']>[0]['question'];
}

const FAQBlock: React.FC<IFAQBlockProps> = ({ alignment, answer, backgroundColor, onRemove, question, onChangeAnswer, onChangeQuestion, pageId, arrayId, blockArrayId }) => {
  const isEditing = useSelector(isEditMode);
  const { inlineTextEditorEndUserT } = useEndUserInlineTextEditorTranslation(isEditing);
  const localizedQuestion = inlineTextEditorEndUserT(DynamicTranslationType.faqBlockQuestion, question.rawData, {
    pageId,
    blockId: blockArrayId,
    questionId: arrayId,
  });

  const localizedAnswer = inlineTextEditorEndUserT(DynamicTranslationType.faqBlockAnswer, answer.rawData, {
    pageId,
    blockId: blockArrayId,
    answerId: arrayId,
  });

  const isRichTextEditorV3Enabled = useSelector(getIsRichTextEditorV3Enabled);
  const questionRawDataV2 = question.rawDataV2;
  const useQuestionRawDataV2 = Boolean(isRichTextEditorV3Enabled && questionRawDataV2);
  const questionValue = useQuestionRawDataV2 ? questionRawDataV2 : localizedQuestion;

  const answerRawDataV2 = answer.rawDataV2;
  const useAnswerRawDataV2 = Boolean(isRichTextEditorV3Enabled && answerRawDataV2);
  const answerValue = useAnswerRawDataV2 ? answerRawDataV2 : localizedAnswer;

  return (
    <StyledEditableArea
      isEditing={isEditing}
      editorActions={[
        {
          confirmationMessageKey: 'ADMIN_PROMPT_CONFIRM_FAQ_CARD_DELETION',
          descriptionTextKey: 'ADMIN_LABEL_UNDO_WARNING',
          icon: 'cancel-thin',
          onClick: onRemove,
          tooltipKey: 'ACTION_DELETE',
        },
      ]}

    >
      <Wrapper alignment={alignment} backgroundColor={backgroundColor}>
        <Title
          blockTitleKey="ADMIN_LABEL_QUESTION"
          onChange={onChangeQuestion}
          data={questionValue}
          useRawDataV2={useQuestionRawDataV2}
          alignment={alignment}
          toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.titleEditor}
          data-testid="faq-block-question"
          htmlTag="h3"
        />
        <Answer
          blockTitleKey="ANSWER"
          onChange={onChangeAnswer}
          data={answerValue}
          useRawDataV2={useAnswerRawDataV2}
          alignment={alignment}
          background={backgroundColor}
          toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.bodyEditor}
          data-testid="faq-block-answer"
        />
      </Wrapper>
    </StyledEditableArea>
  );
};

export default FAQBlock;

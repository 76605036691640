import React from 'react';
import PollOverlay from 'components/overlays/PollOverlay';

export default class PollResultsOverlay extends React.Component {
  render() {
    return (
      <PollOverlay {...this.props} onlyResults />
    );
  }
}

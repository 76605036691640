import { connect } from 'react-redux';
import { isLoggedIn } from 'services/auth/selectors';
import { loginModal } from 'services/modals';
import LoginOverlayCta from './view';

const mapStateToProps = (state) => ({
  loggedIn: isLoggedIn(state),
});

const mapDispatchToProps = dispatch => ({
  onLogInClick: () => {
    dispatch(loginModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginOverlayCta);

import styled from 'styled-components';
import withTooltip from 'components/core/withTooltip';
import Icon from 'components/ui/Icon';
import { ADMIN_TEXT_300, ADMIN_TEXT_200 } from 'style/constants';
import UploadZone from 'components/admin2/UploadZone';
import { SectionTitle } from 'components/admin-bridge/EditorModal/ModalComponents/Section';
import { ADMIN_TEXT_LABEL_M_BOLD } from 'style/design-system/textStyles';

const IMAGE_ACCEPTED_TYPES = 'image/jpeg, image/png, image/jpg';

const StyledIcon = styled(Icon).attrs({
  name: 'info',
})`
  margin-left: 5px;
  svg path {
    fill: ${ADMIN_TEXT_300};
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  ${ADMIN_TEXT_LABEL_M_BOLD};
  color: ${ADMIN_TEXT_200};
  text-transform: capitalize;
  margin-right: 8px;
`;

export const UploadZoneWrapper = styled.section`
  display: flex;
  flex-direction: column;
`;


export const DesktopBackgroundUploadZone = styled(UploadZone).attrs(() => ({
  acceptedTypes: IMAGE_ACCEPTED_TYPES,
  canDrop: true,
  dimensionRequirements: {
    pixelHeight: 675,
    pixelWidth: 1920,
  },
  height: 111,
}))`padding: 0;`;

export const MobileBackgroundUploadZone = styled(UploadZone).attrs(() => ({
  acceptedTypes: IMAGE_ACCEPTED_TYPES,
  canDrop: true,
  dimensionRequirements: {
    pixelHeight: 755,
    pixelWidth: 375,
  },
  height: 177,
}))`padding: 0;`;

export const TooltipInfoIcon = withTooltip(StyledIcon, {
  admin: true,
  useAbsolutePosition: true,
  tooltipArrowCss: 'left: 91%;',
  tooltipCss: 'width: 163px !important; white-space: break-spaces; margin-bottom: 8px; margin-right: 135px; text-align: left;',
});

export const DesktopBackgroundInfoIcon = withTooltip(StyledIcon, {
  admin: true,
  useAbsolutePosition: true,
  tooltipArrowCss: 'left: 12%;',
  tooltipCss: 'width: 215px !important; white-space: break-spaces; margin-bottom: 8px; margin-left: 170px; text-align: left;',
});

export const MobileBackgroundInfoIcon = withTooltip(StyledIcon, {
  admin: true,
  useAbsolutePosition: true,
  tooltipArrowCss: 'left: 12%;',
  tooltipCss: 'width: 217px !important; white-space: break-spaces; margin-bottom: 8px; margin-left: 175px; text-align: left;',
});

export const BackgroundImageText = styled(SectionTitle)`
  margin-bottom: 11px;
`;

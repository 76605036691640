import React from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { ASPECT_RATIO_LABEL_MAP, AspectRatio, IImageGallery } from '../utils';
import { Section, SectionTitle, SubSectionTitle } from 'components/admin-bridge/EditorModal/ModalComponents/Section';
import {
  UploadZoneWrapper,
  DesktopBackgroundUploadZone,
  MobileBackgroundUploadZone,
  StyledLabel,
  ToggleWrapper,
} from './styles';
import { OptionRow } from 'components/admin-bridge/EditorModal';
import { IMAGE_LAYOUT_OPTIONS_ROW, OVERFLOW_BEHAVIOR_OPTIONS } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow/types';
import ToggleSwitch from 'components/admin2/ui/ToggleSwitch';
import { Checkbox } from 'components/admin2/ui/Checkbox';
import useEditPageBlock from 'hooks/use-page-block';
import BackgroundColorPicker from 'components/admin-bridge/EditorModal/ModalComponents/BackgroundColorOptions';
import usePageBlockBackgroundColorOptions from 'components/admin-bridge/EditorModal/ModalComponents/BackgroundColorOptions/use-page-block-background-color-options';
import Dropdown from 'components/admin2/ui/Dropdown';
import GridTextAlignment from 'components/admin-bridge/EditorModal/ModalComponents/GridTextAlignment';
import Slider from 'components/admin-bridge/EditorModal/ModalComponents/Slider';
import PageBlockSettings from 'components/admin-bridge/PageBlockSettings';

interface IBlockSettingsProps {
  isOpen: boolean;
  item: IImageGallery;
  onChange: (item: IImageGallery) => void;
  onClose: () => void;
}

const ASPECT_RATIO_OPTIONS: { label: string; value: AspectRatio; }[] = Object.keys(ASPECT_RATIO_LABEL_MAP).map((key) => ({
  label: ASPECT_RATIO_LABEL_MAP[key as AspectRatio],
  value: key as AspectRatio,
}));

type EditableStringPaths = (
  'background.image.mobile' |
  'background.image.desktop' |
  'background.customColor' |
  'background.color' |
  'background.image.show' |
  'showTitle' |
  'showLabel' |
  'showDescription' |
  'showCta' |
  'imageLayout' |
  'gridTextAlignment' |
  'overflowBehavior.desktop' |
  'overflowBehavior.mobile' |
  'imagesPerRow' |
  'aspectRatio.desktop' |
  'aspectRatio.mobile' |
  'aspectRatio.mobileEqualDesktop'
);

const BlockSettings = ({
  item,
  isOpen,
  onClose,
  onChange,
}: IBlockSettingsProps) => {
  const { isCustomColor } = usePageBlockBackgroundColorOptions(item.background.color);
  const { handleChange } = useEditPageBlock<EditableStringPaths, IImageGallery>({
    item,
    onChange,
  });

  const handleClearDesktopBackground = React.useCallback(() => {
    handleChange('background.image.desktop')('');
  }, [handleChange]);

  const handleClearMobileBackground = React.useCallback(() => {
    handleChange('background.image.mobile')('');
  }, [handleChange]);

  const handleChangeBackgroundColor = React.useCallback((value: string) => {
    const newItem: IImageGallery = structuredClone(item);

    newItem.background.color = value;
    if (isCustomColor(value)) {
      newItem.background.customColor = value;
    }

    onChange(newItem);
  }, [item, onChange, isCustomColor]);

  return (
    <PageBlockSettings isOpen={isOpen} titleKey="ADMIN_LABEL_IMAGE_GALLERY_SETTINGS" onClose={onClose}>
      <Section gap={30}>

        {/* Display */}
        <Section gap={16}>
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_DISPLAY" />
          <Section gap={15}>
            <Checkbox
              checked={item.showLabel}
              labelKey="ADMIN_LABEL"
              onCheckedChange={handleChange('showLabel')}
            />
            <Checkbox
              checked={item.showTitle}
              labelKey="ADMIN_LABEL_TITLE"
              onCheckedChange={handleChange('showTitle')}
            />
            <Checkbox
              checked={item.showDescription}
              labelKey="ADMIN_LABEL_DESCRIPTION"
              onCheckedChange={handleChange('showDescription')}
            />
            <Checkbox
              checked={item.showCta}
              labelKey="ADMIN_LABEL_CTA"
              onCheckedChange={handleChange('showCta')}
            />
          </Section>
        </Section>

        {/* Aspect Ratios */}
        <Section gap={20}>
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_IMAGE_ASPECT_RATIO" />
          <Section gap={16}>
            <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_DESKTOP" />
            <Dropdown
              onChange={handleChange('aspectRatio.desktop')}
              options={ASPECT_RATIO_OPTIONS}
              value={{ value: item.aspectRatio.desktop, label: ASPECT_RATIO_LABEL_MAP[item.aspectRatio.desktop] }}
              padding="0"
              admin={true}
              isSearchable={false}
            />
          </Section>
          <ToggleWrapper>
            <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_SAME_AS_DESKTOP" />
            <ToggleSwitch checked={item.aspectRatio.mobileEqualDesktop} onChange={handleChange('aspectRatio.mobileEqualDesktop')} />
          </ToggleWrapper>
          {!item.aspectRatio.mobileEqualDesktop && (
            <Section gap={16}>
              <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_MOBILE" />
              <Dropdown
                onChange={handleChange('aspectRatio.mobile')}
                options={ASPECT_RATIO_OPTIONS}
                value={{ value: item.aspectRatio.mobile, label: ASPECT_RATIO_LABEL_MAP[item.aspectRatio.mobile] }}
                padding="0"
                admin={true}
                isSearchable={false}
              />
            </Section>
          )}
        </Section>

        {/* Layout */}
        <Section gap={16}>
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_LAYOUT" />
          <OptionRow
            onChange={handleChange('imageLayout')}
            options={IMAGE_LAYOUT_OPTIONS_ROW}
            value={item.imageLayout}
            wrapText={true}
          />
        </Section>

        {/* Grid text alignment */}
        <Section gap={16}>
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_TEXT_ALIGNMENT" />
          <GridTextAlignment alignment={item.gridTextAlignment} setAlignment={handleChange('gridTextAlignment')} />
        </Section>

        {/* Desktop overflow behavior */}
        <Section gap={20}>
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_OVERFLOW_BEHAVIOR" />
          <Section gap={16}>
            <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_DESKTOP" />
            <OptionRow
              onChange={handleChange('overflowBehavior.desktop')}
              options={OVERFLOW_BEHAVIOR_OPTIONS}
              value={item.overflowBehavior.desktop}
            />
          </Section>
        </Section>

        {/* Images per row */}
        <Section gap={10}>
          <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_IMAGES_PER_ROW" />
          <Slider
            min={2}
            max={6}
            onChange={handleChange('imagesPerRow')}
            showStepIndicators={true}
            step={1}
            value={item.imagesPerRow}
          />
        </Section>

        {/* Mobile overflow behavior */}
        <Section gap={16}>
          <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_MOBILE" />
          <OptionRow
            onChange={handleChange('overflowBehavior.mobile')}
            options={OVERFLOW_BEHAVIOR_OPTIONS}
            value={item.overflowBehavior.mobile}
          />
        </Section>


        {/* Background images */}
        <Section gap={20}>
          <ToggleWrapper>
            <TranslatedText component={SectionTitle} stringKey="ADMIN_SETTINGS_DESIGN_PAGE_BACKGROUND_IMAGE" />
            <ToggleSwitch checked={item.background.image.show} onChange={handleChange('background.image.show')} />
          </ToggleWrapper>
          {
            item.background.image.show && (
              <>
                <UploadZoneWrapper>
                  <StyledLabel padding="0" labelKey="ADMIN_LABEL_DESKTOP" />
                  <DesktopBackgroundUploadZone
                    imagePreview={item.background.image.desktop}
                    onClearImage={handleClearDesktopBackground}
                    onFileSubmit={handleChange('background.image.desktop')}
                  />
                </UploadZoneWrapper>
                <UploadZoneWrapper>
                  <StyledLabel padding="0" labelKey="ADMIN_LABEL_MOBILE" />
                  <MobileBackgroundUploadZone
                    imagePreview={item.background.image.mobile}
                    onClearImage={handleClearMobileBackground}
                    onFileSubmit={handleChange('background.image.mobile')}
                  />
                </UploadZoneWrapper>
              </>
            )
          }

          {/* Background color */}
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_BACKGROUND_COLOR" />
          <BackgroundColorPicker
            lastCustomColor={item.background.customColor}
            color={item.background.color}
            onChange={handleChangeBackgroundColor}
          />
        </Section>
      </Section>
    </PageBlockSettings>
  );
};

export default BlockSettings;

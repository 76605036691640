import styled, { css } from 'styled-components';
import Icon from 'components/ui/Icon';
import {
  ACCENT_PRIMARY,
  ALERT_CONFIRMATION,
  ALERT_ERROR,
  ADMIN_SURFACE_5,
  TEXT_100,
  HIGHLIGHT_PRIMARY,
  SURFACE_3,
  HIGHLIGHT_SECONDARY,
  HIGHLIGHT_TERTIARY,
  TEXT_CONTRAST_ON_ACCENT_PRIMARY,
  BODY_FONT_FAMILY,
} from 'style/constants';
import { mobileOnly, TEXT_H3 } from 'style/mixins';

export const ContainerSpacing = styled.div`
  width: 100%;
  min-width: auto;
  ${mobileOnly`
      ${props => props.open && `
        display: none;
      `}
  `}
`;

export const ImageContainer = styled.div`
  border-radius: 4px;
  flex: none;
  margin-left: 10px;
  width: 61.93px;
  height: 51.85px;
  padding: 0;
`;

export const StyledIcon = styled(Icon)<{ nomargin?: boolean; }>`
  line-height: 0;
  margin-left: ${({ nomargin }) => nomargin ? '0' : '10px'};
  & > svg {
    width: 16px;
    height: 16px;
  }
`;

export const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  ${({ src }) => src ? null : 'background-color: black;'};
`;

export const Container = styled.div`
  color: ${ADMIN_SURFACE_5};
  font-family: ${BODY_FONT_FAMILY};
`;

export const Top = styled.div`
  background-color: ${SURFACE_3};
  display: flex;
  align-items: flex-start;
  border-radius: 5px;
  padding: 20px;
  margin-top: 5px;
`;

export const StyledAnswerButton = styled.button`
  display: none;
  width: 100%;
  color: ${TEXT_100};
  background: ${ACCENT_PRIMARY};
  height:45px;
  ${TEXT_H3}
  ${mobileOnly`
    display: block;
  `}
  text-transform: uppercase;
  cursor: pointer;
`;

export const ResultsHeader = styled.span`
  font-weight: 700;
  font-size: 16px;
  flex-grow: 1;
  color: ${TEXT_100};
`;

export const AnswersContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  max-width: 100%;
  ${mobileOnly`
      display: none;
  `}
`;

export const selectedCss = css`
  background-color: ${ACCENT_PRIMARY};
  cursor: default;
`;

export const answeredCss = css`
  cursor: default;
  color: ${TEXT_100};
`;

export const Radio = styled.input.attrs({
  type: 'radio',
})`
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: transparent;
  border-radius: 100%;
  height: 15px;
  margin-left: 10px;
  width: 15px;
  border: 1px solid ${ACCENT_PRIMARY};
  box-sizing: border-box;
  vertical-align: text-top;
  flex: none;
  &:checked {
    background: ${SURFACE_3};
    border: solid 4px ${HIGHLIGHT_PRIMARY};
  }
  &:checked::after {
    display: block;
  }
`;

const getAnsweredCss = ({ answered, selected }) => {
  if (answered && !selected) {
    return answeredCss;
  }
  if (answered && selected) {
    return selectedCss;
  }
  return '';
};

export const AnswerDiv = styled.div<{ answered: boolean, isAnswerOk: boolean; }>`
  position: relative;
  min-height: 71.94px;
  display: flex;
  align-items: center;
  margin: 0;
  color: ${TEXT_100};
  border-radius: 4px;
  border: 1px solid ${ACCENT_PRIMARY};
  box-sizing: border-box;
  margin: 5px 5px 0 0;
  padding: 0;
  width: calc(1/2*100% - (1 - 1/2)*10px);
  &:nth-child(2n) {
    margin-right: 0;
  }
  &::after {
    content: '';
    flex: auto;
  }
  &:nth-child(-n+2) {
    margin-top: 5px;
  }
  ${({ answered }) => !answered && css`
  &:hover {
    cursor: pointer;
    background-color: ${ACCENT_PRIMARY};
    & input {
      background-color: ${HIGHLIGHT_PRIMARY};
      border: solid 4px ${SURFACE_3};
    }
  }
  `};

  ${ // @ts-ignore
    getAnsweredCss }
  ${// @ts-ignore
    ({ answered, isAnswerOk, ...props }) => answered && isAnswerOk !== null &&
    `${isAnswerOk ? `border: solid ${ALERT_CONFIRMATION(props)} 1px;` : 'border: 0;'}`};
`;

export const BackgroundColorContent = styled.div`
  background-color: ${SURFACE_3};
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
`;

export const Answer = styled.div<{ isActive: boolean, isAnswerOk: boolean; }>`
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 100%;
  ${({ isAnswerOk }) => isAnswerOk === false ?
    `
  opacity: 0.5;
  ` :
    null};
  &:hover {
    ${({ isActive }) => !isActive &&
      css`
        color: ${TEXT_CONTRAST_ON_ACCENT_PRIMARY};
        background-color: ${ACCENT_PRIMARY};
        span {
          color: ${TEXT_CONTRAST_ON_ACCENT_PRIMARY};
        }
      `}
  }
`;

export const AnswerContainer = styled.div<{ showImage?: boolean; }>`
  display: flow-root;
  align-items: center;
  margin: ${({ showImage }) => showImage ? '10px' : '18px'};
  width: 100%;
  flex: 100%;

`;

export const Circle = styled.div<{ isOk: boolean; }>`
  background-color: ${props => props.isOk ? ALERT_CONFIRMATION : ALERT_ERROR};
  border-radius: ${props => props.isOk ? '4px 0 0 4px' : '4px'};
  color: ${props => props.isOk ? 'white' : 'black'};
  height: 100%;
  width: 53px;
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StatsDiv = styled.div<{ answered: boolean; }>`
  margin-left: auto;
  margin-top: 2px;
  color: ${props => props.answered ? TEXT_CONTRAST_ON_ACCENT_PRIMARY(props) : TEXT_100(props)};
  ${props => props.answered && css`
      span {
        color: ${TEXT_CONTRAST_ON_ACCENT_PRIMARY(props)};
      }
    `}
`;

export const PercentageContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${HIGHLIGHT_PRIMARY};
`;

export const ProgressBarContainer = styled.div`
  border-radius: 4px;
  border: 1px solid ${HIGHLIGHT_SECONDARY};
  background-color: ${HIGHLIGHT_PRIMARY};
  height: 7px;
  width: 100%;
  max-width: 120px;
  margin-left: 5px;
`;

export const BarColor = styled.div<{ width: number; }>`
  height: 100%;
  width: ${({ width }) => width};
  background-color: ${HIGHLIGHT_TERTIARY};
  opacity: 0.97;
  border-radius: 4px 0 0 4px;
`;

export const TextWrapper = styled.span<{ isActive: boolean; }>`
  font-size: 14px;
  font-weight: 500;
  ${({ isActive }) => isActive &&
    css`
      color: ${TEXT_CONTRAST_ON_ACCENT_PRIMARY};
    `};
`;

import { HIGHLIGHT_PRIMARY, SURFACE_3, SURFACE_4, SURFACE_6, TEXT_100, TEXT_300, TEXT_CONTRAST_ON_ACCENT_PRIMARY } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_MEDIUM, TEXT_BODY_L_REGULAR, TEXT_BODY_S_REGULAR, TEXT_HEADLINE_S, TEXT_LABEL_L_BOLD, TEXT_LABEL_M_BOLD, TEXT_LABEL_M_MEDIUM, TEXT_LABEL_S_BOLD, TEXT_LABEL_S_MEDIUM, TEXT_LABEL_XS_MEDIUM, TEXT_TITLE_M, TEXT_TITLE_S } from 'style/design-system/textStyles';
import styled, { css } from 'styled-components';
import EditableArea from 'components/admin2/ui/EditableArea';
import Price from '../Price';
import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';
import { AspectRatio, ProductViewKeys } from '../utils';
import { MULTI_LINE_ELLIPSIS, desktopOnly, mobileOnly } from 'style/mixins';

export const EDITOR_ACTIONS_CSS = css`
  position: absolute;
  right: 3px;
  top: 16px;
  cursor: pointer;
`;

export const StyledEditableArea = styled(EditableArea)<{ horizontalView: boolean; productView: ProductViewKeys; }>`
  display: flex;
  display: grid;
  ${({ productView }) => productView !== 'singleProduct' && desktopOnly`height: 100%;`}
  ${props => props.horizontalView && css`
    grid-template-columns: 1fr 2fr;
    column-gap: 60px;
    align-items: center;
  `}

  ${props => !props.horizontalView && css`
    grid-template-rows: auto 1fr;
  `}

  ${props => props.isEditing && css`
    & div:first-child {
      z-index: 1;
    }
  `}
`;

export const IMAGE_CSS = css<{ aspectRatio: AspectRatio; }>`
  aspect-ratio: ${props => props.aspectRatio};
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

export const NoImage = styled.div`
  ${IMAGE_CSS}
  background-color: ${SURFACE_3};

  ${ADMIN_TEXT_LABEL_M_MEDIUM}
  color: ${TEXT_300};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  cursor: pointer;
`;

export const AddIcon = styled(Icon).attrs({
  name: 'plus',
})`
  & svg {
    & path {
      fill: ${HIGHLIGHT_PRIMARY};
    }
  }
`;

export const Content = styled.div<{ horizontalView: boolean; imageHeight: number; }>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1;
  width: 100%;
  ${props => props.horizontalView && css`
    justify-content: center;
    max-height: ${props.imageHeight}px;
  `}

  ${props => !props.horizontalView && css`
    margin-top: 20px;
  `}

  &:empty {
    display: none;
  }
`;

export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;
`;

const getTitleText = (isRenderingOnPanel: boolean, horizontalView: boolean, productView: ProductViewKeys) => {
  if (!isRenderingOnPanel) {
    return horizontalView ? TEXT_HEADLINE_S : TEXT_TITLE_S;
  }

  if (productView === 'singleProduct') {
    return TEXT_LABEL_M_MEDIUM;
  }

  return TEXT_LABEL_S_MEDIUM;
};

const getTitleTextMobile = (productView: ProductViewKeys) => {
  if (productView === 'singleProduct') {
    return TEXT_TITLE_M;
  }
};

export const Title = styled.h3<{ horizontalView: boolean; isRenderingOnPanel: boolean; productView: ProductViewKeys; }>`
  margin: 0;
  ${props => getTitleText(props.isRenderingOnPanel, props.horizontalView, props.productView)}
  color: ${TEXT_100};
  max-width: 100%;
  white-space: normal;
  overflow-wrap: break-word;

  ${mobileOnly` ${props => getTitleTextMobile(props.productView)} `}
`;

const getDescriptionText = (isRenderingOnPanel: boolean, horizontalView: boolean, productView: ProductViewKeys) => {
  if (!isRenderingOnPanel) {
    return horizontalView ? TEXT_BODY_L_REGULAR : TEXT_BODY_S_REGULAR;
  }

  if (productView === 'singleProduct') {
    return TEXT_LABEL_S_MEDIUM;
  }

  return TEXT_LABEL_XS_MEDIUM;
};

const getDescriptionTextMobile = (productView: ProductViewKeys) => {
  if (productView === 'singleProduct') {
    return TEXT_LABEL_M_MEDIUM;
  }
};

export const Description = styled.p<{
  horizontalView: boolean;
  isRenderingOnPanel: boolean;
  maxLines?: number;
  productView: ProductViewKeys;
}>`
  margin: 0;
  margin-top: 6px;
  color: ${TEXT_300};
  ${MULTI_LINE_ELLIPSIS}
  ${({ isRenderingOnPanel }) =>
  isRenderingOnPanel
    ? css`
        -webkit-line-clamp: 4;
        -moz-box-lines: 4;
      `
    : css`
        @media (min-width: 1280px) {
          -webkit-line-clamp: 2;
          -moz-box-lines: 2;
        }
        @media (min-width: 1360px) {
          -webkit-line-clamp: 3;
          -moz-box-lines: 3;
        }
        @media (min-width: 1440px) {
          -webkit-line-clamp: 4;
          -moz-box-lines: 4;
        }
        @media (min-width: 1600px) {
          -webkit-line-clamp: 5;
          -moz-box-lines: 5;
        }
        @media (min-width: 1920px) {
          -webkit-line-clamp: 6;
          -moz-box-lines: 6;
        }
        @media (min-width: 2560px) {
          -webkit-line-clamp: 9;
          -moz-box-lines: 9;
        }
      `}}
  ${(props) =>
    getDescriptionText(
      props.isRenderingOnPanel,
      props.horizontalView,
      props.productView,
    )}
  ${mobileOnly`
    -webkit-line-clamp: 9999;
    -moz-box-lines: 9999;
    ${(props) => getDescriptionTextMobile(props.productView)}
  `}
`;

const getStyledPriceMobile = (productView: ProductViewKeys) => {
  if (productView === 'singleProduct') {
    return TEXT_LABEL_L_BOLD;
  }
};

export const StyledPrice = styled(Price)<{ isRenderingOnPanel: boolean; productView: ProductViewKeys; }>`
  ${props => props.isRenderingOnPanel ? TEXT_LABEL_M_BOLD : TEXT_LABEL_L_BOLD}
  color: ${TEXT_100};

  ${mobileOnly` ${props => getStyledPriceMobile(props.productView)} `}
`;

export const StyledButton = styled(Button).attrs(props => ({
  textColor: TEXT_CONTRAST_ON_ACCENT_PRIMARY(props),
  hoverTextColor: TEXT_100(props),
}))<{ blockBackgroundColor: string; horizontalView: boolean; isRenderingOnPanel: boolean; }>`
  width: fit-content;
  height: 44px;
  min-height: 44px;
  padding: 13px 24px;
  ${props => !props.horizontalView && !props.isRenderingOnPanel && css`margin-top: auto;`}
  ${props => props.isRenderingOnPanel ? TEXT_LABEL_S_BOLD : TEXT_LABEL_L_BOLD}
  ${props => props.isRenderingOnPanel && css`
    width: 100%;
    height: 40px;
  `}

  &:hover {
    background: ${props => ['surface5', 'surface6'].includes(props.blockBackgroundColor) ? SURFACE_4(props) : SURFACE_6(props)};
  }

  ${({ disabled }) => disabled && '&, '}&:disabled {
    &, & *, & * * {
      background-color: ${SURFACE_4} !important;
      color: ${TEXT_300} !important;
    }
  }
`;

export const AvailableOptions = styled.div<{ isRenderingOnPanel: boolean; }>`
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  ${props => props.isRenderingOnPanel ? TEXT_LABEL_S_MEDIUM : TEXT_LABEL_M_MEDIUM}
  color: ${TEXT_300};
`;

export const PriceAndOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
`;

export const ShippingInformation = styled.div`
  ${TEXT_LABEL_M_MEDIUM};
  color: ${TEXT_300};
  margin: 8px 0px 20px 0px;
`;

export const OptionsSelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${mobileOnly`
    flex-direction: column;
    gap: 20px;
  `}
  ${desktopOnly`
  flex-wrap: wrap;
  & > *:not(:last-child) {
    margin-right: 60px;
  }
  `}
`;

import styled from 'styled-components';
import UploadZone from 'components/admin2/UploadZone';
import Label from 'components/admin2/ui/Label';
import Button from 'components/admin2/ui/Button';
import { ADMIN_ACCENT_PRIMARY, ADMIN_ALERT_ERROR, ADMIN_SURFACE_5, ADMIN_TEXT_100, ADMIN_TEXT_200 } from 'style/constants';
import { ADMIN_TEXT_BODY_XS_BOLD, ADMIN_TEXT_BODY_XS_REGULAR, ADMIN_TEXT_TITLE_S, TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';
import Icon from 'components/ui/Icon';
import Link from 'components/ui/Link';

export const BlockPanelSettingsWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const BlockPanelSettingsTitle = styled.span`
  ${ADMIN_TEXT_TITLE_S}
  color: ${ADMIN_TEXT_100};
`;

export const ShopifyBlockPanelBackgroundColorPickerWrapper = styled.div`
  & > * {
    justify-content: unset;
    gap: 4px;
  }
`;

export const StoreConnectedWrapper = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  border-radius: 10px;
  background: ${ADMIN_SURFACE_5};
  ${ADMIN_TEXT_BODY_XS_REGULAR}
  color: ${ADMIN_TEXT_200};
  text-align: center;
  align-items: center;
`;

export const InstructionsLink = styled(Link)`
  ${ADMIN_TEXT_BODY_XS_BOLD}
  color: ${ADMIN_ACCENT_PRIMARY};
  text-decoration: underline;
`;

export const OpenShopButton = styled(Button)`
  margin-top: 20px;
  width: 100%;
`;

export const ShopifyIcon = styled(Icon).attrs({
  name: 'shopifyV2',
})`
  margin-right: 8px;

  & svg {
    width: 16px;
    height: 16px;
  }
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledLabel = styled(Label)`
  margin-bottom: 16px;
`;

export const UploadZoneWrapper = styled.section`
  display: flex;
  flex-direction: column;
`;


export const DesktopBackgroundUploadZone = styled(UploadZone).attrs(() => ({
  canDrop: true,
  dimensionRequirements: {
    pixelHeight: 1080,
    pixelWidth: 1920,
  },
  height: 177,
  padding: '0',
}))``;

export const MobileBackgroundUploadZone = styled(UploadZone).attrs(() => ({
  canDrop: true,
  dimensionRequirements: {
    pixelHeight: 1200,
    pixelWidth: 750,
  },
  height: 177,
  padding: '0',
}))``;

export const CheckIcon = styled(Icon).attrs({
  name: 'checked',
})`
  position: absolute;
  right: 0;
  bottom: 12px;
  width: 16px;
  height: 16px;
  margin-right: 16px;

  & svg {
    & path {
      fill: ${ADMIN_ACCENT_PRIMARY};
    }
  }
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const RecurringChargeHighlightedText = styled.div`
  ${TEXT_BODY_S_MEDIUM};
  color: ${ADMIN_TEXT_100}
`;

export const WarningIcon = styled(Icon).attrs(props => ({
  color: ADMIN_ALERT_ERROR(props),
  name: 'warning',
}))`
  padding-bottom: 10px;
  padding-right: 5px;
  & svg {
    width: 14px;
    height: 14px;
  }
`;

export const Spacer = styled.div`
  margin-bottom: 10px;
`;

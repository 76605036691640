import React from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { OptionRow } from '../../../admin-bridge/EditorModal';
import { Section, SectionTitle } from '../../../admin-bridge/EditorModal/ModalComponents/Section';
import { IImageAndText } from '../utils';
import { AddIcon, Image, NoImage, StyledEditorWrapper, StyledUploadZone, Wrapper } from './styles';
import { IMAGE_DESIGN_OPTIONS } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow/types';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import useEditPageBlock from 'hooks/use-page-block';
import { useAdminTranslation } from 'hooks/use-translation';

interface IImageEditorModalProps {
  item: IImageAndText;
  onChange: (item: IImageAndText) => void;
}

type EditableStringPaths = 'image.src' | 'image.objectFit';

const ImageEditorModal: React.FC<IImageEditorModalProps> = ({
  item,
  onChange,
}) => {
  const { t } = useAdminTranslation();
  const editMode = useSelector(isEditMode);
  const { handleChange } = useEditPageBlock<EditableStringPaths, IImageAndText>({
    item,
    onChange,
  });

  const handleClearImage = React.useCallback(() => {
    handleChange('image.src')('');
  }, [handleChange]);

  const renderContent = React.useCallback(() => {
    if (!item.image.src) {
      return (
        <NoImage>
          {editMode && (
            <>
              <AddIcon name="addImage" />
              <TranslatedText stringKey="ADMIN_LABEL_ADD_IMAGE" />
              <span>
                {t('ADMIN_IMAGE_EDITOR_RECOMMENDED_SIZE', { width: 960, height: 540 })}
              </span>
              <span>
                {t('ADMIN_UPLOAD_MAX_FILE_SIZE_MB', { size: 5 })}
              </span>
            </>
          )}
        </NoImage>
      );
    }

    return (
      <Image
        src={item.image.src}
        objectFit={item.image.objectFit}
        alt="Image and Text content image"
      />
    );
  }, [item.image.src, item.image.objectFit, editMode]);

  return (
    <Wrapper layout={item.layout} image={item.image.src}>
      <StyledEditorWrapper
        layout={item.layout}
        blockTitleKey="ADMIN_LABEL_IMAGE"
        modalTitleKey="ADMIN_LABEL_IMAGE_SETTINGS"
        borderStyle="dashed"
        data-testid="image-editor-modal"
        modalContent={(
          <Section gap={20}>
            <StyledUploadZone
              imagePreview={item.image.src}
              onClearImage={handleClearImage}
              onFileSubmit={handleChange('image.src')}
            />
            <Section gap={16}>
              <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_DESIGN" />
              <OptionRow
                onChange={handleChange('image.objectFit')}
                options={IMAGE_DESIGN_OPTIONS}
                value={item.image.objectFit}
              />
            </Section>
          </Section>
        )}
      >
        {renderContent()}
      </StyledEditorWrapper>
    </Wrapper>
  );
};

export default ImageEditorModal;

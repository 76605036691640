import React from 'react';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import { OVERFLOW_BEHAVIOR } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';
import useChunkPagination from 'hooks/use-chunk-pagination';
import { IQuestsBlockData } from '../utils';
import { isMobileLayout } from 'services/device';
import IQuest from 'models/IQuest';
import { IBlockRegion } from 'services/app/models/ILandingPageContent';
import { QuestEngageMetadata } from 'components/objects/QuestCard';
import QuestLibraryModal from 'components/admin2/QuestLibraryModal';
import { useAdminTranslation } from 'hooks/use-translation';
import VisibleQuests from './VisibleQuests';
import { AddIcon, AddQuestButton } from './styles';
import QuestsBlockSlider from './SlideWrapper';
import EditableArea from 'components/admin2/ui/EditableArea';

type GalleryProps = {
  item: IQuestsBlockData;
  onChange: (item: IQuestsBlockData) => void;
  questEngage: (extra: QuestEngageMetadata) => void;
  region: IBlockRegion;
};

const Gallery: React.FC<GalleryProps> = ({ item, region, onChange, questEngage }) => {
  const isEditing = useSelector(isEditMode);
  const isMobile = useSelector(isMobileLayout);
  const isRenderingOnPanel = region === 'panel';
  const { t } = useAdminTranslation();
  const [Modal, setModal] = React.useState<React.LazyExoticComponent<typeof QuestLibraryModal> | null>(null);

  const questIds = (item.questIds || []);
  const hasQuests = questIds.length > 0;

  const isScrollable = React.useMemo(() => {
    if (isRenderingOnPanel) {
      return false;
    }

    return (isMobile && item.overflowBehavior.mobile === OVERFLOW_BEHAVIOR.slider && questIds.length > 1);
  }, [isRenderingOnPanel, isMobile, item.overflowBehavior.mobile, questIds.length]);

  const [overflowBehavior, questsPerRow] = React.useMemo(() => {
    if (isRenderingOnPanel) {
      return [
        OVERFLOW_BEHAVIOR.stack,
        1,
      ];
    }
    if (isMobile) {
      return [
        item.overflowBehavior.mobile,
        item.overflowBehavior.mobile === OVERFLOW_BEHAVIOR.slider ? questIds.length : 1,
      ];
    }

    return [
      item.overflowBehavior.desktop,
      item.questsPerRow,
    ];
  }, [isMobile, questIds.length, item.overflowBehavior, item.questsPerRow, region]);

  const pagination = useChunkPagination(questIds, overflowBehavior === OVERFLOW_BEHAVIOR.slider ? questsPerRow : 0);

  const onRemoveQuest = (questId: string) => () => {
    if (pagination.page.length === 1) {
      pagination.goToPrevPage();
    }

    onChange({
      ...item,
      questIds: (item.questIds || []).filter(id => id !== questId),
    });
  };

  if (pagination.pageCount && pagination.pageIndex >= pagination.pageCount) {
    pagination.goToPage(0);
  }


  const openModal = () => {
    if (!isEditing) {
      return;
    }

    setModal(React.lazy(() => import('components/admin2/QuestLibraryModal')));
  };

  const closeModal = () => {
    setModal(null);
  };

  const onIncludeQuest = (questId: string) => {
    const newQuestIds = [...questIds, questId];
    onChange({
      ...item,
      questIds: newQuestIds,
    });
  };

  const filterQuests = React.useCallback((quests: IQuest[]) => {
    return quests.filter(quest => !questIds.includes(quest._id!));
  }, [item.questIds]);

  return (
    <>
      <EditableArea isEditing={isEditing} blockTitleKey="ADMIN_LABEL_QUESTS" borderStyle="dashed">
      {
        (isEditing && !hasQuests) ? (
          <AddQuestButton onClick={openModal}>
            <AddIcon />
            {t('ADD_A_QUEST')}
          </AddQuestButton>
        ) : (
          <QuestsBlockSlider
            isScrollable={isScrollable}
            onAddClick={openModal}
            pagination={pagination}
            showAddMoreButton={isEditing && (hasQuests || isRenderingOnPanel)}
          >
            {chunkQuestIds => (
              <VisibleQuests
                region={region}
                key={`${chunkQuestIds.length}-${pagination.pageIndex}-${pagination.pages.length}`}
                questIds={chunkQuestIds}
                isScrollable={isScrollable}
                onRemoveQuest={onRemoveQuest}
                overflowBehavior={overflowBehavior}
                questEngage={questEngage}
                questsPerRow={questsPerRow}
              />
            )}
          </QuestsBlockSlider>
        )
      }
      </EditableArea>
      {
        Modal && (
          <React.Suspense fallback={null}>
            <Modal
              onClose={closeModal}
              onSelectItem={onIncludeQuest}
              filterResults={filterQuests}
            />
          </React.Suspense>
        )
      }
    </>
  );
};

export default Gallery;

import React, { PropsWithChildren } from 'react';
import { ASPECT_RATIO_LABEL_MAP, AspectRatio, IImageGallery } from '../../utils';
import { ImageCardProps } from '..';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import TranslatedText from 'components/i18n/TranslatedText';
import { AddIcon, Image, NoImage, StyledEditorWrapper, StyledUploadZone } from './styles';
import { Section } from 'components/admin-bridge/EditorModal/ModalComponents/Section';
import usePageBlock from 'hooks/use-page-block';
import { isMobileLayout } from 'services/device';
import { useAdminTranslation } from 'hooks/use-translation';
import { useImageGalleryContext } from '../../ImageGalleryContext/ImageGalleryContext';

interface IGalleryCardImageProps extends PropsWithChildren {
  imageCard: ImageCardProps;
}

const ASPECT_RATIO_UPLOAD_SIZE_MAP: Record<AspectRatio, string> = {
  '1 / 1': '800x800px',
  '4 / 3': '800x600px',
  '3 / 2': '800x533px',
  '16 / 9': '800x450px',
  '21 / 9': '800x342px',
  '3 / 4': '600x800px',
  '2 / 3': '600x900px',
  '9 / 16': '600x1066px',
};

const defaultUploadSize = ASPECT_RATIO_UPLOAD_SIZE_MAP['1 / 1'];

type EditableStringPath = 'image.desktop' | 'image.mobile';

const GalleryCardImage: React.FC<IGalleryCardImageProps> = ({ imageCard, children }) => {
  const editMode = useSelector(isEditMode);
  const isMobile = useSelector(isMobileLayout);
  const { aspectRatio, editImageCard, aspectRatioConfig } = useImageGalleryContext();
  const { t } = useAdminTranslation();

  const image = React.useMemo(() => {
    if (isMobile && imageCard.image.mobile) {
      return imageCard.image.mobile;
    }

    if (imageCard.image.desktop) {
      return imageCard.image.desktop;
    }

    return null;
  }, [isMobile, imageCard.image.mobile, imageCard.image.desktop]);

  const { handleChange } = usePageBlock<EditableStringPath, ImageCardProps>({
    item: imageCard,
    onChange: editImageCard,
  });

  const { desktop, mobile } = React.useMemo(() => {
    const uploadDesktop = ASPECT_RATIO_UPLOAD_SIZE_MAP[aspectRatioConfig.desktop] || defaultUploadSize;
    const uploadMobile = ASPECT_RATIO_UPLOAD_SIZE_MAP[aspectRatioConfig.mobile] || defaultUploadSize;
    const labelDesktop = ASPECT_RATIO_LABEL_MAP[aspectRatioConfig.desktop] || '1:1';
    const labelMobile = ASPECT_RATIO_LABEL_MAP[aspectRatioConfig.mobile] || '1:1';

    return {
      desktop: {
        uploadSizeText: t('ADMIN_IMAGE_GALLERY_UPLOAD_SIZE', { uploadSize: uploadDesktop }),
        aspectRatioLabelText: t('ADMIN_IMAGE_GALLERY_DESKTOP_ASPECT_RATIO', { aspectRatio: labelDesktop }),
      },
      mobile: {
        uploadSizeText: t('ADMIN_IMAGE_GALLERY_UPLOAD_SIZE', { uploadSize: uploadMobile }),
        aspectRatioLabelText: t('ADMIN_IMAGE_GALLERY_MOBILE_ASPECT_RATIO', { aspectRatio: labelMobile }),
      },
    };
  }, [aspectRatioConfig, t]);

  const handleClearDesktopImage = React.useCallback(() => {
    handleChange('image.desktop')('');
  }, [handleChange]);

  const handleClearMobileImage = React.useCallback(() => {
    handleChange('image.mobile')('');
  }, [handleChange]);

  const renderContent = React.useCallback(() => {
    if (!image) {
      return (
        <NoImage aspectRatio={aspectRatio}>
          {editMode && (
            <>
              <AddIcon name="addImage" />
              <TranslatedText stringKey="ADMIN_LABEL_ADD_IMAGE" />
              <span>{desktop.uploadSizeText}</span>
            </>
          )}
          {children}
        </NoImage>
      );
    }

    return (
      <Image aspectRatio={aspectRatio} src={image}>
        {children}
      </Image>
    );
  }, [image, aspectRatio, editMode, children, desktop.uploadSizeText]);

  return (
    <StyledEditorWrapper
      modalTitleKey="ADMIN_LABEL_ADD_IMAGE"
      borderStyle="dashed"
      data-testid={`image-gallery-image-editor-modal-${imageCard.id}`}
      modalContent={(
        <Section gap={20}>
          <StyledUploadZone
            label={desktop.aspectRatioLabelText}
            dimensionMessage={desktop.uploadSizeText}
            imagePreview={imageCard.image.desktop}
            onClearImage={handleClearDesktopImage}
            onFileSubmit={handleChange('image.desktop')}
          />
          <StyledUploadZone
            label={mobile.aspectRatioLabelText}
            dimensionMessage={mobile.uploadSizeText}
            imagePreview={imageCard.image.mobile}
            onClearImage={handleClearMobileImage}
            onFileSubmit={handleChange('image.mobile')}
          />
        </Section>
      )}
    >
      {renderContent()}
    </StyledEditorWrapper>
  );
};

export default GalleryCardImage;

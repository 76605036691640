import React from 'react';
import { AllOptionsLabel, CheckIcon, FilterBy, FilterIcon, FilterTitle, FilterWrapper, FiltersWrapper, Option, StyledLabel, StyledReactSelect } from './styles';
import { useAdminTranslation } from 'hooks/use-translation';
import { useEventCalendarContext } from 'components/page-blocks/EventCalendar/EventCalendarContext';
import { FormatOptionLabelMeta } from 'react-select';

export interface IFilterOption {
  color: string;
  value: string;
}

export interface IFilter {
  options: IFilterOption[];
  title: string;
}

interface IFilterProps {
  filter: IFilter;
}

const Filter: React.FC<IFilterProps> = ({ filter }) => {
  const { selectedSiteCategories, setSelectedSiteCategories } = useEventCalendarContext();
  const { t } = useAdminTranslation();

  const onChangeFilter = React.useCallback((option: IFilterOption) => {
    setSelectedSiteCategories({
      ...selectedSiteCategories,
      [filter.title]: option,
    });
  }, [filter, selectedSiteCategories, setSelectedSiteCategories]);

  const formatOptionLabel = React.useCallback((option: IFilterOption, meta: FormatOptionLabelMeta<IFilterOption, false>) => {
    const isSelected = selectedSiteCategories[filter.title] && selectedSiteCategories[filter.title].value === option.value;
    const croppedTitle = option.value.length > 8 ? `${option.value.slice(0, 8)}...` : option.value;

    if (!option.value) {
      return (
        <Option>
          {isSelected && meta.context === 'menu' && <CheckIcon />}
          <AllOptionsLabel isSelected={isSelected}>{t('ADMIN_LABEL_ALL')}</AllOptionsLabel>
        </Option>
      );
    }

    return (
      <Option>
        {isSelected && meta.context === 'menu' && <CheckIcon />}
        <StyledLabel isSelected={isSelected} backgroundColor={option.color}>{croppedTitle}</StyledLabel>
      </Option>
    );
  }, [filter.title, selectedSiteCategories, t]);

  return (
    <FilterWrapper>
      <FilterTitle>{filter.title}</FilterTitle>
      <StyledReactSelect
        options={filter.options}
        onChange={onChangeFilter}
        value={selectedSiteCategories[filter.title]}
        formatOptionLabel={formatOptionLabel}
      />
    </FilterWrapper>
  );
};

const Filters: React.FC = () => {
  const { siteCategories } = useEventCalendarContext();
  const { t } = useAdminTranslation();

  if (!siteCategories.length) {
    return null;
  }

  return (
    <FiltersWrapper>
      <FilterBy>
        <FilterIcon />
        {t('ADMIN_LABEL_FILTER_BY')}:
      </FilterBy>
      {siteCategories.map(category => (
        <Filter
          filter={{
            title: category.title,
            options: category.labels.map(label => ({
              value: label.value,
              color: label.color,
            })),
          }}
          key={category.title}
        />
      ))}
    </FiltersWrapper>
  );
};

export default Filters;

import { connect } from 'react-redux';
import { trackOverlay } from 'services/insights';
import { engageOverlayCta, removeOverlay } from 'services/overlays';
import { isVideoOnlyMode } from 'services/user-layout';
import OverlayCta from './view';

const mapStateToProps = state => ({
  isVideoOnlyMode: isVideoOnlyMode(state),
});

const mapDispatchToProps = dispatch => ({
  onDismiss: key => dispatch(removeOverlay(key)),
  onEngagedOverlay: broadcastId => dispatch(engageOverlayCta(broadcastId)),
  trackOverlay: doc => dispatch(trackOverlay('engage', doc)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OverlayCta);

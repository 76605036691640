/* eslint react/destructuring-assignment: off */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { rgba } from 'colors';
import TranslatedText from 'components/i18n/TranslatedText';
import CloseButton from 'components/ui/CloseButton';
import Icon from 'components/ui/Icon';
import { SURFACE_3, SPACING_SMALL, TEXT_100 } from 'style/constants';
import { PADDING_SMALL } from 'style/mixins';

const Container = styled.div`
  align-items: stretch;
  background-color: ${props => rgba(SURFACE_3(props), 0.9)};
  color: ${TEXT_100};
  display: flex;
  flex-flow: row nowrap;
  & > :not(:first-child) {
    margin-left: ${SPACING_SMALL};
  }
`;

const LeftSidebar = styled.div`
  padding: ${SPACING_SMALL};
  padding-right: 0;
`;

const Message = styled.div`
  flex: 1 1 auto;
  padding: ${SPACING_SMALL} 0;
`;

const Reward = styled.div`
  color: ${props => rgba(TEXT_100(props), 0.9)};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  ${PADDING_SMALL};
  padding-left: 0;
`;

export default class RaffleWinnerOverlay extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      key: PropTypes.string.isRequired,
      payload: PropTypes.shape({
        data: PropTypes.shape({
          reward: PropTypes.string,
          winner: PropTypes.shape({
            _id: PropTypes.string,
            name: PropTypes.string,
          }),
        }).isRequired,
      }).isRequired,
    }).isRequired,
    onDismiss: PropTypes.func.isRequired,
    overlayType: PropTypes.string.isRequired,
    trackDismiss: PropTypes.func.isRequired,
    userId: PropTypes.string,
  };

  static defaultProps = {
    userId: {}, // So === will always be false
  };

  dismissRaffle = () => {
    const { onDismiss, trackDismiss, data } = this.props;
    onDismiss(data.key);
    trackDismiss(data);
  };

  render() {
    const { userId } = this.props;
    const { reward, winner } = this.props.data.payload.data;
    const { _id: winnerId, name: winnerName } = winner;
    const userWon = winnerId === userId;
    const { overlayType } = this.props;
    return (
      <Container className={overlayType}>
        <LeftSidebar>
          <CloseButton onClick={this.dismissRaffle} />
        </LeftSidebar>
        <Message>
          {
            userWon && (
              <TranslatedText stringKey="RAFFLE_WINNER_YOU" />
            )
          }
          {
            !userWon && winnerId && (
              <React.Fragment>
                { winnerName } <TranslatedText stringKey="RAFFLE_WINNER_OTHER_FRAGMENT" />
              </React.Fragment>
            )
          }
          {
            !userWon && !winnerId && (
              <TranslatedText stringKey="RAFFLE_WINNER_NOBODY" />
            )
          }
        </Message>
        <Reward>
          {
            winnerId && (
              <React.Fragment>
                <Icon name="award" />{ ' ' }
                <TranslatedText stringKey="RAFFLE_WINNER_REWARD_PREFIX" />{ ' ' }
                {
                  reward ? (
                    <span>{reward}</span>
                  ) : (
                    <TranslatedText stringKey="RAFFLE_WINNER_REWARD_DEFAULT" />
                  )
                }
              </React.Fragment>
            )
          }
        </Reward>
      </Container>
    );
  }
}

import { TEXT_100, TEXT_200, TEXT_300 } from 'style/constants';
import { TEXT_BODY_L_REGULAR, TEXT_BODY_S_REGULAR, TEXT_HEADLINE_S, TEXT_LABEL_S_BOLD, TEXT_LABEL_S_MEDIUM, TEXT_TITLE_M } from 'style/design-system/textStyles';
import { MULTI_LINE_ELLIPSIS, THEME_PADDING_DESKTOP, desktopOnly, mobileOnly } from 'style/mixins';
import styled from 'styled-components';
import { IVideoSpotlight } from '../utils';
import Tags from 'components/Video/video-object/VideoMetadata/Tags';

export const Content = styled.div<{ alignment: IVideoSpotlight['alignment']; }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  min-width: calc((100% - ${THEME_PADDING_DESKTOP}) / 2);
  text-align: ${({ alignment }) => alignment};
  padding: 20px 0;
`;

export const Title = styled.h2`
  ${desktopOnly`${TEXT_HEADLINE_S}`}
  ${mobileOnly`${TEXT_TITLE_M}`}
  margin: 0;
  color: ${TEXT_100};
`;

export const Description = styled.h5`
  ${desktopOnly`${TEXT_BODY_L_REGULAR}`}
  ${mobileOnly`${TEXT_BODY_S_REGULAR}`}
  margin: 0;
  color: ${TEXT_200};
  ${MULTI_LINE_ELLIPSIS};
  white-space: pre-wrap;
`;

const getJustifyContentByAlignment = (alignment: IVideoSpotlight['alignment']) => {
  switch (alignment) {
    case 'right':
      return 'flex-end';
    case 'center':
      return 'center';
    case 'left':
      return 'flex-start';
    default:
      return 'unset';
  }
};

export const VideoTags = styled(Tags)<{ alignment: IVideoSpotlight['alignment']; }>`
  justify-content: ${({ alignment }) => getJustifyContentByAlignment(alignment)};
`;

export const UploadDate = styled.span`
  ${desktopOnly`${TEXT_LABEL_S_BOLD}`}
  ${mobileOnly`${TEXT_LABEL_S_MEDIUM}`}
  color: ${TEXT_300};
`;

/* eslint react/destructuring-assignment: off */
import React from 'react';
import PropTypes from 'prop-types';
import TranslatedText from 'components/i18n/TranslatedText';
import Price from 'components/page-blocks/Shopify/Price';
import OverlayHeader from '../OverlayHeader';
import {
  Container,
  StyledClickableImage,
  DescriptionContainer,
  ProductLabel,
  PriceContainer,
  BackgroundColorContent,
  StyledPrice,
  ProductImage,
  ShopifyContainer,
  BuyButton,
  EllipsisContainer,
} from './styles';

export default class ShopifyOverlay extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      key: PropTypes.string.isRequired,
      payload: PropTypes.shape({
        data: PropTypes.object.isRequired,
      }).isRequired,
      timeRemaining: PropTypes.number,
    }).isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isMobileLayout: PropTypes.bool.isRequired,
    isVideoOnlyMode: PropTypes.bool.isRequired,
    onDismiss: PropTypes.func.isRequired,
    overlayType: PropTypes.string.isRequired,
    renderer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    showShopifyPanel: PropTypes.func.isRequired,
    trackDismiss: PropTypes.func.isRequired,
  };

  render() {
    const { product, variant, image } = this.props.renderer;
    const {
      addProductToCart,
      data: {
        key,
        timeRemaining,
        payload: {
          data: { broadcastId, views, duration },
        },
      },
      renderer: {
        imageUrl,
      },
      isVideoOnlyMode,
      showShopifyPanel,
      isAdmin,
      onDismiss,
      trackDismiss,
      isMobileLayout,
    } = this.props;
    const showPreview = key?.startsWith('preview');

    if (isVideoOnlyMode) {
      return (
        <div>
          <StyledClickableImage src={imageUrl} />
        </div>
      );
    }

    const handleBuy = () => {
      addProductToCart({
        productId: product.value,
        variantId: variant.value,
      });
      showShopifyPanel({ kind: 'shopifyBlockPanel', product, variant });
    };

    return (
      product && variant ? (
        <Container className={this.props.overlayType}>
          <OverlayHeader
            broadcastId={broadcastId}
            data={this.props.data}
            defaultState={{ engaged: 0 }}
            duration={duration}
            isAdmin={isAdmin}
            onDismiss={onDismiss}
            overlayType="shopify"
            showPreview={showPreview}
            timeRemaining={timeRemaining}
            trackDismiss={trackDismiss}
            views={views}
          />
          <ShopifyContainer>
            <ProductImage disable={!image?.src && !image?.url} src={image?.src || image?.url} />
            <DescriptionContainer>
              <ProductLabel>
                <EllipsisContainer isMobile={isMobileLayout}>{product?.label}</EllipsisContainer>
              </ProductLabel>
              <PriceContainer>
                <BackgroundColorContent />
                <StyledPrice>
                  <Price isUSD keepDecimalsFontSize price={variant?.price} />
                </StyledPrice>
                <BuyButton onClick={handleBuy}>
                  <TranslatedText stringKey="SHOP_NOW" />
                </BuyButton>
              </PriceContainer>
            </DescriptionContainer>
          </ShopifyContainer>
        </Container>
      ) : null
    );
  }
}

import React from 'react';
import styled from 'styled-components';
import { BACKGROUND_ACTIVE, TITLE_FONT } from 'style/mixins';

const FlexWrapper = styled.div`
  ${BACKGROUND_ACTIVE}
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 5px;
  ${TITLE_FONT}
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
`;

export default class VideoOnlyOverrideCta extends React.Component {
  render() {
    const tempMessage = 'Visit www.maestro.io';

    return (
      <div>
        <FlexWrapper>{ tempMessage }</FlexWrapper>
      </div>
    );
  }
}

import { CTAButton } from 'components/ui/CTAButton';
import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';
import InlineTextEditor from 'components/admin2/ui/InlineTextEditor';
import CTAButtonEditorModal from 'components/admin-bridge/CTAEditorModal';
import { FONT_FAMILY_BODY, FONT_FAMILY_TITLE, MIN_HERO_BANNER_HEIGHT_PX, MIN_HERO_BANNER_MOBILE_HEIGHT_PX, SURFACE_1, TEXT_100, TEXT_200 } from 'style/constants';
import { desktopOnly, mobileOnly, THEME_PADDING } from 'style/mixins';
import styled, { css } from 'styled-components';

export const StyledQuickEditOverlay = styled(QuickEditOverlay)<{ bleed: boolean; hasBackgroundImage: boolean; imageHeight: number; }>`
  ${THEME_PADDING}
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  ${({ hasBackgroundImage }) => !hasBackgroundImage && css`background-color: ${SURFACE_1}`};

  ${({ bleed }) => bleed && desktopOnly`max-height: ${MIN_HERO_BANNER_HEIGHT_PX}px;`};
  ${({ bleed }) => bleed && mobileOnly`max-height: ${MIN_HERO_BANNER_MOBILE_HEIGHT_PX}px !important`};

  ${({ imageHeight }) => mobileOnly`
    height: ${imageHeight || MIN_HERO_BANNER_MOBILE_HEIGHT_PX}px;
    justify-content: flex-end;
  `};

  ${({ imageHeight }) => desktopOnly`
    height: ${imageHeight || MIN_HERO_BANNER_HEIGHT_PX}px;
  `};
`;

export const CallToActionsWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  margin-top: 30px;
  flex-wrap: wrap;
  row-gap: 10px;
`;

export const Title = styled(InlineTextEditor)`
  ${desktopOnly`max-width: 543px;`};

  & .mce-content-body {
    & * {
      font-family: ${FONT_FAMILY_TITLE};
      ${mobileOnly`font-size: 36px !important;`};
    }
    color: ${TEXT_100};
  }
`;

export const Description = styled(InlineTextEditor)`
  margin-top: 12px;
  ${desktopOnly`max-width: 543px;`};

  & .mce-content-body {
    & * {
      font-family: ${FONT_FAMILY_BODY};
      ${mobileOnly`font-size: 14px !important;`};
    }
    color: ${TEXT_200};
  }
`;

export const CTAEditorModal = styled(CTAButtonEditorModal)`
  cursor: pointer;
  & button {
    min-width: 181px !important;
  }

  ${mobileOnly`
    & button {
      min-width: fit-content !important;
    }
  `};
`;


export const StyledCTAButton = styled(CTAButton)`
  min-width: 158px;
`;

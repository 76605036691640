import Button from 'components/admin2/ui/Button';
import styled from 'styled-components';

export const AddMoreButton = styled(Button)`
  height: 24px;
  padding: 0px 14px 0px 10px;
  & > *:first-child {
    width: 16px;
    height: 16px;
    display: flex;
  }
`;

import React from 'react';

type UseEventLiveStatusProps = {
  endTimestamp: number;
  startTimestamp: number;
  useEndTime: boolean;
};

const useCalendarEventLiveStatus = ({ startTimestamp, endTimestamp, useEndTime }: UseEventLiveStatusProps) => {
  const [isLive, setIsLive] = React.useState(false);

  React.useEffect(() => {
    setIsLive(false);
    if (!useEndTime) {
      return;
    }

    const startDate = new Date(startTimestamp);
    const endDate = new Date(endTimestamp);

    const ended = endDate.getTime() < Date.now();
    if (ended) {
      return;
    }

    const onlineTimeout = setTimeout(() => {
      setIsLive(true);
    }, Math.max(0, startDate.getTime() - Date.now()));
    const offlineTimeout = setTimeout(() => {
      setIsLive(false);
    }, Math.max(0, endDate.getTime() - Date.now()));

    return () => {
      clearTimeout(onlineTimeout);
      clearTimeout(offlineTimeout);
    };
  }, [startTimestamp, endTimestamp]);

  return isLive;
};

export default useCalendarEventLiveStatus;

import React, { memo, MouseEventHandler, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SYSTEM_MESSAGE, removeMessage } from 'services/chat';
import { DynamicTranslationType, useAdminTranslation } from 'hooks/use-translation';
import { isUserChatModeratorRole, isUserAdmin } from 'services/auth';
import { MessageBody, MessageBodyProps } from '../MessageBody';
import Icon from 'components/ui/Icon';
import {
  MessageItemWrapper,
  AdminBadge,
  DeleteButtonWithConfirmation,
} from './styles';
import { UserInfo } from '../UserInfo';
import { useEndUserTranslationWithConditionalLocalization } from 'components/objects/Panel/utils';
import { isEditMode } from 'services/admin';

export type IMessageItemProps = {
  allowLinks?: boolean;
  avatar: string;
  chatId: string;
  content: string;
  /**
   * Whether the message was sent by an admin
   */
  isFromAdmin: boolean;
  /**
   * Whether the message was sent by a moderator
   */
  isFromChatModerator?: boolean;
  /**
   * Whether the message is a system message
   */
  isFromSystem?: boolean;
  messageId?: string;
  onMessageDeleted?: (messageId: string) => unknown;
  testId?: string;
  userId?: string;
  userName: string;
} & Pick<
  MessageBodyProps,
  | 'filteredWordsList'
>;

const MessageItem: React.FC<IMessageItemProps> = memo(
  ({
    allowLinks,
    avatar,
    chatId,
    content,
    filteredWordsList,
    isFromAdmin,
    isFromChatModerator,
    isFromSystem,
    messageId,
    onMessageDeleted,
    testId,
    userId,
    userName,
  }) => {
    const dispatch = useDispatch();
    const { t } = useAdminTranslation();
    const { endUserT } = useEndUserTranslationWithConditionalLocalization();
    const isEditing = useSelector(isEditMode);

    const currentUserIsAnAdmin = useSelector(isUserAdmin);
    const currentUserIsModerator = useSelector(isUserChatModeratorRole);
    const currentUserCanEdit = currentUserIsAnAdmin || currentUserIsModerator;

    const shouldShowModerationOptions = currentUserCanEdit && !isFromSystem;

    const handleDeleteMessage = useCallback<MouseEventHandler>(
      (ev) => {
        ev.preventDefault();

        if (!messageId) {
          // tslint:disable-next-line:no-console
          console.warn(`Chat message has no 'messageId'. Can't delete it.`);
          return;
        }

        dispatch(
          removeMessage({
            channel: chatId,
            messageKey: messageId,
          }),
        );

        onMessageDeleted?.(messageId);
      },
      [chatId, messageId, onMessageDeleted],
    );

    return (
      <MessageItemWrapper data-testid={testId}>
        <UserInfo
          isPrivileged={isFromAdmin || isFromChatModerator}
          profilePictureUrl={avatar}
          showModerationOptions={shouldShowModerationOptions}
          userName={userName}
          chatId={chatId}
          userId={userId}
        />
        {' '}
        <MessageBody
          // we always render clickable hyperlinks for moderators since it makes
          // it easier for them to inspect the content of the message
          allowLinks={allowLinks || currentUserCanEdit}
          text={isSystemMessage(content) ? t(content) : content}
          filteredWordsList={filteredWordsList}
          // TODO: handle html?
        />
        {
          isFromAdmin && (
            <AdminBadge>
            {endUserT(
              [DynamicTranslationType.chatMessageAdminLabel],
              ['CHAT_MESSAGE_ITEM_ADMIN'],
              isEditing,
            )}
          </AdminBadge>
          )
        }
        {
          shouldShowModerationOptions && (
            <DeleteButtonWithConfirmation
              action={handleDeleteMessage}
              messageKey="REMOVE_CHAT_MESSAGE_PROMPT"
            >
              <Icon name="trash" />
              {t('ACTION_DELETE')}
            </DeleteButtonWithConfirmation>
          )
        }
      </MessageItemWrapper>
    );
  },
);

const isSystemMessage = (message: string): message is SYSTEM_MESSAGE => (
  Object.values(SYSTEM_MESSAGE).includes(message as any)
);

export default MessageItem;

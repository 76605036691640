import { OverflowBehavior } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';
import Button from 'components/admin2/ui/Button';
import QuestCard from 'components/objects/QuestCard';
import Icon from 'components/ui/Icon';
import { ExternalPageSlider } from 'components/ui/PageSlider';
import { SURFACE_2, SURFACE_5, TEXT_200 } from 'style/constants';
import { ADMIN_TEXT_BODY_M_MEDIUM } from 'style/design-system/textStyles';
import styled from 'styled-components';
import { css } from 'styled-components';

const SCROLLABLE_CSS = css`
  display: flex;
  & > * {
    min-width: calc(100% - 80px);
  }
`;

const NON_SCROLLABLE_CSS = css<{ overflowBehavior: OverflowBehavior; questsPerRow: number; }>`
  display: grid;
  grid: ${({ overflowBehavior }) => overflowBehavior === 'stack' ? 'auto-flow' : '1fr'} / repeat(${({ questsPerRow }) => questsPerRow}, 1fr);
  grid-auto-rows: min-content;
  & > * {
    min-width: 0;
  }
`;

export const AddMoreBadge = styled(Button)`
  height: 24px;
  padding: 0px 14px 0px 10px;
  & > *:first-child {
    width: 16px;
    height: 16px;
    display: flex;
  }
`;

export const StyledExternalPageSlider = styled(ExternalPageSlider<string>)`
  min-height: 450px;
`;

export const AddIcon = styled(Icon).attrs({
  name: 'plus-thin',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;

  & svg {
    width: 28px;
    height: 28px;
  }
`;


export const AddQuestButton = styled.button`
  border-radius: 20px;
  height: 270px;
  width: 390px;
  background-color: ${SURFACE_2};
  border: 1px solid ${SURFACE_5};
  ${ADMIN_TEXT_BODY_M_MEDIUM}
  color: ${TEXT_200};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  justify-self: center;
  margin-top: 44px;
  margin-bottom: 44px;
  max-width: 100%;
`;

export const StyledQuestCard = styled(QuestCard)`
  & .quest-content {
    ${({ region }) => region === 'landingPageBlock' && css`height: 350px;`}
  }
`;

export const GalleryWrapper = styled.div<{
  isScrollable: boolean;
  overflowBehavior: OverflowBehavior;
  questsPerRow: number;
}>`
  position: relative;
  ${({ overflowBehavior }) => css`gap: min(1vw, ${overflowBehavior === 'stack' ? 60 : 40}px);`};
  ${({ isScrollable }) => isScrollable ? SCROLLABLE_CSS : NON_SCROLLABLE_CSS};
`;

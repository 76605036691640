import { RANK_ICONS_MAP, RankDivision } from 'models/IFortniteLeaderboard';
import React from 'react';

const RankBadge = React.memo(({ rank }: { rank: RankDivision; }) => {
  const [badge, setBadge] = React.useState('');

  if (badge === '') {
    import(`./badges/${RANK_ICONS_MAP[rank]}`)
      .then((result) => setBadge(result.default));
  }

  if (!badge) {
    return null;
  }

  return <img src={badge} alt={RANK_ICONS_MAP[rank]} />;
}, (prevProps, nextProps) => prevProps.rank === nextProps.rank);

export default RankBadge;

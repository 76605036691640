import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import styled from 'styled-components';

import { matchMedia } from 'dom-utils';
import { MOBILE_LANDSCAPE_MEDIA } from 'style/constants';
import { BACKGROUND_ACTIVE, TITLE_FONT } from 'style/mixins';
import { withEndUserT, DynamicTranslationType } from 'hooks/use-translation';

const ClickableContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  max-width: 90px;
`;

const FlexWrapper = styled.div`
  ${BACKGROUND_ACTIVE}
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 5px;
  ${TITLE_FONT};
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
`;

const ButtonText = styled.span`
  text-align: center;
  padding-top: 5px;
`;

class ShowPlayerStatsCta extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    ctaRenderer: PropTypes.shape({
      playerData: PropTypes.object,
      playerId: PropTypes.string,
    }).isRequired,
    endUserT: PropTypes.func.isRequired,
    isLandscape: PropTypes.bool.isRequired,
    isMobileLayout: PropTypes.bool.isRequired,
    onAddMessageOverlay: PropTypes.func.isRequired,
    onEngagedOverlay: PropTypes.func.isRequired,
    onOpenPanel: PropTypes.func.isRequired,
    testId: PropTypes.string,
  };

  static defaultProps = {
    children: undefined,
    testId: undefined,
  };

  onOpenPanelClick = () => {
    const {
      ctaRenderer,
      isLandscape,
      isMobileLayout,
      onEngagedOverlay,
      onOpenPanel,
      onAddMessageOverlay,
    } = this.props;

    const { playerId, playerData } = ctaRenderer;

    onEngagedOverlay();

    if (playerId) {
      onOpenPanel({
        doc: {
          renderer: {
            panelType: 'playerStats',
            playerData,
            playerId,
          },
        },
      });

      if (isMobileLayout && isLandscape && matchMedia(MOBILE_LANDSCAPE_MEDIA).matches) {
        const overlayPayload = {
          key: shortid.generate(),
          payload: {
            data: {
              duration: 15,
              kind: 'message',
              message: 'Please rotate your phone',
              trackName: 'Message: Please rotate your phone',
            },
            removeOnPortrait: true,
          },
        };

        onAddMessageOverlay(overlayPayload);
      }
    }
  };

  render() {
    const { children, endUserT, testId } = this.props;

    return (
      <ClickableContainer data-testid={testId} onClick={this.onOpenPanelClick}>
        {
          children || (
            <FlexWrapper>
              <ButtonText>
                {
                  endUserT(
                    [DynamicTranslationType.overlayCtaViewStats],
                    ['VIEW_STATS'],
                  )
                }
              </ButtonText>
            </FlexWrapper>
          )
        }
      </ClickableContainer>
    );
  }
}

export default withEndUserT(ShowPlayerStatsCta);

import Icon from 'components/ui/Icon';
import { ACCENT_PRIMARY, SURFACE_1, SURFACE_2, TEXT_100 } from 'style/constants';
import styled from 'styled-components';
import { ProfilePanelButton } from 'components/ui/Button/styles';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_XS_BOLD, ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid ${ACCENT_PRIMARY};
  flex-shrink: 0;
`;

export const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${SURFACE_2};
  padding: 10px 18px;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const BottomHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 18px;
  flex-shrink: 0;
`;

export const SettingsLabel = styled.div`
  ${ADMIN_TEXT_BODY_XS_BOLD}
  color: ${TEXT_100};
  text-transform: uppercase;
`;

export const UserProfileLabel = styled.div`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  color: ${TEXT_100}
`;

export const ProfileSettingsLabel = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${TEXT_100}
`;

export const LogoutButton = styled(ProfilePanelButton).attrs({
  'data-testid': 'upLogOutButton',
})`
  height: 24px;
  width: 97px;
`;

export const CloseButtonIcon = styled.div`
  background: ${SURFACE_1};
  cursor: pointer;
  width: 21px;
  height: 21px;
  background: black;
  border-radius: 4.54px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseIcon = styled(Icon)`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  color: rgba(0, 0, 0, 0.5);
  svg {
    width: 7px;
    height: 7px;
  }
`;

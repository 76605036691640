import React from 'react';
import { GeneralProps } from '.';
import useDebounceWithFunction from 'hooks/use-debounce-with-function';
import { Section, SubSectionTitle } from 'components/admin-bridge/EditorModal/ModalComponents/Section';
import { OptionRow } from 'components/admin-bridge/EditorModal';
import { GRADIENT_MASK_OPTIONS } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';
import TranslatedText from 'components/i18n/TranslatedText';
import { DesktopBackgroundUploadZone, MobileBackgroundUploadZone, StyledDropdown } from './styles';
import { OBJECT_FIT_LABEL_MAP, OBJECT_FIT_OPTIONS } from '../utils';
import { GRID_TEXT_ALIGNMENT, GRID_TEXT_ALIGNMENT_WORD_MAP } from 'components/admin-bridge/EditorModal/ModalComponents/GridTextAlignment/types';
import Slider from 'components/admin-bridge/EditorModal/ModalComponents/Slider';
import Divider from 'components/admin2/Divider';

const ImageBackground = ({ handleChange, item }: GeneralProps) => {
  const [desktopOverlayOpacity, setDesktopOverlayOpacity] = React.useState(item.background.image.desktop.overlayOpacity);
  const [mobileOverlayOpacity, setMobileOverlayOpacity] = React.useState(item.background.image.mobile.overlayOpacity);

  useDebounceWithFunction(() => {
    if (desktopOverlayOpacity !== item.background.image.desktop.overlayOpacity) {
      handleChange('background.image.desktop.overlayOpacity')(desktopOverlayOpacity);
    }
  }, desktopOverlayOpacity, 500);

  useDebounceWithFunction(() => {
    if (mobileOverlayOpacity !== item.background.image.mobile.overlayOpacity) {
      handleChange('background.image.mobile.overlayOpacity')(mobileOverlayOpacity);
    }
  }, mobileOverlayOpacity, 500);

  const handleClearDesktopBackground = React.useCallback(() => {
    handleChange('background.image.desktop.url')('');
  }, [handleChange]);

  const handleClearMobileBackground = React.useCallback(() => {
    handleChange('background.image.mobile.url')('');
  }, [handleChange]);

  return (
    <>
      <Section gap={20}>
        <Section gap={16}>
          <TranslatedText component={SubSectionTitle} stringKey="GRADIENT_MASK" />
          <OptionRow
            onChange={handleChange('background.image.gradientMask')}
            options={GRADIENT_MASK_OPTIONS}
            value={item.background.image.gradientMask}
          />
        </Section>

        <Section gap={16}>
          <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_DESKTOP" />
          <DesktopBackgroundUploadZone
            imagePreview={item.background.image.desktop.url}
            onClearImage={handleClearDesktopBackground}
            onFileSubmit={handleChange('background.image.desktop.url')}
          />
        </Section>

        <Section gap={16}>
          <TranslatedText component={SubSectionTitle} stringKey="DISPLAY_AND_POSITION" />
          <div style={{ display: 'flex', gap: '6px' }}>
            <StyledDropdown
              onChange={handleChange('background.image.desktop.display')}
              options={OBJECT_FIT_OPTIONS}
              value={{ value: item.background.image.desktop.display, label: OBJECT_FIT_LABEL_MAP[item.background.image.desktop.display] }}
            />
            <StyledDropdown
              onChange={handleChange('background.image.desktop.position')}
              options={Object.keys(GRID_TEXT_ALIGNMENT).map(key => ({ value: key, label: GRID_TEXT_ALIGNMENT_WORD_MAP[key] }))}
              value={{ value: item.background.image.desktop.position, label: GRID_TEXT_ALIGNMENT_WORD_MAP[item.background.image.desktop.position] }}
            />
          </div>

          <Section gap={16}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <TranslatedText component={SubSectionTitle} stringKey="OVERLAY_OPACITY" />
              <SubSectionTitle>{desktopOverlayOpacity}%</SubSectionTitle>
            </div>
            <Slider
              onChange={setDesktopOverlayOpacity}
              value={desktopOverlayOpacity}
              step={1}
              min={0}
              max={100}
            />
          </Section>
        </Section>

        <Divider />

        <Section gap={20}>
          <Section gap={16}>
            <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_MOBILE" />
            <MobileBackgroundUploadZone
              imagePreview={item.background.image.mobile.url}
              onClearImage={handleClearMobileBackground}
              onFileSubmit={handleChange('background.image.mobile.url')}
            />
          </Section>

          <Section gap={16}>
            <TranslatedText component={SubSectionTitle} stringKey="DISPLAY_AND_POSITION" />
            <div style={{ display: 'flex', gap: '6px' }}>
              <StyledDropdown
                onChange={handleChange('background.image.mobile.display')}
                options={OBJECT_FIT_OPTIONS}
                value={{ value: item.background.image.mobile.display, label: OBJECT_FIT_LABEL_MAP[item.background.image.mobile.display] }}
              />
              <StyledDropdown
                onChange={handleChange('background.image.mobile.position')}
                options={Object.keys(GRID_TEXT_ALIGNMENT).map(key => ({ value: key, label: GRID_TEXT_ALIGNMENT_WORD_MAP[key] }))}
                value={{ value: item.background.image.mobile.position, label: GRID_TEXT_ALIGNMENT_WORD_MAP[item.background.image.mobile.position] }}
              />
            </div>
          </Section>

          <Section gap={16}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <TranslatedText component={SubSectionTitle} stringKey="OVERLAY_OPACITY" />
              <SubSectionTitle>{mobileOverlayOpacity}%</SubSectionTitle>
            </div>
            <Slider
              onChange={setMobileOverlayOpacity}
              value={mobileOverlayOpacity}
              step={1}
              min={0}
              max={100}
            />
          </Section>
        </Section>
      </Section>
    </>
  );
};

export default ImageBackground;

import { TEXT_200 } from 'style/constants';
import { TEXT_LABEL_L_BOLD, TEXT_LABEL_S_BOLD } from 'style/design-system/textStyles';
import { mobileOnly } from 'style/mixins';
import styled from 'styled-components';

export const Time = styled.span`
  ${TEXT_LABEL_L_BOLD}
  color: ${TEXT_200};

  ${mobileOnly`${TEXT_LABEL_S_BOLD}}`}
`;

import { connect } from 'react-redux';
import { showModal } from 'services/modals';
import { engageOverlayCta, removeOverlay } from 'services/overlays';
import { trackOverlay } from 'services/insights';

import InstagramOverlay from './view';

const mapDispatchToProps = dispatch => ({
  onDismiss: key => dispatch(removeOverlay(key)),
  onEngagedOverlay: broadcastId => dispatch(engageOverlayCta(broadcastId)),
  onShowPost: data => dispatch(showModal({ data, kind: 'instagram' })),
  trackDismiss: doc => dispatch(trackOverlay('dismiss', doc)),
  trackEngage: doc => dispatch(trackOverlay('engage', doc)),
});

export default connect(null, mapDispatchToProps)(InstagramOverlay);

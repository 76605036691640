import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/ui/Icon';
import TranslatedText from 'components/i18n/TranslatedText';
import KeyedListMapper from 'components/core/KeyedListMapper';
import RelativeTimestamp from 'components/i18n/RelativeTimestamp';
import { useUserRewards } from 'hooks';
import {
  Award,
  AwardImage,
  ClaimCode,
  ClaimTime,
  RewardsWrapper,
  RewardDesc,
  RewardItem,
  RewardHeader,
  RewardTitle,
  RewardText,
  Reward,
  Title,
  TitleText,
} from './styles';

const RenderRewardsHeader = ({
  active,
  claimDate,
  name,
  onClick,
}) => {
  return (
    <RewardHeader
      active={active}
      onClick={onClick}
    >
      { name }
      {
        claimDate && (
          <ClaimTime>
            <TranslatedText stringKey="CLAIMED" />
            <RelativeTimestamp target={claimDate} />
          </ClaimTime>
        )
      }
    </RewardHeader>
  );
};

RenderRewardsHeader.propTypes = {
  active: PropTypes.bool.isRequired,
  claimDate: PropTypes.number,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
RenderRewardsHeader.defaultProps = {
  claimDate: null,
};

const RenderRewardsDetail = ({
  claimCode,
  image,
  reward,
}) => {
  return (
    <React.Fragment>
      <RewardDesc>
        <Award>
          { image ? <AwardImage src={image} /> : <Icon name="award" /> }
        </Award>
        <RewardTitle>
          <RewardText>
            <TranslatedText stringKey="REWARD" />
          </RewardText>
          <Reward>{ reward }</Reward>
        </RewardTitle>
      </RewardDesc>
      {
        claimCode && (
          <ClaimCode>{ claimCode }</ClaimCode>
        )
      }
    </React.Fragment>
  );
};

RenderRewardsDetail.propTypes = {
  claimCode: PropTypes.string,
  image: PropTypes.string,
  reward: PropTypes.string.isRequired,
};

RenderRewardsDetail.defaultProps = {
  claimCode: null,
  image: null,
};

const RenderTitle = ({
  iconName,
  stringKey,
}) => {
  return (
    <Title>
      <Icon name={iconName} />
      <TitleText>
        <TranslatedText stringKey={stringKey} />
      </TitleText>
    </Title>
  );
};

RenderTitle.propTypes = {
  iconName: PropTypes.string.isRequired,
  stringKey: PropTypes.string.isRequired,
};

const RewardsPage = () => {
  const { loaded, data } = useUserRewards();
  const [quests, setQuests] = useState([]);
  const [raffles, setRaffles] = useState([]);
  const [activeMap, setActiveMap] = useState({});

  const toggleActive = useCallback((type, id) => {
    const key = [type, id].join(':');
    setActiveMap({
      ...activeMap,
      [key]: !activeMap[key],
    });
  }, [
    activeMap,
    quests,
    raffles,
  ]);

  useEffect(() => {
    if (loaded && data) {
      setQuests(data.questRewards);
      setRaffles(data.raffleRewards);
    } else {
      setQuests([]);
      setRaffles([]);
    }
  }, [
    loaded,
    data,
  ]);

  return (
    <RewardsWrapper>
      {
        quests.length > 0 && (
          <React.Fragment>
            <RenderTitle iconName="award" stringKey="REWARDS_YOUVE_RECEIVED" />
            <KeyedListMapper keyField="questId" list={quests}>
              {
                (key, quest) => {
                  const isActive = !!activeMap[`quests:${quest.questId}`];
                  return (
                    <RewardItem
                      key={key}
                      active={isActive}
                    >
                      <RenderRewardsHeader
                        active={isActive}
                        claimDate={quest.claimDate}
                        name={quest.rewardDescription}
                        onClick={() => toggleActive('quests', quest.questId)}
                      />
                      {
                        isActive && (
                          <RenderRewardsDetail
                            claimCode={quest.claimCode}
                            image={quest.image}
                            reward={quest.reward}
                          />
                        )
                      }
                    </RewardItem>
                  );
                }
              }
            </KeyedListMapper>
          </React.Fragment>
        )
      }
      {
        raffles.length > 0 && (
          <React.Fragment>
            <RenderTitle iconName="ticket" stringKey="RAFFLES_YOUVE_WON" />
            <KeyedListMapper keyField="broadcastId" list={raffles}>
              {
                (key, raffle) => {
                  const isActive = !!activeMap[`raffles:${raffle.broadcastId}`];
                  return (
                    <RewardItem
                      key={key}
                      active={isActive}
                    >
                      <RenderRewardsHeader
                        active={isActive}
                        claimDate={raffle.claimDate}
                        name={raffle.raffleReward}
                        onClick={() => toggleActive('raffles', raffle.broadcastId)}
                      />
                      {
                        isActive && (<RenderRewardsDetail reward={raffle.raffleReward} />)
                     }
                    </RewardItem>
                  );
                }
              }
            </KeyedListMapper>
          </React.Fragment>
        )
      }
    </RewardsWrapper>
  );
};

RewardsPage.propTypes = {};

export default RewardsPage;

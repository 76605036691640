import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import Link from 'components/ui/Link';
import { SOUNDCLOUD_ORANGE, FACEBOOK_BLUE, INSTAGRAM_BLUE } from 'style/constants';
import PaymentMethodContainer from 'components/payment-methods/PaymentMethodContainer';
import EntitlementsContainer from 'components/panels/EntitlementsContainer';
import Separator from 'components/panels/Separator';
import StripeElements from 'components/application/StripeElements';
import { STRIPE_PUBLISHABLE_KEY } from 'config';

const SocialWrapper = styled.div`
  display: flex;
  & *:first-child {
    margin-left: 0;
  }
`;

const Bio = styled.div`
  margin: 10px 0;
  overflow: visible !important;
`;

const Container = styled.div`
  padding: 10px;
`;

const SoundcloudButton = styled(Icon)`
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${SOUNDCLOUD_ORANGE};
  color: white;
  margin: 0 5px;
`;

const FacebookButton = styled(Icon)`
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${FACEBOOK_BLUE};
  color: white;
  margin: 0 5px;
`;

const InstagramButton = styled(Icon)`
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${INSTAGRAM_BLUE};
  color: white;
  margin: 0 5px;
`;

export default class InfoPage extends React.Component {
  static propTypes = {
    defaultPaymentId: PropTypes.string,
    paymentMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
    siteTheme: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    userData: PropTypes.shape({
      bio: PropTypes.string,
      facebookUrl: PropTypes.string,
      instagramUsername: PropTypes.string,
      soundcloudUsername: PropTypes.string,
    }),
  };

  static defaultProps = {
    defaultPaymentId: null,
    userData: {
      bio: '',
      facebookUrl: '',
      instagramUsername: '',
      soundcloudUsername: '',
    },
  };

  render() {
    const {
      defaultPaymentId,
      paymentMethods,
      userData,
      siteTheme,
    } = this.props;

    const {
      bio,
      soundcloudUsername,
      facebookUrl,
      instagramUsername,
    } = userData || {};

    return (
      <Container>
        <SocialWrapper>
          {
            soundcloudUsername && (
              <Link href={`http://www.soundcloud.com/${soundcloudUsername}`}>
                <SoundcloudButton name="soundcloud" />
              </Link>
            )
          }
          {
            facebookUrl && (
              <Link href={facebookUrl}>
                <FacebookButton name="facebook" />
              </Link>
            )
          }
          {
            instagramUsername && (
              <Link href={`https://www.instagram.com/${instagramUsername}`}>
                <InstagramButton name="instagram" />
              </Link>
            )
          }
        </SocialWrapper>
        <Bio>
          {bio}
        </Bio>
        <Separator />
        <EntitlementsContainer />
        <StripeElements
          apiKey={STRIPE_PUBLISHABLE_KEY}
          theme={siteTheme}
        >
          <PaymentMethodContainer
            addCard
            defaultPaymentId={defaultPaymentId}
            payObjects={paymentMethods}
            shouldRenderMakePrimaryPaymentMethodCheckbox
          />
        </StripeElements>
      </Container>
    );
  }
}

import styled from 'styled-components';
import UploadZone, { IMAGE_ACCEPTED_TYPES } from 'components/admin2/UploadZone';

export const StyledUploadZone = styled(UploadZone).attrs(() => ({
  acceptedTypes: IMAGE_ACCEPTED_TYPES,
  canDrop: true,
  dimensionRequirements: {
    pixelHeight: 480,
    pixelWidth: 960,
  },
  height: 177,
}))`padding: 0;`;

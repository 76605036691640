import React, { PropsWithChildren } from 'react';
import { Arrows, HeaderText, MobileCollapsibleIcon, Wrapper } from './styles';
import { CircledButton } from 'components/ui/ScrollSlider/styles';
import { useEventCalendarContext } from '../../EventCalendarContext';
import MiniCalendarMobileFilters from '../MobileFilters';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';

interface IMiniCalendarHeaderProps extends PropsWithChildren {
  isMobileCalendarOpen: boolean;
  onToggle: () => void;
}

const MiniCalendarHeader: React.FC<IMiniCalendarHeaderProps> = ({ children, onToggle, isMobileCalendarOpen }) => {
  const isMobile = useSelector(isMobileLayout);
  const { setTimestamp, timestamp } = useEventCalendarContext();
  const handleAdvanceMonth = React.useCallback(() => {
    const date = new Date(timestamp);
    date.setMonth(date.getMonth() + 1);
    setTimestamp(date.getTime());
  }, [timestamp]);

  const handleRetreatMonth = React.useCallback(() => {
    const date = new Date(timestamp);
    date.setMonth(date.getMonth() - 1);
    setTimestamp(date.getTime());
  }, [timestamp]);

  return (
    <Wrapper>
      <HeaderText onClick={onToggle}>
        {children}
        <MobileCollapsibleIcon isOpen={isMobileCalendarOpen} name="cleanDownGradArrow" />
      </HeaderText>
      <Arrows>
        <CircledButton
          active={true}
          name="adminbarLt"
          onClick={handleRetreatMonth}
        />
        <CircledButton
          active={true}
          name="adminbarGt"
          onClick={handleAdvanceMonth}
        />
      </Arrows>
      {isMobile && <MiniCalendarMobileFilters />}
    </Wrapper>
  );
};

export default MiniCalendarHeader;

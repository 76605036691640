import EditorWrapper from 'components/admin-bridge/EditorWrapper';
import styled, { css } from 'styled-components';
import { HIGHLIGHT_PRIMARY, SURFACE_3, TEXT_300 } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';
import Icon from 'components/ui/Icon';
import { AspectRatio } from '../../utils';
import UploadZone from 'components/admin2/UploadZone';

export const StyledEditorWrapper = styled(EditorWrapper)`
  height: fit-content;
  border-radius: 5px;
`;

export const NoImage = styled.div<{ aspectRatio: AspectRatio; }>`
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
  border-radius: 5px;
  background-color: ${SURFACE_3};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  column-gap: 10px;

  ${ADMIN_TEXT_LABEL_M_MEDIUM}
  color: ${TEXT_300};
`;

export const AddIcon = styled(Icon)`
  & svg {
    width: 34px;
    height: 34px;

    & path {
      fill: ${HIGHLIGHT_PRIMARY};
    }
  }
`;

export const Image = styled.div<{ aspectRatio: AspectRatio; src: string; }>`
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
  border-radius: 5px;
  ${({ src }) => css`background: center / cover no-repeat url(${src});`};
`;

export const StyledUploadZone = styled(UploadZone).attrs(() => ({
  canDrop: true,
  dimensionRequirements: {
    pixelHeight: 800,
    pixelWidth: 800,
  },
  height: 177,
}))`padding: 0;`;

/* eslint react/destructuring-assignment: off */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { rgba } from 'colors';
import AdminOverlayEngagement from 'components/admin/AdminOverlayEngagement';
import CloseButton from 'components/ui/CloseButton';
// import defaultPerson from 'assets/default-person.svg';
import { mobilePortraitOnly, TEXT_LABEL3 } from 'style/mixins';
import { SURFACE_3, SPACING_SMALL, TEXT_100 } from 'style/constants';
import OverlayCta from 'components/overlays/OverlayCta';

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  ${props => props.hideOnPortrait ?
    mobilePortraitOnly`display: none;` :
    ''}
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  ${TEXT_LABEL3}
  flex-flow: row nowrap;
`;

const ImageWrapper = styled.div`
  align-items: center;
  height: 100%;
`;

const Text = styled.span`
  margin-left: 10px;
`;

const StyledImage = styled.div`
  background-color: black;
  background-image: url("${props => props.imageUrl}");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  height: 80px;
  width: 120px;
  ${props => props.isMobile && 'max-width: 80px'}
  display: flex;
`;

const OverlayCtaContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const OverlayContent = styled.div`
  background-color: ${props => rgba(SURFACE_3(props), 0.9)};
  color: ${TEXT_100};
  display: flex;
  flex-flow: row nowrap;
  padding: 0 ${SPACING_SMALL};
`;

const LeftSidebar = styled.div`
  padding: ${SPACING_SMALL} 10px ${SPACING_SMALL} 0;
  display: flex;
  align-items: center;
`;

export default class HighlightOverlay extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    isMobile: PropTypes.bool.isRequired,
    onDismiss: PropTypes.func.isRequired,
    overlayType: PropTypes.string.isRequired,
    renderer: PropTypes.shape({
      ctaRenderer: PropTypes.object,
      description: PropTypes.string,
      imageUrl: PropTypes.string,
    }).isRequired,
    trackDismiss: PropTypes.func.isRequired,
  };

  handleDismiss = () => {
    const { data, onDismiss, trackDismiss } = this.props;
    trackDismiss(data);
    onDismiss(data.key);
  };

  render() {
    const {
      data: {
        key,
        payload: {
          data: { broadcastId, views },
          removeOnPortrait,
        },
      },
      renderer: {
        ctaRenderer,
        description,
        imageUrl,
      },
      isMobile,
    } = this.props;

    const hasCta = Boolean(ctaRenderer);
    const { overlayType } = this.props;

    return (
      <Container hideOnPortrait={removeOnPortrait}>
        <AdminOverlayEngagement
          broadcastId={broadcastId}
          broadcastKey={key}
          showEngaged={hasCta}
          views={views}
        />
        <OverlayCtaContainer>
          <OverlayContent
            className={overlayType}
            data-testid="overlayContainer"
          >
            <LeftSidebar>
              <CloseButton onClick={this.handleDismiss} />
            </LeftSidebar>
            <Content>
              {imageUrl && (
                <ImageWrapper>
                  <StyledImage imageUrl={imageUrl} isMobile={isMobile} />
                </ImageWrapper>
              )}
              <Text>
                {description}
              </Text>
            </Content>
          </OverlayContent>
          <OverlayCta
            broadcastId={broadcastId}
            broadcastKey={key}
            ctaRenderer={ctaRenderer}
            rawData={this.props.data}
          />
        </OverlayCtaContainer>
      </Container>
    );
  }
}

import React, { PropsWithChildren } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { IQuestsBlockData } from '../utils';
import { Section, SectionTitle } from 'components/admin-bridge/EditorModal/ModalComponents/Section';
import PageBlockSettings from 'components/admin-bridge/PageBlockSettings';
import usePageBlock from 'hooks/use-page-block';
import BackgroundColorOptions from 'components/admin-bridge/EditorModal/ModalComponents/BackgroundColorOptions';
import { IBlockRegion } from 'services/app/models/ILandingPageContent';
import { OptionRow } from 'components/admin-bridge/EditorModal';
import { BACKGROUND_TYPE_OPTIONS } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';
import usePageBlockBackgroundColorOptions from 'components/admin-bridge/EditorModal/ModalComponents/BackgroundColorOptions/use-page-block-background-color-options';
import Display from './Display';
import OverflowBehavior from './OverflowBehavior';
import ImageBackground from './ImageBackground';
import Spacing from './Spacing';
import { useAdminTranslation } from 'hooks/use-translation';
import { BlockPanelSettingsTitle, BlockPanelSettingsWrapper } from './styles';

interface IBlockSettingsProps {
  isOpen?: boolean;
  item: IQuestsBlockData;
  onChange: (item: IQuestsBlockData) => void;
  onClose?: () => void;
  region: IBlockRegion;
}

export type EditableStringPaths = (
  'label.show' |
  'title.show' |
  'description.show' |
  'overflowBehavior.desktop' |
  'overflowBehavior.mobile' |
  'questsPerRow' |
  'background.image.show' |
  'background.image.desktop.url' |
  'background.image.desktop.position' |
  'background.image.mobile.position' |
  'background.image.desktop.display' |
  'background.image.mobile.display' |
  'background.image.mobile.url' |
  'background.color' |
  'background.customColor' |
  'background.type' |
  'background.image.gradientMask' |
  'background.image.desktop.overlayOpacity' |
  'background.image.mobile.overlayOpacity' |
  'spacing'
);

export type GeneralProps = {
  handleChange: (stringPath: EditableStringPaths) => (value: unknown) => void
  item: IQuestsBlockData;
};

type WrapperProps = PropsWithChildren & { isOpen: boolean; onClose: () => void; region: IBlockRegion; };

const Wrapper: React.FC<WrapperProps> = ({ region, children, isOpen, onClose }) => {
  const { t } = useAdminTranslation();

  if (region === 'panel') {
    return (
      <BlockPanelSettingsWrapper>
        <BlockPanelSettingsTitle>{t('QUEST_SETTINGS')}</BlockPanelSettingsTitle>
        {children}
      </BlockPanelSettingsWrapper>
    );
  }

  return (
    <PageBlockSettings
      isOpen={isOpen}
      titleKey="QUEST_SETTINGS"
      onClose={onClose}
    >
      {children}
    </PageBlockSettings>
  );
};

const BlockSettings: React.FC<IBlockSettingsProps> = ({ region, item, onChange, isOpen = true, onClose }) => {
  const handleClose = React.useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <Wrapper
      isOpen={isOpen}
      region={region}
      onClose={handleClose}
    >
      {isOpen && <Content item={item} onChange={onChange} region={region} />}
    </Wrapper>
  );
};

const Content = ({ item, onChange, region }: Pick<IBlockSettingsProps, 'item' | 'onChange' | 'region'>) => {
  const { handleChange } = usePageBlock<EditableStringPaths, IQuestsBlockData>({
    item,
    onChange,
  });
  const { isCustomColor } = usePageBlockBackgroundColorOptions(item.background.color);

  const handleChangeBackgroundColor = React.useCallback((value: string) => {
    const newItem: IQuestsBlockData = structuredClone(item);

    newItem.background.color = value;
    if (isCustomColor(value)) {
      newItem.background.customColor = value;
    }

    onChange(newItem);
  }, [item, onChange, isCustomColor]);

  return (
    <Section gap={30}>
      <Display handleChange={handleChange} item={item} />
      {region !== 'panel' && (
        <>
          <Spacing handleChange={handleChange} item={item} />
          <OverflowBehavior handleChange={handleChange} item={item} />
        </>
      )}

      {/* Background */}
      <Section gap={16}>
        <TranslatedText component={SectionTitle} stringKey="ADMIN_DESIGN_TAB_BACKGROUND" />
        <OptionRow
          onChange={handleChange('background.type')}
          options={BACKGROUND_TYPE_OPTIONS}
          value={item.background.type}
        />
      </Section>
      {
        item.background.type === 'color' ? (
          <BackgroundColorOptions
            lastCustomColor={item.background.customColor}
            color={item.background.color}
            onChange={handleChangeBackgroundColor}
          />
        ) : <ImageBackground handleChange={handleChange} item={item} />
      }
    </Section>
  );
};

export default BlockSettings;

/* eslint react/destructuring-assignment: off */
/* eslint react/no-access-state-in-setstate: warn */
// TODO: Fix this ^^^
import React from 'react';
import PropTypes from 'prop-types';
import InsightsViewable from 'components/insights/InsightsViewable';
import TranslatedText from 'components/i18n/TranslatedText';
import moment from 'moment';
import ProfilePanelHeader from './ProfilePanelHeader';
import {
  Container,
  EditProfileButton,
  Name,
  Picture,
  PictureWrapper,
  ProfileContainer,
  MemberDateText,
} from './styles';

import InfoPage from './InfoPage';
import RewardsPage from './RewardsPage';
import EditPage from './EditPage';

export default class ProfilePanel extends React.Component {
  static propTypes = {
    createdDate: PropTypes.number.isRequired,
    data: PropTypes.shape({
      initialPage: PropTypes.string,
    }).isRequired,
    doc: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    hidden: PropTypes.bool.isRequired,
    onClosePanel: PropTypes.func.isRequired,
    onClosePanelMobile: PropTypes.func,
    onLogout: PropTypes.func.isRequired,
    trackEngage: PropTypes.func.isRequired,
    userAvatar: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
  };

  state = {
    editing: false,
    page: this.props.data?.initialPage === 'REWARDS' ? 'REWARDS' : 'INFO',
  };

  setPage = (page) => {
    const { trackEngage } = this.props;

    trackEngage({
      data: {
        kind: 'profile',
      },
    }, {
      action: page.toLowerCase(),
    });

    this.setState({ page });
  };

  renderPage = () => {
    const { doc, onLogout } = this.props;
    const { page } = this.state;
    switch (page) {
      case 'INFO':
        return <InfoPage onLogout={onLogout} panelDoc={doc} toggleEditPage={this.toggleEditPage} />;
      case 'REWARDS':
        return <RewardsPage />;
      default:
        return <InfoPage />;
    }
  };

  toggleEditPage = (shouldTrack = true) => {
    const { trackEngage } = this.props;

    if (shouldTrack) {
      trackEngage({
        data: {
          kind: 'profile',
        },
      }, {
        action: this.state.editing ? 'cancel_edit_profile' : 'edit_profile',
      });
    }

    this.setState({
      editing: !this.state.editing,
    });
  };

  render() {
    const {
      doc,
      hidden,
      userAvatar,
      userName,
      createdDate,
      onLogout,
      onClosePanel,
      onClosePanelMobile,
    } = this.props;
    const { editing } = this.state;
    return (
      <React.Fragment>
        <InsightsViewable doc={doc} kingdom="panel" visible={!hidden} />
        <ProfilePanelHeader
          onClose={onClosePanelMobile || onClosePanel}
          onLogout={onLogout}
        />
        {editing ? (
          <EditPage toggleEditPage={this.toggleEditPage} />
        ) : (
          <Container>
            <ProfileContainer>
              <EditProfileButton onClick={() => this.toggleEditPage(false)}>
                <TranslatedText stringKey="EDIT_PROFILE" />
              </EditProfileButton>
              <PictureWrapper>
                <Picture src={userAvatar} />
              </PictureWrapper>
              <Name>{userName}</Name>
              <MemberDateText>
                <TranslatedText stringKey="USER_PROFILE_MEMBER_SINCE" />{' '}
                {moment(createdDate).format('MMM D, YYYY')}
              </MemberDateText>
            </ProfileContainer>
            {this.renderPage()}
          </Container>
        )}
      </React.Fragment>
    );
  }
}

ProfilePanel.defaultProps = {
  onClosePanelMobile: null,
};

import Thumbnail from 'components/ui/Thumbnail';
import React from 'react';
import { StyledVideoLink } from '../styles';

interface IVideoPagePlayerBehaviorProps {
  thumbnail: string;
  videoId: string;
}

const VideoPage: React.FC<IVideoPagePlayerBehaviorProps> = ({ thumbnail, videoId }) => {
  return (
    <StyledVideoLink tag={videoId}>
      <Thumbnail src={thumbnail} renderPlayIcon={true} />
    </StyledVideoLink>
  );
};

export default VideoPage;

import { ImageDesign, Layout } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow/types';
import Icon from 'components/admin2/Icon';
import UploadZone from 'components/admin2/UploadZone';
import { HIGHLIGHT_PRIMARY, SURFACE_3, TEXT_300 } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';
import { desktopOnly, mobileOnly, THEME_PADDING_DESKTOP } from 'style/mixins';
import styled, { css } from 'styled-components';
import EditorWrapper from '../../../admin-bridge/EditorWrapper';

export const Wrapper = styled.div<{ image: string; layout: Layout; }>`
  min-width: calc((100% - ${THEME_PADDING_DESKTOP}) / 2);
  justify-content: center;
  ${({ image }) => image && css`display: flex;`};
  ${({ layout }) => layout !== 'stacked' && desktopOnly`max-width: calc(50% - 30px);`};
`;

export const StyledEditorWrapper = styled(EditorWrapper)<{ layout: Layout; }>`
  height: fit-content;
  width: 100%;
  border-radius: 10px;
`;

export const Image = styled.img<{ objectFit: ImageDesign; }>`
  object-fit: ${({ objectFit }) => objectFit};
  ${desktopOnly`aspect-ratio: 16 / 9;`};
  z-index: 0;
  border-radius: 10px;
  max-width: 100%;
  max-height: 70vh;
  margin-bottom: -4px;
  width: 100%;
`;

export const NoImage = styled.div`
  ${desktopOnly`aspect-ratio: 16 / 9;`};
  z-index: 0;
  border-radius: 10px;
  background-color: ${SURFACE_3};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  column-gap: 10px;

  ${ADMIN_TEXT_LABEL_M_MEDIUM}
  color: ${TEXT_300};
`;

export const AddIcon = styled(Icon)`
  & svg {
    width: 34px;
    height: 34px;

    & path {
      fill: ${HIGHLIGHT_PRIMARY}
    }
  }
`;

export const StyledUploadZone = styled(UploadZone).attrs(() => ({
  canDrop: true,
  dimensionRequirements: {
    pixelHeight: 540,
    pixelWidth: 950,
  },
  height: 177,
}))`padding: 0;`;

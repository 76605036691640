import React from 'react';
import { AddNewCardButton, PlusIcon } from './styles';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import { ChunkPaginationReturnType } from 'hooks/use-chunk-pagination';
import { OVERFLOW_BEHAVIOR } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';
import TranslatedText from 'components/i18n/TranslatedText';
import { IProduct } from 'services/shopify/models';
import { useShopifyBlockContext } from '../../ShopifyBlockContext';

interface IAddCardButtonProps {
  onClick: () => void;
  pagination: ChunkPaginationReturnType<IProduct>;
  products: IProduct[];
}

const AddCardButton: React.FC<IAddCardButtonProps> = ({ pagination, products, onClick }) => {
  const inEditMode = useSelector(isEditMode);
  const { overflowBehavior, productsPerRow, background, productView, isRenderingOnPanel } = useShopifyBlockContext();

  if (!inEditMode || productView !== 'multipleProducts') {
    return null;
  }

  const shouldRender = (
    (
      // if the overflow behavior is to stack
      overflowBehavior === OVERFLOW_BEHAVIOR.stack &&
      // and the number of cards is not a multiple of the number of images per row or is being rendered on a panel
      (products.length % productsPerRow !== 0 || isRenderingOnPanel)
    ) ||
    // or the number of cards on the page is less than the number of images per row
    products.length < productsPerRow ||
    // or there are no pages
    !pagination.pageCount
  );

  if (!shouldRender) {
    return null;
  }

  return (
    <AddNewCardButton
      backgroundColor={background.color}
      onClick={onClick}
    >
      <PlusIcon />
      <TranslatedText stringKey="ADMIN_LABEL_ADD_MORE" />
    </AddNewCardButton>
  );
};

export default AddCardButton;

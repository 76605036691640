import React, { createContext, FC, PropsWithChildren, useContext } from 'react';
import useImageGallery from './use-image-gallery';
import { defaultImageCard, IImageGalleryItem } from '../utils';
import { ImageCardProps } from '../ImageCard';
import { useAdminTranslation } from 'hooks/use-translation';
import { useSelector } from 'react-redux';
import { getDraftHomeId } from 'services/admin';

type TContext = ReturnType<typeof useImageGallery> & {
  addNewImageCard: () => void;
  editImageCard: (imageCard: ImageCardProps) => void;
  removeImageCard: (id: string) => void;
};

export const ImageGalleryContext = createContext<TContext>({} as any);

interface ImageGalleryProviderProps extends PropsWithChildren {
  item: IImageGalleryItem;
  onEdit: (item: IImageGalleryItem) => void;
}

export const ImageGalleryProvider: FC<ImageGalleryProviderProps> = ({ children, item, onEdit }) => {
  const { t } = useAdminTranslation();
  const result = useImageGallery(item);
  const homeId = useSelector(getDraftHomeId);

  const addNewImageCard = () => {
    onEdit({
      ...item,
      data: {
        ...item.data,
        imageCards: [
          ...(item.data.imageCards || []),
          defaultImageCard(t, homeId),
        ],
      },
    });
  };

  const editImageCard = (imageCard: ImageCardProps) => {
    const imageCards = (item.data.imageCards || []);
    const index = imageCards.findIndex(_ => _.id === imageCard.id);

    if (index === -1) {
      return;
    }

    const newImageCards = [
      ...imageCards.slice(0, index),
      imageCard,
      ...imageCards.slice(index + 1),
    ];

    onEdit({
      ...item,
      data: {
        ...item.data,
        imageCards: newImageCards,
      },
    });
  };

  const removeImageCard = (id: string) => {
    const imageCards = (item.data.imageCards || []);
    const index = imageCards.findIndex(_ => _.id === id);

    if (index === -1) {
      return;
    }

    const newImageCards = [
      ...imageCards.slice(0, index),
      ...imageCards.slice(index + 1),
    ];

    onEdit({
      ...item,
      data: {
        ...item.data,
        imageCards: newImageCards,
      },
    });
  };

  const value = React.useMemo(() => {
    return {
      ...result,
      addNewImageCard,
      editImageCard,
      removeImageCard,
    };
  }, [result, addNewImageCard, editImageCard, removeImageCard]);

  return (
    <ImageGalleryContext.Provider value={value}>
      {children}
    </ImageGalleryContext.Provider>
  );
};

export const useImageGalleryContext = () => useContext(ImageGalleryContext);

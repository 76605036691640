import { connect } from 'react-redux';
import { trackOverlay } from 'services/insights';
import { loginModal } from 'services/modals';
import { removeOverlay } from 'services/overlays';
import { isLoggedIn } from 'services/auth';

import RaffleStartingOverlay from './RaffleStartingOverlay';

const mapStateToProps = state => ({
  loggedIn: isLoggedIn(state),
});

const mapDispatchToProps = dispatch => ({
  onDismiss: key => dispatch(removeOverlay(key)),
  onLogInClick: () => dispatch(loginModal()),
  trackDismiss: (doc, extra) => dispatch(trackOverlay('dismiss', doc, extra)),
  trackEngage: (doc, extra) => dispatch(trackOverlay('engage', doc, extra)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RaffleStartingOverlay);

import styled, { css } from 'styled-components';
import { ACCENT_PRIMARY, SURFACE_1, SURFACE_2, SURFACE_3, SURFACE_4, SURFACE_5, SURFACE_6, TEXT_100, TEXT_200, TEXT_300 } from 'style/constants';
import { TEXT_BODY_S_REGULAR, TEXT_LABEL_L_BOLD, TEXT_LABEL_L_MEDIUM, TEXT_LABEL_M_BOLD, TEXT_LABEL_M_MEDIUM, TEXT_LABEL_S_MEDIUM, TEXT_LABEL_XS_MEDIUM, TEXT_TITLE_S } from 'style/design-system/textStyles';
import { SINGLE_LINE_ELLIPSIS, desktopOnly, mobileOnly } from 'style/mixins';
import { rgba } from 'colors';
import FormInput from 'components/ui/v2/Inputs';
import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';
import Expandable from 'components/ui/Expandable';
import TableBodyRow from './TableBodyRow';
import MobileModalBottomSheet from 'components/mobile/MobileModalBottomSheet';
import PageNavigation from 'components/ui/PageNavigation';

export const Wrapper = styled.div<{ short: boolean; }>`
  margin: 0px 40px 0 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${({ short }) => short && css`
    margin: 0;
    gap: 10px;
    position: relative;
  `};

  ${mobileOnly`
    gap: 20px;
    margin: 0;
  `}
`;

const TABLE_BODY_ROW_CSS = css<{ isRanked: boolean; short: boolean; }>`
  ${({ short }) => short ? TEXT_LABEL_S_MEDIUM : TEXT_TITLE_S}
  display: flex;
  width: 100%;
  background-color: ${SURFACE_2};
  min-height: ${({ short }) => short ? '48px' : '60px'};
  display: flex;
  align-items: center;

  ${({ isRanked, short }) => isRanked && css`
    & td:nth-child(1) {
      min-width: 76px;
      text-align: center;
      color: ${TEXT_300};
    }

    & td:nth-child(2) {
      display: flex;
      align-items: center;
      gap: 10px;
      min-width: 166px;
      color: ${TEXT_200};
      padding-left: 20px;
      ${TEXT_LABEL_M_MEDIUM}

      & img {
        width: 40px;
      }
    }

    & td:nth-child(3) {
      width: 100%;
      color: ${TEXT_100};
      padding-left: 20px;
    }

    ${short && css`
      & td:nth-child(1) {
        min-width: 24px;
        text-align: end;
        margin-right: 2px;
        margin-left: 5px;
      }

      & td:nth-child(2) {
        min-width: 36px;
        justify-content: center;
        padding-left: 0;
        ${TEXT_LABEL_S_MEDIUM}

        & img {
          width: 24px;
        }
      }

      & td:nth-child(3) {
        padding-left: 8px;
      }
    `};
  `};

  ${({ isRanked, short }) => !isRanked && css`
    & td {
      color: ${TEXT_100};
      &:nth-child(2) {
        padding-left: 20px;
        width: 100%;
      }

      &:not(:nth-child(2)) {
        min-width: 76px;
        max-width: 76px;
        display: flex;
        justify-content: center;
      }

      ${short && css`
        &:nth-child(2) {
          padding-left: 8px;
        }

        &:not(:nth-child(2)) {
          min-width: 36px;
        }

        &:nth-child(1) {
          min-width: 24px;
          justify-content: end;
          margin-right: 2px;
          margin-left: 5px;
        }
      `};
    }
  `};
`;

export const Table = styled.table<{ isRanked: boolean; short: boolean; }>`
  ${({ short }) => !short && 'z-index: 1'};

  thead {
    display: flex;
    width: 100%;
    height: 48px;
    background-color: ${SURFACE_3};
    border: 1px solid ${SURFACE_1};
    border-radius: 10px 10px 0 0;

    & tr {
      display: flex;
      width: 100%;

      & th {
        ${({ short }) => !short ? TEXT_LABEL_M_MEDIUM : TEXT_LABEL_S_MEDIUM}
        color: ${TEXT_300};
        display: flex;
        align-items: center;

        ${({ isRanked, short }) => isRanked && css`
          padding-left: 20px;

          &:nth-child(1) {
            min-width: 76px;
          }

          &:nth-child(2) {
            min-width: 166px;
          }

          &:nth-child(3) {
            width: 100%;
          }

          ${short && css`
            padding-left: 0;

            &:nth-child(1) {
              min-width: 24px;
              justify-content: end;
              margin-right: 2px;
              margin-left: 5px;
            }

            &:nth-child(2) {
              min-width: 36px;
              justify-content: center;
            }

            &:nth-child(3) {
              padding-left: 8px;
            }
          `};
        `};

        ${({ isRanked, short }) => !isRanked && css`
          &:nth-child(2) {
            padding-left: 20px;
            width: 100%;
          }

          &:not(:nth-child(2)) {
            min-width: 76px;
            display: flex;
            justify-content: center;
          }

          ${short && css`
            &:nth-child(2) {
              padding-left: 8px;
            }

            &:not(:nth-child(2)) {
              min-width: 36px;
            }

            &:nth-child(1) {
              min-width: 24px;
              justify-content: end;
              margin-right: 2px;
              margin-left: 5px;
            }
          `};
        `};
      }
    }
  }
`;

export const StyledTableBody = styled.tbody<{ short: boolean; }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ short }) => !short && css`max-height: 600px;`};
  overflow-y: auto;
  background-color: ${SURFACE_2};
  border-radius: 0 0 10px 10px;
`;

export const StyledTableBodyRow = styled.tr<{ isRanked: boolean; rank: number; short: boolean; }>`
  ${TABLE_BODY_ROW_CSS}
  &:last-child {
    border-radius: 0 0 10px 10px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid ${SURFACE_4};
  }

  ${({ rank }) => rank === 1 && css`
    background-color: ${props => rgba(ACCENT_PRIMARY(props), 0.3)};
    & td:nth-child(1) {
      color: ${ACCENT_PRIMARY};
    }
  `}

  ${({ rank }) => rank === 2 && css`
    background-color: ${props => rgba(ACCENT_PRIMARY(props), 0.2)};
    & td:nth-child(1) {
      color: ${ACCENT_PRIMARY};
    }
  `}

  ${({ rank }) => rank === 3 && css`
    background-color: ${props => rgba(ACCENT_PRIMARY(props), 0.15)};
    & td:nth-child(1) {
      color: ${ACCENT_PRIMARY};
    }
  `}
`;

export const CurrentUserPosition = styled(TableBodyRow)`
  ${TABLE_BODY_ROW_CSS}
  border-radius: 8px !important;
  background-color: ${ACCENT_PRIMARY};

  & td {
    &:nth-child(1) {
      color: ${TEXT_100};
    }
  }
`;

export const LastUpdatedText = styled.span<{ short: boolean; }>`
  ${({ short }) => short ? TEXT_LABEL_XS_MEDIUM : TEXT_LABEL_M_MEDIUM}
  ${({ short }) => !short && 'z-index: 1'};
  color: ${TEXT_300};
  text-align: right;
`;

export const OptionsToggle = styled.div`
  display: flex;
  border-radius: 6px;
  height: 44.48px;
  background-color: ${SURFACE_3};
  contain: content;
`;

export const OptionToggle = styled.button<{ active: boolean; }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 20px 10px 20px;
  ${TEXT_LABEL_M_MEDIUM}
  color: ${TEXT_300};
  ${SINGLE_LINE_ELLIPSIS}

  ${({ active }) => active && css`
    color: ${TEXT_100};
    background-color: ${SURFACE_6};
  `};

  &:hover {
    color: ${TEXT_100};
    background-color: ${SURFACE_6};
  }
`;

export const FieldSet = styled(FormInput.FieldSet)`
  background-color: ${SURFACE_3};
  border-color: transparent;
`;

export const FiltersWrapper = styled.div<{ columnGap: number; }>`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${({ columnGap }) => `${columnGap}px;`};
`;

export const Filter = styled.div<{ disabled?: boolean; flex?: number; short: boolean; }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${({ short }) => !short && 'min-width: 170px;'};

  ${({ flex }) => flex && css`
    flex: ${flex};
  `};

  ${({ disabled }) => disabled && css`
    opacity: 0.5;
    pointer-events: none;
  `};
`;

export const FiltersVisualization = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 5px;
`;

export const StyledMobileModalBottomSheet = styled(MobileModalBottomSheet)`
  background-color: ${SURFACE_1};
  & > div {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & > div {
      margin-bottom: 10px;
    }
  }
`;

export const BottomSheetSaveButton = styled(Button)`
  min-height: 52px;
  margin-top: 20px;
`;

export const GameTypeSpan = styled.span`
  ${TEXT_TITLE_S}
  color: ${TEXT_100};
`;

export const RemainingFilters = styled.span`
  ${TEXT_BODY_S_REGULAR}
  color: ${TEXT_200};
`;

export const FilterIcon = styled(Icon).attrs({
  name: 'textAlignmentCenter',
})`
  margin-right: 14px;
  & svg {
    width: 20px;
    height: 20px;
    & path {
      fill: ${ACCENT_PRIMARY};
    }
  }
`;

export const ArrowIcon = styled(Icon)`
  margin-left: 8px;
  & svg {
    width: 10px;
    height: 10px;
    & path {
      fill: ${ACCENT_PRIMARY};
    }
  }
`;

export const FiltersButton = styled(Button)`
  background-color: ${SURFACE_3};
  ${TEXT_BODY_S_REGULAR}
  color: ${TEXT_300} !important;

  &:hover {
    background-color: ${SURFACE_4};
    color: ${TEXT_100} !important;
    & svg path {
      fill: ${ACCENT_PRIMARY};
    }
  }
`;

export const StyledExpandable = styled(Expandable)`
  width: 100%;
  transition: overflow 1s;
  ${({ isExpanded }) => isExpanded && css`margin-top: 10px; overflow: unset;`};
  & > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export const LeaderboardTypes = styled.div<{ short: boolean; }>`
  height: 48px;
  display: flex;
  border-radius: 6px;
  contain: content;
  ${TEXT_LABEL_L_MEDIUM}

  ${({ short }) => short && css`
    height: 40px;
    ${TEXT_LABEL_M_BOLD}

    & button {
      ${TEXT_LABEL_M_BOLD}
      &:hover {
        ${TEXT_LABEL_M_BOLD}
      }
    }
  `};
`;

const ACTIVE_TYPE_CSS = css`
  ${TEXT_LABEL_L_BOLD}
  color: ${TEXT_100};
  background-color: ${SURFACE_2};
  border-color: ${ACCENT_PRIMARY};
  border-radius: 6px 6px 0 0;
`;

export const LeaderboardTypeButton = styled.button<{ active: boolean; }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 10px 20px 10px 20px;
  color: ${TEXT_300};
  cursor: pointer;
  border-bottom: 1px solid ${SURFACE_5};
  background-color: ${SURFACE_1};
  border-radius: 6px 6px 0 0;

  ${({ active }) => active && ACTIVE_TYPE_CSS};

  &:hover {
    ${ACTIVE_TYPE_CSS}
  }
`;

export const StyledPageNavigation = styled(PageNavigation)`
  height: 34px;
  margin-top: 20px;

  & svg {
    height: 30px !important;
    width: 30px !important;
  }
`;

export const NoResults = styled.div`
  ${desktopOnly`height: 600px;`}
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${SURFACE_2};
  border-radius: 0 0 8px 8px;

  ${TEXT_LABEL_M_MEDIUM}
  color: ${TEXT_100};

  ${mobileOnly`padding: 40px 0;`}
`;

import React from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { OptionRow } from 'components/admin-bridge/EditorModal';
import { TEXT_ALIGNMENT_OPTIONS } from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';
import usePageBlock from 'hooks/use-page-block';
import usePageBlockBackgroundColorOptions from 'components/admin-bridge/EditorModal/ModalComponents/BackgroundColorOptions/use-page-block-background-color-options';
import BackgroundColorOptions from 'components/admin-bridge/EditorModal/ModalComponents/BackgroundColorOptions';
import { Checkbox } from 'components/admin2/ui/Checkbox';
import { Section, SectionTitle, SubSectionTitle } from 'components/admin-bridge/EditorModal/ModalComponents/Section';
import { IEventCalendar } from '../utils';
import PageBlockSettings from 'components/admin-bridge/PageBlockSettings';

interface IBlockSettingsProps {
  isOpen: boolean;
  item: IEventCalendar;
  onChange: (item: IEventCalendar) => void;
  onClose: () => void;
}

type EditableStringPaths = 'textAlignment' | 'background.color' | 'title.show';

const BlockSettings = ({
  item,
  isOpen,
  onClose,
  onChange,
}: IBlockSettingsProps) => {
  const { isCustomColor } = usePageBlockBackgroundColorOptions(item.background.color);
  const { handleChange } = usePageBlock<EditableStringPaths, IEventCalendar>({
    item,
    onChange,
  });

  const handleChangeBackgroundColor = React.useCallback((value: string) => {
    const newItem: IEventCalendar = structuredClone(item);

    newItem.background.color = value;
    if (isCustomColor(value)) {
      newItem.background.customColor = value;
    }

    onChange(newItem);
  }, [item, onChange, isCustomColor]);

  return (
    <PageBlockSettings isOpen={isOpen} titleKey="ADMIN_LABEL_BLOCK_SETTINGS" onClose={onClose}>
      <Section gap={20}>
        <Section gap={24}>
          <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_DISPLAY" />
          <Section gap={15}>
            <Checkbox
              checked={item.title.show}
              labelKey="ADMIN_LABEL_TITLE"
              onCheckedChange={handleChange('title.show')}
            />
          </Section>
        </Section>
        <Section gap={16}>
          <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_ALIGNMENT" />
          <OptionRow
            options={TEXT_ALIGNMENT_OPTIONS}
            value={item.textAlignment}
            onChange={handleChange('textAlignment')}
            wrapText={true}
          />
        </Section>
        <Section gap={16}>
          <TranslatedText component={SubSectionTitle} stringKey="ADMIN_LABEL_BACKGROUND_COLOR" />
          <BackgroundColorOptions
            lastCustomColor={item.background.customColor}
            color={item.background.color}
            onChange={handleChangeBackgroundColor}
          />
        </Section>
      </Section>
    </PageBlockSettings>
  );
};

export default BlockSettings;
